import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';
import {GroupiTheme} from "../../../customTheme";
import {useTheme} from "@material-ui/core";

const Plus = (props: SvgIconProps) => {
  const theme: GroupiTheme = useTheme();

  return (
    <SvgIcon
      htmlColor={theme.palette.common.white}
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 16 16">
      <rect
        x="7"
        y="2"
        width="2"
        height="12"
        rx="1"
      />
      <rect
        x="14"
        y="7"
        width="2"
        height="12"
        rx="1"
        transform="rotate(90 14 7)"
      />
    </SvgIcon>
  );
};

export default Plus;
