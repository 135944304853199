import React, {useEffect, useState} from 'react';
import SvgIcon, {SvgIconProps} from "@material-ui/core/SvgIcon";

const RateStar = (props: SvgIconProps & IFullness) => {
  const {fullness} = props;
  const [firstStar, setFirstStar] = useState('#FFFFFF');
  const [secondStar, setSecondStar] = useState('#FFFFFF');

  useEffect(() => {
    switch (fullness) {
    case 'full':
      setFirstStar('#FFBF00');
      setSecondStar('#FFBF00');
      break;

    case 'half':
      setFirstStar('#FFFFFF');
      setSecondStar('#FFBF00');
      break;

    case 'empty':
      setFirstStar('#FFFFFF');
      setSecondStar('#FFFFFF');
      break;

    default:
      break;
    }
  }, [fullness]);

  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 16 16">
      <path
        d="M2.38022 6.92824L2.72579 6.57373L2.38022 6.92824L4.60987 9.10161L4.08353 12.1705C3.97144 12.824 4.65738 13.3224 5.24429 13.0138L8.00028 11.5649L10.7563 13.0138C11.3432 13.3224 12.0291 12.824 11.917 12.1705L11.3907 9.10161L13.6203 6.92824C14.0952 6.46541 13.8331 5.65903 13.177 5.56368L10.0957 5.11595L8.71767 2.32381L8.26931 2.5451L8.71767 2.32381C8.42422 1.72922 7.57635 1.72922 7.2829 2.32381L5.9049 5.11595L2.8236 5.56368C2.16742 5.65903 1.90541 6.46541 2.38022 6.92824Z"
        stroke="#FFBF00"
        fill={firstStar}
      />
      <path
        d="M4.47357 12.8541L8.00028 11V2L6.23693 5.57295L2.29395 6.1459L5.14711 8.92705L4.47357 12.8541Z"
        fill={secondStar}
      />
    </SvgIcon>
  );
};

export default RateStar;

interface IFullness {
  fullness: 'full' | 'half' | 'empty'
}