import {all, call} from 'redux-saga/effects';
import {fetchViews} from "./fetchViews";
import {fetchFavorites} from "./fetchFavorites";
import {fetchReservations} from "./fetchReservations";

export function* fetchStatistics(offerID: number){
  yield all([
    call(fetchViews, offerID),
    call(fetchFavorites, offerID),
    call(fetchReservations, offerID)
  ]);
}
