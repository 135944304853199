import React from "react";
export const LocaleContext = React.createContext<{locale: string, setLocale: (arg: string) => void}>({
  locale: 'en',
  setLocale: ()=>{}
});

export const LocaleContextProvider = (props: React.PropsWithChildren<any>) => {
  const [locale, setLocale] = React.useState("en");

  return (
    <LocaleContext.Provider value={{locale, setLocale}}>
      {props.children}
    </LocaleContext.Provider>
  );
};