import React from 'react';
import SvgIcon, {SvgIconProps} from "@material-ui/core/SvgIcon";

const Locate = (props: SvgIconProps) => {
  const {htmlColor} = props;

  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9C16 11.9306 14.7376 14.5286 13.3661 16.4716C12.8914 17.144 12.4161 17.7201 12 18.1837C11.5839 17.7201 11.1086 17.144 10.6339 16.4716C9.26238 14.5286 8 11.9306 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM18 9C18 16 12 21 12 21C12 21 6 16 6 9C6 5.68629 8.68629 3 12 3C15.3137 3 18 5.68629 18 9ZM12 11C13.1046 11 14 10.1046 14 9C14 7.89543 13.1046 7 12 7C10.8954 7 10 7.89543 10 9C10 10.1046 10.8954 11 12 11Z"
        fill={htmlColor}
      />
    </SvgIcon>
  );
};

export default Locate;