import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTabPanel-root': {
      padding: '30px 0 0 0'
    },
    '& .MuiTabs-root': {
      width: 'fit-content',
      '& .MuiTab-root': {
        minWidth: 140,
        [breakpoints.down(575)]: {
          minWidth: 'unset'
        }
      }
    },
    '& .MuiTabs-flexContainer': {
      borderBottom: '1px solid',
      borderBottomColor: '#C4C4C4'
    },
    '& .MuiTab-wrapper': {
      fontFamily: 'Source Sans Pro',
      fontWeight: '600',
      fontSize: 16,
      lineHeight: '21px'
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#0080FF'
    }
  },
  topBlock: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});
