import {GroupiTheme} from "../../customTheme";
import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  list: {
    overflow: 'auto',
    listStyle: 'none',
    padding: '6px 0 0',
    margin: 0,
    borderBottomColor: theme.palette.common.gray88,
    borderBottom: '1px solid',
    [breakpoints.down(1200)]: {
      borderBottom: 'none'
    },
    '& li': {
      display: 'flex',
      alignItems: 'center',
      height: 40,
      padding: '0 12px',
      cursor: 'pointer',
      backgroundColor: theme.palette.common.white,
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover': {
        backgroundColor: theme.palette.common.gray95
      },
      [breakpoints.down(1200)]: {
        padding: '0 16px'
      },
      [breakpoints.down(600)]: {
        padding: '0 12px'
      },
      '& p': {
        maxWidth: 310,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }
    },
    '& .MuiSvgIcon-root': {
      width: 8,
      height: 8,
      marginRight: 12
    }
  },
  currentPlace: {
    position: 'relative'
  },
  currentPlaceMarker: {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: 4,
    height: 40,
    background: theme.palette.common.blue50
  },
  emptyList: {
    padding: '14px 12px 0',
    '& p': {
      color: theme.palette.common.gray75
    }
  },
  search: {
    padding: '16px 12px',
    borderBottomColor: theme.palette.common.gray88,
    borderBottom: '1px solid',
    '& .MuiSvgIcon-root': {
      marginTop: 10,
      marginRight: -5
    }
  }
});