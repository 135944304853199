import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    margin: '0 0 20px',
    [breakpoints.down(575)]: {},
    '& .MuiCheckbox-root': {
      marginRight: 10,
      color: theme.palette.common.gray88,
      '&.Mui-checked': {
        color: theme.palette.common.blue50
      },
      '&.Mui-disabled': {
        color: theme.palette.common.gray88,
        opacity: 1
      }
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Source Sans Pro',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      color: theme.palette.common.black
    }
  }
});
