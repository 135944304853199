//Core
import React from 'react';

//Helpers
import {ListStatus} from "../../../../../setup/typedefs";
import {book} from "../../../../../routes/book";

//Components
import ListOfVouchers from "../ListOfVouchers";

const ActiveVouchers = () => {
  return (
    <ListOfVouchers
      status={ListStatus.Active}
      paginationPath={book.activeVouchers}
      title={'Active'}
    />
  );
};

export default ActiveVouchers;