export const types = {
  FETCH_WITH_REFRESH_TOKEN: '@AUTH/FETCH_WITH_REFRESH_TOKEN',
  TERMS_AGREE: '@AUTH/TERMS_AGREE',
  AUTHENTICATE: '@AUTH/AUTHENTICATE',
  SIGN_UP: '@AUTH/SIGN_UP',
  SIGN_IN: '@AUTH/SIGN_IN',
  RESEND_ON_EMAIL: '@AUTH/RESEND_ON_EMAIL',
  VERIFY_EMAIL: '@AUTH/VERIFY_EMAIL',
  RESET_PASSWORD: '@AUTH/RESET_PASSWORD',
  CHANGE_PASSWORD: '@AUTH/CHANGE_PASSWORD',
  FILL_TOKENS: '@AUTH/FILL_TOKENS',
  LOGOUT: '@AUTH/LOGOUT',
  CLEAR: '@AUTH/CLEAR'
};
