import React from 'react';
import {styles} from './styles';
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const useStyle = makeStyles(styles);

const RevenueTable = ({tableInfo}: any) => {
  const classes = useStyle();

  return (
    <div className={classes.table}>
      <div className={classes.tableHead}>
        <div className={classes.headerText}>
          <Typography variant={'subtitle2'}>
            Voucher
          </Typography>
        </div>
        <div className={classes.headerRevenue}>
          <Typography variant={'subtitle2'}>
            Revenue
          </Typography>
        </div>
      </div>
      <div className={classes.tableBody}>
        {tableInfo && tableInfo.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={classes.bodyRow}>
              <Typography
                className={classes.bodyRowCell}
                variant={'body2'}>
                {item.text}
              </Typography>
              <Typography
                className={classes.bodyRowCell}
                variant={'body2'}>
                {item.revenue}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RevenueTable;