import {RootState} from "../../setup/typedefs";
import {createSelector} from "reselect";

export const optionsSelector = (state: RootState) => state.options;

export const getAllTags = createSelector([optionsSelector], (result) => {
  return result.allTags;
});

export const getCountries = createSelector([optionsSelector], (result) => {
  return result.countries;
});

export const getBusinessTypes = createSelector([optionsSelector], (result) => {
  return result.businessTypes;
});

export const getUsePeriod = createSelector([optionsSelector], (result) => {
  return result.usePeriod;
});
