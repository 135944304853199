import {useEffect, useState} from "react";
import {actionsWithFewFiles, showArrayDiff} from "../../../../../utils/helpers";
import {ActionWithFile} from "../../../../../setup/typedefs";

export const useTrackUpdates = (current: any, initial: any) => {
  const [updateTags, setUpdateTags] = useState(false);
  const [actionWithPhotos, setActionWithPhotos] = useState<ActionWithFile>('nothing');
  const [actionWithDocuments, setActionWithDocuments] = useState<ActionWithFile>('nothing');

  //tags
  useEffect(() => {
    if (current?.tags && initial?.tags) {
      setUpdateTags(!!showArrayDiff(initial.tags, current.tags).length);
    }
  }, [current, initial]);

  //photos
  useEffect(() => {
    setActionWithPhotos(actionsWithFewFiles(initial?.photos, current?.photos));
  },[initial, current]);

  //documents
  useEffect(() => {
    const action = actionsWithFewFiles(initial?.documents, current?.documents);
    setActionWithDocuments(action);
  },[initial, current]);

  return {
    updateTags,
    actionWithPhotos,
    actionWithDocuments
  };
};
