import React from 'react';
import {styles} from './styles';
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(styles);

const RevenueCard = ({cardInfo}: any) => {
  const classes = useStyles();

  return (
    <div className={classes.cardsWrapper}>
      {cardInfo && (
        cardInfo.map((item: any, index: any) => {
          return (
            <div
              key={index}
              className={classes.card}>
              <Typography variant={'h1'}>
                {item.sum}
                <span>
                  USD
                </span>
              </Typography>
              <Typography variant={'body2'}>
                {item.text}
              </Typography>
            </div>
          );
        })
      )}
    </div>
  );
};

export default RevenueCard;