import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  rootWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 0%',
    background: theme.palette.common.gradient,
    [breakpoints.down(1500)]: {
      padding: '0 30px'
    },
    [breakpoints.down(1200)]: {
      padding: '60px 15px 58px',
      alignItems: 'baseline',
      flex: 'unset'
    },
    [breakpoints.down(600)]: {
      padding: '40px 15px 58px'
    }
  },
  innerWrapper: {
    maxWidth: 619,
    marginLeft: 70,
    marginTop: -104,
    color: theme.palette.common.white,
    [breakpoints.down(1500)]: {
      marginLeft: 'unset',
      marginTop: 'unset'
    },
    [breakpoints.down(1200)]: {
      marginLeft: 0,
      paddingRight: 0,
      boxSizing: 'border-box'
    },
    [breakpoints.down(600)]: {
      margin: 0,
      padding: 0,
      width: '100%',
      maxWidth: 490
    }
  },
  decoration: {
    position: 'relative',
    marginTop: 37,
    marginBottom: 20,
    height: 160,
    width: '100%'
  },
  airplane: {
    position: 'absolute',
    right: -55,
    top: -64,
    zIndex: 1,
    [breakpoints.down(1400)]: {
      right: 0
    },
    [breakpoints.down(1200)]: {
      right: -55
    },
    [breakpoints.down(800)]: {
      right: 'unset',
      left: 195,
      top: -45
    }
  },
  letter: {
    position: 'absolute',
    left: -20,
    bottom: 4,
    zIndex: 1
  },
  pathDesktop: {
    position: 'absolute',
    left: 120,
    bottom: 28,
    zIndex: 0,
    [breakpoints.down(1400)]: {
      left: 100
    },
    [breakpoints.down(1200)]: {
      left: 120
    },
    [breakpoints.down(800)]: {
      display: 'none'
    }
  },
  pathMobile: {
    position: 'absolute',
    display: 'none',
    left: 110,
    [breakpoints.down(800)]: {
      display: 'block'
    }
  },
  textWrapper: {
    marginTop: 30,
    width: 500,
    [breakpoints.down(1200)]: {
      marginTop: 38
    },
    [breakpoints.down(800)]: {
      width: '100%'
    }
  },
  hintWrapper: {
    marginTop: 40
  },
  inputEmailWrapper: {
    marginTop: 24
  }
});
