import {types} from "./types";
import {AnyAction} from 'redux';
import {Place, PlaceFromGoogle} from "./typedefs";
import {Nullable} from "../../setup/typedefs";

export type PlacesState = {
  place: Nullable<Place>,
  places: Place[],
  placePreview: Nullable<Place>,
  placeFromGoogle: Nullable<PlaceFromGoogle>
}

const initialState: PlacesState = {
  place: null,
  places: [],
  placePreview: null,
  placeFromGoogle: null
};

export const placesReducer = (state= initialState, action: AnyAction) => {
  switch (action.type) {
  case types.FILL_PLACES:
    return {
      ...state,
      places: action.payload
    };

  case types.FILL_PLACE:
    return {
      ...state,
      place: action.payload
    };

  case types.FILL_PREVIEW:
    return {
      ...state,
      placePreview: action.payload
    };

  case types.CLEAR_GOOGLE_PLACE:
    return {
      ...state,
      placeFromGoogle: initialState.placeFromGoogle
    };

  case types.CLEAR_PREVIEW:
    return {
      ...state,
      placePreview: initialState.placePreview
    };

  case types.FILL_PLACE_FROM_GOOGLE:
    return {
      ...state,
      placeFromGoogle: action.payload
    };

  case types.CLEAR:
    return initialState;

  default:
    return state;
  }
};
