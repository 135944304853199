export const questions = [
  {
    question: 'Section 1',
    answer: 'It is a movement . It aims to solve the group outing dilemma by providing users with an easy, hassle free platform to decide and organize any group event. There are 5 tabs on the bottom of the screen which simply helps you ask your friends where they want to go, what they would like to eat or drink, what they could afford along other criteria.'
  },
  {
    question: 'Section 2',
    answer: 'Forget about the hassle of organizing group activities! Groupi helps you to brainstorm ideas and vote for venues based on your interest. It is very simple to use and runs smoothly even on an outdated smartphone or tablet.'
  },
  {
    question: 'Section 3',
    answer: 'Who’s up for brunch, drinks, or dinner? Easy to use, Groupi will make planning your group outing a breeze. Voting on favorite locations is fun and simple. See what’s happening near you and find the best venue for your group.'
  },
  {
    question: 'Section 4',
    answer: 'Come up with ideas for fun things to do with your friends. Groupi will help you choose the best venues that appeal to everyone.'
  }
];
