import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {book} from "../../routes/book";
import CreateOffer from "./pages/CreateOffer";
import SingleOffer from "./pages/SingleOffer";
import EditOffer from "./pages/EditOffer";
import PreviewOffers from "./pages/PreviewOffer";
import ActiveOffers from "./pages/ListOfOffers/ActiveOffers";
import ExpiredOffers from "./pages/ListOfOffers/ExpiredOffers";
import PendingOffers from "./pages/ListOfOffers/PendingOffers";
import RejectedOffers from "./pages/ListOfOffers/RejectedOffers";
import DraftOffers from "./pages/ListOfOffers/DraftOffers";
import RecreateOffer from "./pages/RecreateOffer";

const OffersRouter = () => {
  return (
    <Switch>
      <Route path={book.activeDeals + `/:page`}>
        <ActiveOffers/>
      </Route>
      <Route path={book.expiredDeals + `/:page`}>
        <ExpiredOffers/>
      </Route>
      <Route path={book.pendingDeals + `/:page`}>
        <PendingOffers/>
      </Route>
      <Route path={book.rejectedDeals + `/:page`}>
        <RejectedOffers/>
      </Route>
      <Route path={book.draftDeals + `/:page`}>
        <DraftOffers/>
      </Route>
      <Route path={book.recreateDeal + `/:id`}>
        <RecreateOffer/>
      </Route>
      <Route path={book.createDeals}>
        <CreateOffer/>
      </Route>
      <Route path={book.editDeal + `/:id`}>
        <EditOffer/>
      </Route>
      <Route path={book.singleDeal + `/:id`}>
        <SingleOffer/>
      </Route>
      <Route path={book.previewDeals}>
        <PreviewOffers/>
      </Route>
      <Redirect to={book.activeDeals + `/1`}/>
    </Switch>
  );
};

export default OffersRouter;