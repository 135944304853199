//Core
import React from 'react';

//Helper
import {useVouchersLists} from "./useVouchersLists";
import {Voucher} from "../../../../bus/vouchers/typedefs";

//Styles
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import SingleVoucherInList from "../../components/SingleVoucherInList";
import ContainerHead from "../../components/ContainerHead";
import TableHeader from "../../components/TableHeader";
import RenderPagination from "../../../PageContainer/components/RenderPagination";

const useStyles = makeStyles(styles);

const ListOfVouchers: React.FC<any> = ({title, paginationPath, status}) => {
  const classes = useStyles();
  const {meta, list} = useVouchersLists(paginationPath, status);

  return (
    <>
      <div className={classes.container}>
        <ContainerHead title={title}/>
        <TableHeader/>
        <div className={classes.tableBody}>
          {list.length ? (
            list.map((item: Voucher, index: number) => {
              return (
                <SingleVoucherInList
                  key={index}
                  item={item}
                />
              );
            })
          ) : (
            <Typography
              className={classes.emptyList}
              variant={'body1'}>
              You have not any vouchers right now...
            </Typography>
          )}
        </div>
      </div>
      {!!meta?.pages.last && !!list.length && (
        <RenderPagination
          path={paginationPath}
          count={meta?.pages.last}
        />
      )}
    </>
  );
};

export default ListOfVouchers;