import {call, put, select} from "redux-saga/effects";
import {RootState} from "../../../../setup/typedefs";
import jwtDecode from "jwt-decode";
import {authActions} from "../../actions";
import {push} from "connected-react-router";
import {book} from "../../../../routes/book";
import {fetchUpdateToken} from "../../../utils/fetching";
import {handleErrors} from "../../../../utils/handleErrors";

export function* refreshToken(){
  const {token: tokenAccess, refreshToken} = yield select((state: RootState) => state.auth);

  if (tokenAccess) {
    const { exp } = yield call(jwtDecode, tokenAccess);
    if (Math.round(Date.now() / 1000) >= exp) {
      try {
        const response = yield call(fetchUpdateToken, refreshToken);

        if (response.status !== 200) {
          yield call(handleErrors, response);
          throw new Error(response);
        }

        const {data} = yield call([response, 'json']);

        yield put(authActions.fillTokens({
          token: data.token,
          refreshToken: data.refreshToken
        }));

        return;
      } catch (e) {
        yield put(authActions.clear());
        yield put(push(book.signIn));
        console.log(e);
      }
    }
  } else {
    yield put(authActions.clear());
    yield put(push(book.signIn));
  }
}
