import { all, call, takeEvery } from 'redux-saga/effects';
import {types} from "../types";
import {popup, toast} from "./workers";

function * watchPopup() {
  yield takeEvery(types.POPUP, popup);
}

function * watchToast() {
  yield takeEvery(types.TOAST, toast);
}

export function* watchUi(){
  yield all([
    call(watchPopup),
    call(watchToast)
  ]);
}