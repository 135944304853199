import React from 'react';
import {SvgIcon} from "@material-ui/core";
import {SvgIconProps} from "@material-ui/core/SvgIcon";

const AppStore = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 141.5 46">
      <path
        d="M132.162 3.39297e-05H11.4416C11.0016 3.39297e-05 10.5668 3.39298e-05 10.1279 0.00243393C9.76056 0.00483393 9.39611 0.0118059 9.0252 0.0176739C8.21942 0.0271561 7.41553 0.0980455 6.62052 0.229726C5.82663 0.364273 5.05761 0.617933 4.33946 0.982126C3.6222 1.3494 2.96681 1.82664 2.39708 2.39652C1.82436 2.9648 1.34696 3.62165 0.98322 4.34183C0.61848 5.06056 0.365569 5.83076 0.23322 6.62581C0.0996131 7.41986 0.0277181 8.22306 0.01818 9.02821C0.007044 9.39613 0.005868 9.76527 0 10.1332V37.8704C0.005868 38.243 0.007044 38.6039 0.01818 38.9766C0.027721 39.7817 0.0996161 40.5849 0.23322 41.3789C0.365203 42.1744 0.61813 42.9451 0.98322 43.6641C1.34679 44.3819 1.82426 45.0361 2.39708 45.6012C2.96465 46.1737 3.62046 46.6512 4.33946 47.0157C5.0576 47.3808 5.82654 47.636 6.62052 47.7726C7.41566 47.9033 8.21947 47.9742 9.0252 47.9848C9.39611 47.993 9.76056 47.9976 10.1279 47.9976C10.5668 48 11.0016 48 11.4416 48H132.162C132.593 48 133.031 48 133.463 47.9976C133.828 47.9976 134.203 47.993 134.569 47.9848C135.373 47.9747 136.175 47.9038 136.969 47.7726C137.765 47.6351 138.537 47.3799 139.259 47.0157C139.977 46.651 140.632 46.1735 141.199 45.6012C141.771 45.0339 142.249 44.3802 142.617 43.6641C142.98 42.9445 143.23 42.174 143.36 41.3789C143.494 40.5848 143.568 39.7818 143.583 38.9766C143.588 38.6039 143.588 38.243 143.588 37.8704C143.597 37.4344 143.597 37.0008 143.597 36.5578V11.4434C143.597 11.0039 143.597 10.568 143.588 10.1332C143.588 9.76527 143.588 9.39613 143.583 9.02816C143.568 8.22294 143.494 7.41992 143.36 6.62576C143.23 5.83117 142.979 5.06104 142.617 4.34178C141.876 2.89827 140.702 1.72326 139.259 0.982018C138.537 0.618715 137.765 0.365122 136.969 0.229618C136.175 0.0973573 135.373 0.0264432 134.569 0.0175059C134.203 0.0116499 133.828 0.00461793 133.463 0.00227793C133.031 -0.00012207 132.593 -0.00012207 132.162 -0.00012207V3.39297e-05Z"
        fill="#A6A6A6"
      />
      <path
        d="M10.134 46.95C9.76842 46.95 9.41164 46.9453 9.04889 46.9371C8.29743 46.9274 7.54772 46.8619 6.80592 46.7414C6.11423 46.6223 5.44416 46.4007 4.81783 46.084C4.19724 45.7698 3.63121 45.3579 3.14143 44.864C2.64456 44.376 2.23089 43.8099 1.91682 43.1882C1.59929 42.5625 1.37953 41.8919 1.26522 41.1996C1.14177 40.4557 1.07498 39.7036 1.06542 38.9496C1.05781 38.6965 1.04785 37.8539 1.04785 37.8539V10.1332C1.04785 10.1332 1.05846 9.3035 1.06548 9.05975C1.07463 8.30697 1.14103 7.55597 1.26412 6.81326C1.37864 6.11909 1.59856 5.44649 1.91627 4.81874C2.22919 4.19752 2.64056 3.63102 3.13444 3.1412C3.62776 2.64673 4.1956 2.23271 4.81724 1.91424C5.44214 1.5985 6.11093 1.37847 6.80123 1.26151C7.54546 1.13979 8.29777 1.07398 9.05184 1.06464L10.1346 1.04999H133.457L134.553 1.06523C135.3 1.07411 136.045 1.13933 136.783 1.26034C137.48 1.37876 138.156 1.60033 138.788 1.91776C140.033 2.55957 141.046 3.57498 141.686 4.82167C141.998 5.44508 142.215 6.1122 142.328 6.80038C142.453 7.54918 142.522 8.30607 142.537 9.06503C142.54 9.40487 142.54 9.76991 142.54 10.1332C142.55 10.5832 142.55 11.0115 142.55 11.4433V36.5578C142.55 36.9937 142.55 37.4191 142.54 37.848C142.54 38.2382 142.54 38.5957 142.535 38.9636C142.521 39.709 142.453 40.4524 142.33 41.1878C142.218 41.8851 142 42.561 141.682 43.1918C141.366 43.8067 140.955 44.3679 140.464 44.8547C139.973 45.3512 139.406 45.7655 138.784 46.0817C138.154 46.4009 137.479 46.6233 136.783 46.7414C136.041 46.8626 135.291 46.928 134.54 46.9371C134.188 46.9453 133.82 46.95 133.463 46.95L132.162 46.9524L10.134 46.95Z"
        fill="black"
      />
      <path
        d="M29.7226 24.3608C29.7355 23.3592 30.0015 22.3771 30.496 21.506C30.9904 20.6348 31.6972 19.9029 32.5505 19.3783C32.0084 18.6041 31.2932 17.967 30.4619 17.5175C29.6305 17.0681 28.7057 16.8187 27.7611 16.7891C25.746 16.5776 23.7924 17.9949 22.7656 17.9949C21.7189 17.9949 20.1379 16.8101 18.4354 16.8451C17.3342 16.8807 16.261 17.2009 15.3203 17.7746C14.3797 18.3483 13.6037 19.1558 13.0679 20.1186C10.7471 24.1367 12.4782 30.0419 14.7013 33.2899C15.8136 34.8803 17.1135 36.6568 18.8145 36.5938C20.479 36.5248 21.1006 35.5325 23.1098 35.5325C25.1003 35.5325 25.6835 36.5938 27.419 36.5538C29.205 36.5248 30.3303 34.9563 31.4036 33.3508C32.2028 32.2176 32.8177 30.9651 33.2257 29.6398C32.188 29.2009 31.3025 28.4663 30.6796 27.5275C30.0566 26.5888 29.7238 25.4874 29.7226 24.3608Z"
        fill="white"
      />
      <path
        d="M26.4447 14.653C27.4185 13.484 27.8983 11.9814 27.7821 10.4643C26.2943 10.6206 24.92 11.3316 23.933 12.4558C23.4505 13.005 23.0809 13.6439 22.8454 14.3361C22.6099 15.0282 22.5131 15.7599 22.5606 16.4895C23.3047 16.4972 24.0409 16.3359 24.7137 16.0178C25.3865 15.6997 25.9784 15.233 26.4447 14.653Z"
        fill="white"
      />
      <path
        d="M50.7625 32.5676H45.0824L43.7184 36.5953H41.3125L46.6926 21.6937H49.1922L54.5723 36.5953H52.1254L50.7625 32.5676ZM45.6707 30.709H50.1731L47.9536 24.1723H47.8915L45.6707 30.709Z"
        fill="white"
      />
      <path
        d="M66.1911 31.1637C66.1911 34.5399 64.384 36.709 61.6571 36.709C60.9663 36.7451 60.2793 36.586 59.6747 36.2498C59.0702 35.9137 58.5725 35.414 58.2387 34.8082H58.1871V40.1894H55.957V25.7309H58.1157V27.5379H58.1567C58.5058 26.9349 59.0119 26.4379 59.6211 26.0997C60.2302 25.7615 60.9197 25.5947 61.6161 25.6172C64.3734 25.6172 66.1911 27.7969 66.1911 31.1637ZM63.8989 31.1637C63.8989 28.9641 62.7621 27.518 61.0278 27.518C59.3239 27.518 58.1778 28.9945 58.1778 31.1637C58.1778 33.3527 59.3239 34.8188 61.0278 34.8188C62.7621 34.8188 63.8989 33.3832 63.8989 31.1637Z"
        fill="white"
      />
      <path
        d="M78.1491 31.1637C78.1491 34.5398 76.342 36.709 73.6151 36.709C72.9243 36.7451 72.2373 36.586 71.6327 36.2498C71.0282 35.9136 70.5305 35.414 70.1967 34.8082H70.1451V40.1894H67.915V25.7308H70.0736V27.5379H70.1146C70.4638 26.9349 70.9699 26.4379 71.579 26.0997C72.1882 25.7615 72.8776 25.5947 73.574 25.6172C76.3315 25.6172 78.1491 27.7969 78.1491 31.1637ZM75.8569 31.1637C75.8569 28.9641 74.7201 27.518 72.9858 27.518C71.2819 27.518 70.1358 28.9945 70.1358 31.1637C70.1358 33.3527 71.2819 34.8187 72.9858 34.8187C74.7202 34.8187 75.8569 33.3832 75.8569 31.1637H75.8569Z"
        fill="white"
      />
      <path
        d="M86.0524 32.4434C86.2176 33.9211 87.6532 34.8914 89.6149 34.8914C91.4946 34.8914 92.8469 33.9211 92.8469 32.5886C92.8469 31.432 92.0313 30.7394 90.1 30.2648L88.1688 29.7996C85.4325 29.1386 84.1621 27.8589 84.1621 25.7824C84.1621 23.2113 86.4028 21.4453 89.5844 21.4453C92.7332 21.4453 94.8918 23.2113 94.9645 25.7824H92.7133C92.5785 24.2953 91.3492 23.3976 89.5527 23.3976C87.7563 23.3976 86.527 24.3058 86.527 25.6277C86.527 26.6812 87.3121 27.3011 89.2328 27.7757L90.8746 28.1788C93.932 28.9019 95.2023 30.13 95.2023 32.3097C95.2023 35.0976 92.9816 36.8437 89.4496 36.8437C86.1449 36.8437 83.9137 35.1386 83.7695 32.4433L86.0524 32.4434Z"
        fill="white"
      />
      <path
        d="M100.016 23.1597V25.7308H102.082V27.4968H100.016V33.4863C100.016 34.4168 100.429 34.8504 101.337 34.8504C101.583 34.8461 101.828 34.8289 102.071 34.7988V36.5542C101.663 36.6305 101.248 36.6651 100.832 36.6574C98.6328 36.6574 97.775 35.8312 97.775 33.7241V27.4968H96.1953V25.7308H97.775V23.1597H100.016Z"
        fill="white"
      />
      <path
        d="M103.277 31.1637C103.277 27.7453 105.291 25.5973 108.43 25.5973C111.58 25.5973 113.584 27.7453 113.584 31.1637C113.584 34.5914 111.591 36.7301 108.43 36.7301C105.271 36.7301 103.277 34.5914 103.277 31.1637ZM111.312 31.1637C111.312 28.8188 110.237 27.4348 108.43 27.4348C106.623 27.4348 105.55 28.8293 105.55 31.1637C105.55 33.518 106.623 34.8914 108.43 34.8914C110.237 34.8914 111.312 33.518 111.312 31.1637H111.312Z"
        fill="white"
      />
      <path
        d="M115.423 25.7309H117.55V27.5801H117.601C117.745 27.0025 118.084 26.4921 118.56 26.1347C119.035 25.7772 119.62 25.5945 120.215 25.6172C120.472 25.6163 120.728 25.6442 120.979 25.7004V27.7863C120.654 27.6872 120.316 27.6417 119.977 27.6516C119.653 27.6384 119.33 27.6955 119.03 27.819C118.73 27.9425 118.461 28.1294 118.24 28.3668C118.019 28.6043 117.852 28.8867 117.751 29.1947C117.65 29.5027 117.616 29.829 117.653 30.1512V36.5953H115.423L115.423 25.7309Z"
        fill="white"
      />
      <path
        d="M131.261 33.4043C130.961 35.3765 129.041 36.73 126.583 36.73C123.423 36.73 121.461 34.6125 121.461 31.2152C121.461 27.8074 123.433 25.5972 126.489 25.5972C129.495 25.5972 131.386 27.6621 131.386 30.9562V31.7203H123.712V31.855C123.677 32.2549 123.727 32.6577 123.859 33.0367C123.991 33.4157 124.203 33.7622 124.479 34.0533C124.755 34.3443 125.091 34.5733 125.462 34.7248C125.834 34.8764 126.234 34.9471 126.635 34.9324C127.162 34.9818 127.691 34.8597 128.143 34.5844C128.595 34.309 128.946 33.8951 129.144 33.4042L131.261 33.4043ZM123.723 30.1617H129.154C129.174 29.8022 129.12 29.4424 128.994 29.1049C128.869 28.7675 128.675 28.4596 128.424 28.2007C128.174 27.9418 127.873 27.7374 127.54 27.6003C127.207 27.4633 126.849 27.3965 126.489 27.4043C126.126 27.4021 125.766 27.4719 125.43 27.6096C125.094 27.7472 124.789 27.9501 124.532 28.2065C124.274 28.4628 124.07 28.7676 123.932 29.1032C123.793 29.4388 123.722 29.7985 123.723 30.1617V30.1617Z"
        fill="white"
      />
      <path
        d="M45.391 10.4772C45.8585 10.4436 46.3277 10.5143 46.7646 10.684C47.2016 10.8537 47.5954 11.1183 47.9177 11.4586C48.24 11.799 48.4827 12.2066 48.6284 12.6521C48.7741 13.0977 48.8191 13.57 48.7602 14.035C48.7602 16.3225 47.5238 17.6374 45.391 17.6374H42.8047V10.4772H45.391ZM43.9168 16.6248H45.2668C45.6009 16.6448 45.9352 16.59 46.2455 16.4644C46.5557 16.3389 46.8341 16.1458 47.0602 15.8991C47.2864 15.6524 47.4548 15.3584 47.553 15.0385C47.6512 14.7185 47.6769 14.3807 47.6281 14.0496C47.6734 13.7198 47.6451 13.3841 47.5454 13.0665C47.4456 12.7489 47.2769 12.4573 47.0512 12.2125C46.8255 11.9678 46.5485 11.7761 46.24 11.651C45.9315 11.5259 45.5992 11.4706 45.2668 11.489H43.9168V16.6248Z"
        fill="white"
      />
      <path
        d="M50.0169 14.9332C49.983 14.5781 50.0236 14.2198 50.1362 13.8813C50.2488 13.5428 50.4309 13.2316 50.6708 12.9676C50.9108 12.7037 51.2032 12.4927 51.5295 12.3484C51.8557 12.2041 52.2085 12.1295 52.5652 12.1295C52.9219 12.1295 53.2747 12.2041 53.6009 12.3484C53.9271 12.4927 54.2196 12.7037 54.4595 12.9676C54.6994 13.2316 54.8816 13.5428 54.9942 13.8813C55.1068 14.2198 55.1474 14.5781 55.1134 14.9332C55.148 15.2886 55.1079 15.6474 54.9956 15.9864C54.8833 16.3255 54.7013 16.6373 54.4613 16.9018C54.2213 17.1662 53.9286 17.3776 53.6021 17.5223C53.2755 17.6669 52.9223 17.7416 52.5652 17.7416C52.208 17.7416 51.8548 17.6669 51.5283 17.5223C51.2017 17.3776 50.9091 17.1662 50.6691 16.9018C50.4291 16.6373 50.2471 16.3255 50.1348 15.9864C50.0225 15.6474 49.9823 15.2886 50.0169 14.9332ZM54.0165 14.9332C54.0165 13.7619 53.4904 13.0769 52.5669 13.0769C51.64 13.0769 51.1185 13.7619 51.1185 14.9332C51.1185 16.1138 51.64 16.7935 52.5669 16.7935C53.4904 16.7935 54.0166 16.1091 54.0166 14.9332H54.0165Z"
        fill="white"
      />
      <path
        d="M61.8875 17.6373H60.7812L59.6645 13.6576H59.5801L58.468 17.6373H57.3723L55.8828 12.2338H56.9645L57.9324 16.357H58.0121L59.1231 12.2338H60.1461L61.257 16.357H61.3414L62.3047 12.2338H63.3711L61.8875 17.6373Z"
        fill="white"
      />
      <path
        d="M64.624 12.2338H65.6506V13.0922H65.7303C65.8655 12.7839 66.0935 12.5254 66.3825 12.3528C66.6716 12.1803 67.0072 12.1022 67.3428 12.1295C67.6057 12.1097 67.8697 12.1493 68.1152 12.2455C68.3607 12.3416 68.5815 12.4917 68.7611 12.6847C68.9407 12.8777 69.0746 13.1087 69.1529 13.3605C69.2311 13.6122 69.2517 13.8784 69.2131 14.1392V17.6372H68.1467V14.407C68.1467 13.5387 67.7693 13.1068 66.9807 13.1068C66.8022 13.0985 66.624 13.1289 66.4583 13.1959C66.2926 13.2629 66.1434 13.3649 66.0209 13.495C65.8983 13.625 65.8053 13.78 65.7483 13.9494C65.6913 14.1188 65.6715 14.2985 65.6904 14.4761V17.6373H64.624L64.624 12.2338Z"
        fill="white"
      />
      <path
        d="M70.9121 10.1244H71.9785V17.6373H70.9121V10.1244Z"
        fill="white"
      />
      <path
        d="M73.4612 14.9332C73.4273 14.5781 73.4679 14.2198 73.5806 13.8813C73.6932 13.5428 73.8754 13.2316 74.1153 12.9676C74.3553 12.7036 74.6477 12.4927 74.974 12.3483C75.3002 12.204 75.653 12.1295 76.0098 12.1295C76.3665 12.1295 76.7193 12.204 77.0456 12.3483C77.3718 12.4927 77.6643 12.7036 77.9042 12.9676C78.1442 13.2316 78.3263 13.5428 78.439 13.8813C78.5516 14.2198 78.5923 14.5781 78.5583 14.9332C78.5929 15.2887 78.5527 15.6475 78.4404 15.9865C78.328 16.3255 78.146 16.6373 77.906 16.9018C77.666 17.1663 77.3733 17.3776 77.0467 17.5223C76.7201 17.6669 76.3669 17.7416 76.0098 17.7416C75.6526 17.7416 75.2994 17.6669 74.9729 17.5223C74.6463 17.3776 74.3536 17.1663 74.1136 16.9018C73.8736 16.6373 73.6915 16.3255 73.5792 15.9865C73.4668 15.6475 73.4267 15.2887 73.4612 14.9332ZM77.4608 14.9332C77.4608 13.7619 76.9347 13.077 76.0112 13.077C75.0843 13.077 74.5628 13.7619 74.5628 14.9332C74.5628 16.1139 75.0843 16.7936 76.0112 16.7936C76.9347 16.7936 77.4609 16.1092 77.4609 14.9332H77.4608Z"
        fill="white"
      />
      <path
        d="M79.6807 16.1092C79.6807 15.1365 80.4049 14.5758 81.6904 14.4961L83.1541 14.4117V13.9453C83.1541 13.3746 82.7768 13.0524 82.0479 13.0524C81.4525 13.0524 81.04 13.2709 80.9217 13.653H79.8893C79.9982 12.7248 80.8713 12.1295 82.0971 12.1295C83.4518 12.1295 84.2158 12.8039 84.2158 13.9453V17.6373H83.1893V16.878H83.1049C82.9336 17.1503 82.6931 17.3724 82.408 17.5215C82.1228 17.6705 81.8032 17.7412 81.4818 17.7264C81.255 17.7499 81.0257 17.7257 80.8088 17.6553C80.5919 17.5849 80.3922 17.4698 80.2225 17.3174C80.0528 17.165 79.9169 16.9788 79.8236 16.7707C79.7302 16.5626 79.6816 16.3373 79.6807 16.1092ZM83.1541 15.6475V15.1957L81.8346 15.2801C81.0904 15.3299 80.7529 15.583 80.7529 16.0594C80.7529 16.5457 81.1748 16.8287 81.7549 16.8287C81.9249 16.8459 82.0966 16.8288 82.2598 16.7783C82.423 16.7277 82.5744 16.6449 82.7049 16.5347C82.8355 16.4245 82.9425 16.2892 83.0197 16.1367C83.0968 15.9843 83.1426 15.8179 83.1541 15.6475Z"
        fill="white"
      />
      <path
        d="M85.6172 14.9332C85.6172 13.2258 86.4949 12.1441 87.8602 12.1441C88.1979 12.1286 88.533 12.2095 88.8264 12.3773C89.1199 12.5452 89.3595 12.7931 89.5172 13.0921H89.5969V10.1244H90.6633V17.6373H89.6414V16.7836H89.557C89.3871 17.0806 89.1392 17.3254 88.8401 17.4916C88.541 17.6577 88.2021 17.7389 87.8602 17.7263C86.4856 17.7264 85.6172 16.6447 85.6172 14.9332ZM86.7188 14.9332C86.7188 16.0793 87.259 16.7689 88.1625 16.7689C89.0613 16.7689 89.6168 16.0693 89.6168 14.9379C89.6168 13.8117 89.0555 13.1021 88.1625 13.1021C87.2649 13.1021 86.7188 13.7965 86.7188 14.9332H86.7188Z"
        fill="white"
      />
      <path
        d="M95.0755 14.9332C95.0416 14.5781 95.0822 14.2198 95.1948 13.8813C95.3074 13.5428 95.4895 13.2316 95.7294 12.9676C95.9694 12.7037 96.2618 12.4927 96.588 12.3484C96.9143 12.2041 97.267 12.1295 97.6238 12.1295C97.9805 12.1295 98.3333 12.2041 98.6595 12.3484C98.9857 12.4927 99.2782 12.7037 99.5181 12.9676C99.758 13.2316 99.9401 13.5428 100.053 13.8813C100.165 14.2198 100.206 14.5781 100.172 14.9332C100.207 15.2886 100.166 15.6474 100.054 15.9864C99.9419 16.3255 99.7599 16.6373 99.5199 16.9018C99.2799 17.1662 98.9872 17.3776 98.6607 17.5223C98.3341 17.6669 97.9809 17.7416 97.6238 17.7416C97.2666 17.7416 96.9134 17.6669 96.5869 17.5223C96.2603 17.3776 95.9677 17.1662 95.7277 16.9018C95.4877 16.6373 95.3057 16.3255 95.1934 15.9864C95.081 15.6474 95.0409 15.2886 95.0755 14.9332ZM99.0751 14.9332C99.0751 13.7619 98.549 13.0769 97.6255 13.0769C96.6986 13.0769 96.1771 13.7619 96.1771 14.9332C96.1771 16.1138 96.6986 16.7935 97.6255 16.7935C98.549 16.7935 99.0751 16.1091 99.0751 14.9332Z"
        fill="white"
      />
      <path
        d="M101.603 12.2338H102.629V13.0922H102.709C102.844 12.7839 103.072 12.5254 103.361 12.3528C103.65 12.1803 103.986 12.1022 104.321 12.1295C104.584 12.1097 104.848 12.1493 105.094 12.2455C105.339 12.3416 105.56 12.4917 105.74 12.6847C105.919 12.8777 106.053 13.1087 106.131 13.3605C106.21 13.6122 106.23 13.8784 106.192 14.1392V17.6372H105.125V14.407C105.125 13.5387 104.748 13.1068 103.959 13.1068C103.781 13.0985 103.602 13.1289 103.437 13.1959C103.271 13.2629 103.122 13.3649 102.999 13.495C102.877 13.625 102.784 13.78 102.727 13.9494C102.67 14.1188 102.65 14.2985 102.669 14.4761V17.6373H101.603V12.2338Z"
        fill="white"
      />
      <path
        d="M112.217 10.8885V12.2584H113.388V13.1567H112.217V15.9352C112.217 16.5012 112.451 16.749 112.981 16.749C113.117 16.7486 113.253 16.7404 113.388 16.7244V17.6127C113.197 17.647 113.003 17.6652 112.808 17.6672C111.622 17.6672 111.15 17.25 111.15 16.2082V13.1566H110.292V12.2584H111.15V10.8885H112.217Z"
        fill="white"
      />
      <path
        d="M114.846 10.1244H115.903V13.1021H115.987C116.129 12.7909 116.363 12.531 116.658 12.3578C116.953 12.1846 117.294 12.1065 117.635 12.1342C117.896 12.1199 118.158 12.1636 118.4 12.262C118.643 12.3605 118.861 12.5113 119.039 12.7037C119.217 12.8961 119.35 13.1254 119.429 13.3751C119.508 13.6249 119.531 13.889 119.496 14.1486V17.6373H118.428V14.4117C118.428 13.5486 118.026 13.1115 117.273 13.1115C117.089 13.0965 116.905 13.1217 116.733 13.1853C116.56 13.249 116.403 13.3496 116.274 13.4801C116.144 13.6105 116.045 13.7678 115.982 13.9407C115.92 14.1137 115.896 14.2982 115.912 14.4814V17.6373H114.846L114.846 10.1244Z"
        fill="white"
      />
      <path
        d="M125.713 16.1783C125.568 16.6722 125.254 17.0993 124.826 17.3851C124.398 17.6709 123.883 17.7971 123.372 17.7416C123.016 17.751 122.662 17.6828 122.335 17.5418C122.008 17.4007 121.716 17.1902 121.478 16.9249C121.241 16.6595 121.064 16.3456 120.96 16.005C120.856 15.6645 120.827 15.3053 120.876 14.9525C120.828 14.5987 120.857 14.2388 120.961 13.8971C121.065 13.5555 121.241 13.2402 121.477 12.9725C121.713 12.7048 122.004 12.491 122.33 12.3456C122.656 12.2001 123.01 12.1264 123.367 12.1295C124.87 12.1295 125.778 13.1567 125.778 14.8535V15.2256H121.962V15.2854C121.945 15.4836 121.97 15.6832 122.035 15.8713C122.1 16.0594 122.204 16.2319 122.339 16.3776C122.475 16.5234 122.639 16.6392 122.822 16.7177C123.005 16.7962 123.202 16.8356 123.401 16.8334C123.656 16.864 123.914 16.818 124.143 16.7014C124.372 16.5847 124.561 16.4027 124.687 16.1783L125.713 16.1783ZM121.962 14.4369H124.691C124.705 14.2556 124.68 14.0734 124.619 13.9021C124.558 13.7309 124.461 13.5743 124.336 13.4425C124.211 13.3106 124.059 13.2065 123.891 13.1367C123.724 13.0668 123.543 13.0329 123.361 13.0371C123.177 13.0348 122.994 13.0694 122.823 13.139C122.652 13.2085 122.497 13.3115 122.366 13.442C122.236 13.5724 122.133 13.7277 122.064 13.8985C121.994 14.0694 121.96 14.2525 121.962 14.4369H121.962Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default AppStore;