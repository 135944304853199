import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import InputField from "../../../../../../components/InputField";

const RenderInputEmailAddress = ({...props}:WrappedFieldProps) => {
  return (
    <FieldRow
      labelText={'Email'}
      isRequired={true}>
      <InputField
        {...props}
        placeholder={'Type your email address here'}
        type={'email'}
      />
    </FieldRow>
  );
};

export default RenderInputEmailAddress;
