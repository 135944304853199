import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    display: 'block',
    width: '100%',
    height: 116,
    borderRadius: 8,
    boxShadow: '0px 1px 4px rgba(50, 77, 103, 0.25)',
    marginBottom: 20,
    padding: 20,
    boxSizing: 'border-box',
    '& .MuiTypography-body2': {
      textAlign: 'center',
      marginBottom: 17
    }
  },
  starsWrap: {
    display: 'flex',
    justifyContent: 'center',
    '& .MuiBox-root': {
      margin: '0 12px'
    },
    [breakpoints.down(375)]: {
      '& .MuiBox-root': {
        margin: '0 7px'
      }
    }
  }
});