import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  container: {
    width: 744,
    margin: '0 auto',
    paddingTop: 50,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 235px)',
    boxSizing: 'border-box',
    [breakpoints.down(784)]: {
      width: '100%',
      paddingTop: 60
    },
    '& .MuiBox-root': {
      marginBottom: 20
    },
    '& .MuiTypography-subtitle1': {
      marginBottom: 20
    },
    '& .MuiTypography-body2': {
      marginBottom: 6
    }
  }
});