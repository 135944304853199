import * as yup from 'yup';

const schema = yup.object().shape({
  oldPassword: yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required field.')
    .matches(/(?=.*\d)/, {message: 'Password must contain at least one number'})
    .matches(/(?=.*\W)/, {message: 'Password must contain at least one special characters'})
    .matches(/(?=.*[a-zA-Z])/, {message: 'Password must contain at least one letter'})
    .matches(/^[a-zA-Z0-9!@#$%^&*()_+-=\\|'";:/?.><,` ~]+$/, {message: 'Use only number, special characters and latin letters'}),
  newPassword: yup.string()
    .notOneOf([yup.ref('oldPassword')], 'The new password must differ from the old')
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required field.')
    .matches(/(?=.*\d)/, {message: 'Password must contain at least one number'})
    .matches(/(?=.*\W)/, {message: 'Password must contain at least one special characters'})
    .matches(/(?=.*[a-zA-Z])/, {message: 'Password must contain at least one letter'})
    .matches(/^[a-zA-Z0-9!@#$%^&*()_+-=\\|'";:/?.><,` ~]+$/, {message: 'Use only number, special characters and latin letters'}),
  confirmNewPassword: yup.string()
    .oneOf([yup.ref('newPassword')], 'Passwords not match')
    .required('Confirm new password is required field')
});

export default schema;
