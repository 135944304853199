import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {popups} from "./registeredPopups";
import {IPopupData} from "../../bus/ui/typedefs";
import {uiActions} from "../../bus/ui/actions";
import Dialog from "@material-ui/core/Dialog";
import {uiSelector} from "../../bus/ui/selectors";
import {Nullable} from "../../setup/typedefs";

const Popup = (props:PopupProps) => {
  return props.popupData && React.createElement(popups[props.popupData?.name], {...props});
};

const PopupHolder = () => {
  const {isPopupOpen, popupData} = useSelector(uiSelector);
  const dispatch = useDispatch();
  const closeHandler = () => {
    dispatch(uiActions.popupClose());
    dispatch(uiActions.clearPopupData());
  };

  return (
    <Dialog open={isPopupOpen}>
      <Popup
        popupData={popupData}
        onClose={closeHandler}
      />
    </Dialog>
  );
};

export default PopupHolder;

export interface PopupProps {
  popupData: Nullable<IPopupData>
  onClose: ()=>void
}