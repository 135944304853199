import * as yup from 'yup';

const schema = yup.object().shape({
  password: yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required field.')
    .matches(/(?=.*\d)/, {message: 'Password must contain at least one number'})
    .matches(/(?=.*\W)/, {message: 'Password must contain at least one special characters'})
    .matches(/(?=.*[a-zA-Z])/, {message: 'Password must contain at least one letter'})
    .matches(/^[a-zA-Z0-9!@#$%^&*()_+-=\\|'";:/?.><,` ~]+$/, {message: 'Use only number, special characters and latin letters'}),
  email: yup.string()
    .matches(/^[\w-\.]{3,}@([\w-]{3,}\.)+[\w-]{2,4}$/, {message: 'Do not use special characters: #, %, ^, &, *, ?, !'})
    .required('Email is required field.')
});

export default schema;
