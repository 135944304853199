//Core
import React from 'react';

//Helpers
import {ListStatus} from "../../../../../setup/typedefs";
import {book} from "../../../../../routes/book";

//Components
import ListOfOffers from "../ListOfOffers";

const ActiveOffers = () => {
  return (
    <ListOfOffers
      status={ListStatus.Active}
      paginationPath={book.activeDeals}
      title={'Active'}
    />
  );
};

export default ActiveOffers;