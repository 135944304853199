import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const GoogleMapPin = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 24 24">
      <path
        d="M10.5371 2.1343C9.34125 2.43243 7.96757 3.36182 7.22971 4.37877C6.35634 5.5798 6 6.69328 6 8.21892C6 9.86714 6.39021 10.9543 7.83186 13.4092C9.07003 15.5133 9.49412 16.2937 9.90952 17.2493C10.6728 19.0028 11.1223 20.6861 11.3766 22.6762C11.5123 23.7634 11.6311 24 12.0295 24C12.3772 24 12.4875 23.7457 12.6147 22.6499C12.9201 19.897 13.9376 17.1353 15.7525 14.093C16.9143 12.1641 17.3807 11.2347 17.7201 10.2353C17.9746 9.46373 17.9915 9.31466 18 8.2627C18 7.30704 17.9661 7.01767 17.805 6.46542C17.4658 5.34318 17.0587 4.65938 16.2615 3.83527C15.4305 2.96739 14.5654 2.45016 13.497 2.16955C12.7081 1.95897 11.3088 1.94146 10.5371 2.1343ZM13.0812 5.83415C13.5984 6.0885 14.0989 6.57946 14.3702 7.09669C14.6075 7.55263 14.5821 8.83268 14.3278 9.36742C13.8868 10.2968 13.1743 10.7703 12.1398 10.8316C11.5292 10.8666 11.385 10.8403 10.995 10.6562C10.3421 10.3493 10.0368 10.0425 9.73993 9.42871C9.39228 8.71864 9.36687 7.798 9.68065 7.1142C10.2741 5.79037 11.8091 5.21186 13.0812 5.83415Z"
      />
    </SvgIcon>
  );
};

export default GoogleMapPin;
