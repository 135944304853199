import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const AdornmentOn = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 24 24">
      <circle
        cx="12"
        cy="12"
        r="8"
        fill="#1BC052"
      />
      <path
        d="M9 12.5455L10.6 14L15 10"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default AdornmentOn;
