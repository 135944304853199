//Core
import React, {useEffect, useState} from 'react';
import TagsInput from "react-tagsinput";
import {WrappedFieldProps} from "redux-form";

//Helpers
import {useSelector} from "react-redux";

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {styles} from "./styles";
import clsx from "clsx";
import {getAllTags} from "../../bus/options/selectors";

const useStyles = makeStyles(styles);

const InputTags: React.FC<WrappedFieldProps & any> = ({input, disabled=false}) => {
  const classes = useStyles();
  const allTags = useSelector(getAllTags);
  const [suggestedTagsList, setSuggestedTagsList] = useState<any>([]);

  useEffect(() => {
    allTags && setSuggestedTagsList(allTags);
  }, [allTags]);

  useEffect(() => {
    if (allTags?.length) {
      const resultArray = allTags.filter((obj: any) => {
        return input.value.indexOf(obj) === -1;
      });

      setSuggestedTagsList(resultArray);
    }
  }, [input, allTags]);

  const chooseTags = (tag: string) => {
    !input.value.includes(tag) && input.onChange([...input.value, tag]);

    const tmpSuggestedTags = suggestedTagsList.filter((item: string) => {
      return item !== tag;
    });

    setSuggestedTagsList(tmpSuggestedTags);
  };

  const suggestedTagsListJSX = suggestedTagsList.map((item: any, index: number) => {
    return (
      <div
        className={classes.suggestedTag}
        key={index}
        onClick={()=>{
          chooseTags(item);
        }}>
        <Typography variant={'body2'}>
          {item}
        </Typography>
      </div>
    );
  });

  return (
    <div className={clsx(classes.root, {
      [classes.tagsDisabled]: disabled
    })}>
      <TagsInput
        {...input}
        disabled={disabled}
        addOnPaste
        onlyUnique
      />
      {
        !disabled && (
          <div className={classes.suggestedTagWrapper}>
            <Typography
              variant={'body2'}
              className={classes.suggestedTagsTitle}>
              Suggested tags:
            </Typography>
            {suggestedTagsListJSX}
          </div>
        )
      }
    </div>
  );
};

export default InputTags;