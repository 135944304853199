import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {placesActions} from "../../../../../bus/business/actions";
import {profileID, profileIsVerified} from "../../../../../bus/profile/selectors";
import {change, getFormValues, isDirty, isPristine, isValid} from 'redux-form';
import {Place} from "../../../../../bus/business/typedefs";
import {getPlaceFromGoogle, getPlacePreview} from "../../../../../bus/business/selectors";
import {getCountries} from "../../../../../bus/options/selectors";
import {getFieldsInArray} from "../../../../../utils/helpers";

export const useCreateMyPlaceForm = () => {
  const dispatch = useDispatch();
  const form = 'CreateMyPlaceForm';
  const merchantID = useSelector(profileID);
  const isVerified = useSelector(profileIsVerified);
  const countries = useSelector(getCountries);
  const placeFromGoogle = useSelector(getPlaceFromGoogle);
  const placePreview = useSelector(getPlacePreview);
  const currentPlaceValue = useSelector<Place>(getFormValues(form)) as Place;
  const pristine = useSelector(isPristine(form));
  const dirty = useSelector(isDirty(form));
  const valid = useSelector(isValid(form));

  const [allowDraft, setAllowDraft] = useState(false);
  const [canGo, setCanGo] = useState(false);

  useEffect(() => {
    currentPlaceValue && setAllowDraft(!!currentPlaceValue.name);
  },[currentPlaceValue]);

  //place from google
  useEffect(() => {
    if (placeFromGoogle && countries?.length) {
      const {city, country, streetAddress, website, zip, name} = placeFromGoogle;
      const countryFormGoogle = countries?.filter((item) => { return item.code === country;});
      dispatch(change(form, 'zip', zip ? zip : ''));
      dispatch(change(form, 'name', name ? name : ''));
      dispatch(change(form, 'city', city ? city : ''));
      dispatch(change(form, 'website', website ? website : ''));
      country && dispatch(change(form, 'countryId', countryFormGoogle?.length ? countryFormGoogle[0].id : 1));
      dispatch(change(form, 'address', streetAddress ? streetAddress : ''));
    }
  }, [placeFromGoogle, countries]);

  //place from preview
  useEffect(() => {
    if (placePreview) {
      getFieldsInArray(placePreview).forEach((item) => {
        // @ts-ignore
        dispatch(change(form, item, placePreview[item]));
      });
    }
  },[placePreview]);

  //create and go to preview
  const goPreview = () => {
    currentPlaceValue && dispatch(placesActions.createPreview(currentPlaceValue));
  };

  //get fields from google
  const getGooglePlace = () => {
    currentPlaceValue.googlePlaceUnique && dispatch(placesActions.fetchGooglePlace(currentPlaceValue.googlePlaceUnique));
  };

  //save form
  const onSubmit = (isPublished: boolean, values: Place & any = currentPlaceValue) => {
    setCanGo(true)
    if (merchantID) {
      dispatch(placesActions.updatePlace({
        formData: {
          ...values,
          isPublished
        },
        info: {
          merchantID,
          actionWithPhotos: !values.photos?.length ? "nothing" : "save",
          actionWithDocuments: !values.documents?.length ? "nothing" : "save"
        }
      }));
    }
  };

  return {
    canGo,
    pristine,
    dirty,
    valid,
    goPreview,
    allowDraft,
    isVerified,
    onSubmit,
    getGooglePlace
  };
};
