//Core
import React from 'react';

//Helpers
import {useSingleVoucherInList} from "./customHooks/useSingleVoucherInList";
import {Voucher} from "../../../../bus/vouchers/typedefs";

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import ActionsButton from "./components/ActionsButton";
import MainInfoBlock from "./components/MainInfoBlock";
import Counters from "./components/Counters";

const useStyles = makeStyles(styles);

type SingleVoucherInListProps = {
  item: Voucher
}

const SingleVoucherInList: React.FC<SingleVoucherInListProps> = ({item}) => {
  const classes = useStyles();
  const {toggleTabletView} = useSingleVoucherInList();

  return (
    <div className={classes.root}>
      <MainInfoBlock item={item}/>
      {!toggleTabletView && <Counters item={item}/>}
      <ActionsButton id={item.id}/>
    </div>
  );
};

export default SingleVoucherInList;