import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const DraftDocument = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.25C7.37665 3.25 5.25 5.37665 5.25 8V24C5.25 26.6234 7.37665 28.75 10 28.75H22C24.6234 28.75 26.75 26.6234 26.75 24V13.2426C26.75 12.7785 26.5656 12.3334 26.2374 12.0052L17.9948 3.76256C17.6666 3.43437 17.2215 3.25 16.7574 3.25H10ZM2.75 8C2.75 3.99594 5.99594 0.75 10 0.75H16.7574C17.8845 0.75 18.9655 1.19777 19.7626 1.9948L28.0052 10.2374C28.8022 11.0345 29.25 12.1155 29.25 13.2426V24C29.25 28.0041 26.0041 31.25 22 31.25H10C5.99594 31.25 2.75 28.0041 2.75 24V8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5216 1.34513C17.9887 1.15165 18.5264 1.25859 18.8839 1.61609L28.3839 11.1161C28.7414 11.4736 28.8483 12.0112 28.6548 12.4783C28.4614 12.9454 28.0056 13.25 27.5 13.25H24C19.9959 13.25 16.75 10.004 16.75 5.99998V2.49998C16.75 1.9944 17.0546 1.5386 17.5216 1.34513ZM19.25 5.51774V5.99998C19.25 8.62333 21.3766 10.75 24 10.75H24.4822L19.25 5.51774Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 22.75C9 22.0596 9.55964 21.5 10.25 21.5L21.75 21.5C22.4404 21.5 23 22.0596 23 22.75C23 23.4404 22.4404 24 21.75 24L10.25 24C9.55964 24 9 23.4404 9 22.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 16.75C9 16.0596 9.55964 15.5 10.25 15.5L21.75 15.5C22.4404 15.5 23 16.0596 23 16.75C23 17.4404 22.4404 18 21.75 18L10.25 18C9.55964 18 9 17.4404 9 16.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 10.75C9 10.0596 9.55964 9.5 10.25 9.5L13.75 9.5C14.4404 9.5 15 10.0596 15 10.75C15 11.4404 14.4404 12 13.75 12L10.25 12C9.55964 12 9 11.4404 9 10.75Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default DraftDocument;
