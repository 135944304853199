import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import InputField from "../../../../../../components/InputField";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  my_place_form_render_input_zip_code_label: {
    id: "my_place_form_render_input_zip_code_label",
    defaultMessage: "Zip code"
  },
  my_place_form_render_input_zip_code_placeholder: {
    id: "my_place_form_render_input_zip_code_placeholder",
    defaultMessage: "89189"
  }
});

const RenderInputZipCode = ({...props}:WrappedFieldProps) => {
  const {formatMessage} = useIntl();

  return (
    <FieldRow labelText={formatMessage(msg.my_place_form_render_input_zip_code_label)}>
      <InputField
        {...props}
        type={'number'}
        placeholder={formatMessage(msg.my_place_form_render_input_zip_code_placeholder)}
      />
    </FieldRow>
  );
};

export default RenderInputZipCode;
