import {useSelector} from "react-redux";
import {profileIsVerified, profileName, profilePhone} from "../../bus/profile/selectors";
import {SideBarLinkType} from "../Sidebar/navigationSetUp";
import {getPlace} from "../../bus/business/selectors";
import {ListStatus} from "../../setup/typedefs";

export const useAccordionPages = (data: SideBarLinkType[]) => {
  const name = useSelector(profileName);
  const phone = useSelector(profilePhone);
  const isVerified = useSelector(profileIsVerified);
  const place = useSelector(getPlace);
  const profile = name && phone && isVerified;
  const currentPlace = place?.status === ListStatus.Active;

  const singlePage = data.map((item) => {
    switch (item.name) {
    case 'profile':
      return {
        ...item,
        error: !profile
      };

    case 'place':
      return {
        ...item,
        error: !currentPlace
      };

    default:
      return item;
    }
  });

  return {
    singlePage
  };
};
