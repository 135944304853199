//Core
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";

//Helpers
import {getVoucher} from "../../../../../bus/vouchers/selectors";
import {vouchersActions} from "../../../../../bus/vouchers/actions";
import {Voucher} from "../../../../../bus/vouchers/typedefs";

export const useSingleVoucher = () => {
  const dispatch = useDispatch();

  const {id} = useParams<{id: string}>();
  const [data, setData] = useState<Voucher>();

  const voucher = useSelector(getVoucher);

  useEffect(() => {
    id && dispatch(vouchersActions.fetchVoucher(Number(id)));
    id && dispatch(vouchersActions.clearPreview());
  },[id]);

  useEffect(() => {
    voucher && setData(voucher);
  }, [voucher]);

  return {
    data
  };
};