import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import InputField from "../../../../../../components/InputField";

const RenderInputSubject = ({...props}:WrappedFieldProps) => {
  return (
    <FieldRow
      labelText={'Subject'}
      isRequired={true}>
      <InputField
        {...props}
        placeholder={'Type subject here'}
        type={'text'}
      />
    </FieldRow>
  );
};

export default RenderInputSubject;
