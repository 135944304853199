//Core
import React from 'react';

//Helpers
import {Offer} from "../../../../../bus/offers/typedefs";

//Styles
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "../styles";

const useStyles = makeStyles(styles);

const Counters: React.FC<{item: Offer, toggleTabletView: boolean}> = ({item, toggleTabletView}) => {
  const classes = useStyles();

  return (
    <>
      {
        toggleTabletView
          ? (
            <div className={classes.infoForMobile}>
              <div className={classes.innerCell}>
                <Typography variant={'caption'}>
                  Views
                </Typography>
                <Typography variant={'h3'}>
                  {item.viewsCount}
                </Typography>
              </div>
              <div className={classes.innerCell}>
                <Typography variant={'caption'}>
                  Added to favorite
                </Typography>
                <Typography variant={'h3'}>
                  {item.favoritesCount}
                </Typography>
              </div>
              <div className={classes.innerCell}>
                <Typography variant={'caption'}>
                  Reservations
                </Typography>
                <Typography variant={'h3'}>
                  {item.reservationsCount}
                </Typography>
              </div>
            </div>
          )
          : (
            <>
              <div className={classes.cell}>
                <Typography variant={'h3'}>
                  {item.viewsCount}
                </Typography>
              </div>
              <div className={classes.cell}>
                <Typography variant={'h3'}>
                  {item.favoritesCount}
                </Typography>
              </div>
              <div className={classes.cell}>
                <Typography variant={'h3'}>
                  {item.reservationsCount}
                </Typography>
              </div>
            </>
          )
      }
    </>
  );
};

export default Counters;