// @ts-ignore
import set from "object-set";
import { Schema } from "yup";

const syncValidate = <T>(schema: Schema<T>) => (values: any) => {
  const formErrors = {};
  try {
    schema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error: any) => {
      set(formErrors, error.path, error.message);
    });
  }

  return formErrors;
};
export default syncValidate;