import React, {useEffect, useState} from 'react';
import AuthRouter from "./AuthRouter";
import PromoInformationBlock from "./components/PromoInformationBlock";
import {Box} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {styles} from "./styles";
import {useParams} from "react-router";
import {book} from "../../routes/book";
import ThankYouPage from "./pages/ThankYouPage";

const useStyles = makeStyles(styles);

const AuthContainer = () => {
  const classes = useStyles();
  const {page} = useParams<{page: string}>();
  const [thankYou, setThankYou] = useState(false);

  useEffect(() => {
    setThankYou('/' + page === book.thankYouPage);
  },[page]);

  return (
    <Box className={classes.mainWrapper}>
      {
        thankYou
          ? <ThankYouPage/>
          : <PromoInformationBlock/>
      }
      <Box className={classes.rootWrapper}>
        <Box className={classes.innerWrapper}>
          <AuthRouter/>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthContainer;
