//Core
import React from 'react';

//Helpers
import {useCreateVoucher} from "./customHooks/useCreateVoucher";

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import VoucherCreateForm from "./form/VoucherCreateForm";
import ButtonBackBlue from "../../../../components/ButtonBackBlue";

const useStyles = makeStyles(styles);

const CreateVoucher = () => {
  const classes = useStyles();
  const {initialValues} = useCreateVoucher();

  return (
    <div className={classes.container}>
      <ButtonBackBlue/>
      {initialValues && <VoucherCreateForm initialValues={initialValues}/>}
    </div>
  );
};

export default CreateVoucher;
