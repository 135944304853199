import React, {useEffect, useState} from "react";
import {Box, useTheme} from "@material-ui/core";

export const IconHolder: React.FC<IIconHolder> = ({children, size= 24, color, ...props}) => {
  const theme = useTheme();
  const [htmlColor, setHtmlColor] = useState(theme.palette.primary.main);

  useEffect(() => {
    color && setHtmlColor(color);
  },[color]);

  return (
    <Box
      display={'flex'}
      fontSize={size}
      height={size}>
      {React.Children?.map(children, child => {
        const _props = {
          htmlColor,
          ...props
        };
        if (React.isValidElement(child)) {
          return React.cloneElement(child, _props);
        }

        return child;
      })}
    </Box>
  );
};
export default IconHolder;

interface IIconHolder {
  children: any,
  size?: number,
  height?: number,
  color?: string
}