import {call} from "redux-saga/effects";
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";

export function* updatePlaceTags({formData, placeID, updateTags}: any){
  try {
    if (updateTags) {
      const response = yield call(fetchWithToken, {
        endpoint: `places/${placeID}/tags`,
        method: 'PUT',
        body: {tags: formData.tags}
      });

      if (response.status !== 200) {
        yield call(handleErrors, response);
        throw new Error(response);
      }
      yield call(handleMsg, 'Tags updated');
    }
  } catch (e) {
    console.log(e);
  }
}