import React from 'react';
import {WrappedFieldProps} from "redux-form";
import InputField from "../../../../../components/InputField";

const RenderInputMaxGuests = ({...props}: WrappedFieldProps) => {
  return (
    <InputField
      {...props}
      width={'calc((100% - 30px) / 2)'}
      type={'number'}
      placeholder={'Max'}
    />
  );
};

export default RenderInputMaxGuests;
