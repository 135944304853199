import {createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../../../customTheme";

export const styles = (theme: GroupiTheme) => createStyles({
  accordion: {
    marginBottom: 20
  },
  accordionHead: {
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.common.gray88,
    padding: '0 20px',
    height: 66,
    '&.Mui-expanded': {
      borderColor: theme.palette.common.blue50,
      boxShadow: '0px 4px 12px rgba(50, 77, 103, 0.25)'
    },
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(0)',
      '&.Mui-expanded': {
        transform: 'rotate(180deg)'
      }
    }
  },
  accordionBody: {
    marginTop: 20,
    marginBottom: 20,
    padding: '0 20px',
    '& .MuiTypography-body1': {
      color: theme.palette.common.gray50
    }
  }
});
