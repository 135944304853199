import React from 'react';
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import PasswordForm from "./form/PasswordForm";

const useStyles = makeStyles(styles);

const Password = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PasswordForm/>
    </div>
  );
};

export default Password;
