import React from 'react';
import {Button} from "@material-ui/core";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import RenderInputMail from "./formFields/RenderInputMail";
import syncValidate from "../../../../../utils/syncValidate";
import schema from "../../../pages/SignIn/form/schema";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {styles} from "./styles";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  confirm_email_button_resend: {
    id: "thank_you_page_button_resend",
    defaultMessage: "Resend"
  }
});

const useStyles = makeStyles(styles);

const ResendOnEmailForm = (props: InjectedFormProps) => {
  const {formatMessage} = useIntl();
  const {handleSubmit} = props;
  const classes = useStyles();

  return (
    <form
      onSubmit={handleSubmit}
      className={classes.root}>
      <Field
        name={'email'}
        component={RenderInputMail}
      />
      <Button
        type='submit'
        variant="outlined"
        color="primary">
        {formatMessage(msg.confirm_email_button_resend)}
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'resendOnEmail',
  validate: syncValidate(schema)
})(ResendOnEmailForm);
