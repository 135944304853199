import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const Account = (props: SvgIconProps) => {
  //TODO: refactor it
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 80 80">
      <svg fill="none">
        <circle
          cx="40"
          cy="40"
          r="38"
          stroke="white"
          strokeWidth="4"
        />
        <path
          d="M56.4142 41.4142C57.1953 40.6332 57.1953 39.3668 56.4142 38.5858L43.6863 25.8579C42.9052 25.0768 41.6389 25.0768 40.8579 25.8579C40.0768 26.6389 40.0768 27.9052 40.8579 28.6863L52.1716 40L40.8579 51.3137C40.0768 52.0948 40.0768 53.3611 40.8579 54.1421C41.6389 54.9232 42.9052 54.9232 43.6863 54.1421L56.4142 41.4142ZM25 42L55 42L55 38L25 38L25 42Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default Account;
