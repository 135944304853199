import React from 'react';
import Typography from "@material-ui/core/Typography";
import {AccordionDetails, Box, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import {NavLink} from "react-router-dom";
import {SideBarLinkType} from "../Sidebar/navigationSetUp";
import IconHolder from "../IconHolder";
import Exclamation from "../Icons/Exclamation";
import {useAccordionPages} from "./useAccordionPages";
import {GroupiTheme} from "../../customTheme";

const useStyles = makeStyles(styles);

type AccordionPagesProps = {
  data: SideBarLinkType[]
}

const AccordionPages: React.FC<AccordionPagesProps> = ({data}) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const {singlePage} = useAccordionPages(data);

  return (
    <AccordionDetails>
      <Box
        className={classes.linksWrapper}
        flexDirection={'column'}>
        {
          singlePage?.map((item: SideBarLinkType, index: number) => {
            return (
              <NavLink
                key={index}
                to={item.link}
                className={classes.link}
                activeClassName={classes.activeLink}>
                <Typography variant={'body2'}>
                  {item.label}
                </Typography>
                {
                  item.error &&(
                    <IconHolder
                      size={16}
                      color={theme.palette.common.red}>
                      <Exclamation/>
                    </IconHolder>
                  )
                }
              </NavLink>
            );
          })
        }
      </Box>
    </AccordionDetails>
  );
};

export default AccordionPages;