import React, {useEffect, useState} from 'react';
import {Brush, CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import useWindowDimensions from "../../../../../../utils/customHooks/useWindowDimensions";

const useStyles = makeStyles(styles);

const GraphChart = ({data, dataKey}: any) => {
  const classes = useStyles();
  const [chartWidth, setChartWidth] = useState(744);
  const {width} = useWindowDimensions();

  useEffect(() => {
    setChartWidth(width <= 784 ? width - 40 : 744);
  }, [width]);

  return (
    <div className={classes.dynamicsWrapper}>
      <LineChart
        className={classes.dynamics}
        width={chartWidth}
        height={360}
        data={data}>
        <CartesianGrid vertical={false}/>
        <XAxis
          dataKey="date"
          padding={{left: 5, right: 5}}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          padding={{top: 12}}
          width={20}
        />
        <Tooltip/>
        <Line
          type="monotone"
          dataKey={dataKey}
          stroke="#5CADFF"
          activeDot={{r: 6}}
        />
        <Brush
          gap={1}
          height={62}
        />
      </LineChart>
      <LineChart
        className={classes.dynamicsForBrush}
        width={chartWidth - 20}
        height={62}
        data={data}>
        <Line
          type="monotone"
          dataKey={dataKey}
          stroke="#5CADFF"
          dot={false}
        />
      </LineChart>
    </div>
  );
};

export default GraphChart;