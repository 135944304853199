import {call, put} from 'redux-saga/effects';
import {authActions} from "../../actions";
import {notificationsActions} from "../../../notifications/actions";
import {SignInFormValues} from "../../../../containers/AuthContainer/pages/SignIn/form/typedefs";
import {push} from "connected-react-router";
import {book} from "../../../../routes/book";
import {handleErrors} from "../../../../utils/handleErrors";
import {fetchWithoutToken} from "../../../utils/fetching";

export function* signIn({payload: body}: {type: string, payload: SignInFormValues}){
  try {
    const dataForFetching = {
      endpoint: 'merchants/auth/sign-in',
      method: 'POST'
    };

    const response = yield call(fetchWithoutToken, {...dataForFetching, body});

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    const {data} = yield call([response, 'json']);

    yield put(authActions.fillTokens({
      token: data.token,
      refreshToken: data.refreshToken
    }));

    yield put(authActions.authenticate(true));
    yield put(notificationsActions.refreshNotifications({merchantID: data.merchant.id}));
    yield put(push(book.welcome));

  } catch (e) {
    console.log(e);
  }
}
