import {call} from "redux-saga/effects";
import {fetchFileWithToken} from "../../../utils/fetching";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";

export function* updatePlaceDocuments({formData, placeID, actionWithDocuments: action}: any){
  try {
    if (action !== 'nothing') {
      const response = yield call(fetchFileWithToken, {
        endpoint: `places/${placeID}/documents`,
        method: 'PUT',
        type: 'documents',
        body: action !== 'remove' ? formData.documents : {removeDocuments: true}
      });

      if (response.status !== 200) {
        yield call(handleErrors, response);
        throw new Error(response);
      }

      yield call(handleMsg, `Documents ${action !== 'remove' ? ' updated' : ' removed'}`);
    }
  } catch (e) {
    console.log(e);
  }
}