import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  mainWrapper: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'stretch',
    [breakpoints.down(1200)]: {
      flexDirection: 'column'
    },
    '& a': {
      textDecoration: 'none',
      '& p': {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 18,
        lineHeight: '24px',
        letterSpacing: 1.3,
        color: theme.palette.common.blue50
      }
    }
  },
  rootWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 0%',
    [breakpoints.down(1500)]: {
      padding: '0 30px'
    },
    [breakpoints.down(1200)]: {
      padding: '58px 15px 60px',
      alignItems: 'baseline'
    },
    [breakpoints.down(600)]: {
      padding: '60px 15px'
    }
  },
  innerWrapper: {
    width: 445,
    marginLeft: 15,
    marginTop: -118,
    [breakpoints.down(1500)]: {
      marginLeft: 'unset',
      marginTop: 'unset'
    },
    [breakpoints.down(1200)]: {
      maxWidth: 490,
      width: '100%',
      paddingRight: 119
    },
    [breakpoints.down(600)]: {
      paddingRight: 0
    }
  }
});
