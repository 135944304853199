import {call, put} from "redux-saga/effects";
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {Workers} from "../../../../setup/typedefs";
import {uiActions} from "../../../ui/actions";
import {push} from "connected-react-router";
import {book} from "../../../../routes/book";
import {offersActions} from "../../actions";

export function* removeOffer({payload}: Workers){
  try {
    const {offerID} = payload;

    const response = yield call(fetchWithToken, {
      endpoint: `offers/${offerID}`,
      method: 'DELETE'
    });

    if (response.status !== 202) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    yield put(offersActions.clear());
    yield put(push(book.activeDeals));
    yield call(handleMsg, `Deal successfully deleted`);

  } catch (e) {
    console.log(e);
  }
  yield put(uiActions.confirm(false));
}