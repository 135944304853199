import React, {useEffect, useState} from 'react';
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import {GroupiTheme} from "../../customTheme";
import {useTheme} from "@material-ui/core";
import IconHolder from "../IconHolder";
import Warning from "../Icons/Warning";
import Prolong from "../Icons/Prolong";
import DraftDocument from "../Icons/DraftDocument";
import CheckMarkInNotification from "../Icons/CheckMarkInNotification";
import clsx from "clsx";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";
import Recreate from "../Icons/Recreate";

const useStyles = makeStyles(styles);

const Status = ({status}: any) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const {width} = useWindowDimensions();
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    setMobileView(width < 600);
  }, [width]);

  if (!status) {
    return <></>;
  }

  const setStatus = (status: string) => {
    switch (status) {
    case 'draft':
      return <DraftDocument/>;

    case 'pending':
      return <Prolong/>;

    case 'active':
      return <CheckMarkInNotification/>;

    case 'rejected':
      return <Warning/>;

    case 'expired':
      return <Recreate/>;

    default:
      return <CheckMarkInNotification/>;
    }
  };

  return (
    <div className={
      clsx(classes.root, {
        [classes.error]: status === 'rejected',
        [classes.draft]: status === 'draft',
        [classes.active]: status === 'active',
        [classes.expired]: status === 'expired',
        [classes.pending]: status === 'pending'
      })
    }>
      <IconHolder
        size={mobileView ? 17 : 32}
        color={theme.palette.common.white}>
        {setStatus(status)}
      </IconHolder>
    </div>
  );
};

export default Status;