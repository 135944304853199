import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const Chart = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 80 80">
      <g clipPath="url(#clip0)">
        <path
          d="M76.3122 76.875H72.8851V27.115C72.8851 24.7989 71.0008 22.9147 68.6848 22.9147H61.0248C58.7087 22.9147 56.8245 24.799 56.8245 27.115V76.875H48.1227V49.6601C48.1227 47.3441 46.2384 45.4598 43.9223 45.4598H36.2623C33.9462 45.4598 32.062 47.3442 32.062 49.6601V76.875H23.3602V62.7806C23.3602 60.4645 21.4758 58.5803 19.1598 58.5803H11.4997C9.18359 58.5803 7.29937 60.4647 7.29937 62.7806V76.875H3.6875C2.82453 76.875 2.125 77.5745 2.125 78.4375C2.125 79.3005 2.82453 80 3.6875 80H76.3122C77.1751 80 77.8747 79.3005 77.8747 78.4375C77.8747 77.5745 77.1751 76.875 76.3122 76.875ZM20.2348 76.875H10.4242V62.7806C10.4242 62.1876 10.9066 61.7053 11.4995 61.7053H19.1595C19.7525 61.7053 20.2348 62.1876 20.2348 62.7806V76.875ZM44.9975 76.875H35.1869V49.6601C35.1869 49.0672 35.6692 48.5848 36.2622 48.5848H43.9222C44.515 48.5848 44.9975 49.0672 44.9975 49.6601V76.875ZM69.7601 76.875H59.9495V27.115C59.9495 26.522 60.4319 26.0397 61.0248 26.0397H68.6848C69.2778 26.0397 69.7601 26.522 69.7601 27.115V76.875Z"
        />
        <path
          d="M72.8817 1.64312C72.9035 1.21672 72.7535 0.783125 72.4279 0.4575C72.1023 0.131875 71.6687 -0.0182813 71.2423 0.00375C71.2171 0.00265625 71.1923 0 71.1667 0H61.7135C60.8506 0 60.151 0.699531 60.151 1.5625C60.151 2.42547 60.8506 3.125 61.7135 3.125H67.5506L52.1042 18.5716L46.2792 12.7466C45.9862 12.4536 45.5887 12.2889 45.1743 12.2889C44.7599 12.2889 44.3624 12.4536 44.0695 12.7466L15.9806 40.8356C15.3704 41.4458 15.3704 42.4352 15.9806 43.0455C16.2857 43.3503 16.6856 43.5028 17.0854 43.5028C17.4853 43.5028 17.8853 43.3503 18.1903 43.0452L45.1743 16.0609L50.9993 21.8859C51.6095 22.4961 52.5988 22.4961 53.2092 21.8859L69.7604 5.33484V10.6177C69.7604 11.4806 70.4599 12.1802 71.3229 12.1802C72.1859 12.1802 72.8854 11.4806 72.8854 10.6177V1.71875C72.8854 1.69328 72.8827 1.66844 72.8817 1.64312Z"
        />
        <path
          d="M11.8188 48.9547C12.6818 48.9547 13.3813 48.2552 13.3813 47.3922C13.3813 46.5293 12.6818 45.8297 11.8188 45.8297C10.9559 45.8297 10.2563 46.5293 10.2563 47.3922C10.2563 48.2552 10.9559 48.9547 11.8188 48.9547Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="80"
            height="80"
            fill="white"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Chart;