//Core
import React from 'react';
import {WrappedFieldProps} from "redux-form";
import NumberFormat from "react-number-format";
import {useSelector} from "react-redux";

//Helpers
import {recreateVoucherFormSelector} from "../../../../../../../bus/forms/selectors";

//Styles
import {Box, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import RequiredAsterisk from "../../../../../../../components/RequiredAsterisk";
import TextBlank from "../../../../../../../components/TextBlank";

const useStyles = makeStyles(styles);

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (value: string) => void;
  name: string;
}

function NumberFormatCustom({ inputRef, onChange, ...other }: NumberFormatCustomProps) {
  const formValue = useSelector(recreateVoucherFormSelector);

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={({value}) => {
        value ? (
          onChange(value)
        ) : (
          onChange('')
        );
      }}
      thousandSeparator
      isNumericString
      prefix="LE "
      isAllowed={({floatValue}) => {
        if (!floatValue) {
          return true;
        } else if (floatValue < 0) {
          return false;
        } else if (floatValue && formValue?.originalPrice) {
          return floatValue <= formValue.originalPrice;
        } else {
          return false;
        }
      }}
    />
  );
}

const DiscountPrice: React.FC<WrappedFieldProps & any> = (props) => {
  const classes = useStyles();
  const {label, input, percent, meta} = props;
  const formValue = useSelector(recreateVoucherFormSelector);

  return (
    <div className={classes.root}>
      <Typography
        variant={'subtitle2'}
        className={classes.label}>
        {label}
        <RequiredAsterisk/>
        {!!percent &&(
          <Box
            ml={1.5}
            display={'inline-block'}>
            <TextBlank
              percent={percent}
            />
          </Box>
        )}
      </Typography>
      <TextField
        {...input}
        value={!formValue?.originalPrice ? '' : input.value}
        className={classes.input}
        type={'text'}
        placeholder={'LE 0.00'}
        fullWidth
        variant={'outlined'}
        helperText={meta.touched && !meta.active && meta.error}
        error={meta.touched && !meta.active && !!meta.error}
        InputProps={{
          inputComponent: NumberFormatCustom as any
        }}
      />
    </div>
  );
};

export default DiscountPrice;
