import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    width: 500,
    boxSizing: 'border-box',
    background: theme.palette.common.white,
    padding: 40,
    boxShadow: '0px 4px 12px rgba(50, 77, 103, 0.25)',
    borderRadius: 8,
    position: 'relative',
    '& .MuiTypography-h3': {
      marginBottom: 16
    },
    '& .MuiDialogContent-root': {
      padding: 0
    },
    [breakpoints.down(375)]: {}
  },
  closeCross: {
    position: 'absolute',
    top: 12,
    right: 12
  },
  content: {
    width: '100%',
    position: 'relative',
    '& .MuiTypography-body2': {
      marginBottom: 40
    }
  },
  action: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'center'
  },
  sendButton: {
    margin: '0 auto'
  }
});