export const types = {
  REASSIGN_PLACE: '@BUSINESS/REASSIGN_PLACE',
  UPDATE_PLACE: '@BUSINESS/UPDATE_PLACE',
  UPDATE_PLACE_TAGS: '@BUSINESS/UPDATE_PLACE_TAGS',
  UPDATE_PLACE_PHOTOS: '@BUSINESS/UPDATE_PLACE_PHOTOS',
  UPDATE_PLACE_DOCUMENTS: '@BUSINESS/UPDATE_PLACE_DOCUMENTS',
  FETCH_PLACE: '@BUSINESS/FETCH_PLACE',
  FETCH_PLACES: '@BUSINESS/FETCH_PLACES',
  FETCH_PLACES_TAGS: '@BUSINESS/FETCH_PLACES_TAGS',
  FETCH_GOOGLE_PLACE: '@BUSINESS/FETCH_GOOGLE_PLACE',
  CLEAR_GOOGLE_PLACE: '@BUSINESS/CLEAR_GOOGLE_PLACE',
  FILL_PLACE: '@BUSINESS/FILL_PLACE',
  FILL_PLACES: '@BUSINESS/FILL_PLACES',
  FILL_PLACE_FROM_GOOGLE: '@BUSINESS/FILL_PLACE_FROM_GOOGLE',
  FILL_PREVIEW: '@BUSINESS/FILL_PREVIEW',
  CREATE_PREVIEW: '@BUSINESS/CREATE_PREVIEW',
  CLEAR_PREVIEW: '@BUSINESS/CLEAR_PREVIEW',
  CLEAR: '@BUSINESS/CLEAR'
};