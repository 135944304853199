import React, {useEffect, useState} from 'react';
import {Pagination} from "@material-ui/lab";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import useWindowDimensions from "../../../../utils/customHooks/useWindowDimensions";
import {useParams} from "react-router";

const useStyles = makeStyles(styles);

type RenderPaginationProps = {
  path: string,
  count: number
}

const RenderPagination: React.FC<RenderPaginationProps> = ({path, count}) => {
  const history = useHistory();
  const classes = useStyles();
  const {width} = useWindowDimensions();
  const {page} = useParams<{page: string}>();
  const [sizeDependence, setSizeDependence] = useState(1);

  useEffect(()=> {
    width < 768 ? setSizeDependence(0) : setSizeDependence(1);
  }, [width, page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    history.push(path + `/${value}`);
  };

  return (
    <Pagination
      className={classes.root}
      boundaryCount={1}
      count={count}
      siblingCount={sizeDependence}
      onChange={handleChange}
    />
  );
};

export default RenderPagination;