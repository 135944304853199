import {call, put} from 'redux-saga/effects';
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors} from "../../../../utils/handleErrors";
import {offersActions} from "../../actions";
import {Workers} from "../../../../setup/typedefs";
import {uiActions} from "../../../ui/actions";
import {fetchStatistics} from "./fetchStatistics";

export function* fetchOffer({offerID}: Workers) {
  try {
    yield put(uiActions.loading(true));
    const response = yield call(fetchWithToken, {
      endpoint: `offers/${offerID}/?relations=cover,tags`,
      method: 'GET'
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    const {data} = yield call([response, 'json']);

    yield put(offersActions.fillOffer({
      ...data,
      dateStart: new Date(data.dateStart * 1000),
      dateEnd: new Date(data.dateEnd * 1000),
      tags: data.tags && data.tags.map((item: any) => item.name)
    }));

    yield call(fetchStatistics, data.id);

  } catch (e) {
    console.log(e);
  } finally {
    yield put(uiActions.loading(false));
  }
}
