import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  innerRow: {
    display: 'flex',
    alignItems: 'end',
    marginBottom: 9,
    '&:last-child': {
      marginBottom: 0
    },
    '& .MuiTypography-root': {
      marginLeft: 6
    },
    [breakpoints.down(1024)]: {
      marginBottom: 7,
      '&:last-child': {
        marginBottom: 0
      }
    },
    [breakpoints.down(768)]: {
      order: 2,
      marginBottom: 11
    }
  }
});
