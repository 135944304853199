import React from 'react';
import {Link} from "react-router-dom";
import {book} from "../../../../../routes/book";
import IconHolder from "../../../../../components/IconHolder";
import ArrowWithTail from "../../../../../components/Icons/ArrowWithTail";
import ButtonLinkTransparent from "../../../../../components/ButtonLinkTransparent";
import {GroupiTheme} from "../../../../../customTheme";
import {useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "../styles";
import {useSingleOfferInList} from "../customHooks/useSingleOfferInList";

const useStyles = makeStyles(styles);

const ActionsButton: React.FC<{id: number}> = ({id}) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const {toggleButtonView, toggleButtonSize} = useSingleOfferInList();

  return (
    <div className={classes.cell}>
      {
        toggleButtonView
          ? (
            <Link
              to={book.singleDeal + `/${id}`}
              style={{textDecoration: 'none'}}>
              <IconHolder
                size={24}
                color={theme.palette.common.blue50}>
                <ArrowWithTail/>
              </IconHolder>
            </Link>
          )
          : (
            <ButtonLinkTransparent
              fullWidth={toggleButtonSize}
              icon={
                <IconHolder
                  size={24}
                  color={theme.palette.common.blue50}>
                  <ArrowWithTail/>
                </IconHolder>
              }
              link={book.singleDeal + `/${id}`}>
              More details
            </ButtonLinkTransparent>
          )
      }
    </div>
  );
};

export default ActionsButton;