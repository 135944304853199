import React from 'react';
import {Button, useTheme} from "@material-ui/core";
import clsx from "clsx";
import IconHolder from "../IconHolder";
import CrossInCircle from "../Icons/CrossInCircle";
import {GroupiTheme} from "../../customTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {styles} from "./styles";

const useStyles = makeStyles(styles);

const SingleImage: React.FC<any> = (props) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();

  const {file, removeFile, disabledForEdit} = props;

  return (
    <div className={classes.droppedFile}>
      <Button
        onClick={() => {removeFile(file.index);}}
        variant="outlined"
        color="inherit"
        fullWidth
        className={
          clsx(classes.cross, {
            [classes.disabledForEdit]: disabledForEdit
          })
        }
        startIcon={(
          <IconHolder
            size={24}
            color={theme.palette.common.white}>
            <CrossInCircle/>
          </IconHolder>
        )}
      />
      <img
        className={classes.image}
        src={file.preview}
        alt=""
      />
    </div>
  );
};

export default SingleImage;