import {config} from "./envVariable";
import {FetchData} from "./typedefs";

export const API = {
  fetchWithoutRefresh: ({endpoint, method, body}: FetchData) => {
    body = JSON.stringify(body);

    return fetch(`${config.REACT_APP_API_GATEWAY}${endpoint}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method,
      body
    });
  },
  fetchFileWithRefresh: ({ tokenAccess, endpoint, method, body: payload, type }: FetchData) => {
    const formData = new FormData();

    if (type === 'photos') {
      if (payload.removePhotos) {
        formData.append('photos', '');
        formData.append('photoIds', '');
      } else {
        payload?.map((item: any) => {
          item.id
            ? formData.append('photoIds', item.id)
            : formData.append('photos', item);
        });
      }
    } else if (type === 'documents') {
      if (payload.removeDocuments) {
        formData.append('documentIds', '');
        formData.append('documents', '');
      } else {
        payload?.map((item: any) => {
          item.id
            ? formData.append('documentIds', item.id)
            : formData.append('documents', item);
        });
      }
    } else if (type === 'singleCover') {
      if (payload.removeCover) {
        formData.append('cover', '');
        formData.append('coverId', '');
      } else if (payload?.id) {
        formData.append('coverId', payload?.id);
      } else {
        const cover = payload ? payload[0] : null;
        formData.append('cover', cover);
      }
    }

    return fetch(`${config.REACT_APP_API_GATEWAY}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${tokenAccess}`,
        type: 'formData'
      },
      method,
      mode: 'cors',
      body: formData
    });
  },
  fetchWithRefresh: ({tokenAccess, endpoint, method, body}: FetchData) => {
    body = JSON.stringify(body);

    return fetch(`${config.REACT_APP_API_GATEWAY}${endpoint}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenAccess}`
      },
      method,
      body
    });
  }
};
