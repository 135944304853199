import {call, put} from 'redux-saga/effects';
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors} from "../../../../utils/handleErrors";
import {profileActions} from "../../actions";
import {authActions} from "../../../auth/actions";
import {push} from "connected-react-router";
import {book} from "../../../../routes/book";
import {placesActions} from "../../../business/actions";

export function* fetchProfile(){
  try {
    const response = yield call(fetchWithToken, {
      endpoint: 'merchants/auth/me',
      method: 'GET'
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    const {data} = yield call([response, 'json']);

    yield put(profileActions.fillProfile(data));
    yield put(placesActions.fetchPlaces(data.id));

  } catch (e) {
    yield put(authActions.clear());
    yield put(push(book.signIn));
    console.log(e);
  }
}
