import React from 'react';
import {Box, Typography} from "@material-ui/core";
import {ChangePasswordFormValues} from "./form/typedefs";
import {defineMessages, useIntl} from "react-intl";
import {types} from "../../../../bus/auth/types";
import {useDispatch} from "react-redux";
import ChangePasswordForm from "./form/ChangePasswordForm";
import {useLocation} from "react-router-dom";

const msg = defineMessages({
  change_password_title: {
    id: "change_password_title",
    defaultMessage: "Change Password"
  },
  change_password_text: {
    id: "change_password_text",
    defaultMessage: "Enter new password to proceed"
  }
});

const ChangePassword = () => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const onSubmit = (values: ChangePasswordFormValues) => {
    const {password} = values;

    dispatch({
      type: types.CHANGE_PASSWORD,
      payload: {
        password: password,
        token: token
      }
    });
  };

  return (
    <>
      <Box mb={1.5}>
        <Typography
          variant="h2">
          {formatMessage(msg.change_password_title)}
        </Typography>
      </Box>
      <Box
        mb={3.5}
        alignItems={'baseline'}
        display={'flex'}>
        <Typography variant={'body1'}>
          {formatMessage(msg.change_password_text)}
        </Typography>
      </Box>
      <ChangePasswordForm onSubmit={onSubmit}/>
    </>
  );
};

export default ChangePassword;
