import {GroupiTheme} from "../../customTheme";
import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  burger: {
    display: 'none',
    [breakpoints.down(1200)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto 20px auto auto',
      width: 72,
      height: 54,
      borderRadius: 8,
      backgroundColor: theme.palette.common.white,
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderColor: theme.palette.common.gray88,
      border: '1px solid',
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: theme.palette.common.gray95
      }
    }
  },
  linesWrapper: {
    position: 'relative',
    height: 18,
    width: 24,
    '& span': {
      transition: '200ms',
      width: 24,
      height: 3,
      left: 0,
      background: theme.palette.common.blue57,
      position: 'absolute',
      '&:nth-child(1)': {
        top: 0
      },
      '&:nth-child(2)': {
        top: '50%',
        transform: 'translateY(-50%)'

      },
      '&:nth-child(3)': {
        bottom: 0
      }
    }
  },
  burgerOpen: {
    transform: 'rotate(45deg)',
    '& span': {
      '&:nth-child(1)': {
        opacity: 1,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      },
      '&:nth-child(2)': {
        opacity: 0
      },
      '&:nth-child(3)': {
        opacity: 1,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(90deg)'
      }
    }
  },
  mobileMenu: {
    display: 'none',
    [breakpoints.down(1200)]: {
      display: 'block'
    },
    '& .MuiBackdrop-root': {
      background: 'none'
    },
    '& .MuiDrawer-paperAnchorRight': {
      width: '100vw',
      height: 'calc(100% - 97px)',
      top: 'unset',
      bottom: 0,
      boxShadow: 'none',
      left: 0,
      overflow: 'hidden'
    }
  }
});
