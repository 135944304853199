import {RootState} from "../../setup/typedefs";
import {createSelector} from "reselect";

export const vouchersSelector = (state: RootState) => state.vouchers;

export const getVoucherPreview = createSelector([vouchersSelector], (result) => {
  return result.voucherPreview;
});

export const getVoucher = createSelector([vouchersSelector], (result) => {
  return result.voucher;
});

export const getVouchersMeta = createSelector([vouchersSelector], (result) => {
  return result.meta;
});

export const getVouchers = createSelector([vouchersSelector], (result) => {
  return result.vouchers;
});
