//Core
import React from 'react';

//Helpers
import {Voucher} from "../../../../bus/vouchers/typedefs";

//Styles
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

const useStyles = makeStyles(styles);

type RenderDescriptionProps = {
  data: Voucher
}

const RenderDescription: React.FC<RenderDescriptionProps> = ({data}) => {
  const classes = useStyles();

  if (!data.description) {
    return <></>;
  }

  return (
    <div className={classes.description}>
      <Typography variant={'subtitle2'}>
        Description
      </Typography>
      <Typography variant={'body2'}>
        {data.description}
      </Typography>
    </div>
  );
};

export default RenderDescription;