import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const HighFive = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 32 32">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3695 7.3377C18.3804 7.34125 18.3913 7.34501 18.4022 7.34897C18.3913 7.34501 18.3804 7.34125 18.3695 7.3377ZM28.0694 6.64765L28.0585 6.64369C28.3027 5.24646 27.5187 3.83276 26.1394 3.33073C25.1002 2.9525 23.9873 3.18787 23.1975 3.85177C23.091 3.79771 22.98 3.74947 22.8649 3.70757C21.3277 3.14809 19.6292 3.93124 19.0539 5.458C17.5145 4.91733 15.8228 5.71509 15.2633 7.25227L11.8548 16.6169C10.2038 21.153 12.5426 26.1686 17.0787 27.8196C20.1281 28.9295 23.5415 28.2585 25.9439 26.077L30.7415 21.7205L32.0036 20.5744L31.0718 19.1467C30.2432 17.8771 28.9056 17.1134 27.484 16.9769L29.8521 10.4706C30.4155 8.92267 29.6174 7.21106 28.0694 6.64765Z"
          fill={props.fill}
        />
        <path
          d="M4.32534 7.58739C4.65469 7.46752 4.99517 7.44037 5.31821 7.4926L5.0704 6.81173C4.69588 5.78274 5.22642 4.64499 6.25541 4.27047C7.20502 3.92484 8.24728 4.35004 8.69678 5.22646C8.91217 4.97247 9.19456 4.76937 9.52989 4.64732C10.5589 4.2728 11.6966 4.80334 12.0712 5.83233L12.487 6.97492C12.7009 6.72726 12.9792 6.5292 13.3085 6.40933C14.3375 6.03481 15.4753 6.56536 15.8498 7.59434L19.2582 16.959C20.7203 20.9761 18.6491 25.4178 14.632 26.8799C11.9315 27.8628 8.90869 27.2687 6.78113 25.3367L1.98356 20.9802L1.35251 20.4072L1.81838 19.6933C2.74053 18.2804 4.4576 17.6575 6.0382 18.0905L3.14033 10.1287C2.76581 9.09967 3.29636 7.96191 4.32534 7.58739Z"
          fill={props.fill}
          stroke={props.fill}
          strokeWidth="2"
        />
        <path
          d="M8.61575 16.502L5.92691 9.11442C5.74128 8.60442 5.17736 8.34146 4.66736 8.52708C4.15736 8.71271 3.8944 9.27663 4.08002 9.78663L7.77719 19.9445L6.22513 19.2208C4.94752 18.625 3.42627 19.0594 2.65582 20.2399L7.45339 24.5964C9.30608 26.2787 11.9384 26.7962 14.29 25.9402C17.7881 24.667 19.5918 20.7991 18.3185 17.301L14.9101 7.93636C14.7245 7.42635 14.1606 7.16339 13.6506 7.34902C13.1406 7.53465 12.8776 8.09856 13.0632 8.60857L15.0799 14.1492L14.1564 14.4853L11.1315 6.17435C10.9458 5.66434 10.3819 5.40138 9.87191 5.58701C9.36191 5.77263 9.09895 6.33655 9.28457 6.84656L12.3095 15.1575L11.3861 15.4936L7.85697 5.7975C7.67135 5.28749 7.10743 5.02454 6.59743 5.21016C6.08742 5.39579 5.82446 5.9597 6.01009 6.46971L9.5392 16.1659L8.61575 16.502Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="32"
            height="32"
            fill="white"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default HighFive;