import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  root: {
    maxWidth: '30%',
    width: '100%',
    [breakpoints.down(768)]: {
      maxWidth: '100%'
    }
  },
  label: {
    textAlign: 'center',
    marginBottom: 12,
    [breakpoints.down(600)]: {}
  },
  input: {
    '& input': {
      textAlign: 'center'
    }
  }
});

