//Core
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

//Helpers
import {getPlace} from "../../../bus/business/selectors";
import {
  accountItems,
  businessItems,
  marketingItems,
  offersItems,
  SideBarLinkType/*, vouchersItems*/
} from "../navigationSetUp";

const accordionPages = [
  {
    name: 'Account',
    expand: true,
    content: accountItems,
    disabled: false
  },
  {
    name: 'Business',
    expand: true,
    content: businessItems,
    disabled: false
  },
  {
    name: 'Deals',
    expand: true,
    content: offersItems,
    disabled: false
  },
  {
    name: 'Marketing',
    expand: true,
    content: marketingItems,
    disabled: false
  }
  // {
  //   name: 'Vouchers',
  //   expand: true,
  //   content: vouchersItems,
  //   disabled: false
  // }
];

export type AccordionPageType = {
  name: string,
  expand: boolean,
  content: SideBarLinkType[],
  disabled: boolean
}

export const useSidebar = () => {
  const place = useSelector(getPlace);

  const [accordionList, setAccordionList] = useState<AccordionPageType[]>();

  useEffect(() => {
    if (accordionPages?.length) {
      const tmpAccordion = accordionPages?.map((item) => {
        if (item.name === 'Deals' || item.name === 'Vouchers') {
          return ({
            ...item,
            disabled: !place
          });
        } else {
          return item;
        }
      });
      setAccordionList(tmpAccordion);
    }
  }, [place, accordionPages]);

  return {
    accordionList
  };
};
