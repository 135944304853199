import { all, call, takeEvery } from 'redux-saga/effects';
import {
  fetchVoucher,
  removeVoucher,
  createVoucher,
  saveVoucherCover,
  updateVoucher,
  saveVoucherTags,
  fetchVouchers,
  createPreview,
  removeVoucherCover
} from "./workers";
import {types} from "../types";

function * watchCreateVoucher() {
  yield takeEvery(types.CREATE_VOUCHER, createVoucher);
}

function * watchSaveVoucherTags() {
  yield takeEvery(types.SAVE_VOUCHER_TAGS, saveVoucherTags);
}

function * watchSaveVoucherCover() {
  yield takeEvery(types.SAVE_VOUCHER_COVER, saveVoucherCover);
}

function * watchRemoveVoucherCover() {
  yield takeEvery(types.REMOVE_VOUCHER_COVER, removeVoucherCover);
}

function * watchFetchVoucher() {
  yield takeEvery(types.FETCH_VOUCHER, fetchVoucher);
}

function * watchUpdateVoucher() {
  yield takeEvery(types.UPDATE_VOUCHER, updateVoucher);
}

function * watchRemoveVoucher() {
  yield takeEvery(types.REMOVE_VOUCHER, removeVoucher);
}

function * watchFetchVouchers() {
  yield takeEvery(types.FETCH_VOUCHERS, fetchVouchers);
}

function * watchCreatePreview() {
  yield takeEvery(types.CREATE_PREVIEW, createPreview);
}

export function* watchVouchers(){
  yield all([
    call(watchCreateVoucher),
    call(watchCreatePreview),
    call(watchFetchVoucher),
    call(watchUpdateVoucher),
    call(watchRemoveVoucher),
    call(watchSaveVoucherTags),
    call(watchSaveVoucherCover),
    call(watchRemoveVoucherCover),
    call(watchFetchVouchers)
  ]);
}