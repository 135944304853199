import {types} from "./types";
import {IProfile} from "./typedefs";
import {ContactPageFormValues} from "../../containers/AccountContainer/pages/ContactPage/form/typedefs";

export const profileActions = {
  changePassword: (data: any) => {
    return {
      type: types.CHANGE_PASSWORD,
      payload: data
    };
  },
  contactAdmin: (data: ContactPageFormValues) => {
    return {
      type: types.CONTACT_ADMIN,
      payload: data
    };
  },
  fillProfile: (data: IProfile) => {
    return {
      type: types.FILL_PROFILE,
      payload: data
    };
  },
  fetchProfile: () => {
    return {
      type: types.FETCH_PROFILE
    };
  },
  clear: () => {
    return {
      type: types.CLEAR
    };
  }
};
