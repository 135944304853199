import React, {useEffect} from 'react';
import {Route, Switch, useHistory} from "react-router-dom";
import {book} from "./book";
import PopUpDialog from "../components/PopupHolder";
import Welcome from "../containers/AuthContainer/pages/Welcome";
import {useDispatch, useSelector} from "react-redux";
import {optionsActions} from "../bus/options/actions";
import {notificationsActions} from "../bus/notifications/actions";
import {profileID} from "../bus/profile/selectors";
import {auth} from "../bus/auth/selectors";
import PageRouter from "../containers/PageContainer/PageRouter";
import VerifyEmail from "../containers/AuthContainer/pages/VerifyEmail";
import TermsAndConditions from "../containers/TermsAndConditionsContainer/pages/TermsAndConditions";

const PrivateRouter = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const merchantID = useSelector(profileID);
  const {token} = useSelector(auth);

  useEffect(() => {
    return history.listen(() => {
      token && merchantID && dispatch(notificationsActions.fetchNotifications({merchantID}));
    });
  });

  useEffect(() => {
    dispatch(optionsActions.getAllOptions());
  },[]);

  return (
    <>
      <PopUpDialog/>
      <Switch>
        <Route path={book.verifyEmail}>
          <VerifyEmail/>
        </Route>
        <Route path={book.termsAndConditions.forSite}>
          <TermsAndConditions/>
        </Route>
        <Route path={book.welcome}>
          <Welcome/>
        </Route>
        <Route path={'/'}>
          <PageRouter/>
        </Route>
      </Switch>
    </>
  );
};

export default PrivateRouter;
