import {call, put} from 'redux-saga/effects';
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors} from "../../../../utils/handleErrors";
import {placesActions} from "../../actions";
import {Workers} from "../../../../setup/typedefs";
import {uiActions} from "../../../ui/actions";

export function* fetchGooglePlace({payload: googlePlaceID}: Workers){
  try {
    yield put(uiActions.loading(true));
    const response = yield call(fetchWithToken, {
      endpoint: `google-places/${googlePlaceID}`,
      method: 'GET'
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    const {data} = yield call([response, 'json']);
    yield put (placesActions.clearPreview());
    yield put(placesActions.fillPlaceFromGoogle({
      ...data,
      googlePlaceUnique: googlePlaceID
    }));

  } catch (e) {
    console.log(e);
  } finally {
    yield put(uiActions.loading(false));
  }
}
