import React from 'react';
import SvgIcon, {SvgIconProps} from "@material-ui/core/SvgIcon";

const SimpleDot = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 8 8">
      <circle
        cx="4"
        cy="4"
        r="4"
      />
    </SvgIcon>
  );
};

export default SimpleDot;