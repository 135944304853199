import {createStyles} from "@material-ui/core/styles";
import {GroupiTheme} from "../../customTheme";
import {createMuiTheme} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    minHeight: 76,
    marginBottom: 10,
    padding: '10px 20px 10px 136px',
    boxSizing: 'border-box',
    border: '1px solid',
    overflow: 'hidden',
    borderRadius: 8,
    alignItems: 'center',
    position: 'relative',
    [breakpoints.down(600)]: {
      minHeight: 60,
      padding: '10px 18px 10px 76px'
    },
    '& .MuiAlert-icon': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 116,
      height: '100%',
      boxSizing: 'border-box',
      padding: 0,
      borderRadius: '0 8px 8px 0',
      marginRight: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [breakpoints.down(600)]: {
        width: 60
      }
    },
    '& .MuiAlert-message': {
      boxSizing: 'border-box',
      padding: 0,
      maxWidth: 'calc(100% - 35px)',
      color: theme.palette.common.black,
      '& a': {
        color: theme.palette.common.blue50
      },
      [breakpoints.down(600)]: {
        maxWidth: 'calc(100% - 35px)'
      }
    },
    '& .MuiAlert-action': {
      margin: '0 0 0 auto',
      padding: 0,
      width: 24,
      height: 24
    },
    '&.MuiAlert-standardSuccess': {
      background: '#EFFAF3',
      borderColor: theme.palette.common.green,
      '& .MuiAlert-icon': {
        background: theme.palette.common.green
      }
    },
    '&.MuiAlert-standardWarning': {
      background: '#FFF4CF',
      borderColor: theme.palette.common.yellow,
      '& .MuiAlert-icon': {
        background: theme.palette.common.orange
      }
    },
    '&.MuiAlert-standardError': {
      background: '#FFE1E3',
      borderColor: theme.palette.common.red,
      '& .MuiAlert-icon': {
        background: theme.palette.common.red
      }
    }
  }
});