import {RootState} from "../../setup/typedefs";
import {createSelector} from "reselect";

export const placesSelector = (state: RootState) => state.places;

export const getPlace = createSelector([placesSelector], (result) => {
  return result.place;
});

export const getPlaces = createSelector([placesSelector], (result) => {
  return result.places;
});

export const getPlaceFromGoogle = createSelector([placesSelector], (result) => {
  return result.placeFromGoogle;
});

export const getPlacePreview = createSelector([placesSelector], (result) => {
  return result.placePreview;
});