import {types} from "./types";
import {AdditionalActionInfo, ListStatus, Meta} from "../../setup/typedefs";
import {Offer} from "./typedefs";

export const offersActions = {
  fetchOffer: (id: number) => {
    return {
      type: types.FETCH_OFFER,
      offerID: id
    };
  },
  fillOffer: (data: Offer) => {
    return {
      type: types.FILL_OFFER,
      payload: data
    };
  },
  fillOfferViews: (data: Number[]) => {
    return {
      type: types.FILL_OFFER_VIEWS,
      payload: data
    };
  },
  fillOfferFavorites: (data: Number[]) => {
    return {
      type: types.FILL_OFFER_FAVORITES,
      payload: data
    };
  },
  fillOfferReservations: (data: Number[]) => {
    return {
      type: types.FILL_OFFER_RESERVATIONS,
      payload: data
    };
  },
  fetchOffers: (data: {placeID: number, page: number, perPage: number, status: ListStatus}) => {
    return {
      type: types.FETCH_OFFERS,
      payload: data
    };
  },
  fillOffers: (data: Offer[], meta: Meta) => {
    return {
      type: types.FILL_OFFERS,
      payload: data,
      meta
    };
  },
  createPreview: (data: Offer) => {
    return {
      type: types.CREATE_PREVIEW,
      payload: data
    };
  },
  fillPreview: (data: Offer) => {
    return {
      type: types.FILL_PREVIEW,
      payload: data
    };
  },
  updateOffer: (data: {formData: Partial<Offer>, info: Partial<AdditionalActionInfo> }) => {
    return {
      type: types.UPDATE_OFFER,
      payload: data
    };
  },
  removeOffer: (payload: {offerID: number}) => {
    return {
      type: types.REMOVE_OFFER,
      payload: payload
    };
  },
  clearPreview: () => {
    return {
      type: types.CLEAR_PREVIEW
    };
  },
  clear: () => {
    return {
      type: types.CLEAR
    };
  }
};
