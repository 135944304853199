import {createStyles} from "@material-ui/core/styles";
import {GroupiTheme} from "../../customTheme";
import {createMuiTheme} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    padding: 0,
    margin: 0,
    boxSizing: 'border-box',
    maxWidth: 272,
    width: '100%',
    position: 'relative',
    [breakpoints.down(768)]: {
      maxWidth: 'calc((100% - 30px) / 2)'
    },
    '& .react-datepicker-wrapper, & .react-datepicker__input-container, & input': {
      padding: 0,
      margin: 0,
      boxSizing: 'border-box',
      width: '100%'
    },

    '& input': {
      textAlign: 'center',
      background: theme.palette.common.white,
      height: 48,
      borderRadius: 8,
      border: '1px solid',
      borderColor: theme.palette.common.gray88,
      fontFamily: 'Source Sans Pro, serif',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: '24px',
      color: theme.palette.common.black,
      '&:disabled': {
        background: theme.palette.common.gray95,
        border: 'none'
      }
    },

    '& .react-datepicker': {
      border: 'none',
      boxSizing: 'border-box',
      boxShadow: '0 4px 12px rgba(50, 77, 103, 0.25)',
      background: theme.palette.common.white,
      borderRadius: 8
    },

    '& .react-datepicker__header': {
      background: theme.palette.common.white,
      border: 'none',
      padding: '15px 15px 0'
    },

    '& .react-datepicker__navigation--next, & .react-datepicker__navigation--previous': {
      width: 10,
      height: 10,
      padding: 0,
      transform: 'rotate(-135deg)',
      border: '2px none',
      borderColor: theme.palette.common.black,
      margin: 0,
      outline: 'none',
      top: 20,
      '&:hover': {
        borderColor: '#0d131c8a'
      }
    },

    '& .react-datepicker__navigation--next': {
      borderBottomStyle: 'solid',
      borderLeftStyle: 'solid',
      right: 20
    },

    '& .react-datepicker__navigation--previous': {
      borderTopStyle: 'solid',
      borderRightStyle: 'solid',
      left: 'unset',
      right: 50
    },

    '& .react-datepicker__current-month': {
      textAlign: 'left',
      marginLeft: 5,
      marginBottom: 10
    },

    '& .react-datepicker__triangle': {
      display: 'none'
    },

    '& .react-datepicker__month': {
      margin: '0 15px 15px'
    },

    '& .react-datepicker__day--outside-month': {
      color: theme.palette.common.gray60,
      '&.react-datepicker__day--in-range': {
        color: theme.palette.common.white
      }
    }
  },
  cross: {
    position: 'absolute',
    right: 8,
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer'
  }
});