import {types} from "./types";
import {Place, PlaceFromGoogle} from "./typedefs";
import {AdditionalActionInfo, Nullable} from "../../setup/typedefs";

export const placesActions = {
  fetchGooglePlace: (data: string) => {
    return {
      type: types.FETCH_GOOGLE_PLACE,
      payload: data
    };
  },
  reassignPlace: (data: {mail: string, placeId: number, userId: number, redirectUrl: string}) => {
    return {
      type: types.REASSIGN_PLACE,
      payload: data
    };
  },
  clearGooglePlace: () => {
    return {
      type: types.CLEAR_GOOGLE_PLACE
    };
  },
  fillPlaceFromGoogle: (data: Nullable<PlaceFromGoogle>) => {
    return {
      type: types.FILL_PLACE_FROM_GOOGLE,
      payload: data
    };
  },
  fetchPlaces: (data: number) => {
    return {
      type: types.FETCH_PLACES,
      payload: data
    };
  },
  fillPlaces: (data: Place[]) => {
    return {
      type: types.FILL_PLACES,
      payload: data
    };
  },
  fetchPlace: (data: {id: number}) => {
    return {
      type: types.FETCH_PLACE,
      payload: data
    };
  },
  fillPlace: (data: Partial<Place>) => {
    return {
      type: types.FILL_PLACE,
      payload: data
    };
  },
  fetchPlaceTags: (data: {id: number}) => {
    return {
      type: types.FETCH_PLACES_TAGS,
      payload: data
    };
  },
  createPreview: (data: Place) => {
    return {
      type: types.CREATE_PREVIEW,
      payload: data
    };
  },
  fillPreview: (data: Place) => {
    return {
      type: types.FILL_PREVIEW,
      payload: data
    };
  },
  updatePlace: (data: {formData: Place, info: Partial<AdditionalActionInfo>}) => {
    return {
      type: types.UPDATE_PLACE,
      payload: data
    };
  },
  clearPreview: () => {
    return {
      type: types.CLEAR_PREVIEW
    };
  },
  clear: () => {
    return {
      type: types.CLEAR
    };
  }
};