import React from 'react';
import {Checkbox, CheckboxProps, withStyles} from "@material-ui/core";
import themeOptions from "../../customTheme";

const CustomCheckbox = withStyles({
  root: {
    color: themeOptions.palette.common.gray88
  },
  checked: {
    color: themeOptions.palette.common.blue50
  }
})((props: CheckboxProps) => (
  <Checkbox
    {...props}
    color="default"
  />
));

const CheckboxBlueSquare = (props: CheckboxProps) => {
  return (
    <CustomCheckbox
      {...props}
      color="default"
    />
  );
};

export default CheckboxBlueSquare;
