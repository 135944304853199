import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    [breakpoints.down(600)]: {
      flexDirection: 'column'
    },
    '& .MuiFormControl-root': {
      width: 450,
      [breakpoints.down(800)]: {
        width: 'calc(100% - 30px)'
      },
      [breakpoints.down(600)]: {
        width: '100%',
        marginBottom: 20
      },
      '& .MuiOutlinedInput-root': {
        background: theme.palette.common.white,
        height: 56
      }
    },
    '& .MuiButton-outlinedPrimary': {
      '& .MuiButton-label': {
        color: theme.palette.common.white
      },
      '&:hover': {
        borderColor: theme.palette.common.white,
        border: '1px solid',
        background: 'rgba(255,255,255,0.4)!important'
      },
      '&:active': {
        background: 'rgba(255,255,255,0.8)!important'
      }
    }
  }
});
