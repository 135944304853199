import React from 'react';
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const TextBlank = ({percent}: any) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {percent}
      % off
    </div>
  );
};

export default TextBlank;