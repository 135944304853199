import React from 'react';
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "../RenderSingleOffer/styles";

const useStyles = makeStyles(styles);

type StatisticsWrapRowProps = {
  value: number,
  title: string
}

const StatisticsWrapRow: React.FC<StatisticsWrapRowProps> = ({value, title}) => {
  const classes = useStyles();

  return (
    <div className={classes.statisticsItem}>
      <Typography variant={'h2'}>
        {value}
      </Typography>
      <Typography variant={'body2'}>
        {title}
      </Typography>
    </div>
  );
};

export default StatisticsWrapRow;