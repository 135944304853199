import React from 'react';
import Typography from "@material-ui/core/Typography";
import {styles} from './styles';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(styles);

const TypographyCustom = ({color, children, variant='body3'}: TypographyCustomProps ) => {
  const classes = useStyles();

  return (
    <Typography
      className={classes[variant]}
      style={{color: color}}>
      {children}
    </Typography>
  );
};

export default TypographyCustom;

interface TypographyCustomProps {
    variant?: | 'body3' | 'button1' | 'button2' | 'link',
    children: string,
    color?: string
}