import {useEffect, useState} from "react";
import {InjectedFormProps} from "redux-form";
import {types} from "../../../../../../bus/profile/types";
import {useDispatch} from "react-redux";

export const usePasswordForm = (props: InjectedFormProps) => {
  const dispatch = useDispatch();
  const {dirty} = props;

  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setIsChanged(dirty);
  }, [dirty]);

  const savePass = (values: any) => {
    const {oldPassword, newPassword} = values;

    setIsChanged(false);

    dispatch({
      type: types.CHANGE_PASSWORD,
      payload: {
        newPassword: newPassword,
        oldPassword: oldPassword
      }
    });
  };

  return {savePass, isChanged};
};
