import React from 'react';
import schema from "./schema";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import syncValidate from "../../../../../utils/syncValidate";
import {Button, Grid} from "@material-ui/core";
import RenderInputEmailAddress from "./formFields/RenderInputEmailAddress";
import RenderInputSubject from "./formFields/RenderInputSubject";
import RenderInputDescribe from "./formFields/RenderInputDescribe";
import FormDivider from "../../../../../components/FormDivider";

const useStyles = makeStyles(styles);

const ContactPageForm = (props: InjectedFormProps) => {
  const classes = useStyles();
  const {valid, handleSubmit} = props;

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={3}>
        <FormDivider/>
        <Field
          name={'emailAddress'}
          component={RenderInputEmailAddress}
        />
        <Field
          name={'subject'}
          component={RenderInputSubject}
        />
        <Field
          name={'describe'}
          component={RenderInputDescribe}
        />
      </Grid>
      <div className={classes.buttonWrapper}>
        <Button
          className={classes.submitButton}
          disabled={!valid}
          type='submit'
          variant="contained"
          color="primary">
          Submit
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'ContactPageForm',
  validate: syncValidate(schema)
})(ContactPageForm);
