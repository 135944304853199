import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const LogOut = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3867 15.9723L26.735 16.6783L23.0427 20.6783C22.6681 21.0841 22.0355 21.1094 21.6296 20.7348C21.2238 20.3602 21.1985 19.7275 21.5731 19.3217L23.7162 17L13.0002 17C12.4479 17 12.0002 16.5523 12.0002 16C12.0002 15.4477 12.4479 15 13.0002 15L23.586 15L21.2931 12.7071C20.9026 12.3166 20.9026 11.6834 21.2931 11.2929C21.6836 10.9024 22.3168 10.9024 22.7073 11.2929L26.7073 15.2929L27.3867 15.9723ZM15.0002 8C15.5525 8 16.0002 7.55228 16.0002 7C16.0002 6.44771 15.5525 6 15.0002 6L7.00023 6C6.44794 6 6.00023 6.44771 6.00023 7L6.00022 25C6.00022 25.5523 6.44794 26 7.00022 26L15.0002 26C15.5525 26 16.0002 25.5523 16.0002 25C16.0002 24.4477 15.5525 24 15.0002 24L8.00022 24L8.00022 8L15.0002 8Z"
      />
    </SvgIcon>
  );
};

export default LogOut;