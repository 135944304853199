import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  container: {
    width: 744,
    margin: '0 auto',
    paddingTop: 50,
    [breakpoints.down(784)]: {
      width: 'calc(100% - 30px)',
      paddingTop: 60
    }
  },
  decorWrapper: {
    overflow: 'hidden',
    position: 'fixed',
    width: '100vw',
    height: '90vh',
    top: 0,
    left: 0,
    zIndex: -1
  },
  decor1: {
    background: theme.palette.common.gradient,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0
  },
  decor2: {
    background: theme.palette.common.white,
    width: '100%',
    height: 200,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderTopRightRadius: 200
  },
  decor3: {
    background: theme.palette.common.white,
    width: '100%',
    height: 300,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderTopRightRadius: 200,
    opacity: 0.3
  },
  decor4: {
    display: 'block',
    width: '200px',
    height: '100px',
    bottom: 200,
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    '&:before': {
      content: '""',
      display: 'block',
      width: '200%',
      height: '200%',
      position: 'absolute',
      borderRadius: '50%',
      bottom: 0,
      left: 0,
      boxShadow: '-50px 50px 0 0 white'
    },
    [breakpoints.down(400)]: {
      width: '100px'
    }
  },
  decor5: {
    display: 'block',
    width: '200px',
    height: '100px',
    bottom: 300,
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    opacity: 0.3,
    '&:before': {
      content: '""',
      display: 'block',
      width: '200%',
      height: '200%',
      position: 'absolute',
      borderRadius: '50%',
      bottom: 0,
      left: 0,
      boxShadow: '-50px 50px 0 0 white'
    },
    [breakpoints.down(400)]: {
      width: '100px'
    }
  },
  title: {
    color: 'white',
    marginBottom: 40,
    [breakpoints.down(784)]: {
      marginBottom: 20
    }
  },
  subTitle: {
    marginBottom: 20,
    color: theme.palette.common.gray75
  },
  accordionFaq: {
    border: '1px solid #000'
  }
});
