import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  container: {
    width: 744,
    margin: '0 auto',
    paddingTop: 50,
    [breakpoints.down(784)]: {
      width: '100%',
      paddingTop: 60
    },
    '& .MuiTabs-flexContainer': {
      borderBottom: '1px solid',
      borderBottomColor: '#C4C4C4'
    },
    '& .MuiTab-wrapper': {
      fontFamily: 'Source Sans Pro',
      fontWeight: '600',
      fontSize: 16,
      lineHeight: '21px'
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#0080FF'
    }
  },
  title: {
    marginBottom: 40,
    [breakpoints.down(784)]: {
      marginBottom: 20
    }
  }
});
