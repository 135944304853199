import React from 'react';
import IconHolder from "../IconHolder";
import Document from "../Icons/Document";
import {Button, Typography, useTheme} from "@material-ui/core";
import {styles} from "./styles";
import {GroupiTheme} from "../../customTheme";
import {makeStyles} from "@material-ui/core/styles";
import Trash from "../Icons/Trash";
import SingleDocumentStatus from "../SingleDocumentStatus";
import clsx from "clsx";

const useStyles = makeStyles(styles);

type SingleDocumentProps = {
  file: any,
  removeImg: any,
  disabledForEdit?: boolean,
  errors?: any
}

const SingleDocument: React.FC<SingleDocumentProps> = ({file, removeImg, disabledForEdit, errors}) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();

  return (
    <div className={classes.singleDocument}>
      <IconHolder
        size={24}
        color={theme.palette.common.blue50}>
        <Document/>
      </IconHolder>
      <div className={
        clsx(classes.name, {
          [classes.uploaded]: !errors,
          [classes.attention]: !!errors
        })
      }>
        <Typography variant={'body2'}>
          {file?.name}
        </Typography>
      </div>
      {
        !disabledForEdit && (
          <>
            <div className={classes.singleDocumentStatus}>
              <SingleDocumentStatus errors={errors}/>
            </div>
            <div className={classes.remove}>
              <Button
                onClick={()=>{removeImg(file.index);}}
                variant="outlined"
                color="inherit"
                fullWidth
                startIcon={(
                  <IconHolder
                    color={theme.palette.common.blue50}>
                    <Trash/>
                  </IconHolder>
                )}
              />
            </div>
          </>
        )
      }
    </div>
  );
};

export default SingleDocument;
