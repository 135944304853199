import { all, call } from 'redux-saga/effects';
import {watchOptions} from "../bus/options/saga/watchers";
import {watchAuth} from "../bus/auth/saga/watchers";
import {watchUi} from "../bus/ui/saga/watchers";
import {watchNotifications} from "../bus/notifications/saga/watchers";
import {watchPlaces} from "../bus/business/saga/watchers";
import {watchOffers} from "../bus/offers/saga/watchers";
import {watchAccount} from "../bus/profile/saga/watchers";
import {watchVouchers} from "../bus/vouchers/saga/watchers";

export function* rootSaga(){
  yield all([
    call(watchNotifications),
    call(watchOptions),
    call(watchAuth),
    call(watchUi),
    call(watchPlaces),
    call(watchOffers),
    call(watchVouchers),
    call(watchAccount)
  ]);
}