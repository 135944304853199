import {call, put} from "redux-saga/effects";
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors} from "../../../../utils/handleErrors";
import {notificationsActions} from "../../actions";
import {Workers} from "../../../../setup/typedefs";

export function* fetchNotifications({payload}: Workers){
  const {merchantID} = payload;

  try {
    const response = yield call(fetchWithToken, {
      endpoint: `merchants/${merchantID}/notifications`,
      method: 'GET'
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    const {data} = yield call([response, 'json']);
    yield put(notificationsActions.fillNotifications(data));

  } catch (e) {
    console.log(e);
  }
}
