import {createStyles} from "@material-ui/core/styles";
import {GroupiTheme} from "../../customTheme";
import {createMuiTheme} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    position: 'relative',
    [breakpoints.down(768)]: {}
  },
  innerLimitText: {
    position: 'absolute',
    bottom: 6,
    right: 6
  }
});
