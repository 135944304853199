import {call} from "redux-saga/effects";
import {fetchFileWithToken} from "../../../utils/fetching";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {Workers} from "../../../../setup/typedefs";

export function* saveVoucherCover({payload}: Workers){
  try {
    const {formData, voucherID} = payload;

    const response = yield call(fetchFileWithToken, {
      endpoint: `vouchers/${voucherID}/cover`,
      method: 'PUT',
      type: 'singleCover',
      body: formData.cover
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    yield call(handleMsg, 'New Cover added');

  } catch (e) {
    console.log(e);
  }
}