import React from 'react';
import {Box, Typography} from "@material-ui/core";
import {ResetPasswordFormValues} from "./form/typedefs";
import {defineMessages, useIntl} from "react-intl";
import {types} from "../../../../bus/auth/types";
import {useDispatch} from "react-redux";
import ResetPasswordForm from "./form/ResetPasswordForm";
import {book} from "../../../../routes/book";

const msg = defineMessages({
  reset_password_title: {
    id: "reset_password_title",
    defaultMessage: "Forgot your password"
  },
  reset_password_text: {
    id: "reset_password_text",
    defaultMessage: "Enter your email address and we will send you a link to reset your password."
  }
});

const ResetPassword = () => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const onSubmit = (values: ResetPasswordFormValues) => {
    const {email} = values;

    dispatch({
      type: types.RESET_PASSWORD,
      payload: {
        email: email,
        redirectUrl: window.location.origin + book.changePassword
      }
    });
  };

  return (
    <>
      <Box mb={1.5}>
        <Typography
          variant="h2">
          {formatMessage(msg.reset_password_title)}
        </Typography>
      </Box>
      <Box
        mb={3.5}
        alignItems={'baseline'}
        display={'flex'}>
        <Typography variant={'body1'}>
          {formatMessage(msg.reset_password_text)}
        </Typography>
      </Box>
      <ResetPasswordForm onSubmit={onSubmit}/>
    </>
  );
};

export default ResetPassword;
