import React from 'react';
import {SvgIconProps} from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";

const AirplanePathDesktop = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 457 154">
      <path
        fill="none"
        d="M-9.53025e-07 124.732C140.498 124.732 172.496 45.2318 261.998 45.2318C379.498 45.2318 404.5 153.232 321.5 153.232C238.5 153.232 273 -11.2682 456 1.73184"
        stroke="white"
        strokeDasharray="5 10"
      />
    </SvgIcon>
  );
};

export default AirplanePathDesktop;
