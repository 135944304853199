import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  inputsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
    [breakpoints.down(575)]: {}
  },
  rootWrapper: {
    marginTop: 14
  }
});
