//Core
import React, {ReactNode} from 'react';

//styles
import {Typography, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import {GroupiTheme} from "../../../../customTheme";
import IconHolder from "../../../../components/IconHolder";

const useStyles = makeStyles(styles);

const WrapRow: React.FC<{icon: ReactNode, content: string}> = ({icon, content}) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();

  if (!content) {
    return <></>;
  }

  return (
    <div className={classes.innerRow}>
      <IconHolder
        size={24}
        color={theme.palette.common.blue50}>
        {icon}
      </IconHolder>
      <Typography variant={'subtitle2'}>
        {content}
      </Typography>
    </div>
  );
};

export default WrapRow;