//Core
import React from 'react';

//Helpers
import {ListStatus} from "../../../../../setup/typedefs";
import {book} from "../../../../../routes/book";

//Components
import ListOfOffers from "../ListOfOffers";

const DraftOffers = () => {
  return (
    <ListOfOffers
      status={ListStatus.Draft}
      paginationPath={book.draftDeals}
      title={'Draft'}
    />
  );
};

export default DraftOffers;