import React, {useState} from 'react';
import {styles} from "./styles";
import {Tab, Tabs, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Personal from "../Personal";
import Password from "../Password";

const useStyles = makeStyles(styles);

function TabPanel(props: any) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
}

const tabsList = [
  {
    index: 0,
    title: 'Personal data'
  },
  {
    index: 1,
    title: 'Password'
  }
];

function tabsProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const AccountTabs = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [title, setTitle] = useState<any>('Personal data');

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
    const title = tabsList.filter((item) => newValue === item.index);
    setTitle(title[0].title);
  };

  return (
    <div className={classes.container}>
      <Typography
        className={classes.title}
        variant={'h1'}>
        {title}
      </Typography>
      <Tabs
        value={value}
        onChange={handleChange}>
        <Tab
          label="Personal"
          {...tabsProps(0)}
        />
        <Tab
          label="Password"
          {...tabsProps(1)}
        />
      </Tabs>
      <TabPanel
        value={value}
        index={0}>
        <Personal/>
      </TabPanel>
      <TabPanel
        value={value}
        index={1}>
        <Password/>
      </TabPanel>
    </div>
  );
};

export default AccountTabs;
