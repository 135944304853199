import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../components/FieldRow";
import InputField from "../../../../../components/InputField";

const RenderInputReassignEmail = ({...props}:WrappedFieldProps) => {
  return (
    <FieldRow
      labelText={'Reassign'}
      isRequired={true}>
      <InputField
        {...props}
        type={'text'}
      />
    </FieldRow>
  );
};

export default RenderInputReassignEmail;
