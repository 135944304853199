//Core
import React from 'react';

//Helpers
import {useSingleOffer} from "./customHooks/useSingleOffer";

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import RenderSingleOffer from "./components/RenderSingleOffer";
import ButtonBackBlue from "../../../../components/ButtonBackBlue";

const useStyles = makeStyles(styles);

const SingleOffer = () => {
  const classes = useStyles();
  const {data} = useSingleOffer();

  if (!data) {
    return (
      <p>
        Sorry nothing to render
      </p>
    );
  }

  return (
    <div className={classes.container}>
      <ButtonBackBlue/>
      <RenderSingleOffer data={data}/>
    </div>
  );
};

export default SingleOffer;
