//Core
import React from 'react';
import YouTube from "@u-wave/react-youtube";

//Styles
import {Typography, useTheme} from "@material-ui/core";
import {GroupiTheme} from "../../../../../../customTheme";
import {styles} from "./styles";

//Components
import IconHolder from "../../../../../../components/IconHolder";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

interface WelcomeInfo {
  children: string,
  headerText: string,
  icon?: React.ReactNode,
  video?: string
  youtube?: boolean
}

const WelcomeRow: React.FC<WelcomeInfo> = (props) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const {
    headerText,
    children,
    icon,
    video,
    youtube = false
  } = props;

  return (
    <div className={classes.rowWrapper}>
      <div className={classes.textWrapper}>
        <IconHolder
          size={80}
          color={theme.palette.common.blue50}>
          {icon}
        </IconHolder>
        <Typography variant={'h3'}>
          {headerText}
        </Typography>
        <Typography variant={'body2'}>
          {children}
        </Typography>
      </div>
      <>
        {
          youtube
            ? (
              <YouTube
                className={classes.videoWrapper}
                width={'100%'}
                height={345}
                video={video}
              />
            )
            : (
              <video
                className={classes.videoWrapper}
                controls
                width={'100%'}
                height={345}
                src={video}
              />
            )
        }
      </>
    </div>
  );
};

export default WelcomeRow;