import {useEffect, useState} from "react";
import useWindowDimensions from "../../../../../utils/customHooks/useWindowDimensions";
import {useDispatch} from "react-redux";
import {offersActions} from "../../../../../bus/offers/actions";
import {Offer} from "../../../../../bus/offers/typedefs";

export const useRenderSingleOffer = (data: Offer) => {
  const dispatch = useDispatch();
  const {width} = useWindowDimensions();
  const [tablet, setTablet] = useState(false);
  const randomImg = Math.floor(Math.random() * Math.floor(3));

  useEffect(() => {
    setTablet(width <= 991 && width >= 575);
  }, [width]);

  const goPreview = () => {
    dispatch(offersActions.createPreview(data));
  };

  return {
    tablet,
    randomImg,
    goPreview
  };
};