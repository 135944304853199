import {types} from "./types";
import {AnyAction} from 'redux';
import {Nullable} from "../../setup/typedefs";

export type AuthState = {
  isAuthenticated: boolean
  token: Nullable<string>
  refreshToken: Nullable<string>
  termsAgree: boolean
}

const initialState: AuthState = {
  isAuthenticated: false,
  token: null,
  refreshToken: null,
  termsAgree: false
};

export const authReducer = (state= initialState, action: AnyAction) => {
  switch (action.type) {
  case types.AUTHENTICATE:
    return {
      ...state,
      isAuthenticated: action.payload
    };

  case types.FILL_TOKENS:
    return {
      ...state,
      ...action.payload
    };

  case types.TERMS_AGREE:
    return {
      ...state,
      termsAgree: action.payload
    };

  case types.CLEAR:
    return initialState;

  default:
    return state;
  }
};
