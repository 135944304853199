import {all, call, put} from "redux-saga/effects";
import {fetchAllTags} from "./fetchAllTags";
import {fetchSettings} from "./fetchSettings";
import {uiActions} from "../../../ui/actions";
import {fetchProfile} from "../../../profile/saga/workers/fetchProfile";

export function* getAllOptions() {
  yield put(uiActions.loading(true));
  yield all([
    call(fetchProfile),
    call(fetchAllTags),
    call(fetchSettings)
  ]);
  yield put(uiActions.loading(false));
}