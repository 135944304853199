//Core
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//Helpers
import {vouchersActions} from "../../../../../bus/vouchers/actions";
import {initialRecreateVoucherFormSelector, recreateVoucherFormSelector} from "../../../../../bus/forms/selectors";
import {getPlace} from "../../../../../bus/business/selectors";
import {actionsWithCover} from "../../../../../utils/helpers";
import {ActionWithFile} from "../../../../../setup/typedefs";
import {Voucher} from "../../../../../bus/vouchers/typedefs";
import {usePreventRedirect} from "./usePreventRedirect";
import {profileIsVerified} from "../../../../../bus/profile/selectors";

export const useVoucherRecreateForm = () => {
  const {saving, isChanged} = usePreventRedirect();
  const dispatch = useDispatch();

  const initialVoucherValue = useSelector(initialRecreateVoucherFormSelector);
  const currentVoucherValue = useSelector(recreateVoucherFormSelector);
  const place = useSelector(getPlace);
  const isVerified = useSelector(profileIsVerified);

  const [allowDraft, setAllowDraft] = useState(false);
  const [actionWithCover, setActionWithCover] = useState<ActionWithFile>('nothing');

  const placeTime = {
    openedTimeEnd: place?.openedTimeEnd,
    openedTimeStart: place?.openedTimeStart
  };

  useEffect(() => {
    currentVoucherValue && setAllowDraft(!!currentVoucherValue.name);
  },[currentVoucherValue]);

  useEffect(() => {
    const action = actionsWithCover(initialVoucherValue, currentVoucherValue, true);
    setActionWithCover(action);
  },[initialVoucherValue, currentVoucherValue]);

  const goPreview = () => {
    dispatch(vouchersActions.createPreview(currentVoucherValue));
  };

  const onSubmit = (values: Voucher | any) => {
    saving();

    if (place?.id) {
      dispatch(vouchersActions.createVoucher({
        formData: {
          ...values,
          isPublished: true
        },
        info: {
          placeID: place?.id,
          actionWithCover: actionWithCover
        }
      }));
    }
  };

  const onDraftSubmit = () => {
    saving();

    if (place?.id) {
      dispatch(vouchersActions.createVoucher({
        formData: {
          ...currentVoucherValue,
          isPublished: false
        },
        info: {
          placeID: place?.id,
          actionWithCover: actionWithCover
        }
      }));
    }
  };

  return {
    goPreview,
    allowDraft,
    placeTime,
    isChanged,
    isVerified,
    onSubmit,
    onDraftSubmit
  };
};
