import {RootState} from "../../setup/typedefs";

//VOUCHERS SELECTORS
//create
export const createVoucherFormSelector = (state: RootState)=>{
  return state.form.VoucherCreateForm?.values;
};

export const initialCreateVoucherFormSelector = (state: RootState)=>{
  return state.form.VoucherCreateForm?.initial;
};

//edit
export const editVoucherFormSelector = (state: RootState)=>{
  return state.form.voucherEditForm?.values;
};

export const initialEditVoucherFormSelector = (state: RootState)=>{
  return state.form.voucherEditForm?.initial;
};

//recreate
export const recreateVoucherFormSelector = (state: RootState)=>{
  return state.form.VoucherRecreateForm?.values;
};

export const initialRecreateVoucherFormSelector = (state: RootState)=>{
  return state.form.VoucherRecreateForm?.initial;
};

//contact Admin
export const contactPageFormSelector = (state: RootState)=>{
  return state.form.ContactPageForm?.values;
};
