import React from 'react';
import {WrappedFieldProps} from "redux-form";
import {MenuItem, Select} from "@material-ui/core";
import FieldRow from "../../../../../../components/FieldRow";
import DropDownArrow from "../../../../../../components/DropDownArrow";

const availabilities = ['everyday', 'weekdays', 'weekends'];

const RenderInputAvailability = ({input}: WrappedFieldProps) => {
  const availabilitiesJSX = availabilities.map((item, index) => {
    return (
      <MenuItem
        key={index}
        value={item}>
        {item}
      </MenuItem>
    );
  });

  return (
    <FieldRow
      isRequired
      labelText={'Availability'}>
      <Select
        IconComponent={DropDownArrow}
        fullWidth
        {...input}
        variant={"outlined"}
        labelId="label"
        id="select">
        {availabilitiesJSX}
      </Select>
    </FieldRow>
  );
};

export default RenderInputAvailability;