import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const ArrowDown = (props: SvgIconProps) => {
  const {htmlColor} = props;

  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 16 16">
      <path
        d="M4 7L8 10L12 7"
        fill="#ffffff"
        stroke={htmlColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default ArrowDown;
