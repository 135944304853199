import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import InputTags from "../../../../../../components/InputTags";

const RenderInputTags: React.FC<WrappedFieldProps & any> = (props) => {
  return (
    <FieldRow
      labelText={'Tags'}
      toolTipText={'Providing this information upfront allows as better understand your business and optimize for a campaign that will suite your unique needs'}>
      <InputTags {...props}/>
    </FieldRow>
  );
};

export default RenderInputTags;
