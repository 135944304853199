import {call, put} from "redux-saga/effects";
import {Workers} from "../../../../setup/typedefs";
import {uiActions} from "../../../ui/actions";
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {vouchersActions} from "../../actions";
import {push} from "connected-react-router";
import {book} from "../../../../routes/book";

export function* removeVoucher({payload}: Workers){
  try {
    const {voucherID} = payload;

    const response = yield call(fetchWithToken, {
      endpoint: `vouchers/${voucherID}`,
      method: 'DELETE'
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    yield call(handleMsg, `Voucher successful delete`);
    yield put(vouchersActions.clear());
    yield put(push(book.activeVouchers));

  } catch (e) {
    console.log(e);
  }
  yield put(uiActions.confirm(false));
}