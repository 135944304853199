import React from 'react';
import PublicRouter from "./PublicRouter";
import PrivateRouter from "./PrivateRouter";
import {useDispatch, useSelector} from "react-redux";
import {authSelector} from "../bus/auth/selectors";
import {useHistory} from "react-router-dom";
import {uiActions} from "../bus/ui/actions";
import {book} from "./book";
import {placesActions} from "../bus/business/actions";
import Preloader from "../components/Preloader";

const RootRouter = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {isAuthenticated} = useSelector(authSelector);

  history.listen((location) => {
    const path = location.pathname;
    dispatch(uiActions.confirm(false));
    if (path !== book.myPlace && path !== book.createMyPlace && path !== book.myPlacePreview) {
      dispatch(placesActions.clearPreview());
      dispatch(placesActions.clearGooglePlace());
    }
  });

  return (
    <Preloader>
      {
        isAuthenticated
          ? <PrivateRouter/>
          : <PublicRouter/>
      }
    </Preloader>
  );
};

export default RootRouter;
