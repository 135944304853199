import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import {book} from "../../routes/book";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import ResetPassword from "./pages/ResetPassword";
import ChangePassword from "./pages/ChangePassword";

const AuthRouter = () => {
  return (
    <Switch>
      <Route path={book.signIn}>
        <SignIn/>
      </Route>
      <Route path={book.signUp}>
        <SignUp/>
      </Route>
      <Route path={book.thankYouPage}>
        <SignIn/>
      </Route>
      <Route path={book.resetPassword}>
        <ResetPassword/>
      </Route>
      <Route path={book.changePassword}>
        <ChangePassword/>
      </Route>
      <Redirect to={book.signIn}/>
    </Switch>
  );
};

export default AuthRouter;
