//Core
import React from 'react';

//Helpers
import {ListStatus} from "../../../../../setup/typedefs";
import {book} from "../../../../../routes/book";

//Components
import ListOfVouchers from "../ListOfVouchers";

const PendingVouchers = () => {
  return (
    <ListOfVouchers
      status={ListStatus.Pending}
      paginationPath={book.pendingVouchers}
      title={'Pending'}
    />
  );
};

export default PendingVouchers;
