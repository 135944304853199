import {useDispatch, useSelector} from "react-redux";
import {profileEmail, profileName, profilePhone} from "../../../../../bus/profile/selectors";
import {useEffect, useState} from "react";
import {PersonalFormValues} from "../form/typedefs";
import {types} from "../../../../../bus/profile/types";

export const usePersonal = () => {
  const dispatch = useDispatch();

  const name = useSelector(profileName);
  const phone = useSelector(profilePhone);
  const email = useSelector(profileEmail);

  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      emailAddress: email,
      contactName: name,
      contactNumber: phone
    });
  }, [name, email, phone]);

  const onSubmit = (values: PersonalFormValues) => {
    const {contactName, contactNumber} = values;

    dispatch({
      type: types.UPDATE_PERSONAL_DATA,
      payload: {
        name: contactName,
        phone: contactNumber
      }
    });
  };

  return {
    data,
    onSubmit
  };
};
