//Core
import React from 'react';

//Helpers
import {useMyPlacePreview} from "./customHooks/useMyPlacePreview";

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import RenderMyPlacePreview from "./components/RenderMyPlacePreview";
import ButtonBackBlue from "../../../../components/ButtonBackBlue";
import PhoneMockUp from "../../../../components/PhoneMockUp";

const useStyles = makeStyles(styles);

const MyPlacePreview = () => {
  const classes = useStyles();
  const {mobileView} = useMyPlacePreview();

  return (
    <div className={classes.rootWrapper}>
      <ButtonBackBlue/>
      {mobileView ?(
        <RenderMyPlacePreview/>
      ) : (
        <PhoneMockUp>
          <RenderMyPlacePreview/>
        </PhoneMockUp>
      )}
    </div>
  );
};

export default MyPlacePreview;