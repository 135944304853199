//Core
import React from 'react';
import {Field, InjectedFormProps, reduxForm} from "redux-form";

//Helpers
import schema from "./schema";
import syncValidate from "../../../../../utils/syncValidate";
import {useMyPlaceForm} from "../customHooks/useMyPlaceForm";
import {book} from "../../../../../routes/book";
import {Prompt} from "react-router-dom";

//Styles
import {Button, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import FormDivider from "../../../../../components/FormDivider";
import TimeFieldRow from "../../../components/TimeFieldRow";
import RenderInputGooglePlacesId from "./formFields/RenderInputGooglePlacesID";
import RenderInputStreetAddress from "./formFields/RenderInputStreetAddress";
import RenderInputBusinessName from "./formFields/RenderInputBusinessName";
import RenderInputBusinessType from "./formFields/RenderInputBusinessType";
import RenderInputPhoneNumber from "./formFields/RenderInputPhoneNumber";
import RenderInputDescription from "./formFields/RenderInputDescription";
import RenderInputZipCode from "./formFields/RenderInputZipCode";
import RenderInputCountry from "./formFields/RenderInputCountry";
import RenderInputWebsite from "./formFields/RenderInputWebsite";
import RenderInputPhotos from "./formFields/RenderInputPhotos";
import RenderInputCity from "./formFields/RenderInputCity";
import RenderInputDocs from "./formFields/RenderInputDocs";
import RenderInputTags from "./formFields/RenderInputTags";
import EditButton from "./components/EditButton";
import AdditionalSettings from "../../../components/AdditionalSettings";

const useStyles = makeStyles(styles);

const MyPlaceForm: React.FC<InjectedFormProps> = ({handleSubmit}) => {
  const classes = useStyles();

  const {
    pristine,
    dirty,
    valid,
    goPreview,
    allowDraft,
    isDisabled,
    setIsDisabled,
    getGooglePlace,
    isVerified,
    onSubmit
  } = useMyPlaceForm();

  return (
    <form onSubmit={handleSubmit}>
      <Prompt
        when={dirty || !pristine}
        message={(location) => {
          return location.pathname === book.myPlacePreview
            ? true
            : 'All your changes will not be saved. Continue?';
        }}
      />
      <EditButton setIsDisabled={setIsDisabled}/>
      <Grid
        container
        spacing={3}>
        <Field
          disabled={isDisabled}
          name={'name'}
          component={RenderInputBusinessName}
        />
        <FormDivider/>
        <Field
          getGooglePlace={getGooglePlace}
          disabled={isDisabled}
          name={'googlePlaceUnique'}
          component={RenderInputGooglePlacesId}
        />
        <Field
          disabled={isDisabled}
          name={'zip'}
          component={RenderInputZipCode}
        />
        <Field
          disabled={isDisabled}
          name={'countryId'}
          component={RenderInputCountry}
        />
        <Field
          disabled={isDisabled}
          name={'city'}
          component={RenderInputCity}
        />
        <Field
          disabled={isDisabled}
          name={'address'}
          component={RenderInputStreetAddress}
        />
        <FormDivider/>
        <Field
          disabled={isDisabled}
          name={'businessTypeId'}
          component={RenderInputBusinessType}
        />
        <Field
          disabled={isDisabled}
          name={'phone'}
          component={RenderInputPhoneNumber}
        />
        <Field
          disabled={isDisabled}
          name={'website'}
          component={RenderInputWebsite}
        />
        <TimeFieldRow
          disabled={isDisabled}
          onlyStar
          labelText={'Business time'}
        />
        <Field
          disabled={isDisabled}
          name={'description'}
          component={RenderInputDescription}
        />
        <FormDivider/>
        <Field
          disabled={isDisabled}
          name={'photos'}
          component={RenderInputPhotos}
        />
        <FormDivider/>
        <Field
          disabled={isDisabled}
          name={'documents'}
          component={RenderInputDocs}
        />
        <FormDivider/>
        <AdditionalSettings
          disabled={isDisabled}
        />
        <FormDivider/>
        <Field
          disabled={isDisabled}
          name={'tags'}
          component={RenderInputTags}
        />
      </Grid>
      <div className={classes.buttonWrapper}>
        <Button
          onClick={goPreview}
          variant="outlined"
          color="primary">
          Preview
        </Button>
        {!isDisabled &&(
          <>
            <Button
              onClick={() => onSubmit(false)}
              type={'submit'}
              disabled={!allowDraft}
              variant="outlined"
              color="primary">
              Save as a Draft
            </Button>
            <Button
              onClick={handleSubmit(values => onSubmit(true, values))}
              type={'submit'}
              disabled={!valid || !isVerified}
              variant="contained"
              color="primary">
              Save changes
            </Button>
          </>
        )}
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'EditMyPlaceForm',
  validate: syncValidate(schema),
  touchOnChange: true,
  enableReinitialize: true
})(MyPlaceForm);
