import {call, put} from 'redux-saga/effects';
import {SignUpFormValues} from "../../../../containers/AuthContainer/pages/SignUp/form/typedefs";
import {push} from "connected-react-router";
import {book} from "../../../../routes/book";
import {handleErrors} from "../../../../utils/handleErrors";
import {fetchWithoutToken} from "../../../utils/fetching";

export function* signUp({payload: body}: {type: string, payload: SignUpFormValues}){
  try {
    const dataForFetching = {
      endpoint: 'merchants/auth/sign-up',
      method: 'POST'
    };

    const response = yield call(fetchWithoutToken, {...dataForFetching, body});

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    yield put(push(book.thankYouPage));

  } catch (e) {
    console.log(e);
  }
}
