//Core
import React from 'react';

//Helpers
import {usePreview} from "./customHooks/usePreview";

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import PhoneMockUp from "../../../../components/PhoneMockUp";
import RenderOfferPreview from "./components/RenderOfferPreview";
import ButtonBackBlue from "../../../../components/ButtonBackBlue";

const useStyles = makeStyles(styles);

const PreviewOffers = () => {
  const classes = useStyles();
  const {mobileView} = usePreview();

  return (
    <div className={classes.rootWrapper}>
      <ButtonBackBlue/>
      {!mobileView
        ? (
          <PhoneMockUp>
            <RenderOfferPreview/>
          </PhoneMockUp>
        )
        : (
          <RenderOfferPreview/>
        )}
    </div>
  );
};

export default PreviewOffers;
