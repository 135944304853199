import {put} from "redux-saga/effects";
import {authActions} from "../../actions";
import {notificationsActions} from "../../../notifications/actions";
import {offersActions} from "../../../offers/actions";
import {optionsActions} from "../../../options/actions";
import {placesActions} from "../../../business/actions";
import {profileActions} from "../../../profile/actions";
import {uiActions} from "../../../ui/actions";

export function* logout(){
  yield put(authActions.clear());
  yield put(notificationsActions.clear());
  yield put(offersActions.clear());
  yield put(optionsActions.clear());
  yield put(placesActions.clear());
  yield put(profileActions.clear());
  yield put(uiActions.clear());
  yield put(uiActions.confirm(false));
}
