import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    maxWidth: 500,
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.common.white,
    padding: 40,
    boxShadow: '0px 4px 12px rgba(50, 77, 103, 0.25)',
    borderRadius: 8,
    position: 'relative',
    '& .MuiTypography-h3': {
      marginBottom: 16,
      [breakpoints.down(600)]: {
        fontSize: 20,
        lineHeight: '25px'
      }
    },
    '& .MuiTypography-root.MuiTypography-body2': {
      [breakpoints.down(600)]: {
        marginBottom: 15
      }
    },
    '& .MuiDialogContent-root': {
      padding: 0
    }
  },
  closeCross: {
    position: 'absolute',
    top: 12,
    right: 12
  },
  content: {
    width: '100%',
    position: 'relative',
    '& .MuiTypography-body2': {
      marginBottom: 40
    }
  },
  action: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'space-between',
    [breakpoints.down(600)]: {
      flexWrap: 'wrap',
      marginTop: 15,
      '& .MuiButtonBase-root': {
        '&:first-child': {
          marginBottom: 15
        }
      }
    }
  },
  sendButton: {
    margin: 0
  }
});
