import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const Trash = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4H18C19.1046 4 20 4.89543 20 6H24.4C25.2837 6 26 6.71634 26 7.6C26 7.82091 25.8209 8 25.6 8H18H14H6.4C6.17909 8 6 7.82091 6 7.6C6 6.71634 6.71634 6 7.6 6H12C12 4.89543 12.8954 4 14 4ZM18 5C18.5523 5 19 5.44772 19 6H13C13 5.44772 13.4477 5 14 5H18ZM8 9H6.88274L7.00612 10.1104L8.68505 25.2209C8.79759 26.2337 9.65372 27 10.6728 27H21.3272C22.3463 27 23.2024 26.2337 23.3149 25.2209L24.9939 10.1104L25.1173 9H24H8ZM10.6728 25L9.11726 11H22.8827L21.3272 25H10.6728ZM16.5 14C16.5 13.7239 16.2761 13.5 16 13.5C15.7239 13.5 15.5 13.7239 15.5 14V22C15.5 22.2761 15.7239 22.5 16 22.5C16.2761 22.5 16.5 22.2761 16.5 22V14ZM12.9651 13.511C13.2406 13.4917 13.4795 13.6994 13.4988 13.9749L14.0568 21.9554C14.0761 22.2309 13.8684 22.4698 13.5929 22.489C13.3175 22.5083 13.0785 22.3006 13.0593 22.0251L12.5012 14.0446C12.482 13.7692 12.6897 13.5302 12.9651 13.511ZM18.5012 13.9749C18.5205 13.6994 18.7594 13.4917 19.0349 13.511C19.3103 13.5302 19.518 13.7692 19.4988 14.0446L18.9407 22.0251C18.9215 22.3006 18.6825 22.5083 18.4071 22.489C18.1316 22.4698 17.9239 22.2309 17.9432 21.9554L18.5012 13.9749Z"
      />
    </SvgIcon>
  );
};

export default Trash;
