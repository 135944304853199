import {GroupiTheme} from "../../customTheme";
import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    position: 'fixed',
    left: 0,
    top: 105,
    zIndex: 1,
    background: theme.palette.common.white,
    width: 268,
    minWidth: 268,
    height: 'calc(100% - 282px)',
    overflowY: 'auto',
    maxHeight: 'calc(100% - 282px)',
    borderRight: '1px solid',
    paddingTop: 30,
    borderRightColor: theme.palette.common.gray88,
    [breakpoints.down(1200)]: {
      position: 'unset',
      overflowY: 'auto',
      overflowX: 'hidden',
      height: 'calc(100% - 73px)',
      width: '100%',
      minWidth: '100%',
      paddingTop: 0,
      maxHeight: 'unset'
    }
  },
  withSaleManagerItems: {
    height: 'calc(100% - 280px)'
  },
  withOutHeader: {
    top: 0,
    maxHeight: '100%'
  }
});
