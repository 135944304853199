import {types} from "./types";
import {AnyAction} from 'redux';
import {Meta, Nullable} from "../../setup/typedefs";
import {Offer} from "./typedefs";

export type OffersState = {
  offer: Nullable<Offer>
  offerPreview: Nullable<Offer>
  offers: Offer[]
  meta: Nullable<Meta>
  offerViews: Number[]
  offerFavorites: Number[]
  offerReservations: Number[]
}

const initialState: OffersState = {
  offer: null,
  offerPreview: null,
  offers: [],
  meta: null,
  offerViews: [],
  offerFavorites: [],
  offerReservations: []
};

export const offersReducer = (state= initialState, action: AnyAction) => {
  switch (action.type) {
  case types.FILL_OFFERS:
    return {
      ...state,
      offers: action.payload,
      meta: action.meta
    };

  case types.FILL_OFFER:
    return {
      ...state,
      offer: action.payload
    };

  case types.FILL_OFFER_VIEWS:
    return {
      ...state,
      offerViews: action.payload
    };

  case types.FILL_OFFER_FAVORITES:
    return {
      ...state,
      offerFavorites: action.payload
    };

  case types.FILL_OFFER_RESERVATIONS:
    return {
      ...state,
      offerReservations: action.payload
    };

  case types.FILL_PREVIEW:
    return {
      ...state,
      offerPreview: action.payload
    };

  case types.CLEAR_PREVIEW:
    return {
      ...state,
      offerPreview: initialState.offerPreview
    };

  case types.CLEAR:
    return initialState;

  default:
    return state;
  }
};
