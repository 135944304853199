import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  input: {
    marginBottom: 48,
    [breakpoints.down(1200)]: {
      marginBottom: 40
    },
    [breakpoints.down(600)]: {
      marginBottom: 20
    }
  },
  buttonsWrapper: {
    display: 'flex',
    [breakpoints.down(600)]: {
      flexWrap: 'wrap'
    }
  },
  sendButton: {
    marginRight: 20,
    [breakpoints.down(1200)]: {
      width: '100%'
    },
    [breakpoints.down(600)]: {
      marginRight: 0,
      marginBottom: 20
    }
  },
  backButton: {
    display: 'block',
    [breakpoints.down(600)]: {
      width: '100%'
    }
  }
});
