//Core
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";

//Helpers
import {getOffer} from "../../../../../bus/offers/selectors";
import {offersActions} from "../../../../../bus/offers/actions";
import {Offer} from "../../../../../bus/offers/typedefs";

export const useSingleOffer = () => {
  const dispatch = useDispatch();

  const {id} = useParams<{id: string}>();
  const [data, setData] = useState<Offer>();

  const offer = useSelector(getOffer);

  useEffect(() => {
    id && dispatch(offersActions.clearPreview());
    id && dispatch(offersActions.fetchOffer(Number(id)));
  },[id]);

  useEffect(() => {
    offer && setData(offer);
  }, [offer]);

  return {
    data
  };
};