import { all, call, takeEvery } from 'redux-saga/effects';
import {types} from "../types";
import {
  logout,
  signIn,
  signUp,
  resendOnEmail,
  refreshToken,
  resetPassword,
  verifyEmail,
  changePassword
} from "./workers";

function * watchSignUp() {
  yield takeEvery(types.SIGN_UP, signUp);
}

function * watchSignIn() {
  yield takeEvery(types.SIGN_IN, signIn);
}

function * watchLogout() {
  yield takeEvery(types.LOGOUT, logout);
}

function * watchResendOnEmail() {
  yield takeEvery(types.RESEND_ON_EMAIL, resendOnEmail);
}

function * watchResetPassword() {
  yield takeEvery(types.RESET_PASSWORD, resetPassword);
}

function * watchChangePassword() {
  yield takeEvery(types.CHANGE_PASSWORD, changePassword);
}

function * watchVerifyEmail() {
  yield takeEvery(types.VERIFY_EMAIL, verifyEmail);
}

export function* watchAuth(){
  yield all([
    call(watchSignUp),
    call(watchSignIn),
    call(watchVerifyEmail),
    call(watchResendOnEmail),
    call(watchResetPassword),
    call(watchChangePassword),
    call(watchLogout),
    call(refreshToken)
  ]);
}
