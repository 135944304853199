import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import ImagesDragNDropInput from "../../../../../../components/ImagesDragNDropInput";

type RenderInputPhotosProps = {
  initialValues: any,
}

const RenderInputPhotos: React.FC<WrappedFieldProps & RenderInputPhotosProps> = ({input}) => {

  return (
    <FieldRow
      labelText={'Photos'}
      toolTipText={'Upload few photos of your Place. These photos will be displayed in Group@t App for Users. Format of photos is JPEG,JPG,PNG. Max number limited to 9 pictures. Max size 10 Mb per file. '}>
      <ImagesDragNDropInput input={input}/>
    </FieldRow>
  );
};

export default RenderInputPhotos;
