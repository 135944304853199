import {History} from 'history';
import {combineReducers} from 'redux';
import {connectRouter} from "connected-react-router";
import {authReducer} from "../bus/auth/reducer";
import {UiState} from "../bus/ui/reducer";
import { reducer as formReducer } from 'redux-form';
import {optionsReducer} from "../bus/options/reducer";
import {notificationsReducer} from "../bus/notifications/reducer";
import {placesReducer} from "../bus/business/reducer";
import {profileReducer} from "../bus/profile/reducer";
import {offersReducer} from "../bus/offers/reducer";
import {vouchersReducer} from "../bus/vouchers/reducer";

export function createRootReducer(history: History){
  return combineReducers({
    router: connectRouter(history),
    options: optionsReducer,
    auth: authReducer,
    ui: UiState,
    form: formReducer,
    places: placesReducer,
    notifications: notificationsReducer,
    profile: profileReducer,
    offers: offersReducer,
    vouchers: vouchersReducer
  });
}
