import React from 'react';
import {Button, createStyles} from "@material-ui/core";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => createStyles({
  onlyIcon: {
    padding: 16,
    '& .MuiButton-iconSizeMedium':{
      margin: 0
    }
  }
}));

const ButtonLinkBlue = ({icon, link, children, fullWidth = true}: ButtonLinkInterface) => {
  const classes = useStyles();

  return (
    <Link
      to={link}
      style={{textDecoration: 'none'}}>
      <Button
        className={
          clsx({
            [classes.onlyIcon]: !children
          })
        }
        fullWidth={fullWidth}
        variant="contained"
        color="primary"
        startIcon={icon}>
        {children}
      </Button>
    </Link>
  );
};

export default ButtonLinkBlue;

interface ButtonLinkInterface {
  icon?: React.ReactElement,
  link: string,
  children?: string,
  fullWidth?: boolean
}