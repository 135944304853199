import React from 'react';
import {Box, Typography} from "@material-ui/core";
import SignInForm from "./form/SignInForm";
import {book} from "../../../../routes/book";
import {SignInFormValues} from "./form/typedefs";
import {types} from "../../../../bus/auth/types";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  sign_in_title: {
    id: "sign_in_title",
    defaultMessage: "Let’s get started"
  },
  sign_in_text_on_form_or: {
    id: "sign_in_text_on_form_or",
    defaultMessage: "Or"
  },
  sign_in_text_on_form_sign_in_link: {
    id: "sign_in_text_on_form_sign_in_link",
    defaultMessage: "create new account"
  },
  sign_in_text_on_form_not_registered: {
    id: "sign_in_text_on_form_not_registered",
    defaultMessage: "if you not registered yet"
  }
});

const SignIn = () => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();

  const onSubmit = (values: SignInFormValues) => {
    const {email, password} = values;

    dispatch({
      type: types.SIGN_IN,
      payload: {
        email: email,
        password: password
      }
    });
  };

  return (
    <>
      <Typography variant="h2">
        {formatMessage(msg.sign_in_title)}
      </Typography>
      <Box
        mb={3.5}
        mt={0.8}
        alignItems={'baseline'}
        flexWrap={'wrap'}
        display={'flex'}>
        <Typography>
          {formatMessage(msg.sign_in_text_on_form_or)}
        </Typography>
        <Link to={book.signUp}>
          <Typography>
            &nbsp;
            {formatMessage(msg.sign_in_text_on_form_sign_in_link)}
            &nbsp;
          </Typography>
        </Link>
        <Typography>
          {formatMessage(msg.sign_in_text_on_form_not_registered)}
        </Typography>
      </Box>
      <SignInForm onSubmit={onSubmit}/>
    </>
  );
};

export default SignIn;
