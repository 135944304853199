import React from 'react';
import {Typography, useTheme} from "@material-ui/core";
import {styles} from "./styles";
import {GroupiTheme} from "../../customTheme";
import {makeStyles} from "@material-ui/core/styles";
import IconHolder from "../IconHolder";
import AdornmentOn from "../Icons/AdornmentOn";
import clsx from "clsx";
import Exclamation from "../Icons/Exclamation";
import {handleErrors} from "../../utils/handleErrors";

const useStyles = makeStyles(styles);

type SingleDocumentStatusProps = {
  errors: any
}

const SingleDocumentStatus: React.FC<SingleDocumentStatusProps> = ({errors}) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();

  handleErrors('response');

  return (
    <div className={
      clsx(classes.root, {
        [classes.uploaded]: !errors,
        [classes.attention]: !!errors
      })
    }>
      <Typography
        className={classes.text}
        variant={'caption'}>
        {
          errors
            ? errors[0].message
            : 'Uploaded'
        }
      </Typography>
      {
        !errors ? (
          <IconHolder
            size={24}
            color={theme.palette.common.green}>
            <AdornmentOn/>
          </IconHolder>
        ) : (
          <IconHolder
            size={24}
            color={theme.palette.common.red}>
            <Exclamation/>
          </IconHolder>
        )
      }
    </div>
  );
};

export default SingleDocumentStatus;