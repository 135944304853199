import {call} from "redux-saga/effects";
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors} from "../../../../utils/handleErrors";
import {Workers} from "../../../../setup/typedefs";

export function* refreshNotifications({payload}: Workers){
  const {merchantID} = payload;

  try {
    const response = yield call(fetchWithToken, {
      endpoint: `merchants/${merchantID}/notifications/refresh`,
      method: 'PUT'
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

  } catch (e) {
    console.log(e);
  }
}
