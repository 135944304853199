import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  rootWrapper: {
    marginTop: 105,
    padding: '120px 0 232px',
    background: theme.palette.common.white,
    [breakpoints.down(1310)]: {
      width: 'calc(100% - 60px)',
      marginLeft: 30,
      marginRight: 30
    },
    [breakpoints.down(1200)]: {
      marginTop: 0
    },
    [breakpoints.down(960)]: {
      padding: '80px 0 150px'
    },
    [breakpoints.down(600)]: {
      padding: '40px 0 80px'
    }
  },
  divider: {
    width: '100%',
    maxWidth: 1508,
    height: 1,
    margin: '0 auto',
    background: theme.palette.common.gray88
  },
  content: {
    marginTop: 112,
    [breakpoints.down(600)]: {
      marginTop: 80
    }
  },
  linkButton: {
    color: 'inherit',
    textDecoration: 'none',
    background: theme.palette.common.blue50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.common.gray88,
    padding: '30px 15px',
    maxWidth: 744,
    boxSizing: 'border-box',
    width: '100%',
    margin: '120px auto',
    '& .MuiTypography-h4': {
      marginRight: 24,
      color: theme.palette.common.white
    },
    [breakpoints.down(1200)]: {
      margin: '80px auto'
    },
    [breakpoints.down(600)]: {
      maxWidth: '100%',
      padding: '15px',
      margin: '40px auto',
      '& .MuiBox-root': {
        display: 'none'
      },
      '& .MuiTypography-h4': {
        fontSize: 14,
        marginRight: 0,
        textAlign: 'center'
      }
    }
  }
});
