//Core
import React from 'react';

//Helpers
import {useSingleOfferInList} from "../customHooks/useSingleOfferInList";
import {concatDate, concatTime} from "../../../../../utils/helpers";
import {Offer} from "../../../../../bus/offers/typedefs";

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "../styles";
import {Typography} from "@material-ui/core";

//Components
import Counters from "./Counters";
import WrapRow from "../../WrapRow";
import Calendar from "../../../../../components/Icons/Calendar";
import Repeat from "../../../../../components/Icons/Repeat";
import Clock from "../../../../../components/Icons/Clock";

const useStyles = makeStyles(styles);

type MainInfoBlockProps = {
  item: Offer,
  toggleTabletView: boolean
}

const MainInfoBlock: React.FC<MainInfoBlockProps> = ({item, toggleTabletView}) => {
  const classes = useStyles();
  const {randomImg} = useSingleOfferInList();

  return (
    <div className={classes.cell}>
      <div className={classes.imageHolder}>
        <img
          src={item.cover?.origin ? item.cover?.origin : `/defaultImage${randomImg}.svg`}
          alt={item.name}
        />
      </div>
      <div className={classes.infoContent}>
        <div className={classes.titleName}>
          <Typography variant={'h4'}>
            {item.name}
          </Typography>
        </div>
        <WrapRow
          icon={<Calendar/>}
          content={concatDate(item.dateStart, item.dateEnd)}
        />
        <WrapRow
          icon={<Repeat/>}
          content={item.availability}
        />
        <WrapRow
          icon={<Clock/>}
          content={concatTime(item.timeStart, item.timeEnd)}
        />
        {toggleTabletView && (
          <Counters
            item={item}
            toggleTabletView={toggleTabletView}
          />
        )}
      </div>
    </div>
  );
};

export default MainInfoBlock;