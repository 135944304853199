import React from 'react';
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import syncValidate from "../../../../../utils/syncValidate";
import {Button} from "@material-ui/core";
import schema from "./schema";
import {styles} from "./styles";
import {defineMessages, useIntl} from "react-intl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RenderInputPassword from "./formFields/RenderInputPassword";
import RenderInputConfirmPassword from "./formFields/RenderInputConfirmPassword";

const msg = defineMessages({
  change_password_button: {
    id: "change_password_button",
    defaultMessage: "Change password"
  }
});

const useStyles = makeStyles(styles);

const ChangePasswordForm = (props: InjectedFormProps) => {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const {handleSubmit} = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.inputsWrapper}>
        <Field
          name={'password'}
          component={RenderInputPassword}
        />
        <Field
          name={'confirmPassword'}
          component={RenderInputConfirmPassword}
        />
      </div>
      <Button
        className={classes.changePasswordButton}
        type='submit'
        variant="contained"
        color="primary">
        {formatMessage(msg.change_password_button)}
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'ChangePasswordForm',
  validate: syncValidate(schema)
})(ChangePasswordForm);
