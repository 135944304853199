import {call} from "redux-saga/effects";
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";

export function* updateOfferTags({formData, offerID, updateTags: action}: any){
  try {
    if (action) {
      const response = yield call(fetchWithToken, {
        endpoint: `offers/${offerID}/tags`,
        method: 'PUT',
        body: {tags: formData.tags}
      });

      if (response.status !== 200) {
        yield call(handleErrors, response);
        throw new Error(response);
      }

      yield call(handleMsg, 'New Tags added');
    }
  } catch (e) {
    console.log(e);
  }
}