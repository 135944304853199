import React, {useEffect} from 'react';
import {IconButton, Typography, useTheme} from "@material-ui/core";
import IconHolder from "../../../../../../components/IconHolder";
import {GroupiTheme} from "../../../../../../customTheme";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import BigCross from "../../../../../../components/Icons/BigCross";
import {useDispatch, useSelector} from "react-redux";
import {uiSelector} from "../../../../../../bus/ui/selectors";
import {uiActions} from "../../../../../../bus/ui/actions";
import {vouchersActions} from "../../../../../../bus/vouchers/actions";

const useStyles = makeStyles(styles);

type CancelButtonProps = {
  id: number,
  name: string
}

const CancelButton: React.FC<CancelButtonProps> = ({id, name}) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const {confirm} = useSelector(uiSelector);
  const dispatch = useDispatch();

  const cancel = () => {
    dispatch(uiActions.popup({
      name: 'areYouSure',
      payload: {
        title: 'Are you sure you want to Cancel Voucher Request?',
        description: 'You won’t be able to undo this action'
      }
    }));
  };

  useEffect(() => {
    if (confirm && id) {
      dispatch(vouchersActions.updateVoucher({
        formData: {
          name: name,
          isPublished: false
        },
        info: {
          through: true,
          voucherID: id
        }
      }));
    }
  }, [confirm]);

  return (
    <div className={classes.button}>
      <IconButton
        color="primary"
        onClick={cancel}>
        <IconHolder
          size={32}
          color={theme.palette.common.red}>
          <BigCross/>
        </IconHolder>
      </IconButton>
      <Typography variant={'body2'}>
        Cancel
      </Typography>
    </div>
  );
};

export default CancelButton;