import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  root: {
    width: '100%',
    paddingTop: 50,
    [breakpoints.down(784)]: {
      paddingTop: 60
    }
  }
});
