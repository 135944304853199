import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    width: '100%',
    position: 'relative',
    paddingTop: 40,
    [breakpoints.down(375)]: {}
  },
  divider: {
    position: 'absolute',
    display: 'block',
    width: 500,
    height: 1,
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    background: theme.palette.common.gray88
  }
});
