import React from 'react';
import {Typography, useTheme} from "@material-ui/core";
import {styles} from './styles';
import {makeStyles} from "@material-ui/core/styles";
import IconHolder from "../../../../components/IconHolder";
import Gear from "../../../../components/Icons/Gear";
import {GroupiTheme} from "../../../../customTheme";

const useStyle = makeStyles(styles);

const Marketing = () => {
  const classes = useStyle();
  const theme: GroupiTheme = useTheme();

  return (
    <div className={classes.container}>
      <IconHolder
        size={56}
        color={theme.palette.common.blue50}>
        <Gear/>
      </IconHolder>
      <Typography
        align={'center'}
        variant={'h2'}>
        Under construction
      </Typography>
      <Typography
        align={'center'}
        variant={'subtitle1'}>
        very soon you will be able to:
      </Typography>
      <Typography
        align={'center'}
        variant={'body2'}>
        Create Marketing Campaigns
      </Typography>
      <Typography
        align={'center'}
        variant={'body2'}>
        Receive reservations and manage your bookings
      </Typography>
      <Typography
        align={'center'}
        variant={'body2'}>
        See Group budgets and start bidding to attract more customers
      </Typography>
    </div>
  );
};

export default Marketing;