//Core
import React from 'react';

//Helpers
import {useSingleVoucher} from "./customHooks/useSingleVoucher";

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import ButtonBackBlue from "../../../../components/ButtonBackBlue";
import RenderSingleVoucher from "./components/RenderSingleVoucher";

const useStyles = makeStyles(styles);

const SingleVoucher = () => {
  const classes = useStyles();
  const {data} = useSingleVoucher();

  if (!data) {
    return (
      <p>
        Sorry nothing to render
      </p>
    );
  }

  return (
    <div className={classes.container}>
      <ButtonBackBlue/>
      <RenderSingleVoucher data={data}/>
    </div>
  );
};

export default SingleVoucher;