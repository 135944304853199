//Core
import React from 'react';

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import Header from "../../../../components/Header";

const useStyles = makeStyles(styles);

const TermsAndConditionsForApps = () => {
  const classes = useStyles();

  return (
    <>
      <Header/>
      <div className={classes.rootWrapper}>
        <h1>
          Terms of Service
        </h1>
        <p>
          Welcome to Groupi Inc the owner and operator of (“Groupi and “the App”). This User Agreement between you and
          “Groupi” (“Groupi”, “We” or the “Company”), governs your access to and use of the Services and Applications
          offered by Groupi. Your access to and use of the App is conditioned on your acceptance of and compliance with
          this agreement.
        </p>
        <h3>
          User Agreement
        </h3>
        <p>
          The following agreement (“Terms”) describes the terms on which we offer you access to our App and Services,
          defined below. Capitalized terms used in these Terms shall have the meanings set forth below under
          “Definitions” or in the text of these Terms. Our offer to allow you to access the App and Services is
          conditioned on your agreement to all of the terms and conditions contained in these Terms, including your
          compliance with the policies and terms linked to (via URLs or hyperlinks) from this Agreement, such as our
          Privacy Policy and our Content Guidelines. If you do not agree to be bound by these Terms in their entirety,
          you must cease accessing or otherwise using the App and Services in any way. Your use of any of the App or
          Services constitutes your agreement to these Terms.
        </p>
        <p>
          We may amend these Terms at any time in our sole discretion, effective upon posting the amended Terms at this
          URL where the prior version of these Terms was posted or by communicating these changes through any written
          contact method we have established with you. Your use of the App or Services following the date on which such
          amended Terms are published will constitute consent to such amendments. However, if you cease using the App or
          Services upon such publication, your relationship with us will continue to be controlled by the previous
          version of these Terms. You agree that you will periodically check this page for updates to these Terms, and
          read the messages we send you to inform you of any changes.
        </p>
        <h3>
          DEFINITIONS
        </h3>
        <p><b>“Company”</b> or “Groupi” or “We” shall mean “Groupi”, and/or any of its affiliates, individually and
          collectively, doing business as “Groupi” or otherwise.
        </p>
        <p><b>“Company Parties”</b> shall mean the Company, its affiliates and subsidiaries, and any of their directors,
          employees, agents, attorneys, third-party content providers, distributors, licensees or licensors.
        </p>
        <p><b>“Service”</b> or <b>“Services”</b> shall mean any of the internet based or other services offered by the
          Company,
          including, but not limited to, those described in this Agreement, whether through a ®website owned or
          controlled by the Company, through a social networking system, a mobile application, on your cellphone or
          otherwise.</p>
        <p><b>“App”</b> shall mean any of the websites owned or operated by “Groupi” including insert names of website
          here.</p>
        <p><b>“User Contributed Content”</b> or <b>“Content”</b> shall mean each and every item of content or other
          material (whether comments, ideas, images, links, documents, text, writings, photographs, graphics, videos, or
          files) uploaded by a user to the App or otherwise integrated into the Sites by a user.
        </p>
        <h3>
          1. Using Our App, User Conduct, and Your Content
        </h3>
        <p>
          Eligibility: You may use our App only if you can form a binding contract with Groupi in compliance with the
          Agreement. In order to become a “Registered User” (by creating an account), Groupi requires an individual to
          be at least eighteen (18) years old and not have a previous account terminated by Groupi or otherwise have had
          his or her access to the App terminated by Groupi. It is a violation of the Agreement to provide false or
          misleading information to Groupi. in connection with the creation of an account. If you would like to report
          an account registered for a minor, please email need email
        </p>
        <p>
          User Responsibilities: You are solely responsible for your use of our App, for any Content you submit to us,
          any interactions with other users, and for any consequences thereof. Content you submit will be viewable by
          other users of the App and through third party services and websites.
        </p>
        <p>
          You should only provide Content that you are comfortable sharing with others under these Terms, and that does
          not violate any third-party’s rights of any kind, including without limitation, any intellectual property
          rights, rights of privacy, or publicity rights.
        </p>
        <p>
          We reserve the right, but is not obligated, to reject and/or remove any Content in its sole discretion,
          including, but not limited to, violations of these Terms. We reserve the right, but has no obligation, to
          monitor disputes between you and other users. We shall have no liability for your interactions with other
          users, or for any user’s action or inaction.
        </p>
        <p>
          User Conduct: In exchange for your being able to use the App, you agree to be bound by the following
          obligations:
        </p>
        <h4>
          1.1 Registration.
        </h4>
        <p>
          Even if you choose to create an anonymous account, you are still agreeing to all of these Terms.
          You may not: Use false information or an account owned or controlled by another person with the intent to
          mislead other users maliciously by impersonating that person or for any other reason; use as a User ID a name
          subject to any rights of a person other than yourself without appropriate authorization or in violation of our
          Content Guidelines; or otherwise submit false or misleading information to the Company.
        </p>
        <h4>
          1.2 No Changes to the Software/Prohibited Uses.
        </h4>
        <p>
          You may not and may not allow any third party to:
          modify, adapt, disassemble, decompile, translate, reverse engineer or otherwise attempt to discover the source
          code or structure, sequence and organization of the App or any portion of any website on which the Services
          are offered (except where the foregoing is required by applicable local law, and then only to the extent so
          required under such laws);
          use the App in any manner that could damage, disable, overburden, or impair the App or another user’s use of
          the App;
          remove, obscure or change any copyright, trademark, hyperlink or other proprietary rights notices (“Notices”)
          contained in or on the App or any website on which the Service is offered, Company code embeddable or embedded
          on a third-party web site, and/or Company software;
          submit any content or material that falsely express or imply that such content or material is sponsored or
          endorsed by the Company; or
          use the App or Services s to violate the security of any computer network or transfer or store illegal
          material.
        </p>
        <h4>
          1.3 Usage Rules.
        </h4>
        <p>
          You agree that your use of and conduct on the App (including any commenting feature), and your User Content
          Submissions shall be lawful and will not:
          violate the contractual, personal, intellectual property or other rights of any party, or promote or
          constitute illegal activity;
          be in violation of these Terms, any local, state, federal or international law, rule or regulation or the
          rules of conduct posted with respect to any individual feature of the Services;
          create user accounts by automated means or under false pretenses or mislead others as to the origins of your
          communications;
          trick, defraud or mislead the Company or other users, especially in any attempt to learn sensitive account
          information such as passwords;
          make improper use of the Company’s support services or submit false reports of abuse or misconduct;
          engage in promoting any pyramid schemes or other multi-tiered marketing schemes or engage in promoting any
          websites or services that are deemed spam, malware, or contain objectionable material in the Company’s sole
          discretion;
          create or transmit unwanted electronic communications such as “spam,” to other users or members of the App or
          otherwise interfere with other users’ or members’ enjoyment of the Sites;
          disseminate or transmit viruses, worms, Trojan horses, RATs, keyboard loggers, time bombs, spyware, adware,
          cancelbots, passive collection mechanisms (“PCMs”), or any other malicious or invasive code or program or
          upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
          information collection or transmission mechanism, including, without limitation, clear graphics interchange
          formats (“gifs”), 1×1 pixels, web bugs, cookies or other similar devices;
          copy or adapt the Service’s software including but not limited to Flash, PHP, HTML, JavaScript or other code;
          reverse engineer, decompile, reverse assemble, modify or attempt to discover any software (source code or
          object code) that the Service create to generate web pages or any software or other products or processes
          accessible through the Sites;
          except as may be the result of standard search engine or Internet browser usage, use or launch, develop or
          distribute any automated system, including, without limitation, any spider, robot (or “bot”), cheat utility,
          scraper or offline reader that accesses the Service, or use or launch any unauthorized script or other
          software;
          cover or obscure any notice, banner, advertisement or other branding on the Sites;
          disguise the source of your User Content Submissions or other information you submit to the Sites;
          interfere with or circumvent any security feature of the Service or any feature that restricts or enforces
          limitations on use of or access to the App, Content or the User Content;
        </p>
        <h4>
          1.4 You agree that you shall be responsible for any consequences (including a responsibility to indemnify the
          Company for damages it may suffer) arising in the event that any use of and conduct on the Service (including
          any commenting feature), and your User Content Submissions:
        </h4>
        <p>
          includes any offensive comments that are connected to race, national origin, gender, sexual preference or
          physical handicap;
          includes profanity or any obscene, indecent, pornographic, sexual or otherwise objectionable content or
          language;
          defames, libels, ridicules, mocks, disparages, threatens, harasses, intimidates or abuses anyone;
          promotes violence or describes how to perform a violent act;
          reveals any personal information about another individual, including another person’s address, phone number,
          e-mail address, credit card number or any information that may be used to track, contact or impersonate that
          individual;
          attempts to impersonate any other party;
          or uses tools which anonymize your internet protocol address (e.g. anonymous proxy) to access the Sites;
        </p>
        <h4>
          1.5 The Company does not control or endorse the content, messages or information found in User Content
          Submissions or external sites that may be linked to from such Content and, therefore, the Company specifically
          disclaims any responsibility with regard thereto.
        </h4>
        <h4>
          1.6 The App may contain or deliver advertising and sponsorships. Advertisers and sponsors are responsible for
          ensuring that material submitted for inclusion is accurate and complies with applicable laws. We are not
          responsible for the illegality or any error, inaccuracy or problem in the advertiser’s or sponsor’s materials.
        </h4>
        <h3>
          2. Licensing Agreements
        </h3>
        <p>
          User License: You retain your rights to any Content you submit to or through the App. By posting any Content
          on the App, however, you expressly grant, and you represent and warrant that you have all rights necessary to
          and do grant, to us a royalty-free, sublicensable, transferable, perpetual, irrevocable, non-exclusive,
          worldwide license to use, reproduce, modify, publish, list information regarding, edit, translate, distribute,
          syndicate, publicly perform, publicly display, and make derivative works of all such Content and your name,
          voice, and/or likeness as contained in your Content, in whole or in part, and in any form, media or
          technology, whether now known or hereafter developed, for use in connection with the App and Groupi’s (and its
          successors, assignees, or affiliates) business, including without limitation for promoting and redistributing
          part or all of the App (and derivative works thereof) in any media formats, whether now known or hereafter
          developed, and through any media channels or search engines for commercial and noncommercial purposes alike.
          You also hereby grant each user of the App a non-exclusive license to access your Content through the App, and
          to use, reproduce, distribute, display and perform such Content as permitted through the functionality of the
          App and under these Terms. Any material you transmit to the Company or otherwise through the App will be
          treated as non-confidential and non-proprietary.
        </p>
        <p>
          Such additional uses by Groupi, or other companies, organizations or individuals who partner with or use
          Groupi, may/must be made with no compensation paid to you with respect to the Content that you submit, post,
          transmit or otherwise make available through the App. We may add or insert affiliate and partner code into
          Content that you submit. We may modify or adapt your Content in order to transmit, display or distribute it
          over computer networks and in various media and/or make changes to your Content as are necessary to conform
          and adapt that Content to any requirements or limitations of any networks, devices, services or Third Party
          Services, and you are responsible for reading and understanding those Third Party Services’ privacy policies.
        </p>
        <p>
          Site License: All right, title, and interest in and to the App (excluding Content provided by users) shall
          remain the exclusive property of Groupi and its licensors. The App are protected by copyright, trademark, and
          other laws of both the United States and foreign countries. Nothing in the Terms gives you a right to use the
          Groupi name or any of the Groupi website names, trademarks, logos, domain names, and other distinctive brand
          features. Any feedback, comments, ideas or suggestions you may provide regarding the App, or the Services is
          entirely voluntary and we will be free to use such feedback, comments or suggestions as we see fit and without
          any obligation to you.
        </p>
        <p>
          Subject to your acceptance of these Terms, Groupi grants you a non-exclusive, limited, non-transferable,
          freely revocable license to use the App for your personal, noncommercial (i.e. you may not use the App to
          provide or serve or permit others to provide or serve ads or contests or sweepstakes) use only and as
          permitted by the features of the App. Groupi reserves all rights not expressly granted herein in the App and
          the Groupi Content. Groupi reserves the right to terminate your license to use the App at any time and for any
          reason or in the future to charge for commercial usage.
        </p>
        <h3>
          3. Privacy
        </h3>
        <p>
          We care about the privacy of our users. You understand that by using the App you consent to the collection,
          use, and disclosure of your personally identifiable information (see “Information Sharing” below) and
          aggregate data as set forth in our Privacy Policy, and to have your personally identifiable information
          collected, used, transferred to and processed in the United States.
        </p>
        <h3>
          4. Information Sharing
        </h3>
        <p>
          We have technical, administrative and physical safeguards in place to help protect against unauthorized access
          to, use or disclosure of user information we maintain. Under our security practices and policies, access to
          personally identifiable information is authorized only for those who have a business need for such access, and
          sensitive records are to be retained only as long as necessary for business or legal needs and destroyed
          before disposal.
        </p>
        <p>
          Although we work hard to protect personal information that we collect and store, no program is one hundred
          percent secure and we cannot guarantee that our safeguards will prevent every unauthorized attempt to access,
          use or disclose personal information. If you become aware of a security issue, please email us at need email.
        </p>
        <p>
          The Company reserves the right to reveal your identity (or whatever information we know about you) in the
          event of a complaint or legal action arising from any comment or other content posted by you or where such
          information is otherwise relevant.
        </p>
        <h3>
          5. Advertisements
        </h3>
        <p>
          As part of the Service, we may include advertisements and/or content provided by Groupi and/or a third party,
          which may be targeted to the Content or information on the App, queries made through the App, or other
          information. The types and extent of advertising by Groupi on the App are subject to change. In consideration
          for Groupi granting you access to and use of the App, you agree that Groupi and its third party providers and
          partners may place such advertising on the App or in connection with the display of Content or information
          from the App whether submitted by you or others.
        </p>
        <h3>
          6. Third-Party Links Disclaimer
        </h3>
        <p>
          The App may contain links to third-party websites, advertisers, services (such as payment processing), special
          offers, or other events or activities that are not owned or controlled by Groupi. Groupi does not endorse or
          assume any responsibility for any such third-party sites, information, materials, products, or services. If
          you access a third party website from the App, you do so at your own risk, and you understand that these Terms
          and Groupi’s Privacy Policy do not apply to your use of such sites. You expressly release Groupi from any and
          all liability arising from your use of any third-party website, service, or Content. Your dealings with or
          participation in promotions of advertisers found on the App, including payment and delivery of goods, and any
          other terms (such as warranties) are solely between you and such advertisers. You agree that Groupi shall not
          be responsible for any loss or damage of any sort relating to your dealings with such advertisers or entities.
        </p>
        <h3>
          7. Copyright Policy
        </h3>
        <p>
          If Groupi receives notice that Content posted is unlawful or not in keeping with the Terms or the intended use
          of the App, we reserve the right to remove the material. Please note that we will respond only to notices of
          alleged infringement that comply with the Digital Millennium Copyright Act (“DMCA”). The text of the Act can
          be found at the U.S. <a href="https://www.copyright.gov/">Copyright Office Web Site.</a>
        </p>
        <p>
          To file a notice of infringement with us, you must provide a written communication (by email with an attached
          and signed PDF or by U.S. mail) that sets forth the items specified below.
        </p>
        <p>
          In all cases, if you do not hear a response from us within 10 days of submitting a complaint, please telephone
          us at need phone number to confirm that we received your original complaint. Spam blockers sometimes devour
          important emails from strangers! To enable us to address your concerns, please provide the following
          information:
        </p>
        <p>
          For each allegedly infringing image, video, music, or piece of text that you wish to have removed from one of
          our sites, provide the exact permanent URL for the page containing the material.
          Provide information reasonably sufficient to permit us to contact you: an email address is preferred, as well
          as a telephone contact number.
          For images, provide the following information detailing your claim to ownership of the copyright in the
          allegedly infringing image, video, music, or piece of text: Proof of copyright in the image, video, music, or
          piece of text concerned, namely proof of registration; OR, absent such registration, a detailed description
          and evidence to support your claim that you own the copyright in the image, video, music, or piece of text. We
          will not comply with requests to remove an image, video, music, or piece of text where the complainant cannot
          prove that they own the copyright in the Content in question.
          Include the following statement: “I swear, under penalty of perjury, that the information in the notification
          is accurate and that I am the copyright owner or am authorized to act on behalf of the owner of an exclusive
          right that is allegedly infringed.”
          Sign the document and send it to need emailwith subject line: Legal / DMCA Complaints, or send it via mail to
          Legal Department, Groupi, address, with subject line: Legal / DMCA Complaints.
          Please note that you will be liable for damages (including costs and attorneys’ fees) if you materially
          misrepresent that any material on our sites is infringing your copyrights. We also reserve the right to
          publish your letter on the Groupi site(s).
        </p>
        <h3>
          8. Termination of Agreement
        </h3>
        <p>
          You may discontinue your use of the App at any time without informing us. Subject to the provisions in the
          Groupi Privacy Policy, we may retain and continue to use any Content that you have submitted or uploaded to
          the Sites.
        </p>
        <p>
          Groupi may, without prior notice, change the App, stop providing access to the App or features of the App to
          you or to users generally, or create usage limits for the App. We may permanently or temporarily terminate or
          suspend your access to the App without notice and liability for any reason, including if, in our sole
          determination, you violate any provision of these Terms. In particular, Groupi may immediately terminate or
          suspend accounts that have been flagged for repeat copyright infringement, in accordance with the DMCA and
          other applicable laws.
        </p>
        <p>
          Upon termination of your access to or ability to use the App, including but not limited to suspension of your
          account, your right to use or access that Service and any Content will immediately cease. All provisions of
          this Agreement that by their nature should survive termination, shall survive termination, including, without
          limitation, ownership provisions, warranty disclaimers, and limitations of liability. Termination of your
          access to and use of the App shall not relieve you of any obligations arising or accruing prior to such
          termination or limit any liability which you otherwise may have to Groupi or any third party.
        </p>
        <p>
          On termination of your Account or upon your deletion of particular pieces of your Content from the App, you
          acknowledge and agree that: (a) caching of, copies of, or references to the Content may not be immediately
          removed; (b) such removed Content may persist in backups (not available to others) for a reasonable period of
          time; and (c) such removed Content may be available (and stored on our servers) through the accounts of other
          users, because of liking. You agree to release and indemnify Groupi from all claims related to the retention
          of deleted content.
        </p>
        <h3>
          9. Indemnity
        </h3>
        <p>
          You agree to defend, indemnify and hold harmless Groupi and its subsidiaries, agents, licensors, managers, and
          other affiliated companies, and their employees, contractors, agents, officers and directors, from and against
          any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not
          limited to attorney’s fees) arising from: (i) your use of and access to the App, including any data or Content
          transmitted, submitted, viewed, or received by you; (ii) your violation of any provision of these Terms,
          including without limitation your breach of any of the representations and warranties above; (iii) your
          violation of any third-party right, including without limitation any right of privacy or intellectual property
          rights; (iv) your violation of any applicable law, rule or regulation; (v) any claim or damages that arise as
          a result of any of your Content or any that is submitted via your account; or (vi) any other party’s access
          and use of the App with your unique username, password or other appropriate security code.
        </p>
        <h3>
          10. Warranty, Disclaimer, and Limitations of Liability
        </h3>
        <p>
          Your access to and use of the Sites or any Content is at your own risk.
        </p>
        <p>
          THE APP ARE AVAILABLE “AS IS”: YOU UNDERSTAND AND AGREE THAT THE APP ARE PROVIDED TO YOU ON AN “AS IS” AND “AS
          AVAILABLE” BASIS. WITHOUT LIMITING THE FOREGOING, TO THE FULL EXTENT PERMITTED BY LAW, GROUPI DISCLAIMS ALL
          WARRANTIES, EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
        </p>
        <p>
          Groupi makes no representations or warranties of any kind with respect to the App, including any
          representation or warranty that the use of the App will (a) be timely, uninterrupted or error-free or operate
          in combination with any other hardware, software, system or data, (b) meet your requirements or expectations,
          (c) be free from errors or that defects will be corrected, or (d) be free of viruses or other harmful
          components. Groupi also makes no representations or warranties of any kind with respect to Content; user
          Content, in particular, is provided by and is solely the responsibility of the users providing that Content.
          No advice or information, whether oral or written, obtained from Groupi or through the App, will create any
          warranty not expressly made herein.
        </p>
        <p>
          Release From Liability: You release, to the fullest extent permitted by law, Groupi, its affiliated entities,
          parent companies, subsidiaries, directors, officers, shareholders, employees, representatives, consultants,
          agents, suppliers, and/or distributors from responsibility, liability, claims, demands, and/or damages (actual
          and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with
          the following: (i) Disputes between users, including those between you and other users; (ii) Third party sites
          and services, including Content found on such sites and services; (iii) Disputes concerning any use of or
          action taken using your account by you or a third party; (iv) Claims relating to the unauthorized access to
          any data communications or Content stored under or relating to your account, including unauthorized use or
          alteration of such communications or your Content.
        </p>
        <p>
          Limitation of Liability: TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Groupi ITS AFFILIATED ENTITIES,
          PARENT COMPANIES, SUBSIDIARIES, DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, REPRESENTATIVES, CONSULTANTS,
          AGENTS, SUPPLIERS, AND/OR DISTRIBUTORS SHALL NOT BE LIABLE FOR: (A) ANY INDIRECT, INCIDENTAL, EXEMPLARY
          PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER; (B) LOSS OF: PROFITS, REVENUE, DATA, USE,
          GOOD-WILL, OR OTHER INTANGIBLE LOSSES; (C) DAMAGES RELATING TO YOUR ACCESS TO, USE OF, OR INABILITY TO ACCESS
          OR USE THE APP; (D) DAMAGES RELATING TO ANY CONDUCT OR CONTENT OF ANY THIRD PARTY OR SUBSCRIBER USING THE APP,
          INCLUDING WITHOUT LIMITATION, DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OR CONTENT; AND/OR (E) DAMAGES IN ANY
          MANNER RELATING TO ANY CONTENT. THIS LIMITATION APPLIES TO ALL CLAIMS, WHETHER BASED ON WARRANTY, CONTRACT,
          TORT, OR ANY OTHER LEGAL THEORY, WHETHER OR NOT Groupi HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE,
          AND FURTHER WHERE A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE. TO THE MAXIMUM
          EXTENT PERMITTED BY APPLICABLE LAW, THE TOTAL LIABILITY OF Groupi AND ITS DIRECTORS, OFFICERS, SHAREHOLDERS,
          EMPLOYEES, REPRESENTATIVES, CONSULTANTS, AGENTS, SUPPLIERS, AND/OR DISTRIBUTORS, FOR ANY CLAIM UNDER THIS
          AGREEMENT, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE GREATER OF FIFTY DOLLARS (USD$50) OR THE
          AMOUNT YOU PAID US TO USE THE APPLICABLE SITE(S).
        </p>
        <h3>
          11. Waiver, Severability & Entire Agreement
        </h3>
        <p>
          Waiver: The failure of Groupi to enforce any right or provision of these Terms will not be deemed a waiver of
          such right or provision.
          Severability: In the event that any provision of these Terms is held to be invalid or unenforceable, then that
          provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these
          Terms will remain in full force and effect.
          Entire Agreement: These Terms and our <a href="https://g-omedia.com/privacy-policy">Privacy Policy</a> and <a
          href="https://legal.kinja.com/content-guidelines-90185358#_ga=1.247336391.290498954.1479140661">Content
          Guidelines</a> are the entire and exclusive agreement between Groupi and you regarding use of the App
          (excluding any services for which you have a separate written agreement with Groupi that is explicitly in
          addition or in place of these Terms), and these Terms supersede and replace any prior agreements between
          Groupi and you regarding use of the Sites.
        </p>
        <h3>
          12. Legal Disputes
        </h3>
        <p>
          Applicable Law. You agree that the laws of the State of New York, without regard to principles of conflict of
          laws, will govern these Terms and any claim or dispute that has arisen or may arise between you and Groupi,
          except as otherwise stated in these Terms.
        </p>
        <p>
          Binding Arbitration. If you and the Company are unable to resolve a Dispute through informal negotiations,
          either you or the Company may elect to have the Dispute (except those Disputes expressly excluded below)
          finally and exclusively resolved by binding arbitration. Any election to arbitrate by one party shall be final
          and binding on the other. YOU UNDERSTAND THAT ABSENT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT
          AND HAVE A JURY TRIAL. The arbitration shall be commenced and conducted under the Commercial Arbitration Rules
          of the American Arbitration Association (“AAA”) and, where appropriate, the AAA’s Supplementary Procedures for
          Consumer Related Disputes (“AAA Consumer Rules”), both of which are available at the AAA website www.adr.org.
          The determination of whether a Dispute is subject to arbitration shall be governed by the Federal Arbitration
          Act and determined by a court rather than an arbitrator. Your arbitration fees and your share of arbitrator
          compensation shall be governed by the AAA Rules and, where appropriate, limited by the AAA Consumer Rules. If
          such costs are determined by the arbitrator to be excessive, the Company will pay all arbitration fees and
          expenses. The arbitration may be conducted in person, through the submission of documents, by phone or online.
          The arbitrator will make a decision in writing, but need not provide a statement of reasons unless requested
          by a party. The arbitrator must follow applicable law, and any award may be challenged if the arbitrator fails
          to do so. Except as otherwise provided in this Agreement, you and the Company may litigate in court to compel
          arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate or enter judgment on the
          award entered by the arbitrator.
        </p>
        <p>
          Judicial Forum for Legal Disputes. Unless you and we agree otherwise, in the event that the Binding
          Arbitration provision above is found not to apply to you or to a particular claim or dispute, either as a
          result of your decision to opt-out of the Terms or a court order, you agree that any claim or dispute that has
          arisen or may arise between you and Groupi must be resolved exclusively by a state or federal court located in
          New York City, New York. You and Groupi agree to submit to the personal jurisdiction of the courts located
          within New York City, New York for the purpose of litigating all such claims or disputes.
        </p>
        <h3>
          13. Statute of Limitations.
        </h3>
        <p>
          You and the Company both agree that regardless of any statute or law to the contrary, any claim or cause of
          action arising out of or related to use of the App, Terms or <a href="https://g-omedia.com/privacy-policy">Privacy
          Policy</a> must be filed within one (1) year after such claim or cause of action arose or be forever barred.
        </p>
        <h3>
          14. Notification of Changes to Terms of Service.
        </h3>
        <p>
          Whenever we change our Terms, we will post those changes to this Terms of Service page, and other places we
          deem appropriate so that our users are aware of what information we collect, how we use it, and under what
          circumstances, if any, we disclose it. By continuing to use the App, you agree and accept the changes and
          agree to the Terms.
        </p>
        <h3>
          15. International Users.
        </h3>
        <p>
          The App and Services are based in the United States. We make no representations that they are appropriate or
          available for use in other locations. Those who access or use the App and Services from other countries do so
          at their own volition and are responsible for compliance with local law.
        </p>
        <h3>
          16. Effective Date.
        </h3>
        <p>
          These Terms of Service are effective Insert Date and shall be updated from time to time.
        </p>
      </div>
    </>
  );
};

export default TermsAndConditionsForApps;
