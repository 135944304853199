import React, {useState} from 'react';
import QuestionTooltip from "../Icons/QuestionTooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {ClickAwayListener, Fab, Tooltip, useTheme} from "@material-ui/core";
import {styles} from "./styles";
import {GroupiTheme} from "../../customTheme";
import IconHolder from "../IconHolder";

const useStyles = makeStyles(styles);

const ToolTip = ({children}: ToolTipsText) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const [open, setOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => {
      setOpen(false);
    }}>
      <Tooltip
        disableFocusListener={false}
        disableHoverListener={false}
        disableTouchListener={false}
        open={open}
        title={children}
        classes={{tooltip: classes.customWidth}}>
        <Fab
          className={classes.Fab}
          onClick={() => {
            setOpen(true);
          }}>
          <IconHolder
            size={24}
            color={theme.palette.common.gray88}>
            <QuestionTooltip/>
          </IconHolder>
        </Fab>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default ToolTip;

interface ToolTipsText {
  children: string
}
