import {RouterState} from "connected-react-router";
import {AuthState} from "../bus/auth/reducer";
import {UiState} from "../bus/ui/reducer";
import {OptionsState} from "../bus/options/reducer";
import {PlacesState} from "../bus/business/reducer";
import {NotificationsState} from "../bus/notifications/reducer";
import {ProfileState} from "../bus/profile/reducer";
import {OffersState} from "../bus/offers/reducer";
import {VouchersState} from "../bus/vouchers/reducer";

export interface RootState {
  options: OptionsState
  router: RouterState
  auth: AuthState
  offers: OffersState
  vouchers: VouchersState
  profile: ProfileState
  places: PlacesState
  ui: UiState
  form: any
  notifications: NotificationsState
}

export type Workers = {
  type: string,
  [k: string]: any
}

export type Nullable<T> = T | null;

export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface INotification {
  id: number,
  msg: string,
  type: 'success' | 'warning' | 'error',
  icon: 'none' | 'success' | 'prolong' | 'attention' | 'mail',
}

export type IFile = {
  id: number,
  name: string,
  origin: string,
  thumbnails: IDynamicObject,
  preview?: string,
  index?: number,
  isConfirm?: boolean
}

export type Availability = 'Everyday' | 'Weekdays' | 'Weekends' | 'everyday' | 'weekdays' | 'weekends' | string;

export interface IDynamicObject {
  [k: string]: any
}

export type AdditionalActionInfo = {
  placeID: number,
  offerID: number,
  voucherID: number,
  updateTags: boolean,
  merchantID: number,
  actionWithCover: ActionWithFile,
  actionWithDocuments: ActionWithFile,
  actionWithPhotos: ActionWithFile,
  through: boolean
}

export type ActionWithFile = 'nothing' | 'save' | 'remove' | 'reSave';

export type Meta = {
  entities: Entities,
  pages: Pages
}

export type Entities = {
  total: number
  per_page: number
  offset: number
}

export type Pages = {
  current: Nullable<number>
  next: Nullable<number>
  prev: Nullable<number>
  last: Nullable<number>
}

export type TagFromAPI = {
  name: string,
  id: number
}

export enum ListStatus {
  Active = 'active',
  Draft = 'draft',
  Expired = 'expired',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type FetchData = {
  endpoint: string,
  method: Method,
  body?: any,
  tokenAccess?: string,
  id?: number,
  type?: 'photos' | 'documents' | 'singleCover' ,
}