import {useEffect, useState} from "react";
import {actionsWithCover, showArrayDiff} from "../../../../../utils/helpers";
import {ActionWithFile} from "../../../../../setup/typedefs";

export const useTrackUpdates = (initial: any, current: any) => {
  const [actionWithCover, setActionWithCover] = useState<ActionWithFile>('nothing');
  const [updateTags, setUpdateTags] = useState(false);

  useEffect(() => {
    const action = actionsWithCover(initial, current);
    setActionWithCover(action);
  },[initial, current]);

  useEffect(() => {
    setUpdateTags(!!showArrayDiff(initial.tags, current.tags).length);
  }, [current?.tags]);

  return {
    actionWithCover,
    updateTags
  };
};