import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore, { history } from './setup/configureStore';
import {ConnectedRouter} from "connected-react-router";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {LocaleContextProvider} from "./setup/LocaleContext";
const {store, persistor} = configureStore({});

const render = (Root: () => any) => {
  return ReactDOM.render(
    <Provider store={store}>
      <PersistGate
        persistor={persistor}
        loading={null}>
        <ConnectedRouter history={history}>
          <LocaleContextProvider>
            <Root/>
          </LocaleContextProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
};
render(App);
// @ts-ignore
if (module?.hot) {
  // @ts-ignore
  module.hot.accept<()=>void>('./App', () => {
    const NextApp = App;
    render(NextApp);
  });
}

serviceWorker.unregister();
