//Core
import React from 'react';
import {WrappedFieldProps} from "redux-form";
import NumberFormat from "react-number-format";

//Helpers

//Styles
import {TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import RequiredAsterisk from "../../../../../../../components/RequiredAsterisk";

const useStyles = makeStyles(styles);

function NumberFormatCustom({ inputRef, onChange, ...other }: NumberFormatCustomProps) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={({value}) => {
        value ? (
          onChange(value)
        ) : (
          onChange('')
        );
      }}
      thousandSeparator
      isNumericString
      prefix="LE "
      isAllowed={({floatValue}) => {
        if (floatValue && floatValue >= 0) {
          return true;
        } else return !floatValue;
      }}
    />
  );
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (value: string) => void;
  name: string;
}

const OriginalPrice: React.FC<WrappedFieldProps & any> = (props) => {
  const classes = useStyles();
  const {label, input, meta} = props;

  return (
    <div className={classes.root}>
      <Typography
        variant={'subtitle2'}
        className={classes.label}>
        {label}
        <RequiredAsterisk/>
      </Typography>
      <TextField
        {...input}
        className={classes.input}
        type={'text'}
        placeholder={'LE 0.00'}
        fullWidth
        variant={'outlined'}
        helperText={meta.touched && !meta.active && meta.error}
        error={meta.touched && !meta.active && !!meta.error}
        InputProps={{
          inputComponent: NumberFormatCustom as any
        }}
      />
    </div>
  );
};

export default OriginalPrice;

