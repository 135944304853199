import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  root: {
    position: 'relative'
  },
  buttonWrapper: {
    marginTop: 55,
    '& button': {
      marginRight: 15,
      [breakpoints.down(600)]: {
        display: 'block',
        marginRight: 0,
        marginBottom: 20
      },
      '&:lastChild': {
        marginRight: 0,
        [breakpoints.down(600)]: {
          marginBottom: 0
        }
      }
    },
    [breakpoints.down(1200)]: {
      marginTop: 120
    },
    [breakpoints.down(767)]: {
      marginTop: 60
    },
    [breakpoints.down(600)]: {
      marginTop: 20
    }
  }
});