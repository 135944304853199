import {createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../customTheme";

export const styles = (theme: GroupiTheme) => createStyles({
  descriptionWrapper: {
    marginTop: 6,
    '& .MuiTypography-body2': {
      letterSpacing: 0
    }
  },
  descriptionShowMore: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    '& .MuiTypography-subtitle2': {
      fontSize: 16,
      marginRight: 4,
      color: theme.palette.common.blue50
    }
  }
});
