import React from 'react';
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import schema from "./schema";
import syncValidate from "../../../../../utils/syncValidate";
import RenderInputMail from "./formFields/RenderInputMail";
import RenderInputName from "./formFields/RenderInputName";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

const useStyles = makeStyles(styles);

const PopupForm = (props: InjectedFormProps) => {
  const {handleSubmit} = props;
  const classes = useStyles();

  return (
    <form
      onSubmit={handleSubmit}
      className={classes.root}>
      <span className={classes.divider}/>
      <Grid
        container
        spacing={3}>
        <Field
          name={'mail'}
          component={RenderInputMail}
        />
        <Field
          name={'name'}
          component={RenderInputName}
        />
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: 'PopupForm',
  validate: syncValidate(schema),
  enableReinitialize: true
})(PopupForm);
