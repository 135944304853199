import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  header: {
    paddingTop: 105,
    [breakpoints.down(1200)]: {
      paddingTop: 0
    }
  },
  sidebar: {
    paddingLeft: 269,
    [breakpoints.down(1200)]: {
      paddingLeft: 0
    }
  },
  sidebarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [breakpoints.down(1200)]: {
      display: 'none'
    }
  },
  pageHolder: {
    padding: 40,
    [breakpoints.down(1200)]: {
      padding: '60px 20px 40px'
    },
    [breakpoints.down(575)]: {
      padding: '40px 15px 60px'
    }
  }
});
