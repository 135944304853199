import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const Upload = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 41 33">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 12H28V24H13V12H7L20.5 0L34 12ZM2 24V31H39V24H41V32V33H40H1H0V32V24H2Z"
      />
    </SvgIcon>
  );
};

export default Upload;
