import React, {useEffect} from 'react';
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {WrappedFieldProps} from "redux-form";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

const useStyles = makeStyles(styles);

const CheckBoxField = ({...props}: WrappedFieldProps & any) => {
  const classes = useStyles();
  const {input, disabled = false, label, setDisabledFields} = props;

  useEffect(() => {
    setDisabledFields(!input.value);
  }, [input]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          checked={!!input.value}
          onChange={input.onChange}
        />
      }
      label={label}
      className={classes.root}
    />
  );
};

export default CheckBoxField;