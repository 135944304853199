import React from 'react';
import {styles} from './styles';
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import RevenueCard from "./components/RevenueCard/RevenueCard";
import RevenueTable from "./components/RevenueTable/RevenueTable";

const useStyles = makeStyles(styles);

const cardInfo = [
  {
    text: 'Weekly revenue',
    sum: '16.50'
  },
  {
    text: 'Sum total',
    sum: '550.25'
  }
];

const tableInfo = [
  {
    text: 'Sharing platter for two',
    revenue: '$ 115.20'
  },
  {
    text: 'Two desserts in a jar',
    revenue: '$ 350'
  },
  {
    text: 'All-You-Can-Eat Indian Buffet',
    revenue: '$ 15.20'
  }
];

const MyRevenue = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography
        variant={'h1'}
        style={{marginBottom: 40}}>
        My Revenue
      </Typography>
      <RevenueCard cardInfo={cardInfo}/>
      <RevenueTable tableInfo={tableInfo}/>
    </div>
  );
};

export default MyRevenue;