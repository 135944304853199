import {put, call} from 'redux-saga/effects';
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors} from "../../../../utils/handleErrors";
import {placesActions} from "../../actions";
import {Workers} from "../../../../setup/typedefs";
import {formatPlaceObjectFromAPI} from "../../../../utils/helpers";
import {uiActions} from "../../../ui/actions";

export function* fetchPlace({payload}: Workers){
  try {
    yield put(uiActions.loading(true));
    const {id} = payload;
    const response = yield call(fetchWithToken, {
      endpoint: `places/${id}?relations=photos,documents,tags`,
      method: 'GET'
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    const {data} = yield call([response, 'json']);

    yield put(placesActions.fillPlace(formatPlaceObjectFromAPI(data)));

  } catch (e) {
    console.log(e);
  } finally {
    yield put(uiActions.loading(false));
  }
}
