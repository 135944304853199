import React from 'react';
import {WrappedFieldProps} from "redux-form";
import {Box} from "@material-ui/core";
import InputField from "../../../../../../components/InputField";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  change_password_render_input_password_placeholder: {
    id: "change_password_render_input_password_placeholder",
    defaultMessage: "New password"
  }
});

const RenderInputPassword = ({...props}:WrappedFieldProps) => {
  const {formatMessage} = useIntl();

  return (
    <Box width={'100%'}>
      <InputField
        {...props}
        type={'password'}
        placeholder={formatMessage(msg.change_password_render_input_password_placeholder)}
      />
    </Box>
  );
};

export default RenderInputPassword;
