import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import PersonBlock from "../PersonBlock";
import LogOutBlock from "../LogOutBlock";
import {styles} from "./styles";
import Sidebar from "../Sidebar";
import {Drawer, Typography, useTheme} from "@material-ui/core";
import ArrowWithTail from "../Icons/ArrowWithTail";
import TypographyCustom from "../TypographyCustom";
import clsx from "clsx";
import ButtonLinkBlue from "../ButtonLinkBlue";
import {book} from "../../routes/book";
import Plus from "../Icons/Plus";
import {GroupiTheme} from "../../customTheme";
import PlacesListWithSearch from "../PlacesListWithSearch";
import {defineMessages, useIntl} from "react-intl";
import {Place} from "../../bus/business/typedefs";
import SidebarBottom from "../SidebarBottom";

const msg = defineMessages({
  mobile_navigation_select_place_text: {
    id: "mobile_navigation_select_place_text",
    defaultMessage: "Selected place"
  },
  mobile_navigation_add_new_place_button_title: {
    id: "mobile_navigation_add_new_place_button_title",
    defaultMessage: "ADD NEW PLACE"
  }
});

const useStyles = makeStyles(styles);

type MobileNavigationProps = {
  isSaleManager: boolean,
  places: Place[] | null,
  choicePlace: any,
  currentPlace: Place | undefined
}

const MobileNavigation: React.FC<MobileNavigationProps> = ({isSaleManager, places, choicePlace, currentPlace}) => {
  const {formatMessage} = useIntl();
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const [openPlacesList, setOpenPlacesList] = useState(false);

  const togglePlacesList = (open: boolean, toggle = false) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    toggle
      ? setOpenPlacesList(!openPlacesList)
      : setOpenPlacesList(open);
  };

  return (
    <div className={classes.root}>
      {
        isSaleManager &&(
          <>
            <div className={classes.salesBlock}>
              <div
                onClick={togglePlacesList(true, true)}
                className={
                  clsx(classes.switcher, {
                    [classes.activeSwitch]: openPlacesList
                  })
                }>
                <ArrowWithTail/>
              </div>
              <div className={classes.infoText}>
                <TypographyCustom variant={'body3'}>
                  {formatMessage(msg.mobile_navigation_select_place_text)}
                </TypographyCustom>
                <Typography variant={'subtitle1'}>
                  {
                    currentPlace &&(
                      currentPlace.name
                    )
                  }
                </Typography>
              </div>
            </div>
            <Drawer
              className={classes.placesList}
              anchor={'right'}
              open={openPlacesList}
              onClose={togglePlacesList(false)}>
              <PlacesListWithSearch
                places={places}
                choicePlace={choicePlace}
                currentPlaceID={currentPlace!.id}
              />
            </Drawer>
          </>
        )
      }
      <Sidebar isSaleManager={isSaleManager}/>
      <SidebarBottom/>
      {
        isSaleManager && (
          <div className={classes.addNewItem}>
            <ButtonLinkBlue
              fullWidth
              link={book.createDeals}
              icon={(
                <Plus
                  color={"inherit"}
                  style={{color: theme.palette.common.white}}
                />
              )}>
              {formatMessage(msg.mobile_navigation_add_new_place_button_title)}
            </ButtonLinkBlue>
          </div>
        )
      }
      <div className={classes.bottomBlock}>
        <PersonBlock/>
        <LogOutBlock/>
      </div>
    </div>
  );
};

export default MobileNavigation;
