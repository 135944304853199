export const questions = [
  {
    question: ' How do I become Groupi vendor?',
    answer:
      `<li>Step 1: Provide your info at  <a href="/business/my-place">merchant.groupatonline.com/business/my-place</a></li>
      <li>Step 2: Speak with a <b>Groupi</b> representative or create your campaign using the <b>Groupi</b> Deal Creator tools.</li>
      <li>Step 3: Submit your Promotion/Voucher and wait for your deal to be approved by Groupi (approval may take up to 24 hours)</li>
      <li>Step 4: you will receive your approval by email or your receive a phone call from Groupi representative confirming your deal is active and available for users on the Groupi App</li>
      <li>Step 3: Prepare your business and staff.</li>
      <li>Step 4: Celebrate your campaign's launch.</li>
      <li>Step 5: Track your results using your <b>Groupi Merchant Portal</b></li>`
  },
  {
    question: 'Why should I become Groupi vendor?',
    answer: 'Groups are very important to almost every type of business, as groups bring a higher value sale and help increase your revenue in a short period of time. Users love Groupi as it’s the only group-based value provider in the market, group were groups can do anything while making use of value deals provided by Groupi vendors. Every individual in any group visiting you is considered a potential future customer who may come back with his family and friends to enjoy your services'
  },
  {
    question: 'Can I cancel my promotion or voucher anytime?',
    answer: 'Once you log on into Groupi Merchant portal, you can request to cancel, extend or edit any of your vouchers/promotions. Please note any voucher or promotion has an expiry date, Groupi vendors must respect and honor any voucher sold or presented before the time of cancellation.Cancelling your vouchers or promotions will have to be approved by Groupi. Once your cancellation is approved, you will see your deal deactivated in the Groupi Merchant portal. Any Voucher or promotion sold or saved by any of the Groupi users before the time of cancellation, will be valid   Groups are very important to almost every type of business, as groups bring a higher value'
  },
  {
    question: 'How many deals I can create per month on Groupi?',
    answer: ' You can create up to 3 deals (voucher/promotions) on Groupi merchant portal.'
  },
  {
    question: 'Is there a minimum for deal discount to be active on Groupi?',
    answer: 'Yes, a minimum of 15% discount is required for your deal to be approved and activated on Groupi. We will also consider deal that includes Freebies or non-discount value. Our recommendations to our vendors is to create a mix of deals between discounts and freebies to attract largest number of groups and maximize the revenue from Groupi.'
  },
  {
    question: 'What’s the minimum deal availability period on Groupi?',
    answer: 'The minimum for any deal on Groupi is 90 days from the day your deal is active on Groupi app. The availability of any deal is different from the expiry date.'
  },
  {
    question: 'Can I create a marketing campaign using my vouchers on Groupi?',
    answer: 'Yes, Groupi vendors can create deals targeting specific segment of the Groupi users using the Marketing tools available on Groupi Merchant portal. Please contact your Groupi Representative to discuss.'
  }
];
