import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import CoverDropInput from "../../../../components/CoverDropInput";

const RenderInputCover: React.FC<WrappedFieldProps & any> = ({input}) => {
  return (
    <FieldRow labelText={'Cover'}>
      <CoverDropInput
        input={input}
      />
    </FieldRow>
  );
};

export default RenderInputCover;
