import {GroupiTheme} from "../../customTheme";
import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  linksWrapper: {
    width: '100%',
    padding: '0 19px 12px',
    [breakpoints.down(804)]: {

    }
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.common.trueBlack,
    background: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 49,
    padding: '7px 15px',
    marginBottom: 5
  },
  activeLink: {
    background: theme.palette.common.gray95
  }
});