//Core
import React, {useEffect, useState} from 'react';
import {Accordion} from "@material-ui/core";

//Components
import {SideBarLinkType} from "../Sidebar/navigationSetUp";
import AccordionTitle from "../AccordionTitle";
import AccordionPages from "../AccordionPages";

type AccordionBoxProps = {
  title?: string,
  data: SideBarLinkType[],
  disabled?: boolean,
  expand: boolean
}

const AccordionBox: React.FC<AccordionBoxProps> = ({title, data, disabled = false, expand}) => {
  const [toggleAccordion, setToggleAccordion] = useState(true);

  const toggle = () => {
    setToggleAccordion((prevState) => !prevState);
  };

  useEffect(() => {
    setToggleAccordion(expand);
  }, [expand]);

  return (
    <Accordion
      disabled={disabled}
      expanded={toggleAccordion && !disabled}>
      {
        title && (
          <AccordionTitle toggle={toggle}>
            {title}
          </AccordionTitle>
        )
      }
      <AccordionPages data={data}/>
    </Accordion>
  );
};

export default AccordionBox;
