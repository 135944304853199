import React from 'react';
import {WrappedFieldProps} from "redux-form";
import {MenuItem, Select} from "@material-ui/core";
import FieldRow from "../../../../../../components/FieldRow";
import DropDownArrow from "../../../../../../components/DropDownArrow";
import {defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {getBusinessTypes} from "../../../../../../bus/options/selectors";

const msg = defineMessages({
  my_place_form_render_input_business_type_label: {
    id: "my_place_form_render_input_business_type_label",
    defaultMessage: "Business type"
  }
});

const RenderInputBusinessType = ({input}: WrappedFieldProps) => {
  const {formatMessage} = useIntl();
  const businessTypes = useSelector(getBusinessTypes);

  const businessTypesJSX = businessTypes?.map((businessType) => {
    return (
      <MenuItem
        key={businessType.id}
        value={businessType.id}>
        {businessType.name}
      </MenuItem>
    );
  });

  return (
    <FieldRow
      labelText={formatMessage(msg.my_place_form_render_input_business_type_label)}
      onlyStar>
      <Select
        IconComponent={DropDownArrow}
        fullWidth
        {...input}
        variant={"outlined"}
        labelId="label"
        id="select">
        {businessTypesJSX}
      </Select>
    </FieldRow>
  );
};

export default RenderInputBusinessType;

