import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: '110px auto 0',
    '&> .MuiPagination-ul': {
      '& li': {
        '&:first-child': {
          marginRight: 20,
          [breakpoints.down(768)]: {
            marginRight: 10
          },
          '& button': {
            background: theme.palette.common.blue50
          }
        },
        '&:last-child': {
          marginLeft: 20,
          [breakpoints.down(768)]: {
            marginLeft: 10
          },
          '& button': {
            background: theme.palette.common.blue50
          }
        }
      },
      '& button': {
        border: 'none',
        width: 40,
        height: 40,
        borderRadius: 8,
        margin: 0,
        background: 'none',
        fontSize: 20,
        fontFamily: 'Source Sans Pro',
        color: theme.palette.common.blue50,
        [breakpoints.down(768)]: {
          width: 38,
          height: 38
        },
        '&.Mui-selected': {
          background: theme.palette.common.gray95
        },
        '& .MuiPaginationItem-icon': {
          color: theme.palette.common.white
        },
        '&:hover': {
          background: theme.palette.common.gray95
        }
      }
    },
    [breakpoints.down(768)]: {}
  }
});