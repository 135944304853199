import React from 'react';
import schema from "./schema";
import {Box, Button} from "@material-ui/core";
import syncValidate from "../../../../../utils/syncValidate";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import RenderInputMail from "./formFields/RenderInputMail";
import RenderInputPassword from "./formFields/RenderInputPassword";
import RenderInputConfirmPassword from "./formFields/RenderInputConfirmPassword";
import CheckBoxWithText from "../../../components/CheckBoxWithText";

import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  signUp: {
    id: "sign_up_submit_button",
    defaultMessage: "Sign up"
  }
});

const SignUpForm = (props: InjectedFormProps & any) => {
  const {formatMessage} = useIntl();
  const {handleSubmit, initialValues} = props;
  const {iAgree, setIAgree} = initialValues;

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={3}>
        <Field
          name={'email'}
          component={RenderInputMail}
        />
      </Box>
      <Box mb={2.4}>
        <Field
          name={'password'}
          component={RenderInputPassword}
        />
      </Box>
      <Box mb={2.4}>
        <Field
          name={'confirmPassword'}
          component={RenderInputConfirmPassword}
        />
      </Box>
      <Box mb={2.4}>
        <CheckBoxWithText
          iAgree={iAgree}
          setIAgree={setIAgree}
        />
      </Box>
      <Button
        disabled={!iAgree}
        type='submit'
        variant="contained"
        color="primary">
        {formatMessage(msg.signUp)}
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'signUpForm',
  validate: syncValidate(schema)
})(SignUpForm);
