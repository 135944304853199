//Core
import React from 'react';

//Helpers
import {Offer} from "../../../../../../bus/offers/typedefs";

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import RemoveButton from "./RemoveButton";
import EditButton from "./EditButton";
import FinishButton from "./FinishButton";
import RecreateButton from "./RecreateButton";
import CancelButton from "./CancelButton";

const useStyles = makeStyles(styles);

type ControlButtonsProps = {
  data: Offer
}

const ControlButtons: React.FC<ControlButtonsProps> = ({data}) => {
  const classes = useStyles();
  const {status, id, name} = data;

  if (!data) {
    return <></>;
  }

  if (status === 'active') {
    return (
      <div className={classes.root}>
        <EditButton id={id}/>
        <FinishButton
          id={id}
          name={name}
        />
      </div>
    );
  }

  if (status === 'draft') {
    return (
      <div className={classes.root}>
        <EditButton id={id}/>
        <RemoveButton id={id}/>
      </div>
    );
  }

  if (status === 'expired') {
    return (
      <div className={classes.root}>
        <RecreateButton id={id}/>
      </div>
    );
  }

  if (status === 'rejected') {
    return (
      <div className={classes.root}>
        <EditButton id={id}/>
        <RemoveButton id={id}/>
      </div>
    );
  }

  if (status === 'pending') {
    return (
      <div className={classes.root}>
        <CancelButton
          id={id}
          name={name}
        />
      </div>
    );
  }

  return (
    <></>
  );
};

export default ControlButtons;