import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const Exclamation = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 16 16">
      <circle
        cx="8"
        cy="8"
        r="6"
        fill={props.fill}
      />
      <rect
        x="7"
        y="4"
        width="2"
        height="5"
        rx="1"
        fill="white"
      />
      <rect
        x="7"
        y="10"
        width="2"
        height="2"
        rx="1"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Exclamation;
