//Core
import React from 'react';
import {Link} from "react-router-dom";

//Helpers
import {book} from "../../../../routes/book";

//Styles
import {Typography, useTheme} from "@material-ui/core";
import {GroupiTheme} from "../../../../customTheme";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import HeadIcon from "../../../../components/Icons/HeadIcon";
import Account from "../../../../components/Icons/Account";
import IconHolder from "../../../../components/IconHolder";
import Chart from "../../../../components/Icons/Chart";
import Hand from "../../../../components/Icons/Hand";
import Header from "../../../../components/Header";
import WelcomeRow from "./components/WelcomeRow";

const useStyles = makeStyles(styles);

const Welcome = () => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();

  return (
    <>
      <Header/>
      <div className={classes.rootWrapper}>
        <Typography
          variant={'h1'}
          align={'center'}>
          Welcome to Group@t family
        </Typography>
        <Link
          className={classes.linkButton}
          to={book.personal}>
          <Typography variant={'h4'}>
            Go to my personal account
          </Typography>
          <IconHolder
            size={80}
            color={theme.palette.common.white}>
            <Account/>
          </IconHolder>
        </Link>
        <div className={classes.divider}/>
        <div className={classes.content}>
          <WelcomeRow
            video={"/groupi-merchant.mp4"}
            icon={<HeadIcon/>}
            headerText={'Why Groupi?'}>
            We’ve created a web application to help businesses manage group bookings. The Groupi for Merchants System enables venues and restaurants to create an attractive group offer and manage it online. It also allows users to easily connect and add information in the application and on google maps. Created by the team of the popular Groupi web app, this new system is convenient, reliable, and easy-to-use.
          </WelcomeRow>
          <WelcomeRow
            video={"/groupi-user.mp4"}
            icon={<Hand/>}
            headerText={'How to Use Groupi'}>
            Bring your venue online and start managing group bookings online through our merchant web application. Add availability, pricing, descriptions, photos, and more all directly from the convenience of your computer. Push this information out onto Groupi apps so that potential customers can search for you by location. Start increasing your efficiency and making new connections through the Groupi platform!
          </WelcomeRow>
          <WelcomeRow
            video={"/groupi-mobile.mp4"}
            icon={<Chart/>}
            headerText={'Groupi Best Practice'}>
            The Groupi concept is simple: create an attractive group offer and easily invite your contacts or advertise online to get more visitors for your venue and/or restaurant.
          </WelcomeRow>
        </div>
      </div>
    </>
  );
};

export default Welcome;