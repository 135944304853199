import {useDispatch, useSelector} from "react-redux";

import {ContactPageFormValues} from "../form/typedefs";
import {profileActions} from "../../../../../bus/profile/actions";
import {isDirty, isPristine, reset} from "redux-form";

export const useContactPage = () => {
  const dispatch = useDispatch();
  const form = 'ContactPageForm';
  const pristine = useSelector(isPristine(form));
  const dirty = useSelector(isDirty(form));

  const onSubmit = (values: ContactPageFormValues) => {
    dispatch(reset(form));
    dispatch(profileActions.contactAdmin(values));
  };

  return {
    onSubmit,
    pristine,
    dirty
  };
};
