import React from 'react';
import {WrappedFieldProps} from "redux-form";
import {Box, Grid} from "@material-ui/core";
import InputField from "../../../../../../components/InputField";

const RenderInputOfferName = ({...props}:WrappedFieldProps) => {
  return (
    <Grid
      item
      xs={12}>
      <Box
        my={0.1}
        display={'flex'}
        alignItems="normal">
        <Box
          width={'100%'}
          flex={'1 1 auto'}>
          <InputField
            {...props}
            type={'text'}
            placeholder={'Name your Deal'}
            variant="filled"
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default RenderInputOfferName;
