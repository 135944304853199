import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  table: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 37,
    [breakpoints.down(784)]: {}
  },
  tableHead: {
    display: 'flex',
    justifyContent: 'space-between',
    borderColor: theme.palette.common.gray88,
    borderBottom: '1px solid #C8CBD0',
    paddingBottom: 12,
    marginBottom: 20
  },
  headerText: {
  },
  headerRevenue: {

  },
  tableBody: {
    display: 'flex',
    flexDirection: 'column'
  },
  bodyRow: {
    display: 'flex',
    justifyContent: 'space-between',
    borderColor: theme.palette.common.gray88,
    borderBottom: '1px solid #C8CBD0',
    paddingBottom: 20,
    marginBottom: 20,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  bodyRowCell: {

  }
});