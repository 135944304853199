import React from 'react';
import {IconButton, Typography, useTheme} from "@material-ui/core";
import IconHolder from "../../../../../../components/IconHolder";
import {GroupiTheme} from "../../../../../../customTheme";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import Edit from "../../../../../../components/Icons/Edit";
import {useHistory} from "react-router-dom";
import {book} from "../../../../../../routes/book";

const useStyles = makeStyles(styles);

const EditButton: React.FC<any> = ({id}) => {
  const history = useHistory();
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();

  const handleClick = (event: React.ChangeEvent<unknown>) => {
    history.push(book.editDeal + `/${id}`);
  };

  return (
    <div className={classes.button}>
      <IconButton
        color="primary"
        onClick={handleClick}>
        <IconHolder
          size={32}
          color={theme.palette.common.blue50}>
          <Edit/>
        </IconHolder>
      </IconButton>
      <Typography variant={'body2'}>
        Edit
      </Typography>
    </div>
  );
};

export default EditButton;
