import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  rowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 1254,
    width: '100%',
    margin: '0 auto 64px',
    [breakpoints.down(960)]: {
      flexDirection: 'column'
    }
  },
  textWrapper: {
    width: 434,
    [breakpoints.down(1280)]: {
      width: 300
    },
    [breakpoints.down(960)]: {
      width: '100%'
    },
    '& .MuiTypography-h3' : {
      marginTop: 32,
      marginBottom: 12
    }
  },
  videoWrapper: {
    width: 616,
    height: 345,
    [breakpoints.down(1280)]: {
      width: 550
    },
    [breakpoints.down(960)]: {
      width: '100%',
      height: 450,
      marginTop: 50
    },
    [breakpoints.down(768)]: {
      height: 300
    },
    [breakpoints.down(600)]: {
      height: 200
    }
  }
});
