import {InjectedFormProps} from "redux-form";
import {useEffect, useState} from "react";

export const usePersonalForm = (props: InjectedFormProps) => {
  const {handleSubmit, dirty} = props;

  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setIsChanged(dirty);
  }, [dirty]);

  return {handleSubmit, isChanged};
};
