//Core
import React from 'react';

//Helpers
import {useRenderSingleVoucher} from "../../customHooks/useRenderSingleVoucher";
import {concatDate, concatTime} from "../../../../../../utils/helpers";
import {Voucher} from "../../../../../../bus/vouchers/typedefs";

//Styles
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import Calendar from "../../../../../../components/Icons/Calendar";
import Repeat from "../../../../../../components/Icons/Repeat";
import Clock from "../../../../../../components/Icons/Clock";
import Status from "../../../../../../components/Status";
import WrapRow from "../../../../components/WrapRow";
import ControlButtons from "../ControlButtons";
import RenderTags from "../RenderTags";
import RenderDescription from "../../../../components/RenderDescription";
import StatisticsWrapRow from "../StatisticsWrapRow";
import RenderPeriod from "../RenderPeriod";

const useStyles = makeStyles(styles);

type RenderSingleOfferProps = {
  data: Voucher
}

const RenderSingleVoucher: React.FC<RenderSingleOfferProps> = ({data}) => {
  const classes = useStyles();
  const {tablet, randomImg} = useRenderSingleVoucher();

  return (
    <div className={classes.root}>
      {!!data?.status && <Status status={data.status}/>}
      <Typography
        variant={'h1'}
        className={classes.name}>
        {data.name}
      </Typography>
      <div className={classes.topBlock}>
        <div className={classes.imgHolder}>
          <img
            src={data.cover?.origin ? data.cover?.origin : `/defaultImage${randomImg}.svg`}
            alt={data.name}
          />
        </div>
        <div className={classes.mainInfoBlock}>
          <WrapRow
            icon={<Calendar/>}
            content={concatDate(data.dateStart, data.dateEnd, true)}
          />
          <WrapRow
            icon={<Repeat/>}
            content={data.availability}
          />
          <WrapRow
            icon={<Clock/>}
            content={concatTime(data.timeStart, data.timeEnd)}
          />
          {!tablet && <RenderDescription data={data}/>}
          <RenderTags tags={data.tags}/>
        </div>
      </div>
      {tablet && <RenderDescription data={data}/>}
      {!!data && (
        <ControlButtons
          status={data.status}
          id={data.id}
          name={data.name}
        />
      )}
      <RenderPeriod data={data}/>
      <div className={classes.subMainInfoBlock}>
        <Typography variant={'h3'}>
          Statistics
        </Typography>
        <div className={classes.statisticsWrapper}>
          <StatisticsWrapRow
            value={data.viewsCount}
            title={'Views'}
          />
          <StatisticsWrapRow
            value={data.favoritesCount}
            title={'Bought'}
          />
          <StatisticsWrapRow
            value={data.reservationsCount}
            title={'Used'}
          />
        </div>
      </div>
    </div>
  );
};

export default RenderSingleVoucher;
