import {useEffect, useState} from "react";
import useWindowDimensions from "../../../../../utils/customHooks/useWindowDimensions";

export const useRenderSingleVoucher = () => {
  const {width} = useWindowDimensions();
  const [tablet, setTablet] = useState(false);
  const randomImg = Math.floor(Math.random() * Math.floor(3));

  useEffect(() => {
    setTablet(width <= 991 && width >= 768);
  }, [width]);

  return {
    tablet,
    randomImg
  };
};