import React, {useEffect, useState} from 'react';
import {Typography, useTheme} from "@material-ui/core";
import useWindowDimensions from "../../../../utils/customHooks/useWindowDimensions";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import {GroupiTheme} from "../../../../customTheme";
import ButtonLinkBlue from "../../../../components/ButtonLinkBlue";
import IconHolder from "../../../../components/IconHolder";
import {book} from "../../../../routes/book";
import Plus from "../../../../components/Icons/Plus";

const useStyles = makeStyles(styles);

const ContainerHead: React.FC<any> = ({title}) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const {width} = useWindowDimensions();
  const [buttonText, setButtonText] = useState('Create new voucher');
  const [titleText, setTitleText] = useState(title + ' Vouchers');

  useEffect(() => {
    width < 768
      ? setButtonText('')
      : setButtonText('Create new Voucher');

    width < 768
      ? setTitleText(title)
      : setTitleText(title + ' Vouchers');

  }, [width]);

  return (
    <div className={classes.pageHeader}>
      <Typography variant={'h1'}>
        {titleText}
      </Typography>
      <ButtonLinkBlue
        icon={
          <IconHolder
            size={16}
            color={theme.palette.common.white}>
            <Plus/>
          </IconHolder>
        }
        link={book.createVoucher}
        fullWidth={false}>
        {buttonText}
      </ButtonLinkBlue>
    </div>
  );
};

export default ContainerHead;