import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Person from "../Icons/Person";
import {Typography, useTheme} from "@material-ui/core";
import {styles} from "./styles";
import {useSelector} from "react-redux";
import {Nullable} from "../../setup/typedefs";
import {GroupiTheme} from "../../customTheme";
import IconHolder from "../IconHolder";
import {profileEmail, profileName} from "../../bus/profile/selectors";

const useStyles = makeStyles(styles);

const PersonBlock = () => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const name = useSelector(profileName);
  const email = useSelector(profileEmail);
  const [person, setPerson] = useState<Nullable<string>>(null);

  useEffect(() => {
    setPerson(name || email);
  },[email, name]);

  return (
    <div className={classes.person}>
      <div className={classes.avatar}>
        <IconHolder
          size={24}
          color={theme.palette.common.white}>
          <Person/>
        </IconHolder>
      </div>
      <Typography variant={'body1'}>
        {person}
      </Typography>
    </div>
  );
};

export default PersonBlock;