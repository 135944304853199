import {createStyles} from "@material-ui/core";

export const styles = () => createStyles({
  pageHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 40
  }
});
