import {ChangePasswordFormValues} from "../../../../containers/AuthContainer/pages/ChangePassword/form/typedefs";
import jwtDecode from "jwt-decode";
import {put, call} from "redux-saga/effects";
import {push} from "connected-react-router";
import {book} from "../../../../routes/book";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {fetchWithDisposableToken} from "../../../utils/fetching";

export function* changePassword({payload}: {type: string, payload: ChangePasswordFormValues}){
  try {
    const {token, password} = payload;

    // @ts-ignore
    const { iss } = yield call(jwtDecode, token);

    const data = {
      tokenAccess: token,
      endpoint: 'set-password',
      method: 'PUT',
      id: iss,
      body: {
        password: password
      }
    };

    // @ts-ignore
    const response = yield call(fetchWithDisposableToken, data);

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    yield call(handleMsg, 'You password was successfully change');
    yield put(push(book.signIn));

  } catch (e) {
    console.log(e);
  }
}
