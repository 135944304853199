//Core
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//Helpers
import {createVoucherFormSelector} from "../../../../../bus/forms/selectors";
import {getPlace} from "../../../../../bus/business/selectors";
import {vouchersActions} from "../../../../../bus/vouchers/actions";
import {Voucher} from "../../../../../bus/vouchers/typedefs";
import {usePreventRedirect} from "./usePreventRedirect";
import {profileIsVerified} from "../../../../../bus/profile/selectors";

export const useVoucherCreateForm = () => {
  const {saving, isChanged} = usePreventRedirect();
  const dispatch = useDispatch();

  const formValue = useSelector(createVoucherFormSelector);
  const place = useSelector(getPlace);
  const isVerified = useSelector(profileIsVerified);

  const [allowDraft, setAllowDraft] = useState(false);
  const [placeTime, setPlaceTime] = useState({});

  useEffect(() => {
    formValue && setAllowDraft(!!formValue.name);
  },[formValue]);

  useEffect(() => {
    setPlaceTime({
      openedTimeEnd: place?.openedTimeEnd,
      openedTimeStart: place?.openedTimeStart
    });
  }, [place]);

  const goPreview = () => {
    formValue && dispatch(vouchersActions.createPreview(formValue));
  };

  const onSubmit = (values: Voucher | any) => {
    saving();

    if (place?.id) {
      dispatch(vouchersActions.createVoucher({
        formData: {
          ...values,
          isPublished: true
        },
        info: {
          placeID: place?.id,
          actionWithCover: !values.cover ? 'nothing' : 'save'
        }
      }));
    }
  };

  const onDraftSubmit = () => {
    saving();

    if (place?.id) {
      dispatch(vouchersActions.createVoucher({
        formData: {
          ...formValue,
          isPublished: false
        },
        info: {
          placeID: place?.id,
          actionWithCover: !formValue.cover ? 'nothing' : 'save'
        }
      }));
    }
  };

  return {
    goPreview,
    allowDraft,
    placeTime,
    onSubmit,
    isChanged,
    isVerified,
    onDraftSubmit
  };
};
