import {useEffect, useState} from "react";
import {voucherFieldsIsEqual} from "../../../../../utils/helpers";
import {useSelector} from "react-redux";
import {editVoucherFormSelector, initialEditVoucherFormSelector} from "../../../../../bus/forms/selectors";

export const usePreventRedirect = () => {
  const current = useSelector(editVoucherFormSelector);
  const initial = useSelector(initialEditVoucherFormSelector);

  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!isSaving) {
      if (initial && current) {
        setIsChanged(!voucherFieldsIsEqual(initial, current) || !!current?.cover);
      }
    }
  }, [initial, current]);

  const saving = () => {
    setIsChanged(false);
    setIsSaving(true);
  };

  return {
    saving,
    isChanged
  };
};