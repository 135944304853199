//Core
import React from 'react';
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import syncValidate from "../../../../../utils/syncValidate";

//Helpers
import schema from "./schema";
import {useVoucherEditForm} from "../customHooks/useVoucherEditForm";

//Styles
import {Button, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import DateFieldRow from "../../../components/DateFieldRow";
import TimeFieldRow from "../../../components/TimeFieldRow";
import FormDivider from "../../../../../components/FormDivider";
import RenderInputAvailability from "./formFields/RenderInputAvailability";
import RenderInputVoucherName from "./formFields/RenderInputVoucherName";
import RenderInputDescription from "./formFields/RenderInputDescription";
import RenderPriceFieldsRow from "./formFields/RenderPriceFieldsRow";
import RenderInputUsePeriod from "./formFields/RenderInputUsePeriod";
import RenderInputCover from "./formFields/RenderInputCover";
import RenderInputTags from "./formFields/RenderInputTags";
import {book} from "../../../../../routes/book";
import {Prompt} from "react-router";
import {Voucher} from "../../../../../bus/vouchers/typedefs";

const useStyles = makeStyles(styles);

const VoucherEditForm: React.FC<InjectedFormProps & any> = (props) => {
  const classes = useStyles();
  const {handleSubmit, initialValues, valid} = props;
  const {
    goPreview,
    placeTime,
    allowDraft,
    isChanged,
    isVerified,
    onSubmit,
    onDraftSubmit
  } = useVoucherEditForm();

  return (
    <form onSubmit={handleSubmit}>
      <Prompt
        when={isChanged}
        message={(location) => {
          return location.pathname === book.previewVoucher
            ? true
            : 'All your changes will not be saved. Continue?';
        }}
      />
      <Grid
        container
        spacing={3}>
        <Field
          name={'name'}
          component={RenderInputVoucherName}
        />
        <FormDivider/>
        <DateFieldRow
          isRequired
          toolTipText={'Period to Promote the Vouchers through Groupi’s services and mobile application and to provide offered services to Users.'}
          initialValues={initialValues}
          labelText={'Promo period'}
        />
        <Field
          isRequired
          toolTipText={'Thats mean the Period when Purchased Voucher can be used . This period Limited by Promotional Value Expiration Date.'}
          labelText={'Usage Period'}
          name={'usePeriod'}
          component={RenderInputUsePeriod}
        />
        <FormDivider/>
        <Field
          name={'availability'}
          component={RenderInputAvailability}
        />
        <TimeFieldRow
          isRequired
          toolTipText={'Voucher can be used only during specific hours indicated. By default it use business hours of the Place.'}
          initialValues={initialValues}
          placeTime={placeTime}
          labelText={'Time'}
        />
        <FormDivider/>
        <Field
          initialValues={initialValues}
          name={'cover'}
          component={RenderInputCover}
        />
        <FormDivider/>
        <Field
          name={'description'}
          component={RenderInputDescription}
        />
        <FormDivider/>
        <RenderPriceFieldsRow/>
        <FormDivider/>
        <Field
          initialValues={initialValues}
          name={'tags'}
          component={RenderInputTags}
        />
        <FormDivider/>
      </Grid>
      <div className={classes.buttonWrapper}>
        <Button
          onClick={goPreview}
          variant="outlined"
          color="primary">
          Preview
        </Button>
        {
          initialValues.status !== 'active' &&(
            <Button
              onClick={() => onDraftSubmit()}
              disabled={!allowDraft}
              variant="outlined"
              color="primary">
              Save as a Draft
            </Button>
          )
        }
        <Button
          onClick={handleSubmit((values: Voucher) => onSubmit(values))}
          disabled={!valid || !isVerified}
          variant="contained"
          color="primary">
          Publish
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'voucherEditForm',
  validate: syncValidate(schema),
  enableReinitialize: true
})(VoucherEditForm);
