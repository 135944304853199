import {call, put} from "redux-saga/effects";
import jwtDecode from "jwt-decode";
import {push} from "connected-react-router";
import {book} from "../../../../routes/book";
import {handleErrors} from "../../../../utils/handleErrors";
import {fetchWithDisposableToken} from "../../../utils/fetching";

export function* verifyEmail(payload: {type: string, token: string}){
  try {
    const {token} = payload;
    const { iss } = yield call(jwtDecode, token);
    const response = yield call(fetchWithDisposableToken, {
      tokenAccess: token,
      endpoint: 'verify',
      method: 'PUT',
      id: iss
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

  } catch (e) {
    console.log(e);
  } finally {
    yield put(push(book.signIn));
  }
}
