import React, {useEffect, useState} from 'react';
import {TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import NumberFormat from "react-number-format";
import ToolTip from "../../../../../../../components/ToolTip";
import {Nullable} from "../../../../../../../setup/typedefs";

const useStyles = makeStyles(styles);

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        values.value &&(
          onChange(values.value)
        );
      }}
      thousandSeparator
      isNumericString
      prefix="LE "
    />
  );
}

const DummyInput = (props: any) => {
  const classes = useStyles();
  const [result, setResult] = useState<Nullable<string>>(null);
  const {meta, setPercent, toolTipText, label, commission, voucherOriginalPrice, voucherDiscountPrice} = props;

  useEffect(() => {
    if (voucherOriginalPrice && voucherDiscountPrice) {
      const percent = 100 - Math.ceil(voucherDiscountPrice / (voucherOriginalPrice / 100));
      const formattedResult = +(voucherDiscountPrice - (voucherDiscountPrice * commission));
      setResult(formattedResult.toFixed(2));
      setPercent(percent);
    } else {
      setPercent('');
      setResult(null);
    }
  }, [voucherOriginalPrice, voucherDiscountPrice, commission, setPercent]);

  return (
    <div className={classes.root}>
      <Typography
        variant={'subtitle2'}
        className={classes.label}>
        {label}
        <ToolTip>
          {toolTipText}
        </ToolTip>
      </Typography>
      <TextField
        disabled={true}
        value={result}
        className={classes.input}
        type={'text'}
        placeholder={'LE 0.00'}
        fullWidth
        variant={'outlined'}
        helperText={meta.touched && !meta.active && meta.error}
        error={meta.touched && !meta.active && !!meta.error}
        InputProps={{
          inputComponent: NumberFormatCustom as any
        }}
      />
    </div>
  );
};

export default DummyInput;

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (value: string) => void;
  name: string;
}
