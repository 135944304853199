import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const Prolong = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8694 9.14891C13.495 8.16881 15.4161 7.79867 17.2895 8.10463C19.1629 8.41059 20.8664 9.37272 22.0957 10.8191C22.9815 11.8613 23.5795 13.1059 23.8445 14.4303L23.7071 14.2929C23.3166 13.9024 22.6834 13.9024 22.2929 14.2929C21.9024 14.6834 21.9024 15.3166 22.2929 15.7071L24.2929 17.7071L25 18.4142L25.7071 17.7071L27.7071 15.7071C28.0976 15.3166 28.0976 14.6834 27.7071 14.2929C27.3166 13.9024 26.6834 13.9024 26.2929 14.2929L25.9117 14.6741C25.6587 12.7823 24.8677 10.9923 23.6197 9.52385C22.083 7.71589 19.9536 6.51323 17.6119 6.13078C15.2701 5.74833 12.8687 6.211 10.8367 7.43613C8.80469 8.66126 7.27452 10.569 6.51961 12.8185C5.76471 15.0679 5.83426 17.5125 6.71584 19.7154C7.59742 21.9183 9.23358 23.736 11.332 24.8436C13.4303 25.9512 15.8542 26.2766 18.1704 25.7617C20.4866 25.2467 22.5442 23.9249 23.9755 22.0325L22.3804 20.826C21.2353 22.3399 19.5893 23.3973 17.7363 23.8093C15.8833 24.2213 13.9443 23.961 12.2656 23.0749C10.5869 22.1888 9.27794 20.7347 8.57267 18.9724C7.86741 17.21 7.81177 15.2544 8.41569 13.4548C9.01962 11.6552 10.2438 10.129 11.8694 9.14891ZM17 12V15.4648L19.5547 17.168L18.4453 18.8321L15.4453 16.8321L15 16.5352V16V12H17Z"
        fill={props.fill}
      />
    </SvgIcon>
  );
};

export default Prolong;
