import {call, put} from 'redux-saga/effects';
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors} from "../../../../utils/handleErrors";
import {offersActions} from "../../actions";
import {Workers} from "../../../../setup/typedefs";
import {uiActions} from "../../../ui/actions";

export function* fetchOffers({payload}: Workers){
  try {
    yield put(uiActions.loading(true));
    const {placeID, page, perPage, status} = payload;
    const response = yield call(fetchWithToken, {
      endpoint: `places/${placeID}/offers?page=${page}&per_page=${perPage}&status=${status}`,
      method: 'GET'
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    const {data, meta} = yield call([response, 'json']);
    yield put(offersActions.fillOffers(data, meta));

  } catch (e) {
    console.log(e);
  } finally {
    yield put(uiActions.loading(false));
  }
}
