import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  rootWrapper: {
    width: 432,
    margin: '0 auto',
    [breakpoints.down(475)]: {
      width: '100%'
    }
  }
});