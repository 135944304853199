import React from 'react';
import CheckboxBlueSquare from "../../../../components/CheckboxBlueSquare";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid, Typography} from "@material-ui/core";
import {book} from "../../../../routes/book";
import {Link} from "react-router-dom";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  sign_up_form_render_checkbox_i_read_and_agree_text: {
    id: "sign_up_form_render_checkbox_i_read_and_agree_text",
    defaultMessage: "I read and agree with the"
  },
  sign_up_form_render_checkbox_i_read_and_agree_link: {
    id: "sign_up_form_render_checkbox_i_read_and_agree_link",
    defaultMessage: "terms and conditions"
  }
});

const useStyles = makeStyles(styles);

type CheckBoxWithTextProps = {
  iAgree: boolean,
  setIAgree: any
}

const CheckBoxWithText: React.FC<CheckBoxWithTextProps> = ({iAgree, setIAgree}) => {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  const HandleCheckBox = () => {
    setIAgree(!iAgree);
  };

  return (
    <Grid
      item
      xs={12}>
      <Box
        my={0.1}
        display={'flex'}
        alignItems="center">
        {/*{!iAgree &&(*/}
        <CheckboxBlueSquare
          className={classes.checkBox}
          onChange={HandleCheckBox}
          checked={iAgree}
          name="agree"
        />
        {/*)}*/}
        <Typography variant={'body2'}>
          {formatMessage(msg.sign_up_form_render_checkbox_i_read_and_agree_text)}
          {' '}
          <Link
            to={book.privacy.forSite}
            target='_blank'>
            Privacy Policy
          </Link>
          {' '}
          and
          {' '}
          <Link
            to={book.termsAndConditions.forSite}
            target='_blank'>
            {formatMessage(msg.sign_up_form_render_checkbox_i_read_and_agree_link)}
          </Link>
        </Typography>
      </Box>
    </Grid>
  );
};

export default CheckBoxWithText;
