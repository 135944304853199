import {call, put} from 'redux-saga/effects';
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors} from "../../../../utils/handleErrors";
import {vouchersActions} from "../../actions";
import {Workers} from "../../../../setup/typedefs";

export function* fetchVoucher({payload: voucherID}: Workers) {
  try {
    const response = yield call(fetchWithToken, {
      endpoint: `vouchers/${voucherID}/?relations=cover,tags`,
      method: 'GET'
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    const {data} = yield call([response, 'json']);

    yield put(vouchersActions.fillVoucher(data));

  } catch (e) {
    console.log(e);
  }
}
