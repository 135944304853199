import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiBox-root': {
      marginLeft: 14
    }
  },
  uploaded: {
    '& .MuiTypography-root': {
      color: theme.palette.common.green
    }
  },
  attention: {
    '& .MuiTypography-root': {
      color: theme.palette.common.red
    }
  },
  text: {
    [breakpoints.down(475)]: {
      display: 'none'
    }
  }

});