//Core
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

//Helpers
import {getPlace} from "../../../../../bus/business/selectors";
import {getVoucherPreview} from "../../../../../bus/vouchers/selectors";
import {Voucher} from "../../../../../bus/vouchers/typedefs";

export const useRenderPreview = () => {
  const voucherPreview = useSelector(getVoucherPreview);
  const place = useSelector(getPlace);

  const [data, setData] = useState<Voucher>();
  const [placeData, setPlaceData] = useState<any>();

  const randomImg = Math.floor(Math.random() * Math.floor(3));

  useEffect(() => {
    voucherPreview && setData(voucherPreview);
  }, [voucherPreview]);

  useEffect(() => {
    place && setPlaceData(place);
  }, [place]);

  return {
    data,
    placeData,
    randomImg
  };
};
