import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../customTheme";
import logo from "../../../../images/logo_white.png";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  rootWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 0%',
    background: theme.palette.common.gradient,
    [breakpoints.down(1500)]: {
      padding: '0 30px'
    },
    [breakpoints.down(1200)]: {
      padding: '60px 15px 58px',
      alignItems: 'baseline',
      flex: 'unset'
    },
    [breakpoints.down(600)]: {
      padding: '40px 15px 58px'
    }
  },
  logo: {
    width: '184px',
    height: '60px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundImage:`url(${logo})`,
    position: 'relative',
  },
  innerWrapper: {
    maxWidth: 619,
    marginLeft: 70,
    marginTop: -104,
    color: theme.palette.common.white,
    [breakpoints.down(1500)]: {
      marginLeft: 'unset',
      marginTop: 'unset'
    },
    [breakpoints.down(1200)]: {
      marginLeft: 10,
      paddingRight: 100,
      boxSizing: 'border-box'
    },
    [breakpoints.down(600)]: {
      margin: 0,
      padding: 0,
      maxWidth: 490,
      width: '100%'
    }
  },
  listWrapper: {
    marginTop: 30,
    [breakpoints.down(1200)]: {
      marginTop: 38
    }
  },
  listItem: {
    marginTop: 14,
    [breakpoints.down(1200)]: {
      marginTop: 20
    }
  },
  listItemText: {
    marginLeft: 25,
    [breakpoints.down(600)]: {
      marginLeft: 17,
      width: 'unset'
    }
  },
  buttonsWrapper: {
    display: 'flex',
    marginTop: 48,
    paddingLeft: 5,
    justifyContent: 'space-between',
    maxWidth: 325,
    [breakpoints.down(1200)]: {
      marginTop: 40,
      paddingLeft: 'unset'
    },
    [breakpoints.down(600)]: {
      flexDirection: 'column-reverse',
      maxWidth: '100%',
      height: 119
    }
  }
});
