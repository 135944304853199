import React from 'react';
import {Button, Typography, useTheme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {styles} from "./styles";
import {useDropzone} from "react-dropzone";
import {WrappedFieldProps} from "redux-form";
import {GroupiTheme} from "../../../../customTheme";
import IconHolder from "../../../../components/IconHolder";
import CrossInCircle from "../../../../components/Icons/CrossInCircle";
import Upload from "../../../../components/Icons/Upload";
import {uiActions} from "../../../../bus/ui/actions";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(styles);

const CoverDropInput: React.FC<WrappedFieldProps & any> = ({input}) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const dispatch = useDispatch();
  const file = input.value;

  //10mb
  const maxSize = 10485760;
  const sizeForFront = Math.round(maxSize / 1000000);

  const removeFile = () => {
    input.onChange(null);
  };

  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept: 'image/jpeg, image/png, image/jpg',
    maxSize: maxSize,
    onDropRejected: (fileRejections) => {
      handleError(fileRejections);
    },
    onDrop: (acceptedFile) => {
      input.onChange({
        ...acceptedFile,
        origin: URL.createObjectURL(new Blob(acceptedFile))
      });
    }
  });

  const handleError = (errors: any) => {
    errors.forEach((item: any) => {
      item.errors.forEach((item: any) => {
        dispatch(uiActions.toast(item.message));
      });
    });
  };

  const ImageJSX = file && (
    <div className={classes.droppedFile}>
      <Button
        onClick={removeFile}
        variant="outlined"
        color="inherit"
        fullWidth
        className={classes.cross}
        startIcon={(
          <IconHolder
            size={24}
            color={theme.palette.common.white}>
            <CrossInCircle/>
          </IconHolder>
        )}
      />
      <img
        className={classes.image}
        src={file?.origin}
        alt=""
      />
    </div>
  );

  return (
    <div>
      <div className={classes.root}>
        {ImageJSX}
        {
          !file && (
            <div
              {...getRootProps()}
              className={classes.fileDropZone}>
              <input
                {...getInputProps()}
                name={input.name}
              />
              <div className={classes.innerInfo}>
                <IconHolder
                  size={41}
                  color={theme.palette.common.gray88}>
                  <Upload/>
                </IconHolder>
                <div>
                  <Typography variant={'body2'}>
                    browse to choose a file
                  </Typography>
                  <Typography variant={'caption'}>
                    .JPG .JPEG .PNG (max
                    {' '}
                    {sizeForFront}
                    {' '}
                    MB)
                  </Typography>
                </div>
              </div>
              <div className={classes.textWrapper}>
                <Typography variant={'body2'}>
                  Drag and drop an image or
                  {' '}
                  <span
                    {...getRootProps()}
                    className={classes.blueText}>
                    browse
                  </span>
                  {' '}
                  to choose a file
                </Typography>
              </div>
            </div>
          )}
      </div>
    </div>
  );

};

export default CoverDropInput;