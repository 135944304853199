import React from 'react';
import InputField from "../../../../../InputField";
import {WrappedFieldProps} from "redux-form";
import {Grid, Typography} from "@material-ui/core";
import RequiredAsterisk from "../../../../../RequiredAsterisk";

const RenderInputName = ({...props}:WrappedFieldProps) => {
  return (
    <Grid
      item
      xs={12}>
      <Typography
        variant={"subtitle2"}
        style={{marginBottom: '6px'}}>
        Contact name
        {' '}
        <RequiredAsterisk/>
      </Typography>
      <InputField
        {...props}
        type={'number'}
        placeholder={'Enter name'}
      />
    </Grid>
  );
};

export default RenderInputName;