import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    paddingTop: 32,
    paddingBottom: 32,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 132,
    borderBottomColor: theme.palette.common.gray88,
    borderBottom: '1px solid',
    [breakpoints.down(1024)]: {
      paddingTop: 40,
      paddingBottom: 40,
      height: 204
    },
    [breakpoints.down(768)]: {
      height: 'unset',
      flexDirection: 'column'
    }
  },
  cell: {
    width: '100%',
    maxWidth: '13.4%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '&:first-child': {
      textAlign: 'left',
      width: '100%',
      maxWidth: '31.1%',
      justifyContent: 'start',
      alignItems: 'start'
    },
    '&:last-child': {
      width: '100%',
      maxWidth: '28.7%',
      position: 'relative',
      borderLeftColor: theme.palette.common.gray88,
      borderLeft: '1px solid'
    },
    [breakpoints.down(1600)]: {
      maxWidth: '15%',
      '&:first-child': {
        maxWidth: '45%'
      },
      '&:last-child': {
        maxWidth: '10%'
      }
    },
    [breakpoints.down(1024)]: {
      maxWidth: '10%',
      '&:first-child': {
        maxWidth: '90%'
      }
    },
    [breakpoints.down(768)]: {
      flexDirection: 'column',
      maxWidth: 'unset',
      '&:last-child': {
        maxWidth: 'unset',
        width: '100%',
        borderTopColor: theme.palette.common.gray88,
        borderTop: '1px solid',
        borderLeft: 'none',
        marginTop: 25,
        paddingTop: 16
      }
    },
    [breakpoints.down(425)]: {
      '&:last-child': {
        paddingTop: 0,
        borderTop: 'none'
      }
    }
  },
  imageHolder: {
    width: 150,
    height: 132,
    borderRadius: 8,
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    [breakpoints.down(1024)]: {
      width: 233,
      height: 204
    },
    [breakpoints.down(768)]: {
      width: '100%',
      height: 300
    },
    [breakpoints.down(425)]: {
      height: 160
    }
  },
  infoContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 20,
    width: 'calc(100% - 170px)',
    maxWidth: 319,
    '& h4': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    [breakpoints.down(1024)]: {
      maxWidth: 'calc(100% - 253px)'
    },
    [breakpoints.down(768)]: {
      maxWidth: 'unset',
      width: '100%',
      marginLeft: 0,
      marginTop: 20
    }
  },
  titleName: {
    marginBottom: 14,
    [breakpoints.down(1024)]: {
      marginBottom: 10
    },
    [breakpoints.down(768)]: {
      order: 0,
      marginBottom: 26
    }
  },
  innerRow: {
    display: 'flex',
    alignItems: 'end',
    marginBottom: 9,
    '&:last-child': {
      marginBottom: 0
    },
    '& .MuiTypography-root': {
      marginLeft: 6
    },
    [breakpoints.down(1024)]: {
      marginBottom: 7,
      '&:last-child': {
        marginBottom: 0
      }
    },
    [breakpoints.down(768)]: {
      order: 2,
      marginBottom: 11
    }
  },
  infoForMobile: {
    display: 'flex',
    [breakpoints.down(768)]: {
      order: 1,
      marginBottom: 26
    }
  },
  innerCell: {
    marginTop: 12,
    paddingLeft: 20,
    paddingRight: 20,
    borderLeftColor: theme.palette.common.gray88,
    borderLeft: '1px solid',
    width: '33%',
    '& .MuiTypography-caption': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '100%',
      overflow: 'hidden',
      display: 'block'
    },
    [breakpoints.down(768)]: {
      marginTop: 0,
      paddingLeft: 16,
      paddingRight: 16,
      overflow: 'hidden'
    }
  }
});
