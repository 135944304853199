//Core
import React, {useEffect, useState} from 'react';
import {Field} from "redux-form";

//Helpers
import {hoursToDate} from "../../../../utils/helpers";

//Styles
import {Box, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import ToolTip from "../../../../components/ToolTip";
import RequiredAsterisk from "../../../../components/RequiredAsterisk";
import InputEndTimePicker from "../../../../components/InputDateTimePickers/EndTimePicker";
import InputStartTimePicker from "../../../../components/InputDateTimePickers/StartTimePicker";

const useStyles = makeStyles(styles);

interface TimeFieldRowTypes {
  labelText: string,
  isRequired?: boolean,
  onlyStar?: boolean,
  toggleRow?: boolean,
  placeTime?: any,
  initialValues?: any,
  disabled?: boolean,
  toolTipText?: string
}

const TimeFieldRow: React.FC<TimeFieldRowTypes> = (props) => {
  const classes = useStyles();
  const {
    labelText,
    isRequired = false,
    onlyStar = false,
    placeTime,
    toolTipText,
    initialValues
  } = props;

  const [startTime, setStartTime] = useState<Date>();
  const [endTime, setEndTime] = useState<Date>();
  const [minTime, setMinTime] = useState<Date>();
  const [maxTime, setMaxTime] = useState<Date>();

  useEffect(() => {
    setMinTime(hoursToDate(placeTime.openedTimeStart));
    setMaxTime(hoursToDate(placeTime.openedTimeEnd));

    if (startTime && endTime) {
      return;
    } else if (initialValues?.timeStart && initialValues?.timeEnd) {
      setStartTime(hoursToDate(initialValues.timeStart));
      setEndTime(hoursToDate(initialValues.timeEnd));
    } else {
      if (placeTime.openedTimeEnd && placeTime.openedTimeStart) {
        setStartTime(hoursToDate(placeTime.openedTimeStart));
        setEndTime(hoursToDate(placeTime.openedTimeEnd));
      }
    }
  }, [placeTime.openedTimeEnd, placeTime.openedTimeStart]);

  return (
    <Grid
      item
      xs={12}>
      <Box className={classes.wrapper}>
        <Box className={classes.label}>
          {!!labelText &&(
            <Typography variant={"subtitle2"}>
              {labelText}
              {isRequired || onlyStar ? <RequiredAsterisk/> : null}
            </Typography>
          )}
          {!!toolTipText &&(
            <Box className={classes.tooltip}>
              <ToolTip>
                {toolTipText}
              </ToolTip>
            </Box>
          )}
        </Box>
        <Box className={classes.fields}>
          <Field
            minTime={minTime}
            maxTime={maxTime}
            startTime={startTime}
            endTime={endTime}
            setStartTime={setStartTime}
            name={'timeStart'}
            component={InputStartTimePicker}
          />
          <div className={classes.divide}/>
          <Field
            minTime={minTime}
            maxTime={maxTime}
            startTime={startTime}
            endTime={endTime}
            setEndTime={setEndTime}
            name={'timeEnd'}
            component={InputEndTimePicker}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default TimeFieldRow;
