import React from 'react';
import {GroupiTheme} from "../../customTheme";
import {useTheme} from "@material-ui/core";

const RequiredAsterisk = () => {
  const theme: GroupiTheme = useTheme();

  return (
    <sup style={{color: theme.palette.common.red, verticalAlign: 'baseline'}}>
      &#160;*
    </sup>
  );
};

export default RequiredAsterisk;