import React from 'react';
import {Typography} from "@material-ui/core";
import {useSingleVoucherInList} from "../customHooks/useSingleVoucherInList";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "../styles";

const useStyles = makeStyles(styles);

type CountersProps = {
  item: {
    viewsCount: number,
    reservationsCount: number,
    usesCount: number
  }
}

const Counters: React.FC<CountersProps> = ({item}) => {
  const classes = useStyles();
  const {toggleTabletView} = useSingleVoucherInList();
  const {
    viewsCount,
    reservationsCount,
    usesCount
  } = item;

  if (!item) {
    return <></>;
  }

  return (
    <>
      {
        toggleTabletView
          ? (
            <div className={classes.infoForMobile}>
              <div className={classes.innerCell}>
                <Typography variant={'caption'}>
                  Views
                </Typography>
                <Typography variant={'h3'}>
                  {viewsCount}
                </Typography>
              </div>
              <div className={classes.innerCell}>
                <Typography variant={'caption'}>
                  Bought
                </Typography>
                <Typography variant={'h3'}>
                  {reservationsCount}
                </Typography>
              </div>
              <div className={classes.innerCell}>
                <Typography variant={'caption'}>
                  Used
                </Typography>
                <Typography variant={'h3'}>
                  {usesCount}
                </Typography>
              </div>
            </div>
          )
          : (
            <>
              <div className={classes.cell}>
                <Typography variant={'h3'}>
                  {viewsCount}
                </Typography>
              </div>
              <div className={classes.cell}>
                <Typography variant={'h3'}>
                  {reservationsCount}
                </Typography>
              </div>
              <div className={classes.cell}>
                <Typography variant={'h3'}>
                  {usesCount}
                </Typography>
              </div>
            </>
          )
      }
    </>
  );
};

export default Counters;