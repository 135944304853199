import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const Edit = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8284 6.41425C23.2663 4.85215 20.7337 4.85215 19.1716 6.41425L6.87868 18.7071C6.31607 19.2698 6 20.0328 6 20.8285V25V26H7H11.1716C11.9672 26 12.7303 25.684 13.2929 25.1214L25.5858 12.8285C27.1479 11.2664 27.1479 8.73371 25.5858 7.17161L24.8284 6.41425ZM19.6071 8.80712L8.29289 20.1214C8.10536 20.3089 8 20.5632 8 20.8285V24H11.1716C11.4368 24 11.6911 23.8947 11.8787 23.7071L23.1929 12.3929L19.6071 8.80712Z"
      />
    </SvgIcon>
  );
};

export default Edit;