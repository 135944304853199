import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  root: {
    position: 'absolute',
    top: 10,
    left: 'calc(100% + 20px)',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down(992)]: {
      position: 'unset',
      flexDirection: 'row',
      marginBottom: 40
    },
    [breakpoints.down(575)]: {
      width: '100%',
      margin: '0 -15px 40px',
      padding: '0 15px',
      overflowX: 'scroll'
    }
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20,
    '& .MuiTypography-body2': {
      marginTop: 8
    },
    [breakpoints.down(1200)]: {
      top: 0,
      left: 'unset',
      right: 0,
      transform: 'translateY(calc(-100% - 10px))'
    },
    [breakpoints.down(992)]: {
      transform: 'unset',
      marginBottom: 0,
      marginRight: 30
    },
    [breakpoints.down(575)]: {
      '&:last-child': {
        paddingRight: 15
      }
    }
  }
});