import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import ImagesDragNDropInput from "../../../../../../components/ImagesDragNDropInput";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  my_place_form_render_input_photos_label: {
    id: "my_place_form_render_input_photos_label",
    defaultMessage: "Photos"
  },
  my_place_form_render_input_photos_tool_tip: {
    id: "my_place_form_render_input_photos_tool_tip",
    defaultMessage: "Upload few photos of your Place. These photos will be displayed in Group@t App for Users. Format of photos is JPEG,JPG,PNG. Max number limited to 9 pictures. Max size 10 Mb per file. "
  }
});

type RenderInputPhotosProps = {
  disabled?: boolean
}

const RenderInputPhotos: React.FC<WrappedFieldProps & RenderInputPhotosProps> = ({input, disabled}) => {
  const {formatMessage} = useIntl();

  return (
    <FieldRow
      labelText={formatMessage(msg.my_place_form_render_input_photos_label)}
      toolTipText={formatMessage(msg.my_place_form_render_input_photos_tool_tip)}>
      <ImagesDragNDropInput
        disabledForEdit={disabled}
        input={input}
      />
    </FieldRow>
  );
};

export default RenderInputPhotos;
