import {Place} from "../bus/business/typedefs";
import {Offer} from "../bus/offers/typedefs";
import {Voucher} from "../bus/vouchers/typedefs";
import {IFile} from "../setup/typedefs";

export const concatTime = (timeStart: string, timeEnd: string) => {
  return timeStart + ' - ' + timeEnd;
};

export const concatDate = (dateStart: number, dateEnd: number, withOutCoefficient= false) => {
  return dateEnd
    ? formatDate(dateStart, withOutCoefficient) + ' - ' + formatDate(dateEnd, withOutCoefficient)
    : formatDate(dateStart, withOutCoefficient);
};

export const formatDate = (date: any, withOutCoefficient = false) => {
  const currentDate = withOutCoefficient ? new Date(date) : new Date(date * 1000);
  const month = String(currentDate.getMonth() + 1).length < 2
    ? '0' + String(currentDate.getMonth() + 1)
    : String(currentDate.getMonth() + 1);
  const day = String(currentDate.getDate()).length < 2
    ? '0' + String(currentDate.getDate())
    : String(currentDate.getDate());
  const year = String(currentDate.getFullYear());

  return `${day}.${month}.${year}`;
};

export const formatHours = (date: any) => {
  const currentDate = new Date(date);
  const hours = String(currentDate.getHours()).length < 2
    ? '0' + String(currentDate.getHours())
    : String(currentDate.getHours());
  const minutes = String(currentDate.getMinutes()).length < 2
    ? '0' + String(currentDate.getMinutes())
    : String(currentDate.getMinutes());

  return `${hours}:${minutes}`;
};

export const hoursToDate = (date: string) => {
  if (date) {
    const arrayOfHoursAndMinutes = date.split(':');
    const time = new Date();

    arrayOfHoursAndMinutes.forEach((item, index) => {
      if (index === 0) {
        time.setHours(Number(item));
      } else if (index === 1) {
        time.setMinutes(Number(item));
      }
    });

    time.setSeconds(0);

    return time;
  } else {
    return new Date();
  }
};

export const getUnixTimeStamp = (date: any) => {
  return Math.round((date / 1000));
};

export const showArrayDiff = (firstArray: string[], secondArray: string[]) => {
  return firstArray.filter((i: any)=>!secondArray.includes(i))
    .concat(secondArray.filter((i: any)=>!firstArray.includes(i)));
};

export const actionsWithCover = (initialValue: any, currentValue: any, recreate = false) => {
  const init = !!initialValue?.cover;
  const curr = !!currentValue?.cover;

  if (!init && curr) {
    return 'save';
  } else if (!init && !curr) {
    return 'nothing';
  } else if (init && !curr) {
    return 'remove';
  } else if (init && curr) {
    if (!currentValue?.cover?.id) {
      return 'save';
    } else {
      return recreate ? 'reSave' : 'nothing';
    }
  } else {
    return 'nothing';
  }
};

export const actionsWithFewFiles = (initialValue: any, currentValue: any) => {
  const init = !!initialValue?.length;
  const curr = !!currentValue?.length;

  if (!init && curr) {
    return 'save';
  } else if (!init && !curr) {
    return 'nothing';
  } else if (init && !curr) {
    return 'remove';
  } else if (init && curr) {
    if (initialValue.length === currentValue.length) {
      const countFileFromApi = currentValue.filter((item: any) => {
        return item.id;
      }).length;
      if (initialValue.length !== countFileFromApi) {
        return 'save';
      }

      return 'nothing';
    } else {
      return 'save';
    }
  } else {
    return 'nothing';
  }
};

export const offerFieldsIsEqual = (obj1: Offer, obj2: Offer) => {
  return JSON.stringify(formatOfferObject(obj1)) === JSON.stringify(formatOfferObject(obj2));
};

export const voucherFieldsIsEqual = (obj1: Voucher, obj2: Voucher) => {
  return JSON.stringify(formatVoucherObject(obj1)) === JSON.stringify(formatVoucherObject(obj2));
};

export const formatVoucherObject = (obj: Partial<Voucher>) => {
  const nullDate = 'Thu Jan 01 1970 03:00:00 GMT+0300 (Восточная Европа, стандартное время)';

  return {
    name: obj.name,
    dateStart: Number(obj.dateStart),
    //this is necessary, because the date picker at 'NULL' returns 'nullDate'
    dateEnd: String(obj.dateEnd) === nullDate ? null : obj.dateEnd === null ? null : Number(obj.dateEnd),
    usePeriod: obj.usePeriod,
    timeStart: obj.timeStart,
    timeEnd: obj.timeEnd,
    availability: obj.availability,
    description: obj.description,
    originalPrice: obj.originalPrice,
    discountPrice: obj.discountPrice,
    tags: obj.tags
  };
};

export const formatOfferObject = (obj: Partial<Offer>) => {
  const nullDate = 'Thu Jan 01 1970 03:00:00 GMT+0300 (Восточная Европа, стандартное время)';

  return {
    name: obj.name,
    dateStart: Number(obj.dateStart),
    //this is necessary, because the date picker at 'NULL' returns 'nullDate'
    dateEnd: String(obj.dateEnd) === nullDate ? null : obj.dateEnd === null ? null : Number(obj.dateEnd),
    availability: obj.availability,
    timeStart: obj.timeStart,
    timeEnd: obj.timeEnd,
    description: obj.description,
    tags: obj.tags
  };
};

const preparingDocuments = (documents: IFile[]) => {
  return documents.map((item, index) => {
    return {
      ...item,
      isConfirm: true,
      index
    };
  });
};

const preparingPhotos = (photos: IFile[]) => {
  return photos.map((item, index) => {
    return {
      ...item,
      preview: item.origin || item.preview,
      index
    };
  });
};

export const formatPlaceObjectFromAPI = (obj: Partial<Place>) => {
  return {
    name: obj?.name,
    googlePlaceUnique: obj?.googlePlaceUnique,
    zip: obj?.zip,
    countryId: obj?.countryId,
    city: obj?.city,
    address: obj?.address,
    businessTypeId: obj?.businessTypeId,
    phone: obj?.phone,
    website: obj?.website,
    openedTimeEnd: obj?.openedTimeEnd,
    openedTimeStart: obj?.openedTimeStart,
    description: obj?.description,
    tags: obj?.tags && obj.tags.map((item: any) => item.name),
    id: obj?.id,
    maxGuests: obj?.maxGuests,
    promotePlace: obj?.promotePlace,
    recommendedGuests: obj?.recommendedGuests,
    status: obj?.status,
    photos: obj.photos ? preparingPhotos(obj.photos) : null,
    documents: obj.documents ? preparingDocuments(obj.documents): null
  };
};

export const formatPlaceObjectToAPI = (obj: Partial<Place>) => {
  return {
    name: obj.name,
    city: obj.city,
    address: obj.address,
    website: obj.website,
    description: obj.description,
    zip: Number(obj.zip),
    phone: obj.phone,
    countryId: obj.countryId,
    businessTypeId: obj.businessTypeId,
    openedTimeStart: obj.openedTimeStart,
    openedTimeEnd: obj.openedTimeEnd,
    googlePlaceUnique: obj.googlePlaceUnique,
    isPublished: obj.isPublished,
    promotePlace: obj.promotePlace,
    recommendedGuests: Number(obj.recommendedGuests),
    maxGuests: Number(obj.maxGuests)
  };
};

export const formatOfferObjectToAPI = (obj: Offer) => {
  const timeZoneOffset = new Date().getTimezoneOffset();

  return {
    name: obj.name,
    dateStart: obj.dateStart && + (obj.dateStart / 1000) + (-timeZoneOffset * 60),
    dateEnd: obj.dateEnd && + (obj.dateEnd / 1000) + (-timeZoneOffset * 60),
    timeStart: obj.timeStart,
    timeEnd: obj.timeEnd,
    availability: obj.availability,
    description: obj.description,
    isPublished: obj.isPublished
  };
};

export const getChangedFields = (obj1: Partial<Place>, obj2: Partial<Place>) => {
  // @ts-ignore
  return Object.keys(obj1).filter(key => obj1[key] !== obj2[key] );
};

export const getFieldsInArray = (obj: object) => {
  return Object.keys(obj);
};

export const getDates = (innerDate = new Date()) => {
  const today = new Date(innerDate.setHours(0,0,0,0));
  const nextDay = new Date(today);
  const ninetyDaysAfterToday = new Date(+ innerDate + 7776000000);
  nextDay.setDate(today.getDate() + 1);

  return {
    today,
    nextDay,
    ninetyDaysAfterToday
  };
};