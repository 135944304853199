import React from 'react';
import DatePicker from "react-datepicker";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {WrappedFieldProps} from "redux-form";
import "react-datepicker/dist/react-datepicker.css";
import {styles} from "./styles";
import {formatHours, hoursToDate} from "../../utils/helpers";

const useStyles = makeStyles(styles);

interface StartDateInterface {
  disabled?: boolean,
  minTime?: Date,
  maxTime?: Date,
}

const InputStartTimePicker: React.FC<WrappedFieldProps & StartDateInterface> = (props) => {
  const classes = useStyles();
  const {
    input,
    minTime,
    maxTime,
    disabled
  } = props;

  return (
    <Box className={classes.root}>
      <DatePicker
        {...input}
        disabled={disabled}
        selectsStart
        onKeyDown={(event) => event.preventDefault()}
        onChange={(date: Date) => input.onChange(formatHours(date))}
        selected={hoursToDate(input.value)}
        startDate={hoursToDate(input.value)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={60}
        timeCaption="Time"
        timeFormat="HH:mm"
        minTime={minTime}
        maxTime={maxTime}
      />
    </Box>
  );
};

export default InputStartTimePicker;

