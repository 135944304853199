import {types} from "./types";
import {AnyAction} from 'redux';
import {IPopupData} from "./typedefs";
import {Nullable} from "../../setup/typedefs";

export type UiState = {
  isLoading: boolean,
  popupData: Nullable<IPopupData>,
  isPopupOpen: boolean,
  isToastOpen: boolean
  toastData: Nullable<string>,
  confirm: boolean
}

const initialState: UiState = {
  isLoading: false,
  popupData: null,
  isPopupOpen: false,
  isToastOpen: false,
  toastData: null,
  confirm: false
};

export const UiState = (state= initialState, action: AnyAction) => {
  switch (action.type) {
  case types.LOADING:
    return {
      ...state,
      isLoading: action.payload
    };

  case types.CONFIRM:
    return {
      ...state,
      confirm: action.payload
    };

  case types.FILL_POPUP_DATA:
    return {
      ...state,
      popupData: action.payload
    };

  case types.CLEAR_POPUP_DATA:
    return {
      ...state,
      popupData: null
    };

  case types.POPUP_OPEN:
    return {
      ...state,
      isPopupOpen: true
    };

  case types.POPUP_CLOSE:
    return {
      ...state,
      isPopupOpen: false
    };

  case types.FILL_TOAST_DATA:
    return {
      ...state,
      toastData: action.payload
    };

  case types.CLEAR_TOAST_DATA:
    return {
      ...state,
      toastData: null
    };

  case types.TOAST_OPEN:
    return {
      ...state,
      isToastOpen: true
    };

  case types.TOAST_CLOSE:
    return {
      ...state,
      isToastOpen: false
    };

  case types.CLEAR:
    return initialState;

  default:
    return state;
  }
};