import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    position: 'relative',
    [breakpoints.down(784)]: {}
  },
  offerName: {
    width: 'calc(100% - 75px)',
    marginLeft: 75,
    wordBreak: 'break-all',
    [breakpoints.down(575)]: {
      width: 'calc(100% - 40px)',
      marginLeft: 40
    }
  },
  topBlock: {
    display: 'flex',
    marginTop: 22,
    marginBottom: 60,
    [breakpoints.down(992)]: {
      marginBottom: 30
    },
    [breakpoints.down(575)]: {
      marginTop: 20,
      flexDirection: 'column'
    }
  },
  wrapperImgNButton: {
    display: 'flex',
    flexDirection: 'column',
    width: 214,
    marginRight: 20,
    [breakpoints.down(992)]: {
      width: 237
    },
    [breakpoints.down(575)]: {
      width: '100%'
    }
  },
  imgHolder: {
    width: '100%',
    height: 186,
    marginBottom: 20,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: 8
    },
    [breakpoints.down(992)]: {
      height: 206,
      marginBottom: 0
    },
    [breakpoints.down(575)]: {
      height: 206,
      marginRight: 0,
      marginBottom: 20
    },
    [breakpoints.down(425)]: {
      height: 160
    }
  },
  mainInfoBlock: {
    width: 'calc(100% - 234px)',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down(575)]: {
      width: '100%'
    }
  },
  previewButton: {
    maxWidth: 'unset',
    margin: 'auto 0 0 0',
    [breakpoints.down(992)]: {
      maxWidth: 214
    },
    [breakpoints.down(575)]: {
      marginBottom: 20,
      maxWidth: 'unset'
    }
  },
  innerRow: {
    display: 'flex',
    marginBottom: 15,
    '& .MuiTypography-root': {
      marginLeft: 6
    },
    [breakpoints.down(575)]: {
      marginBottom: 10
    }
  },
  subMainInfoBlock: {
    marginBottom: 60,
    [breakpoints.down(575)]: {
      marginBottom: 40,
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  statisticsWrapper: {
    display: 'flex',
    width: 'calc(100% + 20px)',
    margin: '15px -10px 0',
    [breakpoints.down(575)]: {
      flexDirection: 'column',
      width: '100%',
      margin: '15px 0 0'
    }
  },
  statisticsItem: {
    margin: '0 10px',
    width: 'calc(33% - 20px)',
    height: 138,
    borderColor: theme.palette.common.gray88,
    border: '1px solid',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.down(575)]: {
      margin: '0 0 20px',
      width: '100%',
      height: 106,
      '&:last-child': {
        margin: 0
      }
    }
  }
});
