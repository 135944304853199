import {PreloadedState, createStore, applyMiddleware} from "redux";
import {createRootReducer} from './createRootReducer';
import {createBrowserHistory} from 'history';
import {composeWithDevTools} from 'redux-devtools-extension';
import {routerMiddleware} from "connected-react-router";
import createSagaMiddleware from 'redux-saga';
import {rootSaga} from "./rootSaga";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
};

export default function configureScore(preloadedState: PreloadedState<any>) {
  const reducer = createRootReducer(history);
  const pReducer = persistReducer(persistConfig, reducer);
  const store = createStore(
    pReducer,
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware
      )
    )
  );

  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);

  return {store, persistor};
}
