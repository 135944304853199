//Core
import React from 'react';

//Helpers
import {ListStatus} from "../../../../../setup/typedefs";
import {book} from "../../../../../routes/book";

//Components
import ListOfVouchers from "../ListOfVouchers";

const ExpiredVouchers = () => {
  return (
    <ListOfVouchers
      status={ListStatus.Expired}
      paginationPath={book.expiredVouchers}
      title={'Expired'}
    />
  );
};

export default ExpiredVouchers;
