import {useDispatch, useSelector} from "react-redux";
import {profileEmail, profileIsVerified} from "../../../../../bus/profile/selectors";
import {authActions} from "../../../../../bus/auth/actions";
import {book} from "../../../../../routes/book";

export const useResendEmail = () => {
  const dispatch = useDispatch();
  const email = useSelector(profileEmail);
  const isVerified = useSelector(profileIsVerified);

  const resend = () => {
    email && dispatch(authActions.resendEmail({
      email: email,
      redirectUrl: window.location.origin + book.verifyEmail
    }));
  };

  return {
    resend,
    isVerified
  };
};
