//Core
import React from 'react';

//Helpers
import {useHeader} from "./customHooks/useHeader";
import {config} from "../../setup/envVariable";

//Styles
import {Typography, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {GroupiTheme} from "../../customTheme";
import {styles} from "./styles";

//Components
// import Logo from "../Icons/Logo";
import DropDownWithSearch from "../DropDownWithSearch";
import LogOutBlock from "../LogOutBlock";
import PersonBlock from "../PersonBlock";
import BurgerMenu from "../BurgerMenu";

const useStyles = makeStyles(styles);

const Header = () => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const {choicePlace, isAuthenticated, isSaleManager, currentPlace, places} = useHeader();

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        {/*<Logo*/}
        {/*  color={'inherit'}*/}
        {/*  style={{ width: 129, height: 48 }}*/}
        {/*/>*/}
        <div className={classes.version}>
          <Typography
            variant={'caption'}
            style={{color: theme.palette.common.blue50}}>
            Version:
            {' '}
            {config.VERSION}
          </Typography>
        </div>
      </div>
      {isAuthenticated &&(
        <>
          <div className={classes.rightSide}>
            <PersonBlock/>
            {isSaleManager && !!currentPlace &&(
              <DropDownWithSearch
                places={places}
                choicePlace={choicePlace}
                currentPlace={currentPlace}
              />
            )}
            <LogOutBlock/>
          </div>
          <BurgerMenu
            isSaleManager={isSaleManager && !!currentPlace}
            places={places}
            choicePlace={choicePlace}
            currentPlace={currentPlace}
          />
        </>
      )}
    </div>
  );
};

export default Header;
