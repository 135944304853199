import React from 'react';
import schema from "./schema";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import syncValidate from "../../../../../utils/syncValidate";
import {Button, Grid} from "@material-ui/core";
import RenderInputConfirmNewPassword from "./formFields/RenderInputConfirmNewPassword";
import RenderInputOldPassword from "./formFields/RenderInputOldPassword";
import RenderInputNewPassword from "./formFields/RenderInputNewPassword";
import {defineMessages, useIntl} from "react-intl";
import {usePasswordForm} from "./customHooks/usePasswordForm";
import {Prompt} from "react-router";

const msg = defineMessages({
  account_password_page_submit_button: {
    id: "account_password_page_submit_button",
    defaultMessage: "Save changes"
  }
});

const useStyles = makeStyles(styles);

const PasswordForm = (props: InjectedFormProps) => {
  const {formatMessage} = useIntl();
  const classes = useStyles();
  const {isChanged, savePass} = usePasswordForm(props);
  const {valid, handleSubmit} = props;

  return (
    <form onSubmit={handleSubmit}>
      <Prompt
        when={isChanged}
        message='All your changes will not be saved. Continue?'
      />
      <Grid
        container
        spacing={3}>
        <Field
          name={'oldPassword'}
          component={RenderInputOldPassword}
        />
        <Field
          name={'newPassword'}
          component={RenderInputNewPassword}
        />
        <Field
          name={'confirmNewPassword'}
          component={RenderInputConfirmNewPassword}
        />
      </Grid>
      <div className={classes.buttonWrapper}>
        <Button
          onClick={handleSubmit((values) => savePass(values))}
          className={classes.submitButton}
          disabled={!valid}
          type={'submit'}
          variant="contained"
          color="primary">
          {formatMessage(msg.account_password_page_submit_button)}
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'PasswordForm',
  validate: syncValidate(schema)
})(PasswordForm);
