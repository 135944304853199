import {createStyles} from "@material-ui/core";
import phone from "../../images/phone.png";

export const styles = () => createStyles({
  mainWrapper: {
    position: 'relative',
    width: 432,
    height: 866,
    overflow: 'hidden',
    borderRadius: 65
  },
  phoneImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundImage:`url(${phone})`,
    width: '100%',
    height: '100%',
    zIndex: 5,
    pointerEvents: 'none'
  },
  content: {
    width: 'calc(100% - 52px)',
    margin: '98px auto 0',
    height: 'calc(100% - 280px)',
    paddingTop: 20,
    overflow: 'auto',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0
    }
  }
});