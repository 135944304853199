import React, {useEffect, useRef, useState} from 'react';
import {Typography, useTheme} from "@material-ui/core";
import ArrowDown from "../Icons/ArrowDown";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import Plus from "../Icons/Plus";
import {GroupiTheme} from "../../customTheme";
import ButtonLinkBlue from "../ButtonLinkBlue";
import {book} from "../../routes/book";
import PlacesListWithSearch from "../PlacesListWithSearch";
import IconHolder from "../IconHolder";
import {defineMessages, useIntl} from "react-intl";
import {Place} from "../../bus/business/typedefs";

const msg = defineMessages({
  drop_down_places_list_with_search_add_new_place_button_title: {
    id: "drop_down_places_list_with_search_add_new_place_button_title",
    defaultMessage: "ADD NEW PLACE"
  }
});

const useStyles = makeStyles(styles);

type DropDownWithSearchProps = {
  currentPlace: Place | undefined,
  places: Place[] | null,
  choicePlace: any
}

const DropDownWithSearch: React.FC<DropDownWithSearchProps> = ({currentPlace, places, choicePlace}) => {
  const {formatMessage} = useIntl();
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const [showDropDown, setShowDropDown] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropDown(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  return (
    <div
      className={classes.root}
      ref={wrapperRef}>
      <div
        className={classes.currentItemWrapper}
        onClick={toggleDropDown}>
        <Typography
          variant={'body1'}
          className={classes.currentItem}>
          {
            currentPlace &&(
              currentPlace.name ? currentPlace.name : 'no name'
            )
          }
        </Typography>
        <IconHolder
          size={16}
          color={theme.palette.common.blue50}>
          <ArrowDown/>
        </IconHolder>
      </div>
      {
        showDropDown &&(
          <div className={classes.itemsList}>
            <PlacesListWithSearch
              choicePlace={choicePlace}
              currentPlaceID={currentPlace!.id}
              places={places}
            />
            <div className={classes.addNewItem}>
              <ButtonLinkBlue
                fullWidth
                link={book.createMyPlace}
                icon={(
                  <Plus
                    color={"inherit"}
                    style={{color: theme.palette.common.white}}
                  />
                )}>
                {formatMessage(msg.drop_down_places_list_with_search_add_new_place_button_title)}
              </ButtonLinkBlue>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default DropDownWithSearch;
