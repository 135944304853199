import React from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import ToolTip from "../ToolTip";
import RequiredAsterisk from "../RequiredAsterisk";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const FieldRow = ({children, labelText, isRequired = false, onlyStar = false, toolTipText}: FieldRowTypes) => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}>
      <Box className={classes.wrapper}>
        <Box className={classes.label}>
          {labelText &&(
            <Typography variant={"subtitle2"}>
              {labelText}
              {(isRequired || onlyStar) ? <RequiredAsterisk/> : null}
            </Typography>
          )}
          {!!toolTipText &&(
            <Box className={classes.tooltip}>
              <ToolTip>
                {toolTipText}
              </ToolTip>
            </Box>
          )}
        </Box>
        <Box className={classes.field}>
          {children}
        </Box>
      </Box>
    </Grid>
  );
};

export default FieldRow;

interface FieldRowTypes {
  children: React.ReactNode,
  labelText?: string,
  isRequired?: boolean,
  toolTipText?: string,
  onlyStar?: boolean
}