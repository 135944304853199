import {all, call, put} from "redux-saga/effects";
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {placesActions} from "../../actions";
import {Workers} from "../../../../setup/typedefs";
import {updatePlacePhotos} from "./updatePlacePhotos";
import {updatePlaceDocuments} from "./updatePlaceDocuments";
import {updatePlaceTags} from "./updatePlaceTags";
import {uiActions} from "../../../ui/actions";
import {formatPlaceObjectToAPI} from "../../../../utils/helpers";
import {push} from "connected-react-router";
import {book} from "../../../../routes/book";

export function* updatePlace({payload}: Workers){
  try {
    yield put(uiActions.loading(true));
    const {formData, info: {merchantID, placeID, updateTags, actionWithPhotos, actionWithDocuments}} = payload;
    const endpoint = !placeID ? `merchants/${merchantID}/places` : `places/${placeID}`;
    const actionType = !placeID ? 'create' : 'update';

    const body = formatPlaceObjectToAPI(formData);
    yield put(placesActions.fillPlaceFromGoogle(null));

    const response = yield call(fetchWithToken, {
      endpoint,
      method: 'PUT',
      body
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    const {data} = yield call([response, 'json']);

    yield all([
      call(updatePlaceTags, {formData, placeID: data.id, updateTags: actionType === 'create' ? !!formData.tags.length : updateTags}),
      call(updatePlacePhotos, {formData, placeID: data.id, actionWithPhotos}),
      call(updatePlaceDocuments, {formData, placeID: data.id, actionWithDocuments})
    ]);

    yield call(handleMsg, `Place '${data.name}' successful ` + actionType);
    yield put(placesActions.fetchPlaces(data.merchantId));
    yield put(placesActions.fetchPlace({id: data.id}));
    yield put(push(book.myPlace));

  } catch (e) {
    console.log(e);
  } finally {
    yield put(uiActions.loading(false));
  }
}
