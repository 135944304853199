import {AnyAction} from "redux";
import {types} from "./types";
import {BusinessType, Countries, Tag} from "./typedefs";
import {Nullable} from "../../setup/typedefs";

export type OptionsState = {
  businessTypes: Nullable<BusinessType[]>,
  countries: Nullable<Countries[]>,
  allTags: Nullable<Tag[]>,
  usePeriod: any,
}

const initialState: OptionsState = {
  businessTypes: null,
  countries: null,
  allTags: null,
  usePeriod: null
};

export const optionsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
  case types.FILL_USE_PERIOD:
    return {
      ...state,
      usePeriod: action.payload
    };
  case types.FILL_BUSINESS_OPTIONS:
    return {
      ...state,
      businessTypes: action.payload
    };

  case types.FILL_COUNTRIES_OPTIONS:
    return {
      ...state,
      countries: action.payload
    };

  case types.FILL_ALL_TAGS:
    return {
      ...state,
      allTags: action.payload
    };

  case types.CLEAR:
    return initialState;

  default:
    return state;
  }
};
