import React, {useEffect} from 'react';
import {IconButton, Typography, useTheme} from "@material-ui/core";
import IconHolder from "../../../../../../components/IconHolder";
import {GroupiTheme} from "../../../../../../customTheme";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import Stop from "../../../../../../components/Icons/Stop";
import {offersActions} from "../../../../../../bus/offers/actions";
import {useDispatch, useSelector} from "react-redux";
import {uiActions} from "../../../../../../bus/ui/actions";
import {uiSelector} from "../../../../../../bus/ui/selectors";
import {getUnixTimeStamp} from "../../../../../../utils/helpers";

const useStyles = makeStyles(styles);

type FinishButtonProps = {
  id: number,
  name: string
}

const FinishButton: React.FC<FinishButtonProps> = ({id, name}) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const {confirm} = useSelector(uiSelector);
  const dispatch = useDispatch();

  const finish = () => {
    dispatch(uiActions.popup({
      name: 'areYouSure',
      payload: {
        title: 'Are you sure you want to Finish this Deal?',
        description: 'You won’t be able to undo this action'
      }
    }));
  };

  useEffect(() => {
    if (confirm && id) {
      dispatch(offersActions.updateOffer({
        formData: {
          name: name,
          dateEnd: getUnixTimeStamp(new Date()),
          timeEnd: String(new Date().getHours() + ':00')
        },
        info: {
          actionWithCover: 'nothing',
          through: true,
          offerID: id
        }
      }));
    }
  }, [confirm]);

  return (
    <div className={classes.button}>
      <IconButton
        color="primary"
        onClick={finish}>
        <IconHolder
          size={32}
          color={theme.palette.common.red}>
          <Stop/>
        </IconHolder>
      </IconButton>
      <Typography variant={'body2'}>
        Finish
      </Typography>
    </div>
  );
};

export default FinishButton;