import * as yup from 'yup';

const schema = yup.object().shape({
  emailAddress: yup.string()
    .matches(/^[\w-\.]{3,}@([\w-]{3,}\.)+[\w-]{2,4}$/, {message: 'Email must be a valid. Do not use special characters: #, %, ^, &, *, ?, !'})
    .required('Email is required field.'),
  subject: yup.string().required('Please enter subject.'),
  describe: yup.string()
    .required('Issue is required field.')
});

export default schema;
