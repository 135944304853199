import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().required('Please enter place name.'),
  zip: yup.string().nullable(),
  countryId: yup.string().required('Please enter country.'),
  city: yup.string().required('Please enter city.'),
  address: yup.string().required('Please enter address.'),
  businessTypeId: yup.string().required('Please enter business type.'),
  phone: yup.string().required('Please enter phone number.').min(6, 'Minimum 6 numbers'),
  website: yup.string().nullable(),
  openedTimeStart: yup.string().required('Please enter opened time.'),
  openedTimeEnd: yup.string().required('Please enter closed time.'),
  description: yup.string().nullable(),
  photos: yup.array().nullable(),
  documents: yup.array().required(),
  tags: yup.array().nullable(),
  recommendedGuests: yup.string(),
  maxGuests: yup.string(),
  promotePlace: yup.boolean()
});

export default schema;
