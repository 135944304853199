import React from 'react';
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import syncValidate from "../../../../../utils/syncValidate";
import {Box, Button, Grid, Typography, useTheme} from "@material-ui/core";
import schema from "./schema";
import {Link} from "react-router-dom";
import {book} from "../../../../../routes/book";
import {GroupiTheme} from "../../../../../customTheme";
import RenderInputMail from "./formFields/RenderInputMail";
import RenderInputPassword from "./formFields/RenderInputPassword";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  sign_in_link_on_forgot_password: {
    id: "sign_in_link_on_forgot_password",
    defaultMessage: "Forgot your password?"
  },
  sign_in_button: {
    id: "sign_in_button",
    defaultMessage: "Sign in"
  }
});

const SignInForm = (props: InjectedFormProps) => {
  const {handleSubmit} = props;
  const {formatMessage} = useIntl();
  const theme: GroupiTheme = useTheme();

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={3}>
        <Field
          name={'email'}
          component={RenderInputMail}
        />
      </Box>
      <Box mb={2.4}>
        <Grid
          container
          direction="column"
          alignItems="flex-end">
          <Box
            width={'100%'}>
            <Field
              name={'password'}
              component={RenderInputPassword}
            />
          </Box>
          <Box
            mr={1.5}
            mt={1.2}>
            <Link to={book.resetPassword}>
              <Typography
                color={"inherit"}
                style={{color: theme.palette.common.blue50}}
                variant={"caption"}>
                {formatMessage(msg.sign_in_link_on_forgot_password)}
              </Typography>
            </Link>
          </Box>
        </Grid>
      </Box>
      <Button
        type='submit'
        variant="contained"
        color="primary">
        {formatMessage(msg.sign_in_button)}
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'signInForm',
  validate: syncValidate(schema)
})(SignInForm);
