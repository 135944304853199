import React from 'react';
import {Box, Grid, Link, Typography} from "@material-ui/core";
// import Logo from "../../../../components/Icons/Logo";
import SimpleDot from "../../../../components/Icons/SimpleDot";
import AppStore from "../../../../components/Icons/AppStore";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import GooglePlay from "../../../../components/Icons/GooglePlay";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  text_item_1: {
    id: "promo_information_text_item_1",
    defaultMessage: "Create an attractive group deals to get more visitors"
  },
  text_item_2: {
    id: "promo_information_text_item_2",
    defaultMessage: "Easily connect and add information in the application and on google maps"
  }
});

const useStyles = makeStyles(styles);

const PromoInformationBlock = () => {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  return (
    <Box className={classes.rootWrapper}>
      <Box className={classes.innerWrapper}>
        <div className={classes.logo}/>
        {/*<Logo*/}
        {/*  color={'inherit'}*/}
        {/*  style={{ width: 159, height: 59 }}*/}
        {/*/>*/}
        <Box mt={3.8}>
          <Typography
            color={'inherit'}
            variant="h1">
            Welcome to
            {' '}
            <br/>
            Group@t Marketplace
          </Typography>
        </Box>
        <Box className={classes.listWrapper}>
          <Box>
            <Grid
              container
              direction="row"
              wrap={'nowrap'}
              alignItems="flex-start">
              <Box mt={0.25}>
                <SimpleDot
                  fontSize={'inherit'}
                  color={'inherit'}
                  style={{ fontSize: 8 }}
                />
              </Box>
              <Box
                className={classes.listItemText}
                width={280}>
                <Typography
                  color={'inherit'}
                  variant="body1">
                  {formatMessage(msg.text_item_1)}
                </Typography>
              </Box>
            </Grid>
          </Box>
          <Box className={classes.listItem}>
            <Grid
              container
              direction="row"
              wrap={'nowrap'}
              alignItems="flex-start">
              <Box mt={0.25}>
                <SimpleDot
                  fontSize={'inherit'}
                  color={'inherit'}
                  style={{ fontSize: 8 }}
                />
              </Box>
              <Box
                className={classes.listItemText}
                width={320}>
                <Typography
                  color={'inherit'}
                  variant="body1">
                  {formatMessage(msg.text_item_2)}
                </Typography>
              </Box>
            </Grid>
          </Box>
          <Box className={classes.buttonsWrapper}>
            <Link href="#">
              <AppStore
                style={{ width: 141.5, height: 48 }}
              />
            </Link>
            <Link href="#">
              <GooglePlay
                style={{ width: 162, height: 48 }}
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PromoInformationBlock;
