import React, {useEffect, useState} from 'react';
import {getFormValues, WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import InputField from "../../../../../../components/InputField";
import {defineMessages, useIntl} from "react-intl";
import {TextField} from "@material-ui/core";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {getCountries} from "../../../../../../bus/options/selectors";
import {Place} from "../../../../../../bus/business/typedefs";

const useStyles = makeStyles(styles);

const msg = defineMessages({
  my_place_form_render_input_reservation_number_label: {
    id: "my_place_form_render_input_reservation_number_label",
    defaultMessage: "Phone number"
  },
  my_place_form_render_input_reservation_number_placeholder: {
    id: "my_place_form_render_input_reservation_number_placeholder",
    defaultMessage: "555-555-1111"
  }
});

const RenderInputPhoneNumber: React.FC<WrappedFieldProps> = (props) => {
  const {formatMessage} = useIntl();
  const classes = useStyles();
  const currentPlaceValue = useSelector<Place>(getFormValues('EditMyPlaceForm')) as Place;
  const countries = useSelector(getCountries);
  const [currentPhoneCode, setCurrentPhoneCode] = useState<any>();

  useEffect(() => {
    if (countries && currentPlaceValue.countryId) {
      setCurrentPhoneCode(countries?.filter((item) => item.id === currentPlaceValue.countryId)[0]);
    }
  }, [currentPlaceValue.countryId]);

  return (
    <FieldRow
      labelText={formatMessage(msg.my_place_form_render_input_reservation_number_label)}
      toolTipText={'This number will be used and shown in Group@t App for table reservation'}
      onlyStar>
      <div className={classes.phoneWrapper}>
        {
          currentPhoneCode?.phoneCode &&(
            <TextField
              disabled={true}
              className={classes.firstPartPhone}
              type={'text'}
              variant={'outlined'}
              value={'+' + currentPhoneCode?.phoneCode}
            />
          )
        }
        <div className={classes.secondPartPhone}>
          <InputField
            {...props}
            type={'number'}
            placeholder={formatMessage(msg.my_place_form_render_input_reservation_number_placeholder)}
          />
        </div>
      </div>
    </FieldRow>
  );
};

export default RenderInputPhoneNumber;
