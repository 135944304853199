//Core
import {useEffect, useState} from "react";

//Helpers
import useWindowDimensions from "../../../../../utils/customHooks/useWindowDimensions";

export const useMyPlacePreview = () => {
  const {width} = useWindowDimensions();
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    setMobileView(width < 475);
  }, [width]);

  return {
    mobileView
  };
};
