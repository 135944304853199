import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const BigCross = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 32 32">
      <rect
        width="25.1428"
        height="3.13906"
        transform="matrix(0.706858 -0.707356 0.708562 0.705648 6.99854 23.7852)"
        fill={props.fill}
      />
      <rect
        width="25.1428"
        height="3.13906"
        transform="matrix(-0.706858 -0.707356 -0.708562 0.705648 26.9985 23.7852)"
        fill={props.fill}
      />
    </SvgIcon>
  );
};

export default BigCross;
