import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import InputField from "../../../../../../components/InputField";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  my_place_form_render_input_website_label: {
    id: "my_place_form_render_input_website_label",
    defaultMessage: "Website"
  },
  my_place_form_render_input_website_placeholder: {
    id: "my_place_form_render_input_website_placeholder",
    defaultMessage: "www.yourbusiness.com"
  },
  my_place_form_render_input_website_tool_tip: {
    id: "my_place_form_render_input_website_tool_tip",
    defaultMessage: "Providing this information up front allows us to better understand your business, and optimize for a campaign that will suite your unique needs."
  }
});

const RenderInputWebsite = ({...props}:WrappedFieldProps) => {
  const {formatMessage} = useIntl();

  return (
    <FieldRow
      labelText={formatMessage(msg.my_place_form_render_input_website_label)}
      toolTipText={formatMessage(msg.my_place_form_render_input_website_tool_tip)}>
      <InputField
        {...props}
        type={'text'}
        placeholder={formatMessage(msg.my_place_form_render_input_website_placeholder)}
      />
    </FieldRow>
  );
};

export default RenderInputWebsite;
