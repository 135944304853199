import {useEffect, useState} from "react";
import useWindowDimensions from "../../../../../utils/customHooks/useWindowDimensions";
import {useLocation} from "react-router";

export const useSingleOfferInList = () => {
  const {width} = useWindowDimensions();

  const [toggleButtonView, setToggleButtonView] = useState(false);
  const [toggleButtonSize, setToggleButtonSize] = useState(false);
  const [toggleTabletView, setToggleTabletView] = useState(false);

  const {pathname} = useLocation<any>();
  const status = pathname.slice(8, -2);
  const randomImg = Math.floor(Math.random() * Math.floor(3));

  useEffect(() => {
    setToggleButtonView(width < 1600 && width >= 768);
    setToggleTabletView(width < 1024);
    setToggleButtonSize(width < 425);
  }, [width]);

  return {
    toggleButtonView,
    toggleButtonSize,
    toggleTabletView,
    status,
    randomImg
  };
};
