import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  rootWrapper: {
    margin: '0 auto',
    maxWidth: 744,
    width: '100%',
    marginTop: 105,
    padding: '64px 0 200px',
    [breakpoints.down(1200)]: {
      marginTop: 0
    },
    [breakpoints.down(992)]: {
      maxWidth: 728,
      width: 'calc(100% - 30px)',
      padding: '54px 0 110px'
    },
    [breakpoints.down(600)]: {
      padding: '54px 0 60px'
    },
    '& h1': {
      fontFamily: 'Montserrat',
      fontSize: 56,
      fontWeight: 'bold',
      lineHeight: '67px',
      letterSpacing: '0.6px',
      [breakpoints.down(804)]: {
        fontSize: 48,
        marginBottom: 40
      },
      [breakpoints.down(600)]: {
        fontSize: 32,
        lineHeight: '38px'
      }
    },
    '& p': {
      fontFamily: 'Source Sans Pro',
      fontSize: 20,
      fontStyle: 'normal',
      lineHeight: '30px',
      marginBottom: 48,
      '&.MuiTypography-body1': {
        marginBottom: 0
      },
      [breakpoints.down(992)]: {
        marginBottom: 40
      },
      [breakpoints.down(600)]: {
        fontSize: 16,
        lineHeight: '22px'
      }
    }
  },
  buttonWrapper: {
    display: 'flex',
    '& .MuiButton-outlined': {
      marginRight: 20
    }
  }
});
