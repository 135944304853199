import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().required('Deal name is required field'),
  dateStart: yup.string().required('Date start is required field'),
  dateEnd: yup.string().nullable(),
  timeStart: yup.string().required('Time start is required field'),
  timeEnd: yup.string().required('Time end is required field'),
  availability: yup.string().required('Availability is required field'),
  description: yup.string().nullable(),
  cover: yup.array().nullable(),
  tags: yup.array()
});

export default schema;