import React from 'react';
import {Button, createStyles} from "@material-ui/core";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => createStyles({
  unsetWidth: {
    textDecoration: 'none',
    width: 'unset'
  },
  fullWidth: {
    textDecoration: 'none',
    width: '100%'
  }
}));

const ButtonLinkTransparent = ({icon, link, children, fullWidth = true}: ButtonLinkInterface) => {
  const classes = useStyles();

  return (
    <Link
      to={link}
      className={
        clsx({
          [classes.fullWidth]: fullWidth,
          [classes.unsetWidth]: !fullWidth
        })
      }>
      <Button
        fullWidth={fullWidth}
        variant="outlined"
        color="primary"
        endIcon={icon}>
        {children}
      </Button>
    </Link>
  );
};

export default ButtonLinkTransparent;

interface ButtonLinkInterface {
  icon?: React.ReactElement,
  link: string,
  children: string,
  fullWidth?: boolean
}