import React from 'react';
import {Box, Typography} from "@material-ui/core";
import Logo from "../../../../components/Icons/Logo";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import {defineMessages, useIntl} from "react-intl";
import ResendOnEmailForm from "./form/ResendOnEmailForm";
import {ResendOnEmailFormValues} from "./form/typedefs";
import PaperAirplane from "../../../../components/Icons/PaperAirplane";
import EmailLatter from "../../../../components/Icons/EmailLatter";
import AirplanePathDesktop from "../../../../components/Icons/AirplanePathDesktop";
import AirplanePathMobile from "../../../../components/Icons/AirplanePathMobile";
import {types} from "../../../../bus/auth/types";
import {useDispatch} from "react-redux";
import {book} from "../../../../routes/book";

const msg = defineMessages({
  confirm_email_title: {
    id: "thank_you_page_title",
    defaultMessage: "Confirm your email"
  },
  confirm_email_text: {
    id: "thank_you_page_text",
    defaultMessage: "Your account was successfully registered. To continue the process please check your email for validation request."
  },
  confirm_email_hint: {
    id: "thank_you_page_hint",
    defaultMessage: "If you don't receive the letter please click the button below and we send you another one."
  },
  confirm_email_button_resend: {
    id: "thank_you_page_button_resend",
    defaultMessage: "Resend"
  },
  confirm_email_input_placeholder: {
    id: "thank_you_page_input_placeholder",
    defaultMessage: "Email"
  }
});

const useStyles = makeStyles(styles);

const ThankYouPage = () => {
  const {formatMessage} = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = (values: ResendOnEmailFormValues) => {
    const {email} = values;

    dispatch({
      type: types.RESEND_ON_EMAIL,
      payload: {
        email: email,
        redirectUrl: window.location.origin + book.verifyEmail
      }
    });
  };

  return (
    <Box className={classes.rootWrapper}>
      <Box className={classes.innerWrapper}>
        <Logo
          color={'inherit'}
          style={{ width: 159, height: 59 }}
        />
        <div className={classes.decoration}>
          <PaperAirplane
            className={classes.airplane}
            style={{ width: 100, height: 92 }}
          />
          <EmailLatter
            className={classes.letter}
            style={{ width: 156, height: 149 }}
          />
          <AirplanePathDesktop
            className={classes.pathDesktop}
            style={{ width: 457, height: 154 }}
          />
          <AirplanePathMobile
            className={classes.pathMobile}
            style={{ width: 132, height: 125 }}
          />
        </div>
        <Box mt={3.8}>
          <Typography
            color={'inherit'}
            variant="h1">
            {formatMessage(msg.confirm_email_title)}
          </Typography>
        </Box>

        <Box className={classes.textWrapper}>
          <Typography
            color={'inherit'}
            variant="body1">
            {formatMessage(msg.confirm_email_text)}
          </Typography>
        </Box>
        <Box className={classes.hintWrapper}>
          <Typography
            color={'inherit'}
            variant="body2">
            {formatMessage(msg.confirm_email_hint)}
          </Typography>
        </Box>
        <Box className={classes.inputEmailWrapper}>
          <ResendOnEmailForm onSubmit={onSubmit}/>
        </Box>
      </Box>
    </Box>
  );
};

export default ThankYouPage;
