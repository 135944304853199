import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  inputsWrapper: {
    '& .MuiFormControl-fullWidth': {
      marginBottom: 32,
      [breakpoints.down(600)]: {
        marginBottom: 20
      }
    }
  },
  changePasswordButton: {
    marginTop: 16,
    [breakpoints.down(1200)]: {
      marginTop: 8
    },
    [breakpoints.down(600)]: {
      width: '100%',
      marginTop: 0
    }
  }
});
