import React from 'react';
import schema from "./schema";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import syncValidate from "../../../../../utils/syncValidate";
import {Button, Grid} from "@material-ui/core";
import RenderInputEmailAddress from "./formFields/RenderInputEmailAddress";
import RenderInputContactName from "./formFields/RenderInputContactName";
import RenderInputContactNumber from "./formFields/RenderInputContactNumber";
import {defineMessages, useIntl} from "react-intl";
import {usePersonalForm} from "../customHooks/usePersonalForm";
import {Prompt} from "react-router";

const msg = defineMessages({
  account_personal_data_page_submit_button: {
    id: "account_personal_data_page_submit_button",
    defaultMessage: "Save changes"
  }
});

const useStyles = makeStyles(styles);

const PersonalForm = (props: InjectedFormProps) => {
  const {formatMessage} = useIntl();
  const classes = useStyles();
  const {handleSubmit, isChanged} = usePersonalForm(props);
  const {valid} = props;

  return (
    <form onSubmit={handleSubmit}>
      <Prompt
        when={isChanged}
        message='All your changes will not be saved. Continue?'
      />
      <Grid
        container
        spacing={3}>
        <Field
          name={'emailAddress'}
          component={RenderInputEmailAddress}
        />
        <Field
          name={'contactName'}
          component={RenderInputContactName}
        />
        <Field
          name={'contactNumber'}
          component={RenderInputContactNumber}
        />
      </Grid>
      <div className={classes.buttonWrapper}>
        <Button
          className={classes.submitButton}
          disabled={!valid}
          type='submit'
          variant="contained"
          color="primary">
          {formatMessage(msg.account_personal_data_page_submit_button)}
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'PersonalForm',
  validate: syncValidate(schema),
  enableReinitialize: true
})(PersonalForm);
