import React from 'react';
import NotificationTemplate from "../../../../components/NotificationTemplate";
import {INotification} from "../../../../setup/typedefs";

const RenderNotification = ({notificationList = null}: any) => {
  return (
    <>
      {
        notificationList?.map((item: INotification) => {
          return (
            <NotificationTemplate
              key={item.id}
              singleNotification={item}
            />
          );
        })
      }
    </>
  );
};

export default RenderNotification;