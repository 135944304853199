import React from 'react';
import DatePicker from "react-datepicker";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {WrappedFieldProps} from "redux-form";
import "react-datepicker/dist/react-datepicker.css";
import {styles} from "./styles";
import {formatHours, hoursToDate} from "../../utils/helpers";

const useStyles = makeStyles(styles);

interface EndDateInterface {
  startTime: Date,
  endTime: Date,
  setEndTime: (date: Date) => void,
  disabled?: boolean,
  minTime: Date,
  maxTime: Date
}

const InputEndTimePicker: React.FC<WrappedFieldProps & EndDateInterface> = (props) => {
  const classes = useStyles();
  const {
    input,
    minTime,
    maxTime,
    disabled
  } = props;

  return (
    <Box className={classes.root}>
      <DatePicker
        {...input}
        disabled={disabled}
        selectsEnd
        onKeyDown={(event) => event.preventDefault()}
        onChange={(date: Date) => input.onChange(formatHours(date))}
        selected={hoursToDate(input.value)}
        endDate={hoursToDate(input.value)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={60}
        timeCaption="Time"
        timeFormat="HH:mm"
        minTime={minTime}
        maxTime={maxTime}
      />
    </Box>
  );
};

export default InputEndTimePicker;

