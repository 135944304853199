import React from 'react';
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(styles);

const RenderTags = ({tags}: any) => {
  const classes = useStyles();

  if (!tags.length) {
    return <></>;
  }

  return (
    <div className={classes.tagsWrapper}>
      {
        tags.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={classes.singleTag}>
              <Typography variant={'body2'}>
                {item.name}
              </Typography>
            </div>
          );
        })
      }
    </div>
  );
};

export default RenderTags;
