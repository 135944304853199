import React, {useEffect, useState} from 'react';
import {Box, Button, Collapse, createMuiTheme, createStyles, TextField, Typography, useTheme} from "@material-ui/core";
import GoogleMapPin from "../../../../../../components/Icons/GoogleMapPin";
import FieldRow from "../../../../../../components/FieldRow";
import {GroupiTheme} from "../../../../../../customTheme";
import {makeStyles} from "@material-ui/core/styles";
import IconHolder from "../../../../../../components/IconHolder";
import {useSelector} from "react-redux";
import {getPlace} from "../../../../../../bus/business/selectors";
import {WrappedFieldProps} from "redux-form";
import {Link} from "react-router-dom";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

const useStyles = makeStyles(() => createStyles({
  buttonAdd: {
    width: 140,
    padding: '16px 10px',
    fontWeight: 400,
    [breakpoints.down(575)]: {
      width: 'auto',
      padding: '16px 20px'
    }
  },
  buttonAddHideWord: {
    [breakpoints.down(575)]: {
      display: 'none'
    }
  },
  root: {
    '& .MuiFormControl-fullWidth': {
      width: 'calc(100% - 160px)',
      [breakpoints.down(575)]: {
        width: 'calc(100% - 110px)'
      }
    }
  },
  helpText: {
    marginTop: '10px'
  }
}));

const RenderInputGooglePlacesId: React.FC<WrappedFieldProps & any> = (props) => {
  const theme: GroupiTheme = useTheme();
  const classes = useStyles();

  const {disabled, input, getGooglePlace} = props;
  const [toggle, setToggle] = useState(false);
  const [googleId, setGoogleId] = useState('');
  const [buttonText, setButtonText] = useState('ID not Connected');
  const [disableConnectButton, setDisableConnectButton] = useState(true);

  const place = useSelector(getPlace);

  const typing = (event: any) => {
    const val = event.target.value;

    setGoogleId(val);
    input.onChange(val);
  };

  useEffect(() => {
    setDisableConnectButton(googleId.length < 27);
  }, [googleId]);

  useEffect(() => {
    setButtonText(!place?.googlePlaceUnique ? 'ID not Connected' : 'ID Connected');
    place?.googlePlaceUnique && setToggle(!!place?.googlePlaceUnique.length);
  },[place]);

  return (
    <FieldRow
      labelText={'Google places'}
      toolTipText={'Sync your Group@t Marketplace account with Google places.'}>
      <Collapse in={toggle}>
        <Box
          className={classes.root}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <TextField
            {...input}
            onChange={typing}
            type={'text'}
            placeholder={'Enter your Google ID'}
            fullWidth
            variant={'outlined'}
          />
          <Button
            className={classes.buttonAdd}
            variant="contained"
            onClick={getGooglePlace}
            fullWidth
            disabled={disableConnectButton || disabled}
            color="primary">
            Connect
            <span className={classes.buttonAddHideWord}>
              &nbsp;
            </span>
            <span className={classes.buttonAddHideWord}>
              place
            </span>
          </Button>
        </Box>
      </Collapse>
      <Collapse in={!toggle}>
        <Button
          onClick={() => {setToggle(!toggle);}}
          variant="contained"
          color="primary"
          fullWidth
          startIcon={(
            <IconHolder
              size={24}
              color={theme.palette.common.white}>
              <GoogleMapPin/>
            </IconHolder>
          )}>
          {buttonText}
        </Button>
      </Collapse>
      <Typography
        variant={'body1'}
        className={classes.helpText}>
        You can find your Place ID with the
        {" "}
        <Link
          to={{pathname: "https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder"}}
          target="_blank">
          Place ID finder by Google.
        </Link>
      </Typography>
    </FieldRow>
  );
};

export default RenderInputGooglePlacesId;
