import { all, call, takeEvery } from 'redux-saga/effects';
import {types} from "../types";
import {fetchNotifications, refreshNotifications, disableNotifications} from "./workers";

function * watchFetchNotifications() {
  yield takeEvery(types.FETCH_NOTIFICATIONS, fetchNotifications);
}

function * watchRefreshNotifications() {
  yield takeEvery(types.REFRESH_NOTIFICATIONS, refreshNotifications);
}

function * watchDisableNotifications() {
  yield takeEvery(types.DISABLE_NOTIFICATIONS, disableNotifications);
}

export function* watchNotifications(){
  yield all([
    call(watchFetchNotifications),
    call(watchDisableNotifications),
    call(watchRefreshNotifications)
  ]);
}