import React from 'react';
import {SvgIconProps} from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";

const AirplanePathMobile = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 132 125">
      <path
        fill={'none'}
        d="M11.4121 65.8907C70.6703 26.3924 130.17 84.4088 85.6699 103.392C41.1699 122.376 15.8768 20.2858 96.4925 2.3937"
        stroke="white"
        strokeDasharray="5 10"
      />
    </SvgIcon>
  );
};

export default AirplanePathMobile;
