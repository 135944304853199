import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const ArrowWithTail = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      fontSize={'inherit'}
      viewBox="0 0 24 24">
      <path
        d="M12 20L10.59 18.59L16.17 13H4V11H16.17L10.59 5.41L12 4L20 12L12 20Z"
      />
    </SvgIcon>
  );
};

export default ArrowWithTail;
