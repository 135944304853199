import React from 'react';
import SvgIcon, {SvgIconProps} from "@material-ui/core/SvgIcon";

const Person = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 24 24">
      <path
        d="M19.5431 15.1241C18.3814 13.9623 16.9985 13.1023 15.4898 12.5817C17.1057 11.4687 18.1673 9.60608 18.1673 7.49992C18.1673 4.09963 15.4009 1.33325 12.0007 1.33325C8.60036 1.33325 5.83398 4.09963 5.83398 7.49992C5.83398 9.60608 6.89561 11.4687 8.51157 12.5817C7.00282 13.1023 5.61998 13.9623 4.45819 15.1241C2.44353 17.1388 1.33398 19.8174 1.33398 22.6666H3.00065C3.00065 17.704 7.03803 13.6666 12.0007 13.6666C16.9633 13.6666 21.0007 17.704 21.0007 22.6666H22.6673C22.6673 19.8174 21.5578 17.1388 19.5431 15.1241ZM12.0007 11.9999C9.51936 11.9999 7.50065 9.98125 7.50065 7.49992C7.50065 5.01859 9.51936 2.99992 12.0007 2.99992C14.4819 2.99992 16.5007 5.01859 16.5007 7.49992C16.5007 9.98125 14.4819 11.9999 12.0007 11.9999Z"
      />
    </SvgIcon>
  );
};

export default Person;