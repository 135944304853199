import {GroupiTheme} from "../../customTheme";
import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    borderLeftColor: theme.palette.common.gray88,
    borderLeft: '1px solid',
    position: 'relative',
    width: 362,
    [breakpoints.down(804)]: {
      position: 'relative'
    }
  },
  currentItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '0 40px',
    cursor: 'pointer',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  currentItem: {
    marginRight: 20,
    maxWidth: 248,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  itemsList: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    width: 360,
    maxHeight: 496,
    height: 'auto',
    right: 0,
    top: 'calc(100% + 25px)',
    zIndex: 6,
    background: theme.palette.common.white,
    boxShadow: '0px 4px 12px rgba(50, 77, 103, 0.25)',
    borderRadius: 8
  },
  addNewItem: {
    padding: '16px 12px',
    marginTop: 'auto',
    marginBottom: 0
  }
});