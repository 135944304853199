import {RootState} from "../../setup/typedefs";
import {createSelector} from "reselect";

export const offersSelector = (state: RootState) => state.offers;

export const getOffers = createSelector([offersSelector], (result) => {
  return result.offers;
});

export const getOfferPreview = createSelector([offersSelector], (result) => {
  return result.offerPreview;
});

export const getOffersMeta = createSelector([offersSelector], (result) => {
  return result.meta;
});

export const getOffer = createSelector([offersSelector], (result) => {
  return result.offer;
});

export const getOfferDynamics = createSelector([offersSelector], (result) => {
  return {
    favorites: result.offerFavorites,
    reservations: result.offerReservations,
    views: result.offerViews
  };
});