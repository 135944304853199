import React, {useEffect, useState} from 'react';
import {Box} from "@material-ui/core";
import {useDropzone} from "react-dropzone";
import {WrappedFieldProps} from "redux-form";
import SingleDocument from "../SingleDocument";
import {handleErrors} from "../../utils/handleErrors";
import {useDispatch} from "react-redux";
import {uiActions} from "../../bus/ui/actions";
import DropFrame from "./DropFrame";

const DocumentsDragNDropInput: React.FC<WrappedFieldProps & any> = (props) => {
  const {input, singleImage = false, disabledForEdit} = props;
  const files = input.value;
  const [rejectedFiles, setRejectedFiles] = useState<any>();
  const dispatch = useDispatch();

  //10mb
  const maxSize = 10485760;
  const removeFile = (index: number) => {
    const result = files.filter((file: any) => file.index !== index);
    input.onChange(result);
    setRejectedFiles(null);
  };

  const {getRootProps, getInputProps, fileRejections} = useDropzone({
    maxSize: maxSize,
    accept: 'image/jpeg, image/png, image/jpg, application/pdf',
    onDropRejected: (fileRejections) => {
      handleError(fileRejections);
    },
    onDrop: (acceptedFiles: object[]) => {
      input.onChange(files.concat(
        acceptedFiles?.map((file: object, index: number) => Object.assign(file, {
          index: files?.length + index
        }))
      ));
    }
  });

  const handleError = (errors: any) => {
    errors.forEach((item: any) => {
      item.errors.forEach((item: any) => {
        dispatch(uiActions.toast(item.message));
      });
    });
  };

  useEffect(() => {
    setRejectedFiles(fileRejections);
  }, [fileRejections]);

  return (
    <div>
      <DropFrame
        input={input}
        files={files}
        singleImage={singleImage}
        disabledForEdit={disabledForEdit}
        maxSize={maxSize}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
      />
      <Box mt={2}>
        {files?.map( (file: any, index: number) => {
          return (
            <SingleDocument
              disabledForEdit={disabledForEdit}
              removeImg={removeFile}
              key={index}
              file={file}
            />
          );
        })}
      </Box>
      <Box mt={2}>
        {rejectedFiles?.map((item: any, index: number) => {
          handleErrors(item?.errors[0].message);

          return (
            <SingleDocument
              disabledForEdit={disabledForEdit}
              removeImg={removeFile}
              key={index}
              file={item?.file}
              errors={item?.errors}
            />
          );
        })}
      </Box>
    </div>
  );

};

export default DocumentsDragNDropInput;