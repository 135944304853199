import {GroupiTheme} from "../../customTheme";
import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  addNewItem: {
    height: 96,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 20px',
    borderTopColor: theme.palette.common.gray88,
    borderTop: '1px solid',
    [breakpoints.down(600)]: {
      padding: '0 12px'
    },
    '& a': {
      width: '100%'
    }
  },
  bottomBlock: {
    borderTopColor: theme.palette.common.gray88,
    borderTop: '1px solid',
    padding: '0 0 0 20px',
    width: 'calc(100% - 20px)',
    height: 72,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: theme.palette.common.white,
    [breakpoints.down(600)]: {
      width: 'calc(100% - 16px)',
      padding: '0 0 0 12px'
    }
  },
  salesBlock: {
    height: 110,
    borderBottomColor: theme.palette.common.gray88,
    borderBottom: '1px solid',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px'
  },
  switcher: {
    width: 72,
    height: 70,
    background: '#FFFFFF',
    borderColor: theme.palette.common.gray88,
    border: '1px solid',
    boxSizing: 'border-box',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  activeSwitch: {
    background: theme.palette.common.gray95,
    '& svg': {
      transform: 'rotate(180deg)'
    }
  },
  infoText: {
    width: 'calc(100% - 88px)',
    '& p': {
      color: theme.palette.common.gray60
    },
    '& h6': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }
  },
  placesList: {
    top: '183px !important',
    height: 'calc(100% - 260px)',
    '& .MuiBackdrop-root': {
      display: 'none'
    },
    '& .MuiDrawer-paperAnchorRight': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      boxShadow: 'none'
    }
  }
});