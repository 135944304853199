import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const QuestionTooltip = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 24 24">
      <circle
        cx="12"
        cy="12"
        r="10"
      />
      <path
        d="M10.9573 14.56C10.904 14.176 10.9146 13.824 10.9893 13.504C11.0746 13.184 11.192 12.8907 11.3413 12.624C11.5013 12.3573 11.6773 12.112 11.8693 11.888C12.072 11.6533 12.2586 11.4293 12.4293 11.216C12.6106 10.992 12.76 10.7733 12.8773 10.56C12.9946 10.3467 13.0533 10.1173 13.0533 9.872C13.0533 9.488 12.936 9.17867 12.7013 8.944C12.4773 8.70933 12.1626 8.592 11.7573 8.592C11.4266 8.592 11.128 8.66667 10.8613 8.816C10.5946 8.96533 10.344 9.168 10.1093 9.424L9.08531 8.48C9.43731 8.07467 9.85331 7.744 10.3333 7.488C10.824 7.22133 11.3733 7.088 11.9813 7.088C12.3973 7.088 12.7813 7.14667 13.1333 7.264C13.4853 7.37067 13.784 7.536 14.0293 7.76C14.2853 7.984 14.4826 8.26133 14.6213 8.592C14.7706 8.92267 14.8453 9.30667 14.8453 9.744C14.8453 10.0853 14.7813 10.3947 14.6533 10.672C14.536 10.9387 14.3866 11.1947 14.2053 11.44C14.024 11.6853 13.8266 11.92 13.6133 12.144C13.4106 12.368 13.224 12.6027 13.0533 12.848C12.8933 13.0933 12.76 13.3547 12.6533 13.632C12.5573 13.9093 12.5306 14.2187 12.5733 14.56H10.9573ZM11.7733 18.192C11.432 18.192 11.1493 18.0747 10.9253 17.84C10.7013 17.6053 10.5893 17.3067 10.5893 16.944C10.5893 16.5813 10.7013 16.2827 10.9253 16.048C11.1493 15.8133 11.432 15.696 11.7733 15.696C12.1146 15.696 12.3973 15.8133 12.6213 16.048C12.856 16.2827 12.9733 16.5813 12.9733 16.944C12.9733 17.3067 12.856 17.6053 12.6213 17.84C12.3973 18.0747 12.1146 18.192 11.7733 18.192Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default QuestionTooltip;
