import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const Document = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 24 24">
      <g clipPath="url(#clip0)">
        <path
          d="M1.50189 21.7495V2.24952C1.50189 1.83529 1.83766 1.49952 2.25189 1.49952H13.5019V4.49952C13.5019 5.32795 14.1735 5.99952 15.0019 5.99952H18.0019V8.24952H19.5019V5.24952C19.5031 5.05016 19.4248 4.85854 19.2844 4.71702L14.7844 0.217024C14.6429 0.0766331 14.4513 -0.00160131 14.2519 -0.000476314H2.25189C1.00924 -0.000476314 0.00189209 1.00691 0.00189209 2.24952V21.7495C0.00189209 22.9922 1.00924 23.9995 2.25189 23.9995H7.50189V22.4995H2.25189C1.8377 22.4995 1.50189 22.1638 1.50189 21.7495Z"
        />
        <path
          d="M9.00189 12H6.00189C5.58766 12 5.25189 12.3358 5.25189 12.75V18.75C5.25189 19.1642 5.58766 19.5 6.00189 19.5H9.00189C9.83031 19.5 10.5019 18.8284 10.5019 18V13.5C10.5019 12.6716 9.83031 12 9.00189 12ZM9.00189 18H6.75189V13.5H9.00189V18Z"
        />
        <path
          d="M14.2519 12C12.595 12 11.2519 13.3432 11.2519 15V16.5C11.2519 18.1568 12.595 19.5 14.2519 19.5C15.9087 19.5 17.2519 18.1568 17.2519 16.5V15C17.2519 13.3432 15.9087 12 14.2519 12ZM15.7519 16.5C15.7519 17.3284 15.0803 18 14.2519 18C13.4235 18 12.7519 17.3284 12.7519 16.5V15C12.7519 14.1716 13.4235 13.5 14.2519 13.5C15.0803 13.5 15.7519 14.1716 15.7519 15V16.5Z"
        />
        <path
          d="M21.0019 18C20.1735 18 19.5019 17.3284 19.5019 16.5V15C19.5019 14.1716 20.1735 13.5 21.0019 13.5C21.8303 13.5 22.5019 14.1716 22.5019 15H24.0019C24.0019 13.3432 22.6587 12 21.0019 12C19.345 12 18.0019 13.3432 18.0019 15V16.5C18.0019 18.1568 19.345 19.5 21.0019 19.5C22.6587 19.5 24.0019 18.1568 24.0019 16.5H22.5019C22.5019 17.3284 21.8303 18 21.0019 18Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.00189209)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Document;
