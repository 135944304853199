import {types} from "./types";
import {INotification} from "../../setup/typedefs";

export const notificationsActions = {
  fetchNotifications: (data: {merchantID: number}) => {
    return {
      type: types.FETCH_NOTIFICATIONS,
      payload: data
    };
  },
  fillNotifications: (data: INotification[]) => {
    return {
      type: types.FILL_NOTIFICATIONS,
      payload: data
    };
  },
  refreshNotifications: (data: {merchantID: number}) => {
    return {
      type: types.REFRESH_NOTIFICATIONS,
      payload: data
    };
  },
  disableNotifications: (data: {notificationID: number, merchantID: number}) => {
    return {
      type: types.DISABLE_NOTIFICATIONS,
      payload: data
    };
  },
  clear: () => {
    return {
      type: types.CLEAR
    };
  }
};