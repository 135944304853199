//Core
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//Helpers
import {getPlace} from "../../../../../bus/business/selectors";
import {offersActions} from "../../../../../bus/offers/actions";
import {Offer} from "../../../../../bus/offers/typedefs";
import {useTrackUpdates} from "./useTrackUpdates";
import {profileIsVerified} from "../../../../../bus/profile/selectors";
import {change, getFormInitialValues, getFormValues, isDirty, isPristine, isValid, reset} from "redux-form";
import {getFieldsInArray} from "../../../../../utils/helpers";
import {getOfferPreview} from "../../../../../bus/offers/selectors";

export const useOfferEditForm = () => {
  const dispatch = useDispatch();
  const formName = 'offerEditForm';
  const pristine = useSelector(isPristine(formName));
  const dirty = useSelector(isDirty(formName));
  const valid = useSelector(isValid(formName));
  const initOfferValue = useSelector<Offer>(getFormInitialValues(formName)) as Offer;
  const currentOfferValue = useSelector<Offer>(getFormValues(formName)) as Offer;
  const {actionWithCover, updateTags} = useTrackUpdates(initOfferValue, currentOfferValue);
  const offerPreview = useSelector(getOfferPreview);
  const place = useSelector(getPlace);
  const isVerified = useSelector(profileIsVerified);
  const [placeStatus, setPlaceStatus] = useState<boolean>();
  const [offerStatus, setOfferStatus] = useState<boolean>();

  useEffect(() => {
    place?.status && setPlaceStatus(place?.status !== 'active');
  }, [place]);

  useEffect(() => {
    initOfferValue?.status && setOfferStatus(initOfferValue?.status !== 'active');
  }, [initOfferValue]);

  //offer from preview
  useEffect(() => {
    if (offerPreview && initOfferValue) {
      getFieldsInArray(offerPreview).forEach((item) => {
        // @ts-ignore
        dispatch(change(formName, item, offerPreview[item]));
      });
    }
  },[offerPreview]);

  //create and go to preview
  const goPreview = () => {
    currentOfferValue && dispatch(offersActions.createPreview(currentOfferValue));
  };

  //submit offer
  const onSubmit = (isPublished: boolean, values: Offer & any) => {
    dispatch(reset(formName));
    if (values && place) {
      dispatch(offersActions.updateOffer({
        formData: {
          ...values,
          isPublished
        },
        info: {
          offerID: values.id,
          updateTags,
          actionWithCover
        }
      }));
    }
  };

  return {
    formName,
    goPreview,
    onSubmit,
    isVerified,
    placeStatus,
    offerStatus,
    pristine,
    dirty,
    valid
  };
};
