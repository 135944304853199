import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {book} from "../../routes/book";
import MyPlace from "./pages/MyPlace";
import MyPlacePreview from "./pages/MyPlacePreview";
import CreateMyPlace from "./pages/CreateMyPlace";
import MyRevenue from "./pages/MyRevenue";
import {useSelector} from "react-redux";
import {getPlace} from "../../bus/business/selectors";

const BusinessRouter = () => {
  const place = useSelector(getPlace);

  return (
    <Switch>
      <Route path={book.myPlacePreview}>
        <MyPlacePreview/>
      </Route>
      <Route path={book.myPlace}>
        {place
          ? <MyPlace/>
          : <CreateMyPlace/>
        }
      </Route>
      <Route path={book.createMyPlace}>
        <CreateMyPlace/>
      </Route>
      <Route path={book.myRevenue}>
        <MyRevenue/>
      </Route>
      <Redirect to={book.myPlace}/>
    </Switch>
  );
};

export default BusinessRouter;
