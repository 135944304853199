import {GroupiTheme} from "../../customTheme";
import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    position: 'absolute',
    top: 5,
    left: 0,
    width: 56,
    height: 56,
    borderRadius: '0 50% 50% 50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [breakpoints.down(600)]: {
      width: 30,
      height: 30
    }
  },
  draft: {
    background: theme.palette.common.gray88
  },
  pending: {
    background: theme.palette.common.yellow
  },
  active: {
    background: theme.palette.common.green
  },
  error: {
    background: theme.palette.common.red
  },
  expired: {
    background: theme.palette.common.blue68
  }
});