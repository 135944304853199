import {all, call, put} from "redux-saga/effects";
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {offersActions} from "../../actions";
import {Workers} from "../../../../setup/typedefs";
import {uiActions} from "../../../ui/actions";
import {push} from "connected-react-router";
import {book} from "../../../../routes/book";
import {formatOfferObjectToAPI} from "../../../../utils/helpers";
import {updateOfferCover} from "./updateOfferCover";
import {updateOfferTags} from "./updateOfferTags";

export function* updateOffer({payload}: Workers){
  try {
    yield put(uiActions.loading(true));
    const {formData, info: {placeID, offerID, updateTags, actionWithCover, through = false}} = payload;
    const endpoint = !offerID ? `places/${placeID}/offers` : `offers/${offerID}`;
    const actionType = !offerID ? 'create' : 'update';

    const body = formatOfferObjectToAPI(formData);

    const response = yield call(fetchWithToken, {
      endpoint: endpoint,
      method: 'PUT',
      body: through ? formData : body
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    const {data: {name, id, status}} = yield call([response, 'json']);
    yield call(handleMsg, `Deal '${name}' successful ` + actionType);

    yield all([
      call(updateOfferCover, {formData, offerID: id, actionWithCover}),
      call(updateOfferTags, {formData, offerID: id, updateTags})
    ]);

    yield put(offersActions.clear());

    switch (status) {
    case 'draft':
      yield put(push(book.draftDeals + `/1`));
      break;

    case 'pending':
      yield put(push(book.pendingDeals + `/1`));
      break;

    default:
      yield put(push(book.activeDeals + `/1`));
      break;
    }

  } catch (e) {
    console.log(e);
  } finally {
    yield put(uiActions.loading(false));
    yield put(uiActions.confirm(false));
  }
}
