//Core
import React from 'react';

//Helpers
import {useRecreateVoucher} from "./customHooks/useRecreateVoucher";

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {styles} from "./styles";

//Components
import VoucherRecreateForm from "./form/VoucherRecreateForm";
import ButtonBackBlue from "../../../../components/ButtonBackBlue";

const useStyles = makeStyles(styles);

const RecreateVoucher = () => {
  const classes = useStyles();
  const {initialValues} = useRecreateVoucher();

  return (
    <div className={classes.container}>
      <ButtonBackBlue/>
      {initialValues
        ? (
          <VoucherRecreateForm initialValues={initialValues}/>
        ) : (
          <Typography variant={'body2'}>
            fetching voucher...
          </Typography>
        )}
    </div>
  );
};

export default RecreateVoucher;
