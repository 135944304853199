import {call} from "redux-saga/effects";
import {fetchFileWithToken} from "../../../utils/fetching";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";

export function* updatePlacePhotos({formData, placeID, actionWithPhotos: action}: any){
  try {
    if (action !== 'nothing') {
      const response = yield call(fetchFileWithToken, {
        endpoint: `places/${placeID}/photos`,
        method: 'PUT',
        type: 'photos',
        body: action !== 'remove' ? formData.photos : {removePhotos: true}
      });

      if (response.status !== 200) {
        yield call(handleErrors, response);
        throw new Error(response);
      }

      yield call(handleMsg, `Photos ${action !== 'remove' ? ' updated' : ' removed'}`);
    }
  } catch (e) {
    console.log(e);
  }
}