//Core
import React from 'react';

//Helpers
import {useSingleOfferInList} from "./customHooks/useSingleOfferInList";
import {Offer} from "../../../../bus/offers/typedefs";

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import Counters from "./components/Counters";
import MainInfoBlock from "./components/MainInfoBlock";
import ActionsButton from "./components/ActionsButton";

const useStyles = makeStyles(styles);

type SingleOfferInListProps = {
  item: Offer
}

const SingleOfferInList: React.FC<SingleOfferInListProps> = ({item}) => {
  const classes = useStyles();
  const {toggleTabletView} = useSingleOfferInList();

  return (
    <div className={classes.root}>
      <MainInfoBlock
        item={item}
        toggleTabletView={toggleTabletView}
      />
      {!toggleTabletView && (
        <Counters
          item={item}
          toggleTabletView={toggleTabletView}
        />
      )}
      <ActionsButton id={item.id}/>
    </div>
  );
};

export default SingleOfferInList;