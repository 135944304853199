import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import PhoneMockUp from "../../../../components/PhoneMockUp";
import RenderVoucherPreview from "./components/RenderVoucherPreview";
import {usePreview} from "./customHooks/usePreview";
import ButtonBackBlue from "../../../../components/ButtonBackBlue";

const useStyles = makeStyles(styles);

const PreviewVoucher = () => {
  const classes = useStyles();
  const {mobileView} = usePreview();

  return (
    <div className={classes.rootWrapper}>
      <ButtonBackBlue/>
      {!mobileView
        ? (
          <PhoneMockUp>
            <RenderVoucherPreview/>
          </PhoneMockUp>
        )
        : (
          <RenderVoucherPreview/>
        )}
    </div>
  );
};

export default PreviewVoucher;
