export const types = {
  POPUP: '@UI/POPUP',
  CONFIRM: '@UI/CONFIRM',
  FILL_POPUP_DATA: '@UI/FILL_POPUP_DATA',
  POPUP_OPEN: '@UI/POPUP_OPEN',
  POPUP_CLOSE: '@UI/POPUP_CLOSE',
  CLEAR_POPUP_DATA: '@UI/CLEAR_POPUP_DATA',
  TOAST: '@UI/TOAST',
  FILL_TOAST_DATA: '@UI/FILL_TOAST_DATA',
  TOAST_OPEN: '@UI/TOAST_OPEN',
  TOAST_CLOSE: '@UI/TOAST_CLOSE',
  CLEAR_TOAST_DATA: '@UI/CLEAR_TOAST_DATA',
  CLEAR: '@UI/CLEAR',
  LOADING: '@UI/LOADING'
};