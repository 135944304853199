import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'normal',
    marginTop: 1,
    marginBottom: 1,
    [breakpoints.down(768)]: {
      flexWrap: 'wrap'
    },
    [breakpoints.down(575)]: {}
  },
  label: {
    display: 'flex',
    flex: '0 0 auto',
    width: '100%',
    maxWidth: 171,
    marginTop: 14,
    [breakpoints.down(768)]: {
      marginTop: 2,
      marginBottom: 8
    },
    [breakpoints.down(575)]: {}
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 1 auto'
  },
  divide: {
    width: 10,
    height: 1,
    background: theme.palette.common.black
  },
  tooltip: {
    marginLeft: 14,
    marginRight: 20
  }
});