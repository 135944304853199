//Core
import React from 'react';

//Helpers
import {ListStatus} from "../../../../../setup/typedefs";
import {book} from "../../../../../routes/book";

//Components
import ListOfOffers from "../ListOfOffers";

const PendingOffers = () => {
  return (
    <ListOfOffers
      status={ListStatus.Pending}
      paginationPath={book.pendingDeals}
      title={'Pending'}
    />
  );
};

export default PendingOffers;

