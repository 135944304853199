import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import AuthContainer from "../containers/AuthContainer";
import {book} from "./book";
import TermsAndConditions from "../containers/TermsAndConditionsContainer/pages/TermsAndConditions";
import TermsAndConditionsForApps from "../containers/TermsAndConditionsContainer/pages/TermsAndConditionsForApps";
import ContactPage from "../containers/AccountContainer/pages/ContactPage";
import HelpPage from "../containers/AdditionalContainer/pages/Help";
import PrivacyPolicyApps from "../containers/TermsAndConditionsContainer/pages/PrivacyPolicyApps";
import PrivacyPolicy from "../containers/TermsAndConditionsContainer/pages/PrivacyPolicy";
import VerifyEmail from "../containers/AuthContainer/pages/VerifyEmail";
import FAQ from "../containers/AdditionalContainer/pages/FAQ";

const PublicRouter = () => {
  return (
    <Switch>
      <Route path={book.verifyEmail}>
        <VerifyEmail/>
      </Route>
      <Route path={book.help}>
        <HelpPage/>
      </Route>
      <Route path={book.faq}>
        <FAQ/>
      </Route>
      <Route path={book.contact.forApp}>
        <ContactPage fromApp={true}/>
      </Route>
      <Route path={book.contact.forSite}>
        <ContactPage/>
      </Route>
      <Route path={book.termsAndConditions.forSite}>
        <TermsAndConditions/>
      </Route>
      <Route path={book.termsAndConditions.forApp}>
        <TermsAndConditionsForApps/>
      </Route>
      <Route path={book.privacy.forSite}>
        <PrivacyPolicy/>
      </Route>
      <Route path={book.privacy.forApp}>
        <PrivacyPolicyApps/>
      </Route>
      <Route
        path={'/:page'}>
        <AuthContainer/>
      </Route>
      <Redirect to={book.myPlace}/>
    </Switch>
  );
};

export default PublicRouter;
