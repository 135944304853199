import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  cardsWrapper: {
    display: 'flex',
    justifyContent: "space-between"
  },
  card: {
    backgroundColor: theme.palette.common.white,
    width: 'calc(50% - 10px)',
    height: 138,
    borderColor: theme.palette.common.gray88,
    border: '1px solid',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [breakpoints.down(784)]: {},
    '& span': {
      fontSize: 30
    }
  }
});