import {createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../customTheme";

export const styles = (theme: GroupiTheme) => createStyles({
  body3: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.text.primary
  },
  button1: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
    textTransform: 'uppercase',
    letterSpacing: '0.06em',
    color: theme.palette.text.primary
  },
  button2: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 13,
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.06em',
    color: theme.palette.text.primary
  },
  link: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '18px',
    color: theme.palette.common.blue50,
    cursor: "pointer"
  }
});