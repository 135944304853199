import {GroupiTheme} from "../../customTheme";
import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  line: {
    top: '50%',
    left: 0,
    position: 'absolute',
    transform: 'translateY(-50%)',
    height: 40,
    width: 4,
    background: theme.palette.common.blue50,
    [breakpoints.down(804)]: {}
  }
});