import React from 'react';
import IconHolder from "../IconHolder";
import Upload from "../Icons/Upload";
import {Typography, useTheme} from "@material-ui/core";
import {GroupiTheme} from "../../customTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {styles} from "./styles";

const useStyles = makeStyles(styles);

const DropFrame: React.FC<any> = (props) => {
  const {input, files, singleImage, disabledForEdit, maxSize, getInputProps, getRootProps} = props;
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();

  const sizeForFront = Math.round(maxSize / 1000000);

  return (
    <>
      <div className={classes.root}>
        {!(files?.length && singleImage) && !disabledForEdit && (
          <div
            {...getRootProps()}
            className={classes.fileDropZone}>
            <input
              {...getInputProps()}
              name={input.name}
            />
            <div className={classes.innerInfo}>
              <IconHolder
                size={41}
                color={theme.palette.common.gray88}>
                <Upload/>
              </IconHolder>
              <div>
                <Typography variant={'body2'}>
                  browse to choose a file
                </Typography>
                <Typography variant={'caption'}>
                  .JPG .JPEG .PNG .PDF (max
                  {' '}
                  {sizeForFront}
                  {' '}
                  MB)
                </Typography>
              </div>
            </div>
          </div>
        )}
      </div>
      {
        !disabledForEdit && (
          <div className={classes.textWrapper}>
            <Typography variant={'body2'}>
              Drag and drop a docs or
              {' '}
              <span
                {...getRootProps()}
                className={classes.blueText}>
                browse
              </span>
              {' '}
              to choose a file
            </Typography>
          </div>
        )
      }
    </>
  );
};

export default DropFrame;