import {call} from "@redux-saga/core/effects";
import {ResendOnEmailFormValues} from "../../../../containers/AuthContainer/pages/ThankYouPage/form/typedefs";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {fetchWithoutToken} from "../../../utils/fetching";

export function* resendOnEmail({payload: body}: { type: string, payload: ResendOnEmailFormValues }) {
  try {
    const response = yield call(fetchWithoutToken, {
      endpoint: 'merchants/auth/resend-confirmation-email',
      method: 'POST',
      body
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    yield call(handleMsg, 'Please check your email');

  } catch (e) {
    console.log(e);
  }
}
