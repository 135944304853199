import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  buttonWrapper: {
    marginTop: 55,
    display: 'flex',
    [breakpoints.down(1200)]: {
      marginTop: 120
    },
    [breakpoints.down(767)]: {
      marginTop: 60
    },
    [breakpoints.down(600)]: {
      marginTop: 20
    }
  },
  submitButton: {
    marginRight: 0,
    marginLeft: 'auto',
    [breakpoints.down(600)]: {
      marginRight: 0,
      marginBottom: 20
    }
  }
});
