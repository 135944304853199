import {useSelector} from "react-redux";
import {getOfferDynamics} from "../../../../../../bus/offers/selectors";

export const useManipulationWithDates = () => {
  const {favorites, reservations, views} = useSelector(getOfferDynamics);

  const months = [
    {
      month: 'Jan',
      maxDaysInMonth: 31
    },
    {
      month: 'Feb',
      maxDaysInMonth: 28
    },
    {
      month: 'Mar',
      maxDaysInMonth: 31
    },
    {
      month: 'Apr',
      maxDaysInMonth: 30
    },
    {
      month: 'May',
      maxDaysInMonth: 31
    },
    {
      month: 'Jun',
      maxDaysInMonth: 30
    },
    {
      month: 'Jul',
      maxDaysInMonth: 31
    },
    {
      month: 'Aug',
      maxDaysInMonth: 31
    },
    {
      month: 'Sep',
      maxDaysInMonth: 30
    },
    {
      month: 'Oct',
      maxDaysInMonth: 31
    },
    {
      month: 'Nov',
      maxDaysInMonth: 30
    },
    {
      month: 'Dec',
      maxDaysInMonth: 31
    }
  ];

  const arrayConvert = (initialArray: any) => {
    const sortToBigger = initialArray.sort((a: any, b: any) => a - b);

    const convertArray = sortToBigger.map((item: any) => {
      return {
        originalDate: item,
        date: new Date(item * 1000).getDate(),
        month: new Date(item * 1000).getMonth()
      };
    });

    const groupedArr = convertArray.reduce(function (previousValue: any, item: any, index: any) {
      if (!index || item.date !== previousValue[previousValue.length - 1][0].date) {
        return previousValue.concat([[item]]);
      }
      previousValue[previousValue.length - 1].push(item);

      return previousValue;
    }, []);

    return groupedArr.reduce((previousValue: any, item: any, index: number) => {
      const fullDate = new Date(item[0].originalDate * 1000);
      const {month, maxDaysInMonth} = months[item[0].month];
      const date = fullDate.getDate();

      //TODO: need test first this workflow (with comment next line)

      // if (!index) {
      //   return previousValue.concat({
      //     date: date + ' ' + month,
      //     amount: item.length
      //   });
      // }

      previousValue.push({
        date: date + ' ' + month,
        amount: item.length
      });

      if (groupedArr[index + 1]) {

        if (item[0].month === groupedArr[index + 1][0].month) {

          if (groupedArr[index + 1][0].date - (item[0].date + 1)) {
            const shift = groupedArr[index + 1][0].date - (item[0].date);

            for (let i = 1; i < shift; i++) {

              previousValue.push({
                date: date + i + ' ' + month,
                amount: 0
              });
            }
          }

        } else {
          if (item[0].date < maxDaysInMonth) {
            const shift = maxDaysInMonth - item[0].date + 1;

            for (let i = 1; i < shift; i++) {
              previousValue.push({
                date: date + i + ' ' + month,
                amount: 0
              });
            }
          }

          if (groupedArr[index + 1][0].date > 1) {
            const shift = groupedArr[index + 1][0].date - 1;

            for (let i = 1; i < shift; i++) {
              previousValue.push({
                date: i + ' ' + months[groupedArr[index + 1][0].month].month,
                amount: 0
              });
            }
          }
        }

      }

      return previousValue;
    }, []);
  };

  const viewsArray = arrayConvert(views);
  const favoritesArray = arrayConvert(favorites);
  const reservationsArray = arrayConvert(reservations);

  return {
    viewsArray,
    favoritesArray,
    reservationsArray
  };
};
