import {call, put} from 'redux-saga/effects';
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors} from "../../../../utils/handleErrors";
import {placesActions} from "../../actions";
import {Workers} from "../../../../setup/typedefs";

export function* fetchPlaces({payload}: Workers){
  try {
    const response = yield call(fetchWithToken, {
      endpoint: `merchants/${payload}/places`,
      method: 'GET'
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    const {data} = yield call([response, 'json']);

    yield put(placesActions.fillPlaces(data));
    if (data[0]) {
      yield put(placesActions.fetchPlace({id: data[0].id}));
    }

  } catch (e) {
    console.log(e);
  }
}
