import React from 'react';
import {Button} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import LogOut from "../Icons/LogOut";
import {uiActions} from "../../bus/ui/actions";

const useStyles = makeStyles(styles);

const LogOutBlock = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(uiActions.popup({
      name: 'areYouSure',
      payload: {
        title: 'Log out from your Merchant account',
        description: 'Are you sure you want to log out?',
        confirm: 'Logout',
        cancel: 'cancel'
      }
    }));
  };

  return (
    <div className={classes.root}>
      <Button
        onClick={logout}
        className={classes.logOutButton}>
        <LogOut
          className={classes.logoutIcon}
          color={'inherit'}
          fontSize={'inherit'}
        />
      </Button>
    </div>
  );
};

export default LogOutBlock;