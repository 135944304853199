import {GroupiTheme} from "../../customTheme";
import {createMuiTheme, createStyles} from "@material-ui/core";
import logo from "../../images/logo_black.png";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    position: 'fixed',
    width: '100%',
    background: theme.palette.common.white,
    zIndex: 100,
    height: 104,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottomColor: theme.palette.common.gray88,
    borderBottom: '1px solid',
    top: 0,
    left: 0,
    [breakpoints.down(1200)]: {
      position: 'relative',
      height: 96
    }
  },
  logo: {
    width: '147px',
    height: '54px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: '0 -10px',
    backgroundImage:`url(${logo})`,
    color: theme.palette.common.blue57,
    marginLeft: 69,
    marginTop: 28,
    position: 'relative',
    [breakpoints.down(1200)]: {
      marginLeft: 34,
      marginTop: 28
    }
  },
  version: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    '& span': {
      whiteSpace: 'nowrap'
    },
    [breakpoints.down(1200)]: {
      bottom: 15
    }
  },
  rightSide: {
    height: '100%',
    display: 'flex',
    [breakpoints.down(1200)]: {
      display: 'none'
    }
  }
});
