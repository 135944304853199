import {types} from "./types";
import {AdditionalActionInfo, ListStatus, Meta} from "../../setup/typedefs";
import {Voucher} from "./typedefs";

export const vouchersActions = {
  fetchVoucher: (data: number) => {
    return {
      type: types.FETCH_VOUCHER,
      payload: data
    };
  },
  fillVoucher: (data: Voucher) => {
    return {
      type: types.FILL_VOUCHER,
      payload: data
    };
  },
  fetchVouchers: (data: {placeID: number, page: number, perPage: number, status: ListStatus}) => {
    return {
      type: types.FETCH_VOUCHERS,
      payload: data
    };
  },
  fillVouchers: (data: Voucher[], meta: Meta) => {
    return {
      type: types.FILL_VOUCHERS,
      payload: data,
      meta
    };
  },
  createPreview: (data: Voucher) => {
    return {
      type: types.CREATE_PREVIEW,
      payload: data
    };
  },
  fillPreview: (data: Voucher) => {
    return {
      type: types.FILL_PREVIEW,
      payload: data
    };
  },
  createVoucher: (data: {formData: Voucher, info: Partial<AdditionalActionInfo>}) => {
    return {
      type: types.CREATE_VOUCHER,
      payload: data
    };
  },
  updateVoucher: (data: {formData: Voucher & any, info: Partial<AdditionalActionInfo>}) => {
    return {
      type: types.UPDATE_VOUCHER,
      payload: data
    };
  },
  saveVoucherTags: (data: {formData: Partial<Voucher>, voucherID: number}) => {
    return {
      type: types.SAVE_VOUCHER_TAGS,
      payload: data
    };
  },
  saveVoucherCover: (data: {formData: Partial<Voucher>, voucherID: number}) => {
    return {
      type: types.SAVE_VOUCHER_COVER,
      payload: data
    };
  },
  removeCover: (data: {voucherID: number}) => {
    return {
      type: types.REMOVE_VOUCHER_COVER,
      payload: data
    };
  },
  removeVoucher: (payload: {voucherID: number}) => {
    return {
      type: types.REMOVE_VOUCHER,
      payload: payload
    };
  },
  clearPreview: () => {
    return {
      type: types.CLEAR_PREVIEW
    };
  },
  clear: () => {
    return {
      type: types.CLEAR
    };
  }
};