import {types} from "./types";
import {ITokens} from "./typedefs";
import {SignUpFormValues} from "../../containers/AuthContainer/pages/SignUp/form/typedefs";
import {SignInFormValues} from "../../containers/AuthContainer/pages/SignIn/form/typedefs";
import {ResendOnEmailFormValues} from "../../containers/AuthContainer/pages/ThankYouPage/form/typedefs";
import {ResetPasswordFormValues} from "../../containers/AuthContainer/pages/ResetPassword/form/typedefs";
import {ChangePasswordFormValues} from "../../containers/AuthContainer/pages/ChangePassword/form/typedefs";

export const authActions = {
  authenticate: (status: boolean) => {
    return {
      type: types.AUTHENTICATE,
      payload: status
    };
  },
  fillTokens: (tokens: ITokens) => {
    return {
      type: types.FILL_TOKENS,
      payload: tokens
    };
  },
  signUp: (data: SignUpFormValues) => {
    return {
      type: types.SIGN_UP,
      payload: data
    };
  },
  termsAgree: (data: boolean) => {
    return {
      type: types.TERMS_AGREE,
      payload: data
    };
  },
  signIn: (data: SignInFormValues) => {
    return {
      type: types.SIGN_IN,
      payload: data
    };
  },
  resendEmail: (data: ResendOnEmailFormValues) => {
    return {
      type: types.RESEND_ON_EMAIL,
      payload: data
    };
  },
  resetPassword: (data: ResetPasswordFormValues) => {
    return {
      type: types.RESET_PASSWORD,
      payload: data
    };
  },
  changePassword: (data: ChangePasswordFormValues) => {
    return {
      type: types.CHANGE_PASSWORD,
      payload: data
    };
  },
  verifyEmail: (data: string) => {
    return {
      type: types.VERIFY_EMAIL,
      token: data
    };
  },
  clear: () => {
    return {
      type: types.CLEAR
    };
  }
};

