import {createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../customTheme";

export const styles = (theme: GroupiTheme) => createStyles({
  Fab: {
    width: 'unset',
    height: 'unset',
    minHeight: 0,
    boxShadow: 'none',
    background: 'none',
    '&:hover': {
      background: 'none'
    }
  },
  customWidth: {
    width: 324,
    padding: '8px 12px',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    borderRadius: 8,
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '24px'
  }
});