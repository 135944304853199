import React from 'react';
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import ContactPageForm from "./form/ContactPageForm";
import {Typography} from "@material-ui/core";
import {useContactPage} from "./customHooks/useContactPage";
import {Prompt} from "react-router";
import Header from "../../../../components/Header";
import ButtonBackBlue from "../../../../components/ButtonBackBlue";

const useStyles = makeStyles(styles);

const ContactPage = ({fromApp = false}) => {
  const classes = useStyles();
  const {
    onSubmit,
    pristine,
    dirty
  } = useContactPage();

  return (
    <>
      {
        fromApp && (
          <Header/>
        )
      }
      <div className={classes.container}>
        {
          !fromApp && (
            <ButtonBackBlue/>
          )
        }
        <Prompt
          when={dirty && !pristine}
          message='All your changes will not be saved. Continue?'
        />
        <Typography
          className={classes.title}
          variant={'h1'}>
          Contact Admin
        </Typography>
        <Typography
          className={classes.subTitle}
          variant={'body1'}>
          Describe your problem below and we will respond as quickly as possible
        </Typography>
        <ContactPageForm onSubmit={onSubmit}/>
      </div>
    </>
  );
};

export default ContactPage;
