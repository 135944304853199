//Core
import React from 'react';
import {Field, InjectedFormProps, reduxForm} from "redux-form";

//Helpers
import schema from "./schema";
import syncValidate from "../../../../../utils/syncValidate";
import {useCreateMyPlaceForm} from "../customHooks/useCreateMyPlaceForm";
import {book} from "../../../../../routes/book";
import {Prompt} from "react-router";

//Styles
import {Button, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import FormDivider from "../../../../../components/FormDivider";
import TimeFieldRow from "../../../components/TimeFieldRow";
import RenderInputGooglePlacesId from "./formFields/RenderInputGooglePlacesID";
import RenderInputStreetAddress from "./formFields/RenderInputStreetAddress";
import RenderInputBusinessName from "./formFields/RenderInputBusinessName";
import RenderInputBusinessType from "./formFields/RenderInputBusinessType";
import RenderInputPhoneNumber from "./formFields/RenderInputPhoneNumber";
import RenderInputDescription from "./formFields/RenderInputDescription";
import RenderInputZipCode from "./formFields/RenderInputZipCode";
import RenderInputCountry from "./formFields/RenderInputCountry";
import RenderInputWebsite from "./formFields/RenderInputWebsite";
import RenderInputPhotos from "./formFields/RenderInputPhotos";
import RenderInputCity from "./formFields/RenderInputCity";
import RenderInputDocs from "./formFields/RenderInputDocs";
import RenderInputTags from "./formFields/RenderInputTags";
import AdditionalSettings from "../../../components/AdditionalSettings";

const useStyles = makeStyles(styles);

const CreateMyPlaceForm: React.FC<InjectedFormProps> = ({handleSubmit}) => {
  const classes = useStyles();

  const {
    canGo,
    pristine,
    dirty,
    valid,
    goPreview,
    allowDraft,
    isVerified,
    onSubmit,
    getGooglePlace
  } = useCreateMyPlaceForm();

  return (
    <form onSubmit={handleSubmit}>
      <Prompt
        when={(dirty || !pristine) && !canGo}
        message={(location) => {
          return location.pathname === book.myPlacePreview
            ? true
            : 'All your changes will not be saved. Continue?';
        }}
      />
      <Grid
        container
        spacing={3}>
        <Field
          name={'name'}
          component={RenderInputBusinessName}
        />
        <FormDivider/>
        <Field
          getGooglePlace={getGooglePlace}
          name={'googlePlaceUnique'}
          component={RenderInputGooglePlacesId}
        />
        <Field
          name={'zip'}
          component={RenderInputZipCode}
        />
        <Field
          name={'countryId'}
          component={RenderInputCountry}
        />
        <Field
          name={'city'}
          component={RenderInputCity}
        />
        <Field
          name={'address'}
          component={RenderInputStreetAddress}
        />
        <FormDivider/>
        <Field
          name={'businessTypeId'}
          component={RenderInputBusinessType}
        />
        <Field
          name={'phone'}
          component={RenderInputPhoneNumber}
        />
        <Field
          name={'website'}
          component={RenderInputWebsite}
        />
        <TimeFieldRow
          onlyStar
          labelText={'Business time'}
        />
        <Field
          name={'description'}
          component={RenderInputDescription}
        />
        <FormDivider/>
        <Field
          name={'photos'}
          component={RenderInputPhotos}
        />
        <FormDivider/>
        <Field
          name={'documents'}
          component={RenderInputDocs}
        />
        <FormDivider/>
        <AdditionalSettings/>
        <FormDivider/>
        <Field
          name={'tags'}
          component={RenderInputTags}
        />
      </Grid>
      <div className={classes.buttonWrapper}>
        <Button
          onClick={goPreview}
          variant="outlined"
          color="primary">
          Preview
        </Button>
        <Button
          onClick={() => onSubmit(false )}
          disabled={!allowDraft}
          variant="outlined"
          color="primary">
          Save as Draft
        </Button>
        <Button
          onClick={handleSubmit(values => onSubmit(true, values))}
          type={'submit'}
          disabled={!valid || !isVerified}
          variant="contained"
          color="primary">
          Publish
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'CreateMyPlaceForm',
  validate: syncValidate(schema),
  touchOnChange: true,
  enableReinitialize: true
})(CreateMyPlaceForm);
