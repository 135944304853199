import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import InputField from "../../../../../../components/InputField";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  account_personal_data_page_render_input_contact_name_label: {
    id: "account_personal_data_page_render_input_contact_name_label",
    defaultMessage: "Contact name"
  }
});

const RenderInputContactName = ({...props}:WrappedFieldProps) => {
  const {formatMessage} = useIntl();

  return (
    <FieldRow
      labelText={formatMessage(msg.account_personal_data_page_render_input_contact_name_label)}
      isRequired={true}>
      <InputField
        {...props}
        type={'text'}
      />
    </FieldRow>
  );
};

export default RenderInputContactName;
