export const types = {
  FETCH_VOUCHER: '@VOUCHERS/FETCH_VOUCHER',
  FILL_VOUCHER: '@VOUCHERS/FILL_VOUCHER',
  FETCH_VOUCHERS: '@VOUCHERS/FETCH_VOUCHERS',
  FILL_VOUCHERS: '@VOUCHERS/FILL_VOUCHERS',
  CREATE_PREVIEW: '@VOUCHERS/CREATE_PREVIEW',
  FILL_PREVIEW: '@VOUCHERS/FILL_PREVIEW',
  CLEAR_PREVIEW: '@VOUCHERS/CLEAR_PREVIEW',
  CREATE_VOUCHER: '@VOUCHERS/CREATE_VOUCHER',
  REMOVE_VOUCHER_COVER: '@VOUCHERS/REMOVE_VOUCHER_COVER',
  SAVE_VOUCHER_COVER: '@VOUCHERS/SAVE_VOUCHER_COVER',
  SAVE_VOUCHER_TAGS: '@VOUCHERS/SAVE_VOUCHER_TAGS',
  UPDATE_VOUCHER: '@VOUCHERS/UPDATE_VOUCHER',
  REMOVE_VOUCHER: '@VOUCHERS/REMOVE_VOUCHER',
  CLEAR: '@VOUCHERS/CLEAR'
};