import {createStyles} from "@material-ui/core/styles";
import {GroupiTheme} from "../../customTheme";

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    padding: '4px 8px',
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 11,
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '0.06em',
    textTransform: 'uppercase',
    color: theme.palette.common.blue50,
    background: theme.palette.common.blue95,
    borderRadius: 4,
    display: 'inline-block'
  }
});