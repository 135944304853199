import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const Logo = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 159 59">
      <path
        d="M75.9835 20.3006H80.6791V29.3231C79.4735 30.2338 78.0775 30.9327 76.4911 31.4198C74.9048 31.907 73.3078 32.1505 71.7003 32.1505C69.3948 32.1505 67.322 31.6634 65.4818 30.6891C63.6417 29.6937 62.1928 28.3276 61.1352 26.5909C60.0988 24.833 59.5806 22.8527 59.5806 20.65C59.5806 18.4474 60.0988 16.4777 61.1352 14.7409C62.1928 12.983 63.6522 11.617 65.5136 10.6427C67.3749 9.64727 69.4689 9.14955 71.7955 9.14955C73.7414 9.14955 75.5076 9.47784 77.0939 10.1344C78.6803 10.791 80.0128 11.744 81.0915 12.9936L77.7919 16.0435C76.2056 14.3703 74.2914 13.5337 72.0493 13.5337C70.6322 13.5337 69.3737 13.8302 68.2738 14.4233C67.1739 15.0163 66.3173 15.8529 65.7039 16.933C65.0905 18.0132 64.7838 19.2522 64.7838 20.65C64.7838 22.0267 65.0905 23.2551 65.7039 24.3353C66.3173 25.4154 67.1634 26.2626 68.2421 26.8768C69.342 27.4698 70.5899 27.7664 71.9859 27.7664C73.4665 27.7664 74.799 27.4487 75.9835 26.8133V20.3006Z"
      />
      <path
        d="M87.9282 16.933C88.5205 16.107 89.3136 15.4822 90.3077 15.0586C91.323 14.6351 92.4863 14.4233 93.7977 14.4233V18.998C93.2478 18.9557 92.8776 18.9345 92.6873 18.9345C91.2701 18.9345 90.1597 19.3369 89.3559 20.1417C88.5522 20.9254 88.1503 22.1114 88.1503 23.6999V31.7693H83.2009V14.6774H87.9282V16.933Z"
      />
      <path
        d="M102.804 32.0234C101.006 32.0234 99.3883 31.6528 97.95 30.9115C96.5329 30.1491 95.4224 29.1007 94.6187 27.7664C93.8149 26.432 93.413 24.9177 93.413 23.2233C93.413 21.529 93.8149 20.0147 94.6187 18.6803C95.4224 17.346 96.5329 16.3082 97.95 15.5669C99.3883 14.8045 101.006 14.4233 102.804 14.4233C104.602 14.4233 106.21 14.8045 107.627 15.5669C109.044 16.3082 110.154 17.346 110.958 18.6803C111.762 20.0147 112.164 21.529 112.164 23.2233C112.164 24.9177 111.762 26.432 110.958 27.7664C110.154 29.1007 109.044 30.1491 107.627 30.9115C106.21 31.6528 104.602 32.0234 102.804 32.0234ZM102.804 27.957C104.073 27.957 105.11 27.5334 105.913 26.6862C106.738 25.8178 107.151 24.6636 107.151 23.2233C107.151 21.7831 106.738 20.6394 105.913 19.7923C105.11 18.9239 104.073 18.4897 102.804 18.4897C101.535 18.4897 100.488 18.9239 99.6633 19.7923C98.8383 20.6394 98.4259 21.7831 98.4259 23.2233C98.4259 24.6636 98.8383 25.8178 99.6633 26.6862C100.488 27.5334 101.535 27.957 102.804 27.957Z"
      />
      <path
        d="M130.764 14.6774V31.7693H126.069V29.7361C125.413 30.4773 124.631 31.0492 123.721 31.4516C122.812 31.8328 121.828 32.0234 120.77 32.0234C118.528 32.0234 116.752 31.3775 115.44 30.0855C114.129 28.7936 113.473 26.8768 113.473 24.3353V14.6774H118.423V23.6046C118.423 26.3579 119.575 27.7346 121.881 27.7346C123.065 27.7346 124.017 27.3534 124.736 26.5909C125.455 25.8073 125.815 24.653 125.815 23.128V14.6774H130.764Z"
      />
      <path
        d="M143.535 14.4233C145.122 14.4233 146.56 14.7939 147.85 15.5352C149.162 16.2553 150.187 17.2825 150.928 18.6168C151.668 19.9299 152.038 21.4654 152.038 23.2233C152.038 24.9812 151.668 26.5274 150.928 27.8617C150.187 29.1748 149.162 30.202 147.85 30.9433C146.56 31.6634 145.122 32.0234 143.535 32.0234C141.357 32.0234 139.643 31.3351 138.396 29.9584V37.9325H133.446V14.6774H138.173V16.6471C139.4 15.1645 141.188 14.4233 143.535 14.4233ZM142.679 27.957C143.948 27.957 144.984 27.5334 145.788 26.6862C146.613 25.8178 147.025 24.6636 147.025 23.2233C147.025 21.7831 146.613 20.6394 145.788 19.7923C144.984 18.9239 143.948 18.4897 142.679 18.4897C141.41 18.4897 140.363 18.9239 139.538 19.7923C138.734 20.6394 138.332 21.7831 138.332 23.2233C138.332 24.6636 138.734 25.8178 139.538 26.6862C140.363 27.5334 141.41 27.957 142.679 27.957Z"
      />
      <path
        d="M153.448 14.6774H158.397V31.7693H153.448V14.6774ZM155.922 12.2947C155.013 12.2947 154.273 12.03 153.702 11.5005C153.131 10.971 152.845 10.3144 152.845 9.53078C152.845 8.74714 153.131 8.09058 153.702 7.56109C154.273 7.0316 155.013 6.76686 155.922 6.76686C156.832 6.76686 157.572 7.02101 158.143 7.52932C158.714 8.03763 159 8.67302 159 9.43548C159 10.2615 158.714 10.9498 158.143 11.5005C157.572 12.03 156.832 12.2947 155.922 12.2947Z"
      />
      <path
        d="M12.575 15.8292C14.3608 15.8292 15.8085 14.3796 15.8085 12.5914C15.8085 10.8032 14.3608 9.35356 12.575 9.35356C10.7891 9.35356 9.34146 10.8032 9.34146 12.5914C9.34146 14.3796 10.7891 15.8292 12.575 15.8292Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5747 25.183C19.5196 25.183 25.1495 19.5456 25.1495 12.5915C25.1495 5.6374 19.5196 0 12.5747 0C5.6299 0 0 5.6374 0 12.5915C0 19.5456 5.6299 25.183 12.5747 25.183ZM12.5747 20.1464C16.7416 20.1464 20.1196 16.7639 20.1196 12.5915C20.1196 8.41903 16.7416 5.0366 12.5747 5.0366C8.40784 5.0366 5.0299 8.41903 5.0299 12.5915C5.0299 16.7639 8.40784 20.1464 12.5747 20.1464Z"
      />
      <path
        d="M41.6764 12.5914C41.6764 14.3796 40.2288 15.8292 38.4429 15.8292C36.6571 15.8292 35.2094 14.3796 35.2094 12.5914C35.2094 10.8032 36.6571 9.35356 38.4429 9.35356C40.2288 9.35356 41.6764 10.8032 41.6764 12.5914Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.4434 45.3293C38.4434 45.3293 51.0181 19.5456 51.0181 12.5915C51.0181 5.6374 45.3882 0 38.4434 0C31.4986 0 25.8687 5.6374 25.8687 12.5915C25.8687 19.5456 38.4434 45.3293 38.4434 45.3293ZM38.4434 20.1464C42.6103 20.1464 45.9882 16.7639 45.9882 12.5915C45.9882 8.41903 42.6103 5.03659 38.4434 5.03659C34.2765 5.03659 30.8986 8.41903 30.8986 12.5915C30.8986 16.7639 34.2765 20.1464 38.4434 20.1464Z"
      />
      <path
        d="M27.6207 56.1219C24.1222 56.1114 20.7417 54.8545 18.0842 52.5762L16.2148 54.7624C19.3908 57.4853 23.431 58.9874 27.6121 58.9999C31.7932 59.0124 35.8422 57.5344 39.0344 54.8304L37.1781 52.6331C34.5071 54.8956 31.1191 56.1323 27.6207 56.1219Z"
      />
    </SvgIcon>
  );
};

export default Logo;