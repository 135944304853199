import React from 'react';
import InputField from "../../../../../components/InputField";
import {WrappedFieldProps} from "redux-form";

const RenderInputRecommendedGuests = ({...props}: WrappedFieldProps) => {
  return (
    <InputField
      {...props}
      width={'calc((100% - 30px) / 2)'}
      type={'number'}
      placeholder={'Recommended'}
    />
  );
};

export default RenderInputRecommendedGuests;
