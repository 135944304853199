import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  phoneWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  nameWrapper: {
    marginLeft: 80,
    width: 'calc(100% - 140px)',
    [breakpoints.down(600)]: {
      marginLeft: 40,
      width: 'calc(100% - 100px)'
    }
  },
  secondPartPhone: {
    width: 'calc(100% - 120px)'
  },
  firstPartPhone: {
    width: 100,
    '& .MuiOutlinedInput-input': {
      textAlign: 'end!important'
    }
  }
});