//Core
import React from 'react';

//Helpers
import {useEditVoucher} from "./customHooks/useEditVoucher";

//Styles
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import VoucherEditForm from "./form/VoucherEditForm";
import ButtonBackBlue from "../../../../components/ButtonBackBlue";

const useStyles = makeStyles(styles);

const EditVoucher = () => {
  const {initialValues} = useEditVoucher();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ButtonBackBlue/>
      {initialValues
        ? (
          <VoucherEditForm initialValues={initialValues}/>
        )
        : (
          <Typography variant={'body2'}>
            Voucher does not exist!
          </Typography>
        )}
    </div>
  );
};

export default EditVoucher;
