import React from 'react';
import FieldRow from "../../../../../../components/FieldRow";
import {WrappedFieldProps} from "redux-form";
import DocumentsDragNDropInput from "../../../../../../components/DocumentsDragNDropInput";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  my_place_form_render_input_docs_label: {
    id: "my_place_form_render_input_docs_label",
    defaultMessage: "Required Business Document"
  },
  my_place_form_render_input_docs_tool_tip: {
    id: "my_place_form_render_input_docs_tool_tip",
    defaultMessage: "Upload business documents required for verification by Group@t Team. Format of document is PDF, JPEG,JPG,PNG. Max size 10 Mb per file. "
  }
});

const RenderInputDocs = ({input}:WrappedFieldProps & any) => {
  const {formatMessage} = useIntl();

  return (
    <FieldRow
      onlyStar
      labelText={formatMessage(msg.my_place_form_render_input_docs_label)}
      toolTipText={formatMessage(msg.my_place_form_render_input_docs_tool_tip)}>
      <DocumentsDragNDropInput input={input}/>
    </FieldRow>
  );
};

export default RenderInputDocs;
