//Core
import React from 'react';

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import AccordionBox from "../AccordionBox";
import {book} from "../../routes/book";

const useStyles = makeStyles(styles);

const SidebarBottom = () => {
  const classes = useStyles();
  const sideBarContent = [
    {
      name: 'termsOfServices ',
      label: 'Terms of Services ',
      link: book.termsAndConditions.forSite,
      error: false
    },
    {
      name: 'faq',
      label: 'FAQ',
      link: book.faq,
      error: false
    },
    {
      name: 'contact',
      label: 'Contact',
      link: book.contact.forSite,
      error: false
    }
  ];

  return (
    <div
      className={classes.root}>
      <AccordionBox
        data={sideBarContent}
        expand={true}
        disabled={false}
      />
    </div>
  );
};

export default SidebarBottom;
