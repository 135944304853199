//Core
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

//Helpers
import {getPlace} from "../../../../../bus/business/selectors";
import {getVoucherPreview} from "../../../../../bus/vouchers/selectors";
import {getUsePeriod} from "../../../../../bus/options/selectors";

export const useCreateVoucher = () => {
  const place = useSelector(getPlace);
  const usePeriod = useSelector(getUsePeriod);
  const voucherPreview = useSelector(getVoucherPreview);

  const [initialValues, setInitialValues] = useState<any>({
    dateStart: new Date(),
    dateEnd: null,
    availability: 'everyday',
    tags: [],
    timeEnd: place?.openedTimeEnd,
    timeStart: place?.openedTimeStart
  });

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      timeEnd: place?.openedTimeEnd,
      timeStart: place?.openedTimeStart
    });
  }, [place]);

  useEffect(() => {
    if (usePeriod) {
      setInitialValues({
        ...initialValues,
        usePeriod: usePeriod[0].value
      });
    }
  }, [usePeriod]);

  useEffect(() => {
    voucherPreview && setInitialValues(voucherPreview);
  }, [voucherPreview]);

  return {
    initialValues
  };
};
