//Core
import React from 'react';
import {AccordionSummary, useTheme} from "@material-ui/core";

//Styles
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {GroupiTheme} from "../../customTheme";
import {styles} from "./styles";

//Components
import ArrowDown from "../Icons/ArrowDown";
import IconHolder from "../IconHolder";

const useStyles = makeStyles(styles);

type AccordionTitleProps = {
  children: string,
  toggle: () => void
}

const AccordionTitle: React.FC<AccordionTitleProps> = ({children, toggle}) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const lowerCaseChildren = children.toLowerCase();
  const ariaControls = lowerCaseChildren + '-content';
  const identifier = lowerCaseChildren + '-header';

  return (
    <AccordionSummary
      onClick={toggle}
      expandIcon={(
        <IconHolder
          size={16}
          color={theme.palette.common.gray75}>
          <ArrowDown/>
        </IconHolder>
      )}
      aria-controls={ariaControls}
      id={identifier}>
      <span className={classes.line}/>
      <Typography variant={'subtitle1'}>
        {children}
      </Typography>
    </AccordionSummary>
  );
};

export default AccordionTitle;