import React from 'react';
import {SvgIconProps} from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";

const EmailLatter = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 156 149">
      <path
        d="M140 57.0864H16V128.913H140V57.0864Z"
        fill="url(#paint0_linear)"
      />
      <g filter="url(#filter0_dd)">
        <path
          d="M140 56.8873L78.8513 12L16 56.8873H140Z"
          fill="url(#paint1_linear)"
        />
      </g>
      <g filter="url(#filter1_d)">
        <path
          d="M140 57.0869L78.8513 101.974L16 57.0869H140Z"
          fill="url(#paint2_linear)"
        />
      </g>
      <g filter="url(#filter2_dd)">
        <rect
          x="27"
          y="28"
          width="102"
          height="88"
          fill="#5CADFF"
        />
      </g>
      <path
        d="M52.704 54.8563H56.3407V62.0938C55.407 62.8243 54.3258 63.385 53.0971 63.7757C51.8685 64.1665 50.6317 64.3619 49.3867 64.3619C47.6011 64.3619 45.9957 63.9711 44.5705 63.1896C43.1453 62.3911 42.0231 61.2953 41.2041 59.9021C40.4014 58.492 40 56.9035 40 55.1366C40 53.3697 40.4014 51.7897 41.2041 50.3965C42.0231 48.9864 43.1535 47.8906 44.5951 47.1091C46.0366 46.3106 47.6584 45.9113 49.4604 45.9113C50.9675 45.9113 52.3354 46.1747 53.564 46.7013C54.7926 47.228 55.8247 47.9925 56.6601 48.9949L54.1046 51.4414C52.876 50.0992 51.3934 49.4281 49.657 49.4281C48.5594 49.4281 47.5847 49.666 46.7329 50.1417C45.881 50.6174 45.2176 51.2885 44.7425 52.1549C44.2674 53.0214 44.0299 54.0153 44.0299 55.1366C44.0299 56.2409 44.2674 57.2263 44.7425 58.0928C45.2176 58.9592 45.8728 59.6388 46.7083 60.1315C47.5601 60.6072 48.5267 60.8451 49.6078 60.8451C50.7546 60.8451 51.7866 60.5902 52.704 60.0805V54.8563Z"
        fill="white"
      />
      <path
        d="M61.9551 52.1549C62.4138 51.4924 63.0281 50.9912 63.7981 50.6514C64.5844 50.3116 65.4854 50.1417 66.501 50.1417V53.8114C66.0751 53.7774 65.7884 53.7604 65.641 53.7604C64.5434 53.7604 63.6834 54.0832 63.0609 54.7288C62.4384 55.3575 62.1271 56.3089 62.1271 57.5831V64.0561H58.2938V50.3456H61.9551V52.1549Z"
        fill="white"
      />
      <path
        d="M73.4766 64.2599C72.0841 64.2599 70.8309 63.9626 69.717 63.368C68.6194 62.7564 67.7594 61.9154 67.1369 60.8451C66.5144 59.7747 66.2031 58.56 66.2031 57.2008C66.2031 55.8417 66.5144 54.6269 67.1369 53.5566C67.7594 52.4862 68.6194 51.6538 69.717 51.0591C70.8309 50.4475 72.0841 50.1417 73.4766 50.1417C74.869 50.1417 76.114 50.4475 77.2116 51.0591C78.3091 51.6538 79.1692 52.4862 79.7917 53.5566C80.4142 54.6269 80.7254 55.8417 80.7254 57.2008C80.7254 58.56 80.4142 59.7747 79.7917 60.8451C79.1692 61.9154 78.3091 62.7564 77.2116 63.368C76.114 63.9626 74.869 64.2599 73.4766 64.2599ZM73.4766 60.998C74.4595 60.998 75.2622 60.6582 75.8847 59.9786C76.5235 59.282 76.843 58.3561 76.843 57.2008C76.843 56.0455 76.5235 55.1281 75.8847 54.4485C75.2622 53.752 74.4595 53.4037 73.4766 53.4037C72.4937 53.4037 71.6828 53.752 71.0439 54.4485C70.405 55.1281 70.0856 56.0455 70.0856 57.2008C70.0856 58.3561 70.405 59.282 71.0439 59.9786C71.6828 60.6582 72.4937 60.998 73.4766 60.998Z"
        fill="white"
      />
      <path
        d="M95.1316 50.3456V64.0561H91.4949V62.4251C90.9871 63.0197 90.381 63.4784 89.6765 63.8012C88.9721 64.107 88.2104 64.2599 87.3913 64.2599C85.6548 64.2599 84.2788 63.7418 83.2631 62.7054C82.2475 61.669 81.7396 60.1315 81.7396 58.0928V50.3456H85.5729V57.5066C85.5729 59.7153 86.4657 60.8196 88.2513 60.8196C89.1687 60.8196 89.9059 60.5138 90.4629 59.9021C91.0198 59.2735 91.2983 58.3476 91.2983 57.1244V50.3456H95.1316Z"
        fill="white"
      />
      <path
        d="M105.023 50.1417C106.251 50.1417 107.365 50.439 108.364 51.0336C109.38 51.6113 110.175 52.4353 110.748 53.5056C111.321 54.559 111.608 55.7907 111.608 57.2008C111.608 58.6109 111.321 59.8512 110.748 60.9215C110.175 61.9749 109.38 62.7988 108.364 63.3935C107.365 63.9711 106.251 64.2599 105.023 64.2599C103.335 64.2599 102.008 63.7078 101.042 62.6035V69H97.2086V50.3456H100.87V51.9256C101.82 50.7363 103.204 50.1417 105.023 50.1417ZM104.359 60.998C105.342 60.998 106.145 60.6582 106.767 59.9786C107.406 59.282 107.726 58.3561 107.726 57.2008C107.726 56.0455 107.406 55.1281 106.767 54.4485C106.145 53.752 105.342 53.4037 104.359 53.4037C103.376 53.4037 102.565 53.752 101.927 54.4485C101.304 55.1281 100.993 56.0455 100.993 57.2008C100.993 58.3561 101.304 59.282 101.927 59.9786C102.565 60.6582 103.376 60.998 104.359 60.998Z"
        fill="white"
      />
      <path
        d="M112.7 50.3456H116.533V64.0561H112.7V50.3456ZM114.616 48.4343C113.912 48.4343 113.339 48.2219 112.896 47.7971C112.454 47.3724 112.233 46.8457 112.233 46.2171C112.233 45.5885 112.454 45.0618 112.896 44.6371C113.339 44.2124 113.912 44 114.616 44C115.321 44 115.894 44.2039 116.337 44.6116C116.779 45.0194 117 45.5291 117 46.1407C117 46.8033 116.779 47.3554 116.337 47.7971C115.894 48.2219 115.321 48.4343 114.616 48.4343Z"
        fill="white"
      />
      <g filter="url(#filter3_dd)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79 129H140V57L78.1328 101.374L16 56.5V129H77H79Z"
          fill="url(#paint3_linear)"
        />
      </g>
      <g filter="url(#filter4_dd)">
        <path
          d="M140 128.907L78.8513 84.0195L16 128.907H140Z"
          fill="url(#paint4_linear)"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd"
          x="0"
          y="0"
          width="156"
          height="76.8873"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="8"/>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0236111 0 0 0 0 0.0495833 0 0 0 0 0.141667 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset/>
          <feGaussianBlur stdDeviation="1"/>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0183333 0 0 0 0 0.0833333 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d"
          x="12"
          y="57.0869"
          width="132"
          height="52.887"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd"
          x="11"
          y="16"
          width="134"
          height="120"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="8"/>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0236111 0 0 0 0 0.0495833 0 0 0 0 0.141667 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset/>
          <feGaussianBlur stdDeviation="1"/>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0183333 0 0 0 0 0.0833333 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_dd"
          x="0"
          y="44.5"
          width="156"
          height="104.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="8"/>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0236111 0 0 0 0 0.0495833 0 0 0 0 0.141667 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset/>
          <feGaussianBlur stdDeviation="1"/>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0183333 0 0 0 0 0.0833333 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_dd"
          x="0"
          y="72.0195"
          width="156"
          height="76.8873"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="8"/>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0236111 0 0 0 0 0.0495833 0 0 0 0 0.141667 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset/>
          <feGaussianBlur stdDeviation="1"/>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0183333 0 0 0 0 0.0833333 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="78"
          y1="57.0864"
          x2="78"
          y2="128.913"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop
            offset="1"
            stopColor="#BBCAFF"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="78"
          y1="12"
          x2="78"
          y2="56.8873"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop
            offset="1"
            stopColor="#BBCAFF"
          />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="16"
          y1="57.0869"
          x2="44.7325"
          y2="136.46"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B54CFF"/>
          <stop
            offset="1"
            stopColor="#2B00FF"
          />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="108.5"
          y1="57"
          x2="108.5"
          y2="129"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop
            offset="1"
            stopColor="#BBCAFF"
          />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="78"
          y1="84.0195"
          x2="78"
          y2="128.907"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop
            offset="1"
            stopColor="#BBCAFF"
          />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default EmailLatter;
