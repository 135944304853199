//Core
import React from 'react';

//Helpers
import {Offer} from "../../../../../../bus/offers/typedefs";

//Styles
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(styles);

type RenderTagsProps = {
  data: Offer
}

const RenderTags: React.FC<RenderTagsProps> = ({data}) => {
  const classes = useStyles();

  if (!data?.tags.length) {
    return <></>;
  }

  return (
    <div className={classes.tagsWrapper}>
      {
        data.tags.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={classes.singleTag}>
              <Typography variant={'body2'}>
                {item}
              </Typography>
            </div>
          );
        })
      }
    </div>
  );
};

export default RenderTags;
