import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const Hand = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 80 80">
      <path
        d="M59.6748 17.628C60.5377 17.628 61.2373 16.9285 61.2373 16.0655C61.2373 15.2026 60.5377 14.5031 59.6748 14.5031C58.8119 14.5031 58.1123 15.2026 58.1123 16.0655C58.1123 16.9285 58.8119 17.628 59.6748 17.628Z"
      />
      <path
        d="M12.8174 64.6295C13.6803 64.6295 14.3799 63.93 14.3799 63.067C14.3799 62.2041 13.6803 61.5045 12.8174 61.5045C11.9544 61.5045 11.2549 62.2041 11.2549 63.067C11.2549 63.93 11.9544 64.6295 12.8174 64.6295Z"
      />
      <path
        d="M70.2652 4.3431C67.3689 1.4867 63.5332 -0.0586113 59.4611 0.0017011C55.5449 0.0562322 51.8474 1.61889 49.0499 4.40169C46.2524 7.18481 44.6708 10.874 44.5965 14.7898C44.5138 19.1462 46.3163 23.3242 49.5419 26.2518C50.7551 27.3531 51.4508 28.932 51.4508 30.584V32.5785C51.4508 33.874 52.0579 35.0298 53.0016 35.7785V40.324C53.0016 42.4429 54.7254 44.1668 56.8444 44.1668H62.5068C64.6258 44.1668 66.3497 42.4429 66.3497 40.324V35.7783C67.2933 35.0294 67.9003 33.8738 67.9003 32.5784V30.5838C67.9003 28.9365 68.6046 27.3499 69.8324 26.2309C72.9625 23.3781 74.7577 19.3145 74.7577 15.0821C74.7577 11.0139 73.1622 7.19997 70.2652 4.3431ZM63.2247 40.3238C63.2247 40.7196 62.9025 41.0416 62.5068 41.0416H56.8444C56.4486 41.0416 56.1266 40.7196 56.1266 40.3238V36.6629H63.2247V40.3238ZM67.7274 23.9212C65.8514 25.6309 64.7753 28.0593 64.7753 30.5838V32.5784C64.7753 33.1076 64.3449 33.538 63.8157 33.538H61.2382V22.2415C61.2382 21.3787 60.5385 20.679 59.6757 20.679C58.8129 20.679 58.1132 21.3787 58.1132 22.2415V33.538H55.5355C55.0063 33.538 54.5758 33.1076 54.5758 32.5784V30.5838C54.5758 28.0524 53.5065 25.6301 51.6422 23.9379C49.0846 21.6163 47.6552 18.3035 47.7208 14.849C47.8418 8.47403 53.128 3.21513 59.5047 3.12638C59.5622 3.1256 59.6191 3.12513 59.6768 3.12513C62.8403 3.12513 65.8158 4.34404 68.0711 6.56809C70.3678 8.83309 71.6328 11.8568 71.6328 15.0821C71.6328 18.4374 70.2094 21.6592 67.7274 23.9212Z"
      />
      <path
        d="M79.2892 52.1721C77.9305 49.0952 74.3222 47.6969 71.2446 49.0555C71.2367 49.059 71.2289 49.0626 71.2214 49.066L61.0632 53.7505C60.2794 54.1119 59.9372 55.0402 60.2985 55.8238C60.6597 56.6074 61.5882 56.9499 62.3718 56.5885L72.5172 51.9099C74.0158 51.2552 75.7695 51.9372 76.4306 53.4346C77.0933 54.9354 76.4114 56.6958 74.9105 57.3583L54.1227 66.5367C50.8305 67.9905 47.0944 67.9058 43.8718 66.3044L36.2624 62.5239C32.2054 60.5088 27.5025 60.4018 23.3587 62.2317L23.2161 62.2946L17.5354 49.4285L25.4237 45.9457C29.1834 44.2855 33.3785 44.1519 37.2357 45.5683L52.5379 51.1877C53.9299 51.699 54.6719 53.2565 54.1919 54.6597C53.7027 56.0893 52.1398 56.8586 50.7087 56.3746L38.8756 52.3722C38.0579 52.0955 37.1713 52.5343 36.8948 53.3518C36.6182 54.1693 37.0568 55.056 37.8743 55.3325L49.7074 59.3349C50.328 59.5449 50.9598 59.6444 51.5819 59.6444C54.0257 59.6444 56.3158 58.106 57.1488 55.6711C58.1743 52.6738 56.589 49.3466 53.6152 48.2544L38.3131 42.6351C33.6913 40.9377 28.6656 41.0983 24.1617 43.0869L16.2242 46.5915C15.1861 45.0063 13.1195 44.3548 11.3298 45.1447L1.20016 49.6174C0.635474 49.8666 0.270475 50.425 0.268756 51.0422L0.191413 78.4331C0.18985 78.9767 0.470787 79.4819 0.933286 79.7672C1.18407 79.9219 1.4686 80 1.75391 80C1.99485 80 2.23641 79.9444 2.45844 79.8322L22.2748 69.8216C24.062 69.008 24.9562 67.013 24.4411 65.1697L24.6206 65.0905C27.9123 63.6367 31.6487 63.7216 34.8717 65.3227L42.481 69.103C46.5374 71.1186 51.2408 71.225 55.3846 69.3955L76.1724 60.2172C79.2499 58.8583 80.6478 55.2496 79.2892 52.1721ZM20.9711 66.9814C20.9464 66.9924 20.9218 67.0041 20.8976 67.0163L3.32359 75.8941L3.34266 69.0964L7.91062 66.8533C8.6853 66.4728 9.00483 65.5366 8.62452 64.7621C8.24405 63.9874 7.30765 63.6677 6.53328 64.0481L3.35234 65.6102L3.39031 52.0663L12.5914 48.0036C13.0065 47.821 13.4923 48.009 13.6753 48.4235L20.9872 64.9839C20.9876 64.9847 20.9879 64.9856 20.9884 64.9864L21.3909 65.898C21.5739 66.3124 21.3856 66.7985 20.9711 66.9814Z"
      />
    </SvgIcon>
  );
};

export default Hand;