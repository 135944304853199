import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  singleDocument: {
    height: 44,
    boxShadow: '0px 1px 4px rgba(50, 77, 103, 0.25)',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    marginBottom: 10,
    width: '100%',
    boxSizing: 'border-box',
    [breakpoints.down(768)]: {
      height: 56
    },
    [breakpoints.down(475)]: {
      paddingLeft: 16
    }
  },
  name: {
    marginLeft: 20,
    maxWidth: 'calc(100% - 260px)',
    width: 'auto',
    [breakpoints.down(475)]: {
      maxWidth: 'calc(100% - 170px)'
    },
    '& p': {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },
  uploaded: {
    color: theme.palette.common.black
  },
  attention: {
    color: theme.palette.common.gray60
  },
  singleDocumentStatus: {
    marginRight: 0,
    marginLeft: 'auto'
  },
  remove: {
    width: 63,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 0,
    marginLeft: 15,
    borderColor: theme.palette.common.gray95,
    borderLeft: '1px solid',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.common.gray95
    },
    '& .MuiButton-fullWidth': {
      height: '100%',
      border: 'none'
    },
    '& .MuiButton-startIcon': {
      margin: 0
    },
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: 24
    }
  }
});