//Core
import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {WrappedFieldProps} from "redux-form";

//Helpers
import {getDates} from "../../utils/helpers";

//Styles
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";

const useStyles = makeStyles(styles);

const InputEndDatePicker: React.FC<WrappedFieldProps & any> = (props) => {
  const classes = useStyles();
  const {width} = useWindowDimensions();
  const [portal, setPortal] = useState(false);

  const {input, start} = props;

  useEffect(() => {
    setPortal(width < 600);
  }, [width]);

  useEffect(() => {
    + input.value <= + start && input.onChange(getDates(start).nextDay);
  }, [start]);

  return (
    <Box className={classes.root}>
      <DatePicker
        {...input}
        withPortal={portal}
        autoComplete={'off'}
        selectsEnd
        minDate={getDates().nextDay}
        maxDate={getDates(start).ninetyDaysAfterToday}
        dateFormat="dd.MM.yyyy"
        selected={input.value}
        onBlur={(v: any) => v.defaultPrevented}
        onChange={(date: Date) => input.onChange(date)}
        startDate={start}
        endDate={input.value}
      />
    </Box>
  );
};

export default InputEndDatePicker;
