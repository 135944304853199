import {GroupiTheme} from "../../../../customTheme";
import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  container: {
    marginTop: 20,
    width: '100%'
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 40,
    [breakpoints.down(425)]: {}
  },
  tableHeader: {
    width: '100%',
    display: 'flex',
    borderBottomColor: theme.palette.common.gray88,
    borderBottom: '1px solid',
    paddingBottom: 16,
    [breakpoints.down(425)]: {
      paddingBottom: 0
    }
  },
  tableBody: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cell: {
    textAlign: 'center',
    width: '100%',
    maxWidth: '13.4%',
    '&:first-child': {
      textAlign: 'left',
      width: '100%',
      maxWidth: '31.1%'
    },
    '&:last-child': {
      width: '100%',
      maxWidth: '28.7%'
    },
    [breakpoints.down(1600)]: {
      maxWidth: '15%',
      '&:first-child': {
        maxWidth: '45%'
      },
      '&:last-child': {
        maxWidth: '10%'
      }
    },
    [breakpoints.down(1024)]: {
      display: 'none'
    }
  },
  emptyList: {
    paddingTop: 20,
    color: theme.palette.common.gray75
  }
});
