//Core
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";

//Helpers
import {getPlace} from "../../../../bus/business/selectors";
import {getOffers, getOffersMeta} from "../../../../bus/offers/selectors";
import {offersActions} from "../../../../bus/offers/actions";
import {ListStatus} from "../../../../setup/typedefs";
import {Offer} from "../../../../bus/offers/typedefs";

export const useOffersLists = (paginationPath: string, status: ListStatus) => {
  const {page} = useParams<{page: string}>();
  const dispatch = useDispatch();

  const place = useSelector(getPlace);
  const meta = useSelector(getOffersMeta);
  const offers = useSelector(getOffers);

  const [offersList, setOffersList] = useState<Offer[]>([]);

  useEffect(() => {
    if (place) {
      dispatch(offersActions.fetchOffers({
        placeID: place.id,
        page: Number(page),
        perPage: 3,
        status: status
      }));
    }
  }, [dispatch, page, place]);

  useEffect(() => {
    offers.length
      ? setOffersList(offers)
      : setOffersList([]);
  }, [offers]);

  return {
    list: offersList,
    meta
  };
};