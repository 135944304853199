import React from 'react';
import {Box, Divider, Grid} from "@material-ui/core";

const FormDivider = () => {
  return (
    <Grid
      item
      xs={12}>
      <Box
        mt={0.9}
        mb={1}>
        <Divider/>
      </Box>
    </Grid>
  );
};

export default FormDivider;