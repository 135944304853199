import {call, select} from "@redux-saga/core/effects";
import {PasswordFormValues} from "../../../../containers/AccountContainer/pages/Password/form/typedefs";
import {RootState} from "../../../../setup/typedefs";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {types} from "../../types";
import {fetchWithToken} from "../../../utils/fetching";

export function* changePassword({payload: body}: {type: typeof types.CHANGE_PASSWORD, payload: Partial<PasswordFormValues>}) {
  try {
    const {token: tokenAccess} = yield select((state: RootState) => state.auth);
    const {id} = yield select((state: RootState) => state.profile);

    const response = yield call(fetchWithToken, {
      endpoint: `merchants/${id}/change-password`,
      method: 'PUT',
      tokenAccess: tokenAccess,
      body
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    yield call(handleMsg, 'Your password was change');

  } catch (e) {
    console.log(e);
  }
}
