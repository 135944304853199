import {call} from "redux-saga/effects";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {fetchFileWithToken} from "../../../utils/fetching";

export function* updateOfferCover({formData, offerID, actionWithCover: action}: any){
  try {
    if (action !== 'nothing') {
      const messageText = action === 'save' ? 'New Cover added' : 'Cover removed';

      const response = yield call(fetchFileWithToken, {
        endpoint: `offers/${offerID}/cover`,
        method: 'PUT',
        type: 'singleCover',
        body: action === 'save' ? formData.cover : {removeCover: true}
      });

      if (response.status !== 200) {
        yield call(handleErrors, response);
        throw new Error(response);
      }

      yield call(handleMsg, messageText);
    }
  } catch (e) {
    console.log(e);
  }
}