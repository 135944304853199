import React, {useEffect, useState} from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import {Field} from "redux-form";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import RequiredAsterisk from "../../../../components/RequiredAsterisk";
import InputEndDatePicker from "../../../../components/InputDateTimePickers/EndDatePicker";
import InputStartDatePicker from "../../../../components/InputDateTimePickers/StartDatePicker";
import {Nullable} from "../../../../setup/typedefs";
import ToolTip from "../../../../components/ToolTip";

const useStyles = makeStyles(styles);

interface DateFieldRowTypes {
  labelText: string,
  isRequired?: boolean,
  onlyStar?: boolean,
  initialValues?: any,
  disabled?: boolean,
  toolTipText?: string
}

const DateFieldRow: React.FC<DateFieldRowTypes> = ({...props}) => {
  const classes = useStyles();
  const {
    labelText,
    isRequired = false,
    onlyStar = false,
    initialValues,
    disabled = false,
    toolTipText
  } = props;

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Nullable<Date>>(null);

  useEffect(() => {
    setStartDate(initialValues.dateStart ? initialValues.dateStart : new Date());
    setEndDate(initialValues.dateEnd ? initialValues.dateEnd : null);
  },[initialValues]);

  return (
    <Grid
      item
      xs={12}>
      <Box className={classes.wrapper}>
        <Box className={classes.label}>
          {!!labelText &&(
            <Typography variant={"subtitle2"}>
              {labelText}
              {isRequired || onlyStar ? <RequiredAsterisk/> : null}
            </Typography>
          )}
          {!!toolTipText &&(
            <Box className={classes.tooltip}>
              <ToolTip>
                {toolTipText}
              </ToolTip>
            </Box>
          )}
        </Box>
        <Box className={classes.fields}>
          <Field
            name={'dateStart'}
            disabled={disabled}
            endDate={endDate}
            startDate={startDate}
            setStartDate={setStartDate}
            component={InputStartDatePicker}
          />
          <div className={classes.divide}/>
          <Field
            name={'dateEnd'}
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            component={InputEndDatePicker}
            normalize={(value: string) => value ? value : null}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default DateFieldRow;
