import {call, put} from 'redux-saga/effects';
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors} from "../../../../utils/handleErrors";
import {offersActions} from "../../actions";

export function* fetchReservations(offerID: number){
  try {
    const response = yield call(fetchWithToken, {
      endpoint: `offers/${offerID}/reservations`,
      method: 'GET'
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    const {data} = yield call([response, 'json']);
    yield put(offersActions.fillOfferReservations(data));

  } catch (e) {
    console.log(e);
  }
}
