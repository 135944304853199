import React from 'react';
import SvgIcon, {SvgIconProps} from "@material-ui/core/SvgIcon";
import {GroupiTheme} from "../../../customTheme";
import {useTheme} from "@material-ui/core";

const Calendar = (props: SvgIconProps) => {
  const theme: GroupiTheme = useTheme();

  return (
    <SvgIcon
      htmlColor={theme.palette.common.blue50}
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4C16 3.44772 16.4477 3 17 3C17.5523 3 18 3.44772 18 4V5C19.6569 5 21 6.34315 21 8V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V8C3 6.34315 4.34315 5 6 5V4C6 3.44772 6.44772 3 7 3C7.55228 3 8 3.44772 8 4V5H16V4ZM5 10V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V10H5Z"
      />
    </SvgIcon>
  );
};

export default Calendar;