import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {book} from "../../routes/book";
import PageContainer from "./index";
import OffersRouter from "../OffersContainer/OffersRouter";
import VouchersRouter from "../VouchersContainer/VouchersRouter";
import BusinessRouter from "../BusinessContainer/BusinessRouter";
import MarketingRouter from "../MarketingContainer/MarketingRouter";
import FAQ from "../AdditionalContainer/pages/FAQ";
import ContactPage from "../AccountContainer/pages/ContactPage";
import AccountTabs from "../AccountContainer/pages/AccountTabs";

const PageRouter = () => {
  return (
    <PageContainer>
      <Switch>
        <Route path={book.faq}>
          <FAQ/>
        </Route>
        <Route path={book.contact.forApp}>
          <ContactPage fromApp={true}/>
        </Route>
        <Route path={book.contact.forSite}>
          <ContactPage/>
        </Route>
        <Route path={book.personal}>
          <AccountTabs/>
        </Route>
        <Route path={book.deals + `/:page`}>
          <OffersRouter/>
        </Route>
        <Route path={book.vouchers + `/:page`}>
          <VouchersRouter/>
        </Route>
        <Route path={book.business + `/:page`}>
          <BusinessRouter/>
        </Route>
        <Route path={book.marketing}>
          <MarketingRouter/>
        </Route>
        <Redirect to={book.myPlace}/>
      </Switch>
    </PageContainer>
  );
};

export default PageRouter;