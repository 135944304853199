import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import InputField from "../../../../../../components/InputField";

const RenderInputDescribe = ({...props}: WrappedFieldProps) => {
  return (
    <FieldRow
      labelText={'Describe your issue'}
      isRequired={true}>
      <InputField
        {...props}
        type={'text'}
        placeholder={'Type your message here'}
        multiline
        limit={1000}
        rows={5}
      />
    </FieldRow>
  );
};

export default RenderInputDescribe;
