//Core
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//Helpers
import {authSelector} from "../../../../../bus/auth/selectors";
import {SignUpFormValues} from "../form/typedefs";
import {book} from "../../../../../routes/book";
import {authActions} from "../../../../../bus/auth/actions";

export const useSignUp = () => {
  const dispatch = useDispatch();

  const {termsAgree} = useSelector(authSelector);
  const [iAgree, setIAgree] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({
    iAgree: iAgree,
    setIAgree
  });

  useEffect(() => {
    setIAgree(termsAgree);
  }, [termsAgree]);

  useEffect(() => {
    setInitialValues({
      iAgree: iAgree,
      setIAgree
    });
  }, [iAgree]);

  const onSubmit = (values: SignUpFormValues) => {
    const {email, password} = values;

    dispatch(authActions.signUp({
      email: email,
      password: password,
      redirectUrl: window.location.origin + book.verifyEmail
    }));
  };

  return {
    initialValues,
    onSubmit
  };
};
