import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {types as authTypes} from "../../../../bus/auth/types";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  useEffect(() => {
    if (token){
      dispatch({
        type: authTypes.VERIFY_EMAIL,
        token: token
      });
    }
  },[token, dispatch]);

  return (
    <></>
  );
};

export default VerifyEmail;