//Core
import React, {useState} from 'react';
import {Field} from "redux-form";
import {useSelector} from "react-redux";

//Helpers
import {createVoucherFormSelector} from "../../../../../../bus/forms/selectors";
import {Nullable} from "../../../../../../setup/typedefs";

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {createMuiTheme, createStyles, Grid} from "@material-ui/core";

//Components
import DummyInput from "../components/DummyInput";
import OriginalPrice from "../components/OriginalPrice";
import DiscountPrice from "../components/DiscountPrice";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

const useStyles = makeStyles(() => createStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [breakpoints.down(768)]: {
      flexDirection: 'column'
    }
  }
}));

const RenderPriceFieldsRow = () => {
  const classes = useStyles();
  const formValue = useSelector(createVoucherFormSelector);
  const [percent, setPercent] = useState<Nullable<number>>(null);
  const commission = 12 / 100;

  if (!formValue) {
    return <></>;
  }

  return (
    <Grid
      className={classes.wrapper}
      item
      xs={12}>
      <Field
        label={'Original price'}
        name={'originalPrice'}
        component={OriginalPrice}
      />
      <Field
        percent={percent}
        label={'Discount price'}
        name={'discountPrice'}
        component={DiscountPrice}
      />
      <Field
        commission={commission}
        voucherDiscountPrice={formValue.discountPrice}
        voucherOriginalPrice={formValue.originalPrice}
        setPercent={setPercent}
        toolTipText={'Group@t Marketplace commission is 12%'}
        label={'You will get in your bank'}
        name={'inYouBank'}
        component={DummyInput}
      />
    </Grid>
  );
};

export default RenderPriceFieldsRow;
