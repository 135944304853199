import React from 'react';
import {SvgIconProps} from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";

const PaperAirplane = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 100 92">
      <path
        d="M17.4071 45.0933L28.545 31.6738L79.6781 47.7295L29.6533 41.2104L17.4071 45.0933Z"
        fill="#5CADFF"
      />
      <g filter="url(#filter0_dd)">
        <path
          d="M79.6781 47.7295L17.4071 45.0933L29.6533 41.2105L79.6781 47.7295Z"
          fill="url(#paint0_linear)"
        />
      </g>
      <g filter="url(#filter1_dd)">
        <path
          d="M31.1574 65.6694L29.6488 41.2096L79.6766 47.7291L28.5465 31.6738L23.2574 12.2002L79.6766 47.7291L31.1574 65.6694Z"
          fill="url(#paint1_linear)"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd"
          x="1.4071"
          y="27.7061"
          width="94.271"
          height="45.4107"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="8"/>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0236111 0 0 0 0 0.0495833 0 0 0 0 0.141667 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset/>
          <feGaussianBlur stdDeviation="1"/>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0183333 0 0 0 0 0.0833333 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd"
          x="0.53772"
          y="0.200195"
          width="98.7494"
          height="91.3869"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="8"/>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0236111 0 0 0 0 0.0495833 0 0 0 0 0.141667 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset/>
          <feGaussianBlur stdDeviation="1"/>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0183333 0 0 0 0 0.0833333 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="48.8936"
          y1="43.7178"
          x2="48.1916"
          y2="49.1051"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop
            offset="1"
            stopColor="#BBCAFF"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="79.9996"
          y1="47.9559"
          x2="29.4996"
          y2="39.9559"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop
            offset="1"
            stopColor="#BBCAFF"
          />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default PaperAirplane;
