import React from 'react';
import {WrappedFieldProps} from "redux-form";
import {Box} from "@material-ui/core";
import InputField from "../../../../../../components/InputField";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  sign_in_render_input_password_placeholder: {
    id: "sign_in_render_input_password_placeholder",
    defaultMessage: "Password"
  }
});

const RenderInputPassword = ({...props}:WrappedFieldProps) => {
  const {formatMessage} = useIntl();

  return (
    <Box width={'100%'}>
      <InputField
        {...props}
        type={'password'}
        placeholder={formatMessage(msg.sign_in_render_input_password_placeholder)}
      />
    </Box>
  );
};

export default RenderInputPassword;
