//Core
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

//Helpers
import {getPlacePreview} from "../../../../../bus/business/selectors";

export const useRenderMyPlacePreview = () => {
  const placePreview = useSelector(getPlacePreview);

  const [data, setData] = useState<any>();
  const randomImg = Math.floor(Math.random() * Math.floor(3));

  useEffect(() => {
    placePreview && setData(placePreview);
  }, [placePreview]);

  return {
    data,
    randomImg
  };
};
