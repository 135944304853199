import {createStyles} from "@material-ui/core";

export const styles = () => createStyles({
  phoneWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  secondPartPhone: {
    width: 'calc(100% - 120px)'
  },
  firstPartPhone: {
    width: 100,
    '& .MuiOutlinedInput-input': {
      textAlign: 'end!important'
    }
  }
});
