//Core
import React, {useEffect} from 'react';

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Styles
import CreateMyPlaceForm from "./form/CreateMyPlaceForm";
import {push} from "connected-react-router";
import {book} from "../../../../routes/book";
import {useDispatch, useSelector} from "react-redux";
import {getPlace} from "../../../../bus/business/selectors";
import {isSaleManager} from "../../../../bus/profile/selectors";

const useStyles = makeStyles(styles);

const initialValues = {
  openedTimeStart: '00:00',
  openedTimeEnd: '23:59',
  countryId: 1,
  businessTypeId: 1,
  recommendedGuests: '5',
  maxGuests: '20',
  promotePlace: true,
  documents: [],
  photos: [],
  tags: []
};

const CreateMyPlace = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const place = useSelector(getPlace);
  const isSale = useSelector(isSaleManager)

  useEffect(() => {
    if (place?.id && !isSale) {
      dispatch(push(book.myPlace));
    }
  }, [place]);

  return (
    <div className={classes.container}>
      {initialValues && (<CreateMyPlaceForm initialValues={initialValues}/>)}
    </div>
  );
};

export default CreateMyPlace;
