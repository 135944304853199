import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const GoogleMapPinSecond = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 6 10">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 10C3 10 6 7 6 3C6 1.34315 4.65685 0 3 0C1.34315 0 0 1.34315 0 3C0 7 3 10 3 10ZM3 4.5C3.82843 4.5 4.5 3.82843 4.5 3C4.5 2.17157 3.82843 1.5 3 1.5C2.17157 1.5 1.5 2.17157 1.5 3C1.5 3.82843 2.17157 4.5 3 4.5Z"
        fill={props.fill}
      />
    </SvgIcon>
  );
};

export default GoogleMapPinSecond;
