import React from 'react';
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import PersonalForm from "./form/PersonalForm";
import {usePersonal} from "./customHooks/usePersonal";

const useStyles = makeStyles(styles);

const Personal = () => {
  const classes = useStyles();
  const {data, onSubmit} = usePersonal();

  return (
    <div className={classes.root}>
      <PersonalForm
        onSubmit={onSubmit}
        initialValues={data}
      />
    </div>
  );
};

export default Personal;
