import React from 'react';
import {WrappedFieldProps} from "redux-form";
import {MenuItem, Select} from "@material-ui/core";
import FieldRow from "../../../../../../components/FieldRow";
import DropDownArrow from "../../../../../../components/DropDownArrow";
import {useSelector} from "react-redux";
import {getUsePeriod} from "../../../../../../bus/options/selectors";

const RenderInputUsePeriod = ({input, toolTipText, labelText, isRequired}: WrappedFieldProps & any) => {
  const usePeriod = useSelector(getUsePeriod);

  const usePeriodJSX = usePeriod && usePeriod.map((item: any, index: number) => {
    return (
      <MenuItem
        key={index}
        value={item.value}>
        {item.name}
      </MenuItem>
    );
  });

  return (
    <FieldRow
      isRequired={isRequired}
      toolTipText={toolTipText}
      labelText={labelText}>
      <Select
        IconComponent={DropDownArrow}
        fullWidth
        {...input}
        variant={"outlined"}
        labelId="label"
        id="select">
        {usePeriodJSX}
      </Select>
    </FieldRow>
  );
};

export default RenderInputUsePeriod;
