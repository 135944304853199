import React from 'react';
import TypographyCustom from "../TypographyCustom";
import {Box, Button, useTheme} from "@material-ui/core";
import {GroupiTheme} from "../../customTheme";
import ArrowLeft from "../Icons/ArrowLeft";
import IconHolder from "../IconHolder";
import {useHistory} from "react-router-dom";

const ButtonBackBlue = () => {
  const theme: GroupiTheme = useTheme();
  const history = useHistory();

  const handledBackClick = () => {
    history.goBack();
  };

  return (
    <Button
      style={{height: 'auto', marginBottom: 40, width: 'unset'}}
      onClick={handledBackClick}>
      <Box
        ml={-0.7}
        display={'flex'}
        alignItems={'center'}>
        <IconHolder
          size={16}
          color={theme.palette.common.blue50}>
          <ArrowLeft/>
        </IconHolder>
        <TypographyCustom
          variant={'button1'}
          color={theme.palette.common.blue50}>
          back
        </TypographyCustom>
      </Box>
    </Button>
  );
};

export default ButtonBackBlue;