import { all, call, takeEvery } from 'redux-saga/effects';
import {
  fetchOffer,
  removeOffer,
  updateOffer,
  fetchOffers,
  createPreview
} from "./workers";
import {types} from "../types";

function * watchFetchOffer() {
  yield takeEvery(types.FETCH_OFFER, fetchOffer);
}

function * watchUpdateOffer() {
  yield takeEvery(types.UPDATE_OFFER, updateOffer);
}

function * watchRemoveOffer() {
  yield takeEvery(types.REMOVE_OFFER, removeOffer);
}

function * watchFetchOffers() {
  yield takeEvery(types.FETCH_OFFERS, fetchOffers);
}

function * watchCreatePreview() {
  yield takeEvery(types.CREATE_PREVIEW, createPreview);
}

export function* watchOffers(){
  yield all([
    call(watchCreatePreview),
    call(watchFetchOffer),
    call(watchUpdateOffer),
    call(watchRemoveOffer),
    call(watchFetchOffers)
  ]);
}