import React from 'react';
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import SingleQuestion from "./components/SingleQuestion";
import {questions} from "./questions";
import ButtonBackBlue from "../../../../components/ButtonBackBlue";

const useStyles = makeStyles(styles);

const FAQ = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ButtonBackBlue/>
      <Typography
        className={classes.title}
        variant={'h2'}>
        Frequently asked questions
      </Typography>
      {questions.length && (
        questions.map((item, index) => {
          return (
            <SingleQuestion
              key={index}
              question={item.question}
              answer={item.answer}
            />
          );
        })
      )}
    </div>
  );
};

export default FAQ;
