import {call, put} from "redux-saga/effects";
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors} from "../../../../utils/handleErrors";
import {notificationsActions} from "../../actions";
import {Workers} from "../../../../setup/typedefs";

export function* disableNotifications({payload}: Workers){
  const {notificationID, merchantID} = payload;

  try {
    const response = yield call(fetchWithToken, {
      endpoint: `merchants/${merchantID}/notifications/${notificationID}/disable`,
      method: 'PUT'
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    yield put(notificationsActions.fetchNotifications({merchantID}));

  } catch (e) {
    console.log(e);
  }
}