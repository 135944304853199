import {createStyles} from "@material-ui/core";

export const styles = () => createStyles({
  button: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    right: 0,
    zIndex: 10,
    '& .MuiTypography-body2': {
      marginTop: 8
    }
  }
});