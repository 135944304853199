import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';
import {GroupiTheme} from "../../../customTheme";
import {useTheme} from "@material-ui/core";

const Clock = (props: SvgIconProps) => {
  const theme: GroupiTheme = useTheme();

  return (
    <SvgIcon
      htmlColor={theme.palette.common.blue50}
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      />
      <path
        d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 12 6 12 6C12 6 13 11.4477 13 12Z"
      />
      <path
        d="M12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 16 12 16 12C16 12 12.5523 13 12 13Z"
      />
    </SvgIcon>
  );
};

export default Clock;
