import React from 'react';
import {Button, Grid} from "@material-ui/core";
import {Field, InjectedFormProps, isValid, reduxForm} from "redux-form";
import syncValidate from "../../../../utils/syncValidate";
import schema from "./schema";
import FormDivider from "../../../../components/FormDivider";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import RenderInputReassignEmail from "./formFields/RenderInputReassignEmail";
import {useDispatch, useSelector} from "react-redux";
import {placesActions} from "../../../../bus/business/actions";
import {profileID} from "../../../../bus/profile/selectors";
import {book} from "../../../../routes/book";

const useStyles = makeStyles(styles);

const ReassignPlaceForm: React.FC<InjectedFormProps> = ({handleSubmit}) => {
  const classes = useStyles();
  const form = 'ReassignPlaceForm';
  const dispatch = useDispatch();
  const valid = useSelector(isValid(form));
  const profileId = useSelector(profileID);

  //save form
  const onSubmit = (values: any) => {
    profileId && dispatch(placesActions.reassignPlace({
      mail: values.email,
      placeId: values.id,
      userId: profileId,
      redirectUrl: window.location.origin + book.changePassword
    }));
  };

  return (
    <form className={classes.mainWrapper} onSubmit={handleSubmit}>
      <Grid
        container
        spacing={3}>
        <FormDivider/>
        <Field
          placeholder={'Enter email for reassign'}
          name={'email'}
          component={RenderInputReassignEmail}
        />
      </Grid>
      <div className={classes.buttonWrapper}>
        <Button
          onClick={handleSubmit(values => onSubmit(values))}
          disabled={!valid}
          variant="contained"
          color="primary">
          Reassign Place
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'ReassignPlaceForm',
  validate: syncValidate(schema),
  touchOnChange: true,
  enableReinitialize: true
})(ReassignPlaceForm);