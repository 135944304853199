import React from 'react';
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

const useStyles = makeStyles(styles);

const TableHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.tableHeader}>
      <div className={classes.cell}>
        <Typography variant={'subtitle2'}>
          Deals
        </Typography>
      </div>
      <div className={classes.cell}>
        <Typography variant={'subtitle2'}>
          Views
        </Typography>
      </div>
      <div className={classes.cell}>
        <Typography variant={'subtitle2'}>
          Added to favorite
        </Typography>
      </div>
      <div className={classes.cell}>
        <Typography variant={'subtitle2'}>
          Reservations
        </Typography>
      </div>
      <div className={classes.cell}>
        <Typography variant={'subtitle2'}>
          Actions
        </Typography>
      </div>
    </div>
  );
};

export default TableHeader;