//Core
import React from 'react';

//Helpers
import {formatDate} from "../../../../../../utils/helpers";
import {Voucher} from "../../../../../../bus/vouchers/typedefs";

//Styles
import {Box, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

const useStyles = makeStyles(styles);

type RenderPeriodProps = {
  data: Voucher
}

const RenderPeriod: React.FC<RenderPeriodProps> = ({data}) => {
  const classes = useStyles();

  if (!data.dateStart && !data.dateEnd) {
    return <></>;
  }

  return (
    <div className={classes.subMainInfoBlock}>
      <Typography variant={'h3'}>
        Voucher period
      </Typography>
      <Box className={classes.fields}>
        <TextField
          value={formatDate(data.dateStart)}
          disabled={true}
          type={'text'}
          fullWidth
          variant={'outlined'}
        />
        {
          !!data.dateEnd && (
            <>
              <div className={classes.divide}/>
              <TextField
                value={formatDate(data.dateEnd)}
                disabled={true}
                type={'text'}
                fullWidth
                variant={'outlined'}
              />
            </>
          )
        }
      </Box>
    </div>
  );
};

export default RenderPeriod;