import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import InputField from "../../../../../../components/InputField";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  my_place_form_render_input_description_label: {
    id: "my_place_form_render_input_description_label",
    defaultMessage: "Short description"
  },
  my_place_form_render_input_description_placeholder: {
    id: "my_place_form_render_input_description_placeholder",
    defaultMessage: "Write a few words about your business..."
  }
});

const RenderInputDescription = ({...props}:WrappedFieldProps) => {
  const {formatMessage} = useIntl();

  return (
    <FieldRow labelText={formatMessage(msg.my_place_form_render_input_description_label)}>
      <InputField
        {...props}
        type={'text'}
        placeholder={formatMessage(msg.my_place_form_render_input_description_placeholder)}
        multiline
        rows={4}
      />
    </FieldRow>
  );
};

export default RenderInputDescription;
