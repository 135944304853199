import React from 'react';
import {IconButton, Typography, useTheme} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {PopupProps} from "../../index";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import IconHolder from "../../../IconHolder";
import CrossInCircle from "../../../Icons/CrossInCircle";
import {GroupiTheme} from "../../../../customTheme";
import {uiActions} from "../../../../bus/ui/actions";
import {useDispatch} from "react-redux";
import {types} from "../../../../bus/auth/types";

const useStyles = makeStyles(styles);

const PopupAreYouSure: React.FC<PopupProps> = (props) => {
  const {onClose, popupData} = props;
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const dispatch = useDispatch();

  const confirm = (confirm='none') => {
    if (confirm === 'Logout') {
      dispatch({
        type: types.LOGOUT
      });
    }
    dispatch(uiActions.confirm(true));
    onClose();
  };

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.closeCross}
        onClick={onClose}
        color="inherit"
        size="small">
        <IconHolder
          size={24}
          color={theme.palette.common.gray88}>
          <CrossInCircle/>
        </IconHolder>
      </IconButton>
      {!!popupData?.payload?.title && (
        <Typography variant={'h3'}>
          {popupData.payload.title}
        </Typography>
      )}
      {!!popupData?.payload?.description && (
        <div className={classes.content}>
          <Typography variant={'body2'}>
            {popupData.payload.description}
          </Typography>
        </div>
      )}
      <div className={classes.action}>
        <Button
          onClick={onClose}
          className={classes.sendButton}
          variant="contained"
          color="primary">
          {
            popupData?.payload?.cancel
              ? popupData?.payload?.cancel
              : (
                <>
                  no
                </>
              )
          }
        </Button>
        <Button
          onClick={() => confirm(popupData?.payload?.confirm)}
          className={classes.sendButton}
          variant="outlined"
          color="primary">
          {
            popupData?.payload?.confirm
              ? popupData?.payload?.confirm
              : (
                <>
                  yes
                </>
              )
          }
        </Button>
      </div>
    </div>
  );
};

export default PopupAreYouSure;
