import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {book} from "../../routes/book";
import ActiveVouchers from "./pages/ListOfVouchers/ActiveVouchers";
import PendingVouchers from "./pages/ListOfVouchers/PendingVouchers";
import RejectedVouchers from "./pages/ListOfVouchers/RejectedVouchers";
import DraftVouchers from "./pages/ListOfVouchers/DraftVouchers";
import CreateVoucher from "./pages/CreateVoucher";
import EditVoucher from "./pages/EditVoucher";
import SingleVoucher from "./pages/SingleVoucher";
import PreviewVoucher from "./pages/PreviewVoucher";
import ExpiredVouchers from "./pages/ListOfVouchers/ExpiredVouchers";
import RecreateVoucher from "./pages/RecreateVoucher";

const VouchersRouter = () => {
  return (
    <Switch>
      <Route path={book.activeVouchers + `/:page`}>
        <ActiveVouchers/>
      </Route>
      <Route path={book.expiredVouchers + `/:page`}>
        <ExpiredVouchers/>
      </Route>
      <Route path={book.pendingVouchers + `/:page`}>
        <PendingVouchers/>
      </Route>
      <Route path={book.rejectedVouchers + `/:page`}>
        <RejectedVouchers/>
      </Route>
      <Route path={book.draftVouchers + `/:page`}>
        <DraftVouchers/>
      </Route>
      <Route path={book.recreateVoucher + `/:id`}>
        <RecreateVoucher/>
      </Route>
      <Route path={book.createVoucher}>
        <CreateVoucher/>
      </Route>
      <Route path={book.editVoucher + `/:id`}>
        <EditVoucher/>
      </Route>
      <Route path={book.singleVoucher + `/:id`}>
        <SingleVoucher/>
      </Route>
      <Route path={book.previewVoucher}>
        <PreviewVoucher/>
      </Route>
      <Redirect to={book.activeVouchers + `/1`}/>
    </Switch>
  );
};

export default VouchersRouter;
