import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  description: {
    marginTop: 8,
    paddingRight: 10,
    '& .MuiTypography-body2': {
      letterSpacing: 0
    },
    [breakpoints.down(992)]: {
      paddingRight: 0
    }
  }
});
