import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const CheckMarkInNotification = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 36 36">
      <path
        d="M17.5 3C8.93421 3 2 9.93408 2 18.5C2 27.0659 8.93421 34 17.5 34C26.0658 34 33 27.0659 33 18.5C33 9.93408 26.0658 3 17.5 3ZM17.5 31.2805C10.4298 31.2805 4.7193 25.5699 4.7193 18.4997C4.7193 11.4295 10.4298 5.71918 17.5 5.71918C24.5702 5.71918 30.2807 11.4298 30.2807 18.5C30.2807 25.5702 24.5702 31.2805 17.5 31.2805Z"
      />
      <path
        d="M23.3291 13.8071L15.8997 21.2455L12.3952 17.7367C11.8345 17.1754 10.9935 17.1754 10.4327 17.7367C9.87203 18.2981 9.87203 19.1402 10.4327 19.7016L14.9184 24.1929C15.1988 24.4736 15.6193 24.6141 15.8997 24.6141C16.18 24.6141 16.6006 24.4736 16.8809 24.1929L25.2916 15.7719C25.8523 15.2105 25.8523 14.3685 25.2916 13.8071C24.7309 13.2457 23.8898 13.2457 23.3291 13.8071Z"
      />
    </SvgIcon>
  );
};

export default CheckMarkInNotification;
