import React from 'react';
import RemoveButton from "./RemoveButton";
import EditButton from "./EditButton";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import FinishButton from "./FinishButton";
import RecreateButton from "./RecreateButton";
import CancelButton from "./CancelButton";
import {ListStatus} from "../../../../../../setup/typedefs";

const useStyles = makeStyles(styles);

type ControlButtonsProps = {
  status: ListStatus,
  id: number,
  name: string
}

const ControlButtons: React.FC<ControlButtonsProps> = ({status, id, name}) => {
  const classes = useStyles();

  if (status === 'active') {
    return (
      <div className={classes.root}>
        <FinishButton
          id={id}
          name={name}
        />
      </div>
    );
  }

  if (status === 'expired') {
    return (
      <div className={classes.root}>
        <RecreateButton/>
      </div>
    );
  }

  if (status === 'pending') {
    return (
      <div className={classes.root}>
        <CancelButton
          id={id}
          name={name}
        />
      </div>
    );
  }

  if (status === 'rejected' || status === 'draft') {
    return (
      <div className={classes.root}>
        <RemoveButton id={id}/>
        <EditButton id={id}/>
      </div>
    );
  }

  return (
    <></>
  );
};

export default ControlButtons;