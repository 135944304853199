import {call, select} from "redux-saga/effects";
import {refreshToken} from "../auth/saga/workers";
import {FetchData, RootState} from "../../setup/typedefs";
import {API} from "../../setup/Api";

export function* fetchWithDisposableToken(payload: FetchData){
  const {id, endpoint: endpointPart} = payload;
  const {token: tokenAccess} = yield select((state: RootState) => state.auth);
  const endpoint = `merchants/${id}/${endpointPart}`;

  return yield call(API.fetchWithRefresh, {tokenAccess, ...payload, endpoint});
}

export function* fetchWithToken(payload: FetchData){
  yield call(refreshToken);
  const {token: tokenAccess} = yield select((state: RootState) => state.auth);

  return yield call(API.fetchWithRefresh, {tokenAccess, ...payload});
}

export function* fetchFileWithToken(payload: FetchData){
  yield call(refreshToken);
  const {token: tokenAccess} = yield select((state: RootState) => state.auth);

  return yield call(API.fetchFileWithRefresh, {tokenAccess, ...payload});
}

export function* fetchWithoutToken(dataForFetching: any){
  return yield call(API.fetchWithoutRefresh, dataForFetching);
}

export function* fetchUpdateToken(refreshToken: string) {
  const tokenObject = {refreshToken: refreshToken};

  return yield call(API.fetchWithoutRefresh, {
    endpoint: 'merchants/auth/refresh-token',
    method: 'POST',
    body: tokenObject
  });
}
