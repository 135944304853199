import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {book} from "../../routes/book";
import Marketing from "./pages/Marketing";

const MarketingRouter = () => {
  return (
    <Switch>
      <Route path={book.marketing}>
        <Marketing/>
      </Route>
      <Redirect to={book.marketing}/>
    </Switch>
  );
};

export default MarketingRouter;
