import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {styles} from "./styles";
import {WrappedFieldProps} from "redux-form";
import clsx from "clsx";
import SingleImage from "./SingleImage";
import DropFrame from "./DropFrame";

const useStyles = makeStyles(styles);

const ImagesDragNDropInput: React.FC<WrappedFieldProps & any> = (props) => {
  const classes = useStyles();
  const {
    input,
    singleImage = false,
    disabled = false,
    disabledForEdit = false
  } = props;

  //10mb
  const maxSize = 10485760;
  const files = input.value;
  const [showDropZone, setShowDropZone] = useState(true);

  const removeFile = (index: number) => {
    const result = files.filter((file: any) => file.index !== index);
    input.onChange(result);
  };

  useEffect(() => {
    setShowDropZone(!(files?.length && singleImage) && files.length < 9 && !disabledForEdit);
  }, [files, singleImage, disabledForEdit]);

  return (
    <div
      className={
        clsx({
          [classes.disabled]: disabled
        })
      }>
      <div className={classes.root}>
        {files?.map( (file: any, index: number) => {
          return (
            <SingleImage
              key={index}
              file={file}
              removeFile={removeFile}
              disabledForEdit={disabledForEdit}
            />
          );
        })}
        {showDropZone && (
          <DropFrame
            maxSize={maxSize}
            singleImage={singleImage}
            input={input}
            files={files}
          />
        )}
      </div>
    </div>
  );

};

export default ImagesDragNDropInput;