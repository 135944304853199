import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  container: {
    width: 744,
    margin: '0 auto',
    paddingTop: 50,
    position: 'relative',
    [breakpoints.down(784)]: {
      width: '100%',
      paddingTop: 60
    }
  }
});