import {put} from "redux-saga/effects";
import {Workers} from "../../../../setup/typedefs";
import {push} from "connected-react-router";
import {book} from "../../../../routes/book";
import {placesActions} from "../../actions";

export function* createPreview({payload}: Workers){
  yield put(placesActions.fillPreview(payload));
  yield put(push(book.myPlacePreview));
}

