import {all, call, takeEvery} from "redux-saga/effects";
import {types} from "../types";
import {fetchAllTags, fetchSettings, getAllOptions} from "./workers";

function * watchAllTags() {
  yield takeEvery(types.FETCH_ALL_TAGS, fetchAllTags);
}

function * watchSettings() {
  yield takeEvery(types.FETCH_SETTINGS, fetchSettings);
}

function * watchAllOptions() {
  yield takeEvery(types.GET_ALL_OPTIONS, getAllOptions);
}

export function* watchOptions() {
  yield all([
    call(watchAllOptions),
    call(watchAllTags),
    call(watchSettings)
  ]);
}
