//Core
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

//Helpers
import {getPlace} from "../../../../../bus/business/selectors";
import {getOfferPreview} from "../../../../../bus/offers/selectors";
import {Offer} from "../../../../../bus/offers/typedefs";

export const useRenderPreview = () => {
  const offerPreview = useSelector(getOfferPreview);
  const place = useSelector(getPlace);

  const [data, setData] = useState<Offer>();
  const [placeData, setPlaceData] = useState<any>();

  const randomImg = Math.floor(Math.random() * Math.floor(3));

  useEffect(() => {
    offerPreview && setData(offerPreview);
  }, [offerPreview]);

  useEffect(() => {
    place && setPlaceData(place);
  }, [place]);

  return {
    data,
    placeData,
    randomImg
  };
};
