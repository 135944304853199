//Core
import React, {useEffect, useState} from 'react';
import {WrappedFieldProps} from "redux-form";

//Helpers
import {Nullable} from "../../../../../../setup/typedefs";
import {Voucher} from "../../../../../../bus/vouchers/typedefs";

//Components
import FieldRow from "../../../../../../components/FieldRow";
import CoverDropInput from "../../../../components/CoverDropInput";

type RenderInputPhotosProps = {
  initialValues: Partial<Voucher>,
}

const RenderInputCover: React.FC<WrappedFieldProps & RenderInputPhotosProps> = ({input, initialValues}) => {
  const [file, setFile] = useState<Nullable<object>>();

  useEffect(() => {
    initialValues?.cover && setFile(initialValues.cover);
  },[initialValues?.cover]);

  return (
    <FieldRow labelText={'Cover'}>
      <CoverDropInput
        input={input}
        file={file}
        setFile={setFile}
      />
    </FieldRow>
  );
};

export default RenderInputCover;
