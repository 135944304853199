import React from 'react';
import {styles} from "./styles";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {GroupiTheme} from "../../../../../../customTheme";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";
import IconHolder from "../../../../../../components/IconHolder";
import ArrowDown from "../../../../../../components/Icons/ArrowDown";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles(styles);

const SingleQuestion = ({question, answer}: any) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        className={classes.accordionHead}
        expandIcon={
          <IconHolder
            size={16}
            color={theme.palette.common.black}>
            <ArrowDown/>
          </IconHolder>}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography variant={'subtitle1'}>
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionBody}>
        <Typography variant={'body1'}>
          {ReactHtmlParser(answer)}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default SingleQuestion;
