import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const Favorite = (props: SvgIconProps) => {
  const {htmlColor} = props;

  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 32 32">
      <path
        d="M16 8.36802C15.9575 8.30874 15.9134 8.24922 15.8675 8.18964C15.0328 7.10636 13.6074 6 11.3769 6C9.69345 6 8.34189 6.4444 7.31487 7.23365C6.29285 8.01907 5.67073 9.0855 5.3345 10.1983C4.67623 12.3769 5.06155 14.8937 5.86447 16.4569C6.63072 17.9487 8.15666 19.7875 9.88551 21.4776C11.6257 23.1787 13.658 24.8134 15.5039 25.8682L16 26.1518L16.4961 25.8682C18.342 24.8134 20.3743 23.1787 22.1145 21.4776C23.8433 19.7875 25.3693 17.9487 26.1355 16.4569C26.9385 14.8937 27.3238 12.3769 26.6655 10.1983C26.3293 9.0855 25.7071 8.01907 24.6851 7.23365C23.6581 6.4444 22.3066 6 20.6231 6C18.3926 6 16.9672 7.10636 16.1325 8.18964C16.0866 8.24922 16.0425 8.30874 16 8.36802Z"
        stroke={htmlColor}
        fill="none"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export default Favorite;
