import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import OfferCreateForm from "./form/OfferCreateForm";
import ButtonBackBlue from "../../../../components/ButtonBackBlue";
import {useSelector} from "react-redux";
import {getPlace} from "../../../../bus/business/selectors";
import {getDates} from "../../../../utils/helpers";

const useStyles = makeStyles(styles);

const CreateOffer = () => {
  const classes = useStyles();
  const place = useSelector(getPlace);
  const [initialValues, setInitialValues] = useState<any>(null);

  useEffect(() => {
    if (place?.openedTimeEnd && place?.openedTimeStart) {
      setInitialValues({
        dateStart: new Date(),
        dateEnd: getDates().ninetyDaysAfterToday,
        availability: 'everyday',
        timeEnd: place?.openedTimeEnd,
        timeStart: place?.openedTimeStart,
        tags: []
      });
    }
  }, [place]);

  return (
    <div className={classes.container}>
      <ButtonBackBlue/>
      {
        initialValues && (<OfferCreateForm initialValues={initialValues}/>)
      }
    </div>
  );
};

export default CreateOffer;
