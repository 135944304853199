//Core
import React, {useEffect, useState} from 'react';
import {Field} from "redux-form";

//Helpers
import {hoursToDate} from "../../../../utils/helpers";

//Styles
import {Box, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import ToolTip from "../../../../components/ToolTip";
import RequiredAsterisk from "../../../../components/RequiredAsterisk";
import InputEndTimePicker from "../../../../components/InputDateTimePickers/EndTimePicker";
import InputStartTimePicker from "../../../../components/InputDateTimePickers/StartTimePicker";
import {useSelector} from "react-redux";
import {getPlace} from "../../../../bus/business/selectors";
import {Place} from "../../../../bus/business/typedefs";

const useStyles = makeStyles(styles);

interface TimeFieldRowTypes {
  labelText: string,
  isRequired?: boolean,
  onlyStar?: boolean,
  toggleRow?: boolean,
  placeTime?: any,
  initialValues?: any,
  disabled?: boolean,
  toolTipText?: string
}

const TimeFieldRow: React.FC<TimeFieldRowTypes> = (props) => {
  const classes = useStyles();
  const {
    labelText,
    isRequired = false,
    onlyStar = false,
    toolTipText
  } = props;

  const place = useSelector(getPlace) as Place;

  const [minTime, setMinTime] = useState<Date>();
  const [maxTime, setMaxTime] = useState<Date>();

  useEffect(() => {
    place?.openedTimeStart && setMinTime(hoursToDate(place.openedTimeStart));
    place?.openedTimeEnd && setMaxTime(hoursToDate(place.openedTimeEnd));
  }, [place]);

  return (
    <Grid
      item
      xs={12}>
      <Box className={classes.wrapper}>
        <Box className={classes.label}>
          {!!labelText &&(
            <Typography variant={"subtitle2"}>
              {labelText}
              {isRequired || onlyStar ? <RequiredAsterisk/> : null}
            </Typography>
          )}
          {!!toolTipText &&(
            <Box className={classes.tooltip}>
              <ToolTip>
                {toolTipText}
              </ToolTip>
            </Box>
          )}
        </Box>
        <Box className={classes.fields}>
          <Field
            minTime={minTime}
            maxTime={maxTime}
            name={'timeStart'}
            component={InputStartTimePicker}
          />
          <div className={classes.divide}/>
          <Field
            minTime={minTime}
            maxTime={maxTime}
            name={'timeEnd'}
            component={InputEndTimePicker}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default TimeFieldRow;
