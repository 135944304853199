import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  tagsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    [breakpoints.down(992)]: {
      marginBottom: 20
    },
    [breakpoints.down(575)]: {
      marginTop: 8
    }
  },
  singleTag: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.common.gray95,
    minHeight: 32,
    minWidth: 64,
    boxSizing: 'border-box',
    padding: '6px 12px',
    borderRadius: 30,
    marginRight: 8,
    marginTop: 16,
    [breakpoints.down(575)]: {
      marginTop: 8
    }
  }
});
