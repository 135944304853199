//Core
import React from 'react';

//Helpers
import {concatTime} from "../../../../../../utils/helpers";
import {useRenderMyPlacePreview} from "../../customHooks/useRenderMyPlacePreview";

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {Typography, useTheme} from "@material-ui/core";
import {GroupiTheme} from "../../../../../../customTheme";
import {styles} from "./styles";

//Components
import HighFive from "../../../../../../components/Icons/HighFive";
import IconHolder from "../../../../../../components/IconHolder";
import Locate from "../../../../../../components/Icons/Locate";
import Clock from "../../../../../../components/Icons/Clock";
import RatePart from "../../../../../../components/RatePart";
import Rate from "../../../../../../components/Rate";
import RenderDescription from "../../../../components/RenderDescription";

const useStyles = makeStyles(styles);

const RenderMyPlacePreview = () => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const {data, randomImg} = useRenderMyPlacePreview();

  if (!data) {
    return (
      <p>
        sorry no data to render
      </p>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.imgHolder}>
        <img
          src={data.photos[0]?.preview ? data.photos[0]?.preview : `/defaultImage${randomImg}.svg`}
          alt={data.name}
        />
      </div>
      <div className={classes.bodyPlace}>
        <div className={classes.topBlockWrapper}>
          <div className={classes.blockWithName}>
            <Typography variant={'h4'}>
              {data.name ? data.name : ' '}
            </Typography>
            <IconHolder
              size={32}
              color={theme.palette.common.blue50}>
              <HighFive/>
            </IconHolder>
          </div>
          <div className={classes.blockWithRateNReviews}>
            <div className={classes.rate}>
              <Typography variant={'body2'}>
                {data.rate}
              </Typography>
              <Rate/>
            </div>
            <div className={classes.reviews}>
              <span/>
              <Typography variant={'body2'}>
                {data.reviews}
                {' '}
                reviews
              </Typography>
            </div>
          </div>
        </div>
        <RenderDescription data={data}/>
        <div className={classes.divider}/>
        <div className={classes.buttonImitateWrapper}>
          <div className={classes.buttonImitate}>
            Show the route
          </div>
          <div className={classes.buttonImitate}>
            Call for reservation
          </div>
        </div>
        <div className={classes.divider}/>
        {!!data.address &&(
          <>
            <div className={classes.innerRow}>
              <IconHolder
                size={24}
                color={theme.palette.common.blue50}>
                <Locate/>
              </IconHolder>
              <Typography variant={'body2'}>
                {data.address}
              </Typography>
              <div className={classes.decorate}>
                <p>
                  (16 km)
                </p>
              </div>
            </div>
            <div className={classes.divider}/>
          </>
        )}
        <div className={classes.innerRow}>
          <IconHolder
            size={24}
            color={theme.palette.common.blue50}>
            <Clock/>
          </IconHolder>
          <Typography variant={'body2'}>
            {concatTime(data.openedTimeStart, data.openedTimeEnd)}
          </Typography>
          <div className={classes.decorate}>
            <span>
              Opened
            </span>
          </div>
        </div>
        <div className={classes.divider}/>
        <div className={classes.offersWrapper}>
          <Typography variant={'subtitle1'}>
            Deals
          </Typography>
          {!data?.offers &&(
            <Typography variant={'body2'}>
              This place hasn't had deals yet
            </Typography>
          )}
        </div>
        <div className={classes.vouchersWrapper}>
          <Typography variant={'subtitle1'}>
            Vouchers
          </Typography>
          {!data?.vouchers &&(
            <Typography variant={'body2'}>
              This place hasn't had vouchers yet
            </Typography>
          )}
        </div>
        <RatePart>
          Have you been there? Rate this place.
        </RatePart>
        <div className={classes.testimonialsWrapper}>
          <Typography variant={'subtitle1'}>
            Testimonials
          </Typography>
          {!data?.testimonials &&(
            <Typography variant={'body2'}>
              This place hasn't had vouchers yet
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default RenderMyPlacePreview;