export const book = {
  //privat
  playground: '/playground',
  styleGuide: '/playground/style-guide',
  welcome: '/welcome',
  business: '/business',
  myPlace: '/business/my-place',
  myPlacePreview: '/business/my-place/preview',
  createMyPlace: '/business/create-place',
  myRevenue: '/business/my-revenue',
  account: '/account',
  personal: '/account/personal',
  password: '/account/password',
  subscriptions: '/account/subscriptions',
  mySubscriptions: '/account/my-subscriptions',
  deals: '/deals',
  activeDeals: '/deals/active',
  expiredDeals: '/deals/expired',
  pendingDeals: '/deals/pending',
  rejectedDeals: '/deals/rejected',
  draftDeals: '/deals/draft',
  singleDeal: '/deals/current-deal',
  createDeals: '/deals/create',
  recreateDeal: '/deals/recreate',
  editDeal: '/deals/current-deal/edit',
  previewDeals: '/deals/preview',
  vouchers: '/vouchers',
  activeVouchers: '/vouchers/active',
  expiredVouchers: '/vouchers/expired',
  pendingVouchers: '/vouchers/pending',
  rejectedVouchers: '/vouchers/rejected',
  draftVouchers: '/vouchers/draft',
  singleVoucher: '/vouchers/current-voucher',
  createVoucher: '/vouchers/create',
  recreateVoucher: '/vouchers/recreate',
  editVoucher: '/vouchers/current-voucher/edit',
  previewVoucher: '/vouchers/preview',
  marketing: '/marketing',

  //public
  signIn: '/sign-in',
  signUp: '/sign-up',
  thankYouPage: '/thank-you-page',
  home: '/',
  resetPassword: '/reset-password',
  changePassword: '/change-password',

  //common
  verifyEmail: '/verify-email',
  faq: '/faq',
  help: '/help',
  contact: {
    forSite: '/contact-us',
    forApp: '/contact'
  },
  termsAndConditions: {
    forSite: '/terms-and-conditions',
    forApp: '/terms-and-conditions-app'
  },
  privacy: {
    forSite: '/privacy-policy',
    forApp: '/privacy-policy-app'
  }
};
