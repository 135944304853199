import React from 'react';
import SvgIcon, {SvgIconProps} from "@material-ui/core/SvgIcon";

const Star = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 32 32">
      <path
        d="M4.61438 14.715L4.61438 14.715L9.21877 19.2032L8.13182 25.5406C7.92166 26.766 9.20781 27.7004 10.3082 27.1219L15.9996 24.1298L21.6909 27.1219C22.7914 27.7004 24.0775 26.766 23.8674 25.5406L22.7804 19.2032L27.3848 14.715C28.2751 13.8472 27.7838 12.3353 26.5535 12.1565L20.1904 11.2319L17.3447 5.46593C16.7945 4.35106 15.2047 4.35106 14.6545 5.46593L11.8088 11.2319L5.4457 12.1565C4.21536 12.3353 3.72411 13.8472 4.61438 14.715Z"
        fill="white"
        stroke="#ACB1B9"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export default Star;