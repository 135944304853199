//Core
import React from 'react';
import {Link} from "react-router-dom";
import {defineMessages, useIntl} from "react-intl";

//Helpers
import {book} from "../../../../routes/book";
import {useSignUp} from "./customHooks/useSignUp";

//Styles
import {Box, Typography} from "@material-ui/core";

//Components
import SignUpForm from "./form/SignUpForm";

const msg = defineMessages({
  sign_up_title: {
    id: "sign_up_title",
    defaultMessage: "Create new account"
  },
  sign_up_text_on_form_or: {
    id: "sign_up_text_on_form_or",
    defaultMessage: "Or"
  },
  sign_up_text_on_form_sign_in_link: {
    id: "sign_up_text_on_form_sign_in_link",
    defaultMessage: "sign in"
  },
  sign_up_text_on_form_already_have_acc: {
    id: "sign_up_text_on_form_already_have_acc",
    defaultMessage: "if you already have an account"
  }
});

const SignUp = () => {
  const {formatMessage} = useIntl();
  const {initialValues, onSubmit} = useSignUp();

  if (!initialValues) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h2">
        {formatMessage(msg.sign_up_title)}
      </Typography>
      <Box
        mb={3.5}
        mt={0.8}
        alignItems={'baseline'}
        flexWrap={'wrap'}
        display={'flex'}>
        <Typography>
          {formatMessage(msg.sign_up_text_on_form_or)}
        </Typography>
        <Link to={book.signIn}>
          <Typography>
            &nbsp;
            {formatMessage(msg.sign_up_text_on_form_sign_in_link)}
            &nbsp;
          </Typography>
        </Link>
        <Typography>
          {formatMessage(msg.sign_up_text_on_form_already_have_acc)}
        </Typography>
      </Box>
      <SignUpForm
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    </>
  );
};

export default SignUp;
