import React, {useEffect, useState} from 'react';
import {InputAdornment, TextField, Typography} from "@material-ui/core";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import Search from "../Icons/Search";
import clsx from "clsx";
import {Nullable} from "../../setup/typedefs";
import {Place} from "../../bus/business/typedefs";
// import SetStatusForDots from "../SetStatusForDots";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  drop_down_places_list_with_search_input_search_placeholder: {
    id: "drop_down_places_list_with_search_input_search_placeholder",
    defaultMessage: "Search"
  },
  drop_down_places_list_with_search_empty_list_text: {
    id: "drop_down_places_list_with_search_empty_list_text",
    defaultMessage: "The list is empty"
  }
});

const useStyles = makeStyles(styles);

type PlacesListWithSearchProps ={
  places: Place[] | null,
  currentPlaceID: number,
  choicePlace: any
}

const PlacesListWithSearch: React.FC<PlacesListWithSearchProps> = ({places, currentPlaceID, choicePlace}) => {
  const {formatMessage} = useIntl();
  const classes = useStyles();
  const [list, setList] = useState<Nullable<Place[]>>(null);

  useEffect(() => {
    places?.length && setList(places)
  }, [places])

  if (!list) {
    return <></>;
  }

  // const changeInput = (event: any) => {
  //   if (places) {
  //     const searchList = places.filter((item: Place) => {
  //       const originalStr = item.name.toLocaleLowerCase();
  //       const searchStr = event.target.value.toLocaleLowerCase();
  //
  //       return originalStr.indexOf(searchStr) === -1
  //         ? null
  //         : item;
  //     });
  //
  //     event.target.value?.length > 0
  //       ? setList(searchList)
  //       : setList(places);
  //   }
  // };

  return (
    <>
      <div className={classes.search}>
        {/*<TextField*/}
        {/*  onChange={changeInput}*/}
        {/*  type={'text'}*/}
        {/*  placeholder={formatMessage(msg.drop_down_places_list_with_search_input_search_placeholder)}*/}
        {/*  fullWidth*/}
        {/*  variant={'outlined'}*/}
        {/*  InputProps={{*/}
        {/*    endAdornment: (*/}
        {/*      <InputAdornment position="end">*/}
        {/*        <Search />*/}
        {/*      </InputAdornment>*/}
        {/*    )*/}
        {/*  }}*/}
        {/*/>*/}
        <Typography variant={'body1'}>
          Places list
        </Typography>
      </div>
      {
        list?.length &&(
          <ul className={classes.list}>
            {
              list!.map( (item: Place) => {
                return (
                  <li
                    key={item.id}
                    onClick={() => {choicePlace(item.id);}}
                    className={
                      clsx({
                        [classes.currentPlace]: item.id === currentPlaceID
                      })
                    }>
                    {
                      item.id === currentPlaceID &&(
                        <span className={classes.currentPlaceMarker}/>
                      )
                    }
                    {/*<SetStatusForDots status={item.status}/>*/}
                    <Typography variant={'body2'}>
                      {item.name ? item.name : 'no name'}
                    </Typography>
                  </li>
                );
              })
            }
          </ul>
        )
      }
      {
        !list!.length &&(
          <div className={classes.emptyList}>
            <Typography variant={'body2'}>
              {formatMessage(msg.drop_down_places_list_with_search_empty_list_text)}
            </Typography>
          </div>
        )
      }
    </>
  );
};

export default PlacesListWithSearch;
