//Core
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";

//Helpers
import {getPlaces} from "../../../bus/business/selectors";
import {authSelector} from "../../../bus/auth/selectors";
import {isSaleManager} from "../../../bus/profile/selectors";
import {Place} from "../../../bus/business/typedefs";
import {placesActions} from "../../../bus/business/actions";
import {push} from "connected-react-router";
import {book} from "../../../routes/book";

export const useHeader = () => {
  const dispatch = useDispatch();

  const places = useSelector(getPlaces);
  const {isAuthenticated} = useSelector(authSelector);
  const isSale = useSelector(isSaleManager);

  const [currentPlace, setCurrentPlace] = useState<Place>();

  useEffect(() => {
    places.length && setCurrentPlace(places[0]);
  }, [places]);

  const choicePlace = (id: number) => {
    places?.length && places.forEach((item: Place) => {
      if (item?.id === id) {
        setCurrentPlace(item);
        dispatch(placesActions.fetchPlace({id: item?.id}));
        dispatch(push(book.myPlace));
      }
    });
  };

  return {
    choicePlace,
    isAuthenticated,
    isSaleManager: isSale,
    currentPlace,
    places
  };
};