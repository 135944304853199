//Core
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";

//Helpers
import {getVoucher, getVoucherPreview} from "../../../../../bus/vouchers/selectors";
import {vouchersActions} from "../../../../../bus/vouchers/actions";
import {Voucher} from "../../../../../bus/vouchers/typedefs";

export const useRecreateVoucher = () => {
  const dispatch = useDispatch();
  const {id} = useParams<{ id: string }>();
  const voucherPreview = useSelector(getVoucherPreview);
  const voucher = useSelector(getVoucher);
  const [initialValues, setInitialValues] = useState<Voucher & any>();

  useEffect(() => {
    id && dispatch(vouchersActions.fetchVoucher(Number(id)));
  }, []);

  useEffect(() => {
    voucherPreview
      ? setInitialValues(voucherPreview)
      : voucher && setInitialValues({
        ...voucher,
        dateStart: new Date(),
        dateEnd: null,
        tags: voucher.tags && voucher.tags.map((item: any) => item.name)
      });
  }, [voucher, voucherPreview]);

  return {
    initialValues
  };
};
