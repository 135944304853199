import {types} from "../../types";
import {call, select, put} from 'redux-saga/effects';
import {PersonalFormValues} from "../../../../containers/AccountContainer/pages/Personal/form/typedefs";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {RootState} from "../../../../setup/typedefs";
import {profileActions} from "../../actions";
import {fetchWithToken} from "../../../utils/fetching";
import {uiActions} from "../../../ui/actions";

export function* updatePersonalData({payload: body}: {type: typeof types.UPDATE_PERSONAL_DATA, payload: PersonalFormValues}){
  try {
    yield put(uiActions.loading(true));
    const {token: tokenAccess} = yield select((state: RootState) => state.auth);
    const {id} = yield select((state: RootState) => state.profile);

    const response = yield call(fetchWithToken, {
      endpoint: `merchants/${id}`,
      method: 'PUT',
      tokenAccess: tokenAccess,
      body
    });

    const {data} = yield call([response, 'json']);

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    yield call(handleMsg, 'Successful update');

    yield put(profileActions.fillProfile(data));

  } catch (e) {
    console.log(e);
  } finally {
    yield put(uiActions.loading(false));
  }
}
