import React from 'react';
import {WrappedFieldProps} from "redux-form";
import InputField from "../../../../../../components/InputField";
import {defineMessages, useIntl} from "react-intl";
import {Box} from "@material-ui/core";

const msg = defineMessages({
  confirm_password_placeholder: {
    id: "sign_up_confirm_password_placeholder",
    defaultMessage: "Confirm password"
  }
});

const RenderInputConfirmPassword = ({...props}:WrappedFieldProps) => {
  const {formatMessage} = useIntl();

  return (
    <Box width={'100%'}>
      <InputField
        {...props}
        type={'password'}
        placeholder={formatMessage(msg.confirm_password_placeholder)}
      />
    </Box>
  );
};

export default RenderInputConfirmPassword;
