import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  container: {
    width: 744,
    margin: '0 auto',
    paddingTop: 50,
    [breakpoints.down(784)]: {
      width: 'calc(100% - 30px)',
      paddingTop: 60
    }
  },
  title: {
    marginBottom: 40,
    [breakpoints.down(784)]: {
      marginBottom: 20
    }
  },
  subTitle: {
    marginBottom: 20,
    color: theme.palette.common.gray75
  },
  accordionFaq: {
    border: '1px solid #000'
  }
});
