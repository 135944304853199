import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {Tab} from "@material-ui/core";
import GraphChart from "../GraphChart";
import {useManipulationWithDates} from "./useManipulationWithDates";

const useStyles = makeStyles(styles);

const Dynamics = () => {
  const classes = useStyles();
  const [tab, setTab] = React.useState('1');
  const {
    viewsArray,
    favoritesArray,
    reservationsArray
  } = useManipulationWithDates();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue);
  };

  return (
    <div className={classes.root}>
      <TabContext value={tab}>
        <div className={classes.topBlock}>
          <TabList onChange={handleChange}>
            <Tab
              label="Views"
              value="1"
            />
            <Tab
              label="Favorite"
              value="2"
            />
            <Tab
              label="Reservations"
              value="3"
            />
          </TabList>
        </div>
        <TabPanel value="1">
          <GraphChart
            data={viewsArray}
            dataKey="amount"
          />
        </TabPanel>
        <TabPanel value="2">
          <GraphChart
            data={favoritesArray}
            dataKey="amount"
          />
        </TabPanel>
        <TabPanel value="3">
          <GraphChart
            data={reservationsArray}
            dataKey="amount"
          />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default Dynamics;
