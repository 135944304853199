//Core
import React from 'react';
import clsx from "clsx";

//Helper
import {AccordionPageType, useSidebar} from "./customHooks/useSidebar";

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import AccordionBox from "../AccordionBox";

const useStyles = makeStyles(styles);

export type SideBarProps = {
  isSaleManager?: boolean,
  withOutHeader?: boolean
}

const Sidebar: React.FC<SideBarProps> = (props) => {
  const classes = useStyles();
  const {accordionList} = useSidebar();
  const {isSaleManager = false, withOutHeader = false} = props;

  return (
    <div
      className={
        clsx(classes.root, {
          [classes.withSaleManagerItems]: isSaleManager,
          [classes.withOutHeader]: withOutHeader
        })
      }>
      {!!accordionList?.length && (
        accordionList.map((item: AccordionPageType, index: number) => {
          return (
            <AccordionBox
              key={index}
              title={item.name}
              data={item.content}
              expand={item.expand}
              disabled={item.disabled}
            />
          );
        })
      )}
    </div>
  );
};

export default Sidebar;
