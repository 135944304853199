import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const Recreate = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4985 29C23.4021 29 28.9985 23.4036 28.9985 16.5C28.9985 9.59644 23.4021 4 16.4985 4C9.59498 4 3.99854 9.59644 3.99854 16.5C3.99854 23.4036 9.59498 29 16.4985 29ZM16.4985 31C24.5067 31 30.9985 24.5081 30.9985 16.5C30.9985 8.49187 24.5067 2 16.4985 2C8.49041 2 1.99854 8.49187 1.99854 16.5C1.99854 24.5081 8.49041 31 16.4985 31Z"
        fill={props.fill}
      />
      <path
        d="M17.2485 9V16.6644L20.6919 18.9599L19.3052 21.0401L15.3052 18.3734L14.7485 18.0023V17.3333V9H17.2485Z"
        fill={props.fill}
      />
    </SvgIcon>
  );
};

export default Recreate;
