//Core
import React, {useEffect, useState} from 'react';

//Styles
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import MyPlaceForm from "./form/MyPlaceForm";
import PlaceStatus from "../../../../components/PlaceStatus";
import {useSelector} from "react-redux";
import {getPlace} from "../../../../bus/business/selectors";
import {isSaleManager} from "../../../../bus/profile/selectors";
import ReassignPlaceForm from "../../components/ReassignPlaceForm";

const useStyles = makeStyles(styles);

const MyPlace = () => {
  const classes = useStyles();
  const place = useSelector(getPlace);
  const isSale = useSelector(isSaleManager)
  const [initialValues, setInitialValues] = useState<any>();

  useEffect(() => {
    place && setInitialValues(place);
  }, [place]);

  return (
    <div className={classes.container}>
      {initialValues?.status && (
        <PlaceStatus status={initialValues.status}/>
      )}
      {initialValues && (<MyPlaceForm initialValues={initialValues}/>)}
      {isSale && initialValues && (<ReassignPlaceForm initialValues={initialValues}/>)}
    </div>
  );
};

export default MyPlace;
