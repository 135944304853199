import {createStyles} from "@material-ui/core/styles";
import {GroupiTheme} from "../../customTheme";
import {createMuiTheme} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    '& .react-tagsinput': {
      backgroundColor: 'inherit',
      overflow: 'hidden',
      padding: 2,
      minHeight: 48,
      border: '1px solid',
      borderColor: theme.palette.common.gray88,
      boxSizing: 'border-box',
      borderRadius: 8
    },

    '& .react-tagsinput--focused': {
      borderColor: theme.palette.common.gray88
    },

    '& .react-tagsinput-tag': {
      backgroundColor: theme.palette.common.gray95,
      color: theme.palette.common.black,
      alignItems: 'center',
      display: 'inline-flex',
      fontFamily: 'Source Sans Pro, serif',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '20px',
      borderRadius: 30,
      padding: '6px 30px 6px 12px',
      position: 'relative',
      margin: 5
    },

    '& .react-tagsinput-remove': {
      cursor: 'pointer',
      color: theme.palette.common.black,
      opacity: .5,
      fontSize: 20,
      fontWeight: 700,
      position: 'absolute',
      right: 10,
      '&:before': {
        content: '"×"'
      }
    },

    '& .react-tagsinput-input': {
      background: 'transparent',
      border: 'none',
      minHeight: 40,
      fontFamily: 'Source Sans Pro, serif',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '20px',
      outline: 'none',
      marginLeft: 10,
      color: theme.palette.common.black
    }
  },
  tagsDisabled: {
    '& .react-tagsinput-tag': {
      padding: '6px 12px'
    },
    '& .react-tagsinput-input': {
      display: 'none'
    }
  },
  suggestedTagWrapper: {
    display :'flex',
    flexWrap: 'wrap',
    marginTop: 22
  },
  suggestedTagsTitle: {
    marginRight: 20,
    [breakpoints.down(600)]: {
      width: '100%'
    }
  },
  suggestedTag: {
    color: theme.palette.common.blue50,
    cursor: 'pointer',
    marginRight: 20,
    height: 30,
    [breakpoints.down(600)]: {
      marginTop: 10
    }
  }
});