//Core
import React from 'react';

//Helpers
import {Place} from "../../../../bus/business/typedefs";

//Styles
import {Typography, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import IconHolder from "../../../../components/IconHolder";
import ArrowDown from "../../../../components/Icons/ArrowDown";
import {GroupiTheme} from "../../../../customTheme";

const useStyles = makeStyles(styles);

type RenderDescriptionProps = {
  data: Place
}

const RenderDescription: React.FC<RenderDescriptionProps> = ({data}) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();

  if (!data.description) {
    return <></>;
  }

  return (
    <div className={classes.descriptionWrapper}>
      <Typography variant={'body2'}>
        {data?.description}
      </Typography>
      {data.description?.length > 101 &&(
        <div className={classes.descriptionShowMore}>
          <Typography variant={'subtitle2'}>
            Show more
          </Typography>
          <IconHolder
            size={16}
            color={theme.palette.common.blue50}>
            <ArrowDown/>
          </IconHolder>
        </div>
      )}
    </div>
  );
};

export default RenderDescription;