import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    background: theme.palette.common.white,
    overflow: 'hidden',
    pointerEvents: 'none',
    [breakpoints.down(475)]: {
      width: 'calc(100% + 30px)',
      margin: '0 -15px -60px'
    }
  },
  imgHolder: {
    width: '100%',
    height: 203,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  bodyPlace: {
    background: theme.palette.common.white,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    padding: '20px 16px',
    marginTop: -20,
    position: 'relative',
    top: -50
  },
  topBlockWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  blockWithRateNReviews: {
    display: 'flex',
    alignItems: 'center'
  },
  rate: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginRight: 5
    }
  },
  reviews: {
    marginLeft: 10,
    paddingLeft: 8,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      width: 1,
      height: 12,
      background: theme.palette.common.gray60
    },
    '& p': {
      color: theme.palette.common.gray60
    }
  },
  blockWithName: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 4,
    '& .MuiTypography-h4': {
      width: 'calc(100% - 32px)'
    }
  },
  fullWidthLine: {
    height: 1,
    width: '100%',
    background: theme.palette.common.gray88
  },
  innerRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    '&>.MuiTypography-root': {
      marginLeft: 5
    }
  },
  decorate: {
    margin: '0 0 0 auto',
    '& p': {
      fontFamily: 'Source Sans Pro',
      fontSize: 16,
      lineHeight: '24px',
      color: theme.palette.common.gray60
    },
    '& span': {
      width: 60,
      height: 22,
      background: theme.palette.common.blue85,
      textTransform: "uppercase",
      borderRadius: 4,
      fontSize: 11,
      lineHeight: '16px',
      color: theme.palette.common.blue50,
      fontFamily: 'Source Sans Pro',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  description: {
    marginTop: 13,
    letterSpacing: 0,
    lineHeight: '24px'
  },
  offersWrapper: {
    marginBottom: 20,
    '& .MuiTypography-subtitle1': {
      marginBottom: 4
    }
  },
  vouchersWrapper: {
    marginBottom: 20,
    '& .MuiTypography-subtitle1': {
      marginBottom: 4
    }
  },
  testimonialsWrapper: {
    '& .MuiTypography-subtitle1': {
      marginBottom: 4
    }
  },
  divider: {
    background: theme.palette.common.gray88,
    width: '100%',
    height: 1,
    boxSizing: 'border-box',
    margin: '20px 0'
  },
  buttonImitateWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonImitate: {
    border: '2px solid #0080FF',
    boxSizing: 'border-box',
    borderRadius: 8,
    height: 42,
    width: 165,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '21px',
    textAlign: 'center',
    color: theme.palette.common.blue50,
    [breakpoints.down(375)]: {
      width: 130,
      fontSize: 14
    }
  },
  imagesWrapper: {
    display: 'flex'
  },
  imageHolder: {
    overflow: 'hidden',
    borderRadius: 8,
    minWidth: 150,
    height: 91,
    marginRight: 12,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  nameWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10
  },
  placeName: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.common.black,
    flex: 'none',
    order: 0,
    alignSelf: 'flex-start',
    width: 'calc(100% - 155px)'
  },
  iconsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 138
  },
  route: {
    width: 90,
    height: 40,
    borderRadius: 32,
    borderColor: theme.palette.common.gray88,
    border: '1px solid',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.common.trueBlack
  },
  party: {
    width: 40,
    height: 40,
    borderRadius: 32,
    borderColor: theme.palette.common.gray88,
    border: '1px solid',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});