import React, {useEffect} from 'react';
import {IconButton, Typography, useTheme} from "@material-ui/core";
import IconHolder from "../../../../../../components/IconHolder";
import Trash from "../../../../../../components/Icons/Trash";
import {GroupiTheme} from "../../../../../../customTheme";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import {offersActions} from "../../../../../../bus/offers/actions";
import {useDispatch, useSelector} from "react-redux";
import {uiActions} from "../../../../../../bus/ui/actions";
import {uiSelector} from "../../../../../../bus/ui/selectors";

const useStyles = makeStyles(styles);

const RemoveButton: React.FC<{id: number}> = ({id}) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const {confirm} = useSelector(uiSelector);
  const dispatch = useDispatch();

  const remove = () => {
    dispatch(uiActions.popup({
      name: 'areYouSure',
      payload: {
        title: 'Are you sure you want to Delete this Deal?',
        description: 'You won’t be able to undo this action'
      }
    }));
  };

  useEffect(() => {
    if (confirm && id) {
      dispatch(offersActions.removeOffer({offerID: id}));
    }
  }, [confirm]);

  return (
    <div className={classes.button}>
      <IconButton
        color="primary"
        onClick={remove}>
        <IconHolder
          size={32}
          color={theme.palette.common.red}>
          <Trash/>
        </IconHolder>
      </IconButton>
      <Typography variant={'body2'}>
        Delete
      </Typography>
    </div>
  );
};

export default RemoveButton;