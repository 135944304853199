import {types} from "./types";
import {AnyAction} from "redux";
import {Meta, Nullable} from "../../setup/typedefs";
import {Voucher} from "./typedefs";

export type VouchersState = {
  voucher: Nullable<Voucher>
  voucherPreview: Nullable<Voucher>
  vouchers: Voucher[]
  meta: Nullable<Meta>
}

const initialState: VouchersState = {
  voucher: null,
  voucherPreview: null,
  vouchers: [],
  meta: null
};

export const vouchersReducer = (state= initialState, action: AnyAction) => {
  switch (action.type) {
  case types.FILL_VOUCHERS:
    return {
      ...state,
      vouchers: action.payload,
      meta: action.meta
    };

  case types.FILL_VOUCHER:
    return {
      ...state,
      voucher: action.payload
    };

  case types.FILL_PREVIEW:
    return {
      ...state,
      voucherPreview: action.payload
    };

  case types.CLEAR_PREVIEW:
    return {
      ...state,
      voucherPreview: initialState.voucherPreview
    };

  case types.CLEAR:
    return initialState;

  default:
    return state;
  }
};
