import {uiActions} from "../bus/ui/actions";
import {IResponseMsg} from "../bus/auth/typedefs";
import {put, all, call} from "redux-saga/effects";

export function* handleErrors (response: any) {
  if (typeof response === "string") {
    yield put(uiActions.toast(response));

    return;
  }
  const {errors} = yield call([response, 'json']);
  yield all(errors?.map((item: IResponseMsg) => put(uiActions.toast(item.msg))));
}

export function* handleMsg (text: string) {
  yield put(uiActions.toast(text));
}
