import React, {useEffect, useState} from 'react';
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import ButtonBackBlue from "../../../../components/ButtonBackBlue";
import OfferEditForm from "./form/OfferEditForm";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {getOffer, getOfferPreview} from "../../../../bus/offers/selectors";
import {Offer} from "../../../../bus/offers/typedefs";
import {offersActions} from "../../../../bus/offers/actions";

const useStyles = makeStyles(styles);

const EditOffer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {id} = useParams<{ id: string }>();
  const offer = useSelector(getOffer);
  const [initialValues, setInitialValues] = useState<Offer & any>();
  const offerPreview = useSelector(getOfferPreview);

  useEffect(() => {
    !offerPreview && id && dispatch(offersActions.fetchOffer(Number(id)));
  }, []);

  useEffect(() => {
    offer && setInitialValues(offer);
  }, [offer]);

  return (
    <div className={classes.container}>
      <ButtonBackBlue/>
      {!initialValues
        ? (
          <Typography variant={'body2'}>
            Deal does not exist!
          </Typography>
        )
        : (
          <OfferEditForm initialValues={initialValues}/>
        )}
    </div>
  );
};

export default EditOffer;
