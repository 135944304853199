export const types = {
  FETCH_OFFER: '@OFFERS/FETCH_OFFER',
  FILL_OFFER: '@OFFERS/FILL_OFFER',
  FILL_OFFER_VIEWS: '@OFFERS/FILL_OFFER_VIEWS',
  FILL_OFFER_FAVORITES: '@OFFERS/FILL_OFFER_FAVORITES',
  FILL_OFFER_RESERVATIONS: '@OFFERS/FILL_OFFER_RESERVATIONS',
  FETCH_OFFERS: '@OFFERS/FETCH_OFFERS',
  FILL_OFFERS: '@OFFERS/FILL_OFFERS',
  CREATE_PREVIEW: '@OFFERS/CREATE_PREVIEW',
  FILL_PREVIEW: '@OFFERS/FILL_PREVIEW',
  UPDATE_OFFER: '@OFFERS/UPDATE_OFFER',
  REMOVE_OFFER: '@OFFERS/REMOVE_OFFER',
  CLEAR_PREVIEW: '@OFFERS/CLEAR_PREVIEW',
  CLEAR: '@OFFERS/CLEAR'
};