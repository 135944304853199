import WebFont from 'webfontloader';
import {Theme, ThemeOptions} from "@material-ui/core/styles/createMuiTheme";
import {CommonColors, Palette, PaletteOptions} from "@material-ui/core/styles/createPalette";
import {createMuiTheme} from '@material-ui/core';

const defTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 804,
      md: 960,
      lg: 1280,
      xl: 1568
    }
  }
});

const {breakpoints} = defTheme;

WebFont.load({
  google: {
    families: ['Montserrat:300,600', 'Source Sans Pro']
  }
});

interface GroupiPalette extends Palette {
    common: GroupiCommonColors,
}

export interface GroupiTheme extends Theme {
    palette: GroupiPalette
}

interface GroupiCommonColors extends CommonColors {
  blue95: string
  gray50: string
  gray60: string
  gray75: string
  gray88: string
  gray95: string
  yellow: string
  orange: string
  red: string
  green: string
  blue50: string
  blue57: string
  blue68: string
  blue85: string
  gradient: string
  trueBlack: string
  overlay: string
}

interface GroupiPaletteOptions extends PaletteOptions {
    common: GroupiCommonColors
}

interface GroupiThemeOptions extends ThemeOptions {
    palette: GroupiPaletteOptions,
}

export const themeOptions: GroupiThemeOptions = {
  spacing: 10,
  breakpoints: {
    values: {
      xs: 0,
      sm: 804,
      md: 960,
      lg: 1280,
      xl: 1568
    }
  },
  typography: {
    fontFamily: 'Source Sans Pro, Arifal'
  },
  palette: {
    common: {
      overlay: 'rgba(0,0,0,0.3)',
      trueBlack: '#000',
      black: '#0D131C',
      white: '#fff',
      gray50: '#737D8C',
      gray60: '#8F97A3',
      gray75: '#BABEC4',
      gray88: '#C8CBD0',
      gray95: '#F4F5F6',
      yellow: '#FFBF00',
      orange: '#FFA800',
      green: '#1BC052',
      blue50: '#0080FF',
      blue57: '#2491FF',
      blue68: '#5CADFF',
      blue85: '#B3D9FF',
      blue95: '#E5F2FF',
      red: '#E33D47',
      gradient: 'linear-gradient(135deg, #B54CFF 0%, #2B00FF 100%)'
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: 20,
        paddingRight: 20,
        [breakpoints.down(600)]: {
          paddingLeft: 15,
          paddingRight: 15
        }
      }
    },
    MuiAccordion: {
      root: {
        boxShadow: 'unset',
        '&:before': {
          opacity: 0,
          top: 12,
          left: 0,
          height: 40,
          width: 4
        },
        '&.Mui-expanded': {
          margin: 0,
          '&:before': {
            opacity: 0
          }
        }
      }
    },
    MuiAccordionDetails: {
      root: {
        padding: '0'
      }
    },
    MuiAccordionSummary: {
      root: {
        minHeight: 64,
        padding: '0 17px 0 34px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [breakpoints.down(600)]: {
          padding: '0 17px 0 24px'
        }
      },
      content: {
        margin: 0,
        '&>span': {
          opacity: 0,
          transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)'
        },
        '&.Mui-expanded': {
          '&>span': {
            opacity: 1
          }
        }
      },
      expandIcon: {
        border: 'none',
        transform: 'rotate(90deg)',
        '&:hover': {
          border: 'none'
        },
        '&.Mui-expanded': {
          border: 'none',
          transform: 'rotate(0)'
        }
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'none'
        }
      }
    },
    MuiList: {
      root: {
        borderRadius: 8,
        boxShadow: '0px 4px 12px rgba(50, 77, 103, 0.25)'
      }
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        top: '100%',
        left: 0,
        '&.Mui-error': {
          margin: '3px 4px',
          lineHeight: 1.2,
          fontSize: 10
        }
      }
    },
    MuiFilledInput: {
      underline: {
        '&:before': {
          opacity: 1,
          height: 2,
          borderBottom: 'none'
        },
        '&:after': {
          height: 2,
          borderBottom: '1px solid #0080FF'
        },
        '&:hover': {
          '&:before': {
            borderBottom: 'none'
          }
        },
        '&.Mui-focused': {
          '&:before': {
            opacity: 0
          }
        },
        '&.Mui-disabled': {
          '&:before': {
            opacity: 0
          }
        }
      },
      root: {
        backgroundColor: 'rgba(0,0,0,0)!important',
        paddingBottom: 8
      },
      input: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 56,
        lineHeight: '67px',
        padding: 0,
        margin: 0,
        [breakpoints.down(600)]: {
          fontSize: 32,
          lineHeight: '38px'
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        boxSizing: 'border-box',
        borderRadius: 8,
        border: 'none',
        height: '48px',
        '&.MuiInputBase-multiline': {
          height: 'auto'
        },
        '&.Mui-disabled': {
          background: '#F4F5F6',
          '&>.MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        },
        '&.Mui-focused': {
          '&>.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #0080FF'
          },
          '&.Mui-error': {
            '&>.MuiOutlinedInput-notchedOutline': {
              border: '1px solid #E33D47'
            }
          }
        },
        '&:hover': {
          '&>.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #C8CBD0'
          },
          '&.Mui-error': {
            '&>.MuiOutlinedInput-notchedOutline': {
              border: '1px solid #E33D47'
            }
          }
        },
        '&>.MuiOutlinedInput-notchedOutline': {
          border: '1px solid #C8CBD0'
        },
        '&.Mui-error': {
          '&>.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E33D47'
          }
        }
      },
      input: {
        color: '#0D131C',
        fontFamily: 'Source Sans Pro',
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '24px',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
        },
        '&::placeholder': {
          color: '#8F97A3',
          opacity: 1
        },
        '&.Mui-disabled': {
          '&::placeholder': {
            color: '#BABEC4'
          }
        }
      }
    },
    MuiIconButton: {
      root: {
        background: '#FFFFFF',
        border: '1px solid #C8CBD0',
        boxSizing: 'border-box',
        '&:hover': {
          background: '#F4F5F6',
          border: '1px solid #C8CBD0'
        },
        '&.MuiIconButton-sizeSmall': {
          background: 'inherit',
          width: 24,
          height: 24,
          padding: 0,
          border: 0,
          '& .MuiIconButton-label': {
            width: '100%',
            height: '100%'
          }
        }
      }
    },
    MuiCheckbox: {
      root: {
        borderRadius: 0,
        padding: 0,
        border: 'none',
        '&:hover': {
          borderRadius: 0,
          padding: 0,
          border: 'none'
        }
      }
    },
    MuiButton: {
      root: {
        minHeight: 56,
        [breakpoints.down(600)]: {
          width: '100%'
        },
        '& .MuiButton-iconSizeMedium': {
          '&>:first-child': {
            fontSize: 'inherit',
            height: 'auto'
          }
        },
        '& .MuiButton-startIcon': {
          marginRight: 12
        },
        '& .MuiButton-endIcon': {
          marginLeft: 12
        }
      },
      outlined: {
        '&.Mui-disabled': {
          '& .MuiButton-label': {
            color: '#C8CBD0'
          }
        }
      },
      text: {
        padding: 0
      },
      fullWidth: {
        '&>.MuiButton-label': {
          textTransform: 'none'
        }
      },
      outlinedSizeLarge: {
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 69,
        paddingRight: 69,
        border: '1px solid #C8CBD0',
        boxSizing: 'border-box',
        backgroundColor: '#FFFFFF',
        '&:hover': {
          boxShadow: '0px 1px 4px rgba(50, 77, 103, 0.25)',
          border: '1px solid #C8CBD0',
          backgroundColor: '#FFFFFF'
        },
        '&.pressed': {
          boxShadow: '0px 4px 12px rgba(50, 77, 103, 0.25)'
        },
        '&>.MuiButton-label': {
          fontFamily: 'Montserrat',
          fontWeight: 600,
          fontSize: 24,
          lineHeight: '32px',
          textTransform: 'none',
          color: '#0D131C',
          '&>.MuiButton-startIcon': {
            marginRight: 24
          }
        }
      },
      containedSizeSmall: {
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 28,
        paddingRight: 28,
        height: 48
      },
      outlinedPrimary: {
        borderRadius: 8,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 36,
        paddingRight: 36,
        border: '1px solid #C8CBD0',
        '&>.MuiButton-label': {
          fontSize: '16px',
          fontFamily: 'Source Sans Pro',
          fontWight: 'bold',
          lineHeight: '24px',
          letterSpacing: '0.06em',
          textTransform: 'uppercase',
          color: '#0080FF'
        }
      },
      containedPrimary: {
        backgroundColor: '#0080FF',
        borderRadius: 8,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 36,
        paddingRight: 36,
        [breakpoints.down(575)]: {
          paddingLeft: 20,
          paddingRight: 20
        },
        boxShadow: 'unset',
        '&:hover': {
          backgroundColor: '#2491FF',
          boxShadow: 'unset'
        },
        '&:active': {
          backgroundColor: '#5CADFF',
          boxShadow: 'unset'
        },
        '&>.MuiButton-label': {
          fontFamily: 'Source Sans Pro',
          fontWeight: 'bold',
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '0.06em',
          [breakpoints.down(600)]: {
            fontSize: 14
          }
        }
      },
      contained: {
        '&.Mui-disabled': {
          backgroundColor: '#C8CBD0',
          '&>.MuiButton-label': {
            color: '#ffffff'
          }
        }
      }
    },
    MuiTypography: {
      root:{
        '& a ': {
          color: '#0080FF',
          transition: 'color 300ms cubic-bezier(0.4, 0, 0.2, 1)',
          textDecoration: 'none'
        },
        '&:hover': {
          '& a ': {
            color: '#2491FF'
          }
        }
      },
      h1: {
        fontFamily: 'Montserrat',
        fontSize: 56,
        fontWeight: 'bold',
        lineHeight: '67px',
        letterSpacing: '0.6px',
        [breakpoints.down(804)]: {
          fontSize: 48
        },
        [breakpoints.down(600)]: {
          fontSize: 32,
          lineHeight: '38px'
        }
      },
      h2: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: 40,
        lineHeight: '48px',
        letterSpacing: 0.45,
        [breakpoints.down(804)]: {
          fontSize: 36,
          lineHeight: '48px'
        },
        [breakpoints.down(600)]: {
          fontSize: 26,
          lineHeight: '34px'
        }
      },
      h3: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: 32,
        lineHeight: '39px'
      },
      h4: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: 24,
        lineHeight: '32px',
        letterSpacing: 0.3
      },
      subtitle1: {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 20,
        lineHeight: '26px',
        letterSpacing: 0.3,
        [breakpoints.down(804)]: {
          fontSize: 16,
          lineHeight: '24px'
        },
        [breakpoints.down(600)]: {
          fontSize: 14,
          lineHeight: '21px'
        }
      },
      subtitle2: {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: '21px',
        letterSpacing: 0.2
      },
      body1: {
        fontSize: 20,
        [breakpoints.down(600)]: {
          fontSize: 16,
          lineHeight: '22px'
        }
      },
      body2: {
        fontSize: 16,
        letterSpacing: 1
      },
      caption: {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '18px',
        letterSpacing: 0.2
      }
    }
  }
};

export default themeOptions;