import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = () => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down(600)]: {}
  },
  checkBox: {
    marginRight: 10
  }
});