import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const ArrowLeft = (props: SvgIconProps) => {
  const {htmlColor} = props;

  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 16 16">
      <path
        d="M9 5L6 8.5L9 12"
        stroke={htmlColor}
        strokeWidth="2"
        strokeLinecap="round"
        fill="#ffffff"
      />
    </SvgIcon>
  );
};

export default ArrowLeft;
