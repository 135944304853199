//Core
import React from 'react';

//Helpers
import {useRenderPreview} from "../../customHooks/useRenderPreview";
import {concatDate, concatTime} from "../../../../../../utils/helpers";

//Styles
import {GroupiTheme} from "../../../../../../customTheme";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {styles} from "./styles";

//Components
import GoogleMapPinSecond from "../../../../../../components/Icons/GoogleMapPinSecond";
import RenderDescription from "../../../../components/RenderDescription";
import FilledStar from "../../../../../../components/Icons/FilledStar";
import IconHolder from "../../../../../../components/IconHolder";
import Favorite from "../../../../../../components/Icons/Favorite";
import RatePart from "../../../../../../components/RatePart";
import Calendar from "../../../../../../components/Icons/Calendar";
import Repeat from "../../../../../../components/Icons/Repeat";
import Clock from "../../../../../../components/Icons/Clock";
import Route from "../../../../../../components/Icons/Route";
import Party from "../../../../../../components/Icons/Party";
import InnerRow from "../InnerRow";

const useStyles = makeStyles(styles);

const RenderVoucherPreview = () => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const {data, randomImg, placeData} = useRenderPreview();

  if (!data) {
    return (
      <p>
        sorry no data to render
      </p>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.imgHolder}>
        <img
          src={data.cover?.origin ? data.cover?.origin : `/defaultImage${randomImg}.svg`}
          alt={data.name}
        />
      </div>
      <div className={classes.bodyVoucher}>
        <div className={classes.topBlock}>
          <Typography variant={'h4'}>
            {data.name ? data.name : ' '}
          </Typography>
          <IconHolder
            size={32}
            color={theme.palette.common.blue50}>
            <Favorite/>
          </IconHolder>
        </div>
        <div className={classes.pricesWrap}>
          <div className={classes.prices}>
            <div className={classes.originalPrice}>
              <Typography variant={'subtitle2'}>
                €
                {data.originalPrice}
              </Typography>
            </div>
            <div className={classes.discountPrice}>
              <Typography variant={'subtitle2'}>
                €
                {data.discountPrice}
              </Typography>
            </div>
          </div>
          <div className={classes.discount}>
            <Typography variant={'caption'}>
              {100 - Math.ceil(data.discountPrice / (data.originalPrice / 100))}
              % off
            </Typography>
          </div>
        </div>
        <InnerRow
          icon={<Calendar/>}
          text={concatDate(data.dateStart, data.dateEnd)}
        />
        <InnerRow
          icon={<Repeat/>}
          text={data.availability}
        />
        <InnerRow
          icon={<Clock/>}
          text={concatTime(data.timeStart, data.timeEnd)}
        />
        <RenderDescription data={data}/>
        <div className={classes.divider}/>
        <div className={classes.buttonImitateWrapper}>
          <div className={classes.buttonImitate}>
            Show on map
          </div>
          <div className={classes.buttonImitate}>
            Buy voucher
          </div>
        </div>
        <div className={classes.divider}/>
        <div className={classes.imagesWrapper}>
          {placeData?.photos?.length && placeData?.photos?.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className={classes.imageHolder}>
                <img
                  src={item.origin}
                  alt={data.name}
                />
              </div>
            );
          })}
        </div>
        <div className={classes.nameWrapper}>
          {placeData.name &&(
            <div className={classes.innerWrapper}>
              <div className={classes.placeName}>
                {placeData.name}
              </div>
              <div className={classes.infoWrapper}>
                <IconHolder
                  size={12}
                  color={theme.palette.common.yellow}>
                  <FilledStar/>
                </IconHolder>
                <Typography variant={'body2'}>
                  4.6
                </Typography>
                <div className={classes.innerDivide}/>
                <GoogleMapPinSecond/>
                <Typography variant={'body2'}>
                  3 km
                </Typography>
              </div>
            </div>
          )}
          <div className={classes.iconsWrapper}>
            <div className={classes.route}>
              <IconHolder
                size={24}
                color={theme.palette.common.blue50}>
                <Route/>
              </IconHolder>
              Route
            </div>
            <div className={classes.party}>
              <IconHolder
                size={24}
                color={theme.palette.common.blue50}>
                <Party/>
              </IconHolder>
            </div>
          </div>
        </div>
        <div className={classes.ratePart}>
          <RatePart>
            Have you used that voucher? Rate it.
          </RatePart>
        </div>
      </div>
    </div>
  );
};

export default RenderVoucherPreview;