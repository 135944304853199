import React, {useState} from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import {Field} from "redux-form";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import RequiredAsterisk from "../../../../components/RequiredAsterisk";
import InputEndDatePicker from "../../../../components/InputDateTimePickers/EndDatePicker";
import InputStartDatePicker from "../../../../components/InputDateTimePickers/StartDatePicker";
import ToolTip from "../../../../components/ToolTip";

const useStyles = makeStyles(styles);

interface DateFieldRowTypes {
  labelText: string,
  isRequired?: boolean,
  onlyStar?: boolean,
  initialValues?: any,
  disabled?: boolean,
  toolTipText?: string
}

const DateFieldRow: React.FC<DateFieldRowTypes> = ({...props}) => {
  const classes = useStyles();
  const {
    labelText,
    isRequired = false,
    onlyStar = false,
    disabled = false,
    toolTipText
  } = props;

  const [start, setStart] = useState<Date>(new Date());

  return (
    <Grid
      item
      xs={12}>
      <Box className={classes.wrapper}>
        <Box className={classes.label}>
          {!!labelText &&(
            <Typography variant={"subtitle2"}>
              {labelText}
              {isRequired || onlyStar ? <RequiredAsterisk/> : null}
            </Typography>
          )}
          {!!toolTipText &&(
            <Box className={classes.tooltip}>
              <ToolTip>
                {toolTipText}
              </ToolTip>
            </Box>
          )}
        </Box>
        <Box className={classes.fields}>
          <Field
            name={'dateStart'}
            disabled={disabled}
            setStart={setStart}
            component={InputStartDatePicker}
          />
          <div className={classes.divide}/>
          <Field
            name={'dateEnd'}
            start={start}
            component={InputEndDatePicker}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default DateFieldRow;
