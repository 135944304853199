import React from 'react';
import ArrowDown from "../Icons/ArrowDown";
import {GroupiTheme} from "../../customTheme";
import {Box, createStyles, useTheme} from "@material-ui/core";
import IconHolder from "../IconHolder";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => createStyles({
  ddArrow: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: -1
  }
}));

const DropDownArrow = () => {
  const theme: GroupiTheme = useTheme();
  const classes = useStyles();

  return (
    <Box
      pr={1.2}
      className={classes.ddArrow}>
      <IconHolder
        size={16}
        color={theme.palette.common.black}>
        <ArrowDown/>
      </IconHolder>
    </Box>
  );
};

export default DropDownArrow;
