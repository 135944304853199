import React, {useEffect, useState} from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import CoverDropInput from "../../../../components/CoverDropInput";
import {Nullable} from "../../../../../../setup/typedefs";

const RenderInputCover: React.FC<WrappedFieldProps & any> = ({input, initialValues}) => {
  const [file, setFile] = useState<Nullable<object>>();

  useEffect(() => {
    initialValues?.cover && setFile(initialValues.cover);
  },[initialValues?.cover]);

  return (
    <FieldRow labelText={'Cover'}>
      <CoverDropInput
        input={input}
        file={file}
        setFile={setFile}
      />
    </FieldRow>
  );
};

export default RenderInputCover;
