import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import {Box, Button, createMuiTheme, createStyles, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useResendEmail} from "../../customHooks/useResendEmail";
import clsx from "clsx";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

const useStyles = makeStyles(() => createStyles({
  root: {
    '& .MuiFormControl-fullWidth': {
      width: 'calc(100% - 160px)',
      [breakpoints.down(575)]: {
        width: 'calc(100% - 110px)'
      }
    }
  },
  button: {
    width: 140,
    padding: '16px 10px',
    fontWeight: 400,
    [breakpoints.down(575)]: {
      width: 'auto',
      padding: '16px 20px'
    }
  }
}));

const RenderInputEmailAddress = ({...props}: WrappedFieldProps) => {
  const classes = useStyles();
  const {resend, isVerified} = useResendEmail();

  const {input} = props;

  return (
    <FieldRow
      labelText={'Email address'}
      isRequired={true}
      toolTipText={isVerified ? '' : 'Click on resend button if you have not received a confirmation email during registration.'}>
      <Box
        className={clsx({
          [classes.root]: !isVerified
        })}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <TextField
          {...input}
          disabled
          type={'text'}
          placeholder={'Enter your Email'}
          fullWidth
          variant={'outlined'}
        />
        {
          !isVerified && (
            <Button
              className={classes.button}
              onClick={resend}
              variant="contained"
              fullWidth
              color="primary">
              Resend
            </Button>
          )
        }
      </Box>
    </FieldRow>
  );
};

export default RenderInputEmailAddress;
