import React from 'react';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import themeOptions from "./customTheme";
import RootRouter from "./routes/RootRouter";
import {IntlProvider} from "react-intl";
import {LocaleContext} from "./setup/LocaleContext";
import en from "./translations/en.json";
import ru from "./translations/ru.json";
import ScrollToTop from "./setup/ScrollToTop";
import ToastMessage from "./components/ToastMessage";

const messages:any= {
  en,
  ru
};

const groupiTheme = createMuiTheme(themeOptions);

function App() {
  const {locale} = React.useContext(LocaleContext);

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}>
      <ThemeProvider theme={groupiTheme}>
        <ToastMessage/>
        <ScrollToTop/>
        <RootRouter/>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
