import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  subMainInfoBlock: {
    marginBottom: 60,
    [breakpoints.down(575)]: {
      marginBottom: 40,
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 1 auto',
    marginTop: 15,
    '& .MuiFormControl-fullWidth': {
      maxWidth: 340,
      [breakpoints.down(784)]: {
        maxWidth: 'calc(50% - 25px)'
      }
    }
  },
  divide: {
    width: 10,
    height: 1,
    background: theme.palette.common.black
  }
});
