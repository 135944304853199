import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import InputField from "../../../../../../components/InputField";
import {defineMessages, useIntl} from "react-intl";

const msg = defineMessages({
  my_place_form_render_input_city_label: {
    id: "my_place_form_render_input_city_label",
    defaultMessage: "City"
  }
});

const RenderInputCity = ({...props}:WrappedFieldProps) => {
  const {formatMessage} = useIntl();

  return (
    <FieldRow
      labelText={formatMessage(msg.my_place_form_render_input_city_label)}
      onlyStar>
      <InputField
        {...props}
        type={'text'}
      />
    </FieldRow>
  );
};

export default RenderInputCity;
