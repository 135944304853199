import React from 'react';
import Loader from 'react-loader-spinner';
import {GroupiTheme} from "../../customTheme";
import {createStyles, useTheme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useSelector} from "react-redux";
import {RootState} from "../../setup/typedefs";
import clsx from "clsx";

const styles = (theme: GroupiTheme) => createStyles({
  noScroll: {
    overflow: 'hidden',
    position: 'fixed',
    width: '100%',
    height: '100%'
  },
  root: {
    width: '100vw',
    height: '100vh',
    background: theme.palette.common.overlay,
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
    '& svg': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }
});

const useStyles = makeStyles(styles);

const Preloader = ({children}: any) => {
  const theme: GroupiTheme = useTheme();
  const classes = useStyles();
  const isLoading = useSelector((state: RootState)=>{
    return state.ui.isLoading;
  });

  return (
    <div className={clsx({[classes.noScroll]: isLoading})}>
      {children}
      {isLoading &&(
        <div className={classes.root}>
          <Loader
            type="Rings"
            color={theme.palette.common.blue50}
            height={200}
            width={200}
          />
        </div>
      )}
    </div>
  );
};

export default Preloader;