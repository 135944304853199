import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    margin: -10,
    flexWrap: 'wrap',
    display: 'flex',
    [breakpoints.down(475)]: {
      margin: -5
    }
  },
  disabled: {
    pointerEvents: 'none'
  },
  fileDropZone: {
    order: -1,
    border: '2px dashed',
    borderColor: theme.palette.common.gray88,
    width: '100%',
    height: 154,
    borderRadius: 8,
    position: 'relative',
    boxSizing: 'border-box',
    margin: '10px 10px 52px',
    cursor: 'pointer',
    background: theme.palette.common.white,
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      background: theme.palette.common.gray95
    },
    [breakpoints.down(768)]: {
      width: '100%',
      height: 150,
      order: -1
    },
    [breakpoints.down(475)]: {
      margin: 5
    }
  },
  innerInfo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '&>.MuiBox-root': {
      display: 'flex',
      justifyContent: 'center'
    },
    '& .MuiTypography-body2': {
      color: theme.palette.common.blue50
    },
    '& .MuiTypography-caption': {
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.common.gray75
    }
  },
  textWrapper: {
    marginTop: 20,
    position: 'absolute',
    top: '100%',
    [breakpoints.down(768)]: {
      display: 'none'
    }
  },
  droppedFile: {
    width: 'calc(33% - 19px)',
    height: 154,
    borderRadius: 8,
    position: 'relative',
    boxSizing: 'border-box',
    margin: 10,
    overflow: 'hidden',
    [breakpoints.down(475)]: {
      width: 'calc(50% - 10px)',
      margin: 5
    }
  },
  disabledForEdit: {
    display: 'none'
  },
  cross: {
    color: theme.palette.common.white,
    position: 'absolute',
    top: 7,
    right: 7,
    cursor: 'pointer',
    minWidth: 'unset',
    width: 'unset',
    minHeight: 'unset',
    height: 'unset',
    padding: 0,
    border: 'none',
    outline: 'red',
    '&:hover': {
      background: 'unset'
    },
    '& .MuiButton-startIcon': {
      margin: 0,
      padding: 0
    },
    '& .MuiButton-iconSizeMedium > *:first-child': {
      height: 'auto',
      borderRadius: '50%',
      boxShadow: '0px 1px 4px rgba(50, 77, 103, 0.15)',
      background: 'rgba(50, 77, 103, 0.15)'
    }
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: "cover"
  },
  blueText: {
    color: theme.palette.common.blue50,
    cursor: 'pointer'
  }
});