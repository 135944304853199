import React from 'react';
import {WrappedFieldProps} from "redux-form";
import {Box, Grid} from "@material-ui/core";
import InputField from "../../../../../../components/InputField";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const RenderInputOfferName: React.FC<WrappedFieldProps & any> = (props) => {
  const classes = useStyles();
  const {disabled} = props;

  return (
    <Grid
      item
      xs={12}>
      <Box
        className={classes.nameWrapper}
        my={0.1}
        display={'flex'}
        alignItems="normal">
        <Box
          width={'100%'}
          flex={'1 1 auto'}>
          <InputField
            {...props}
            disabled={disabled}
            type={'text'}
            placeholder={'Type a place name'}
            variant="filled"
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default RenderInputOfferName;
