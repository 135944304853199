import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {uiSelector} from "../../bus/ui/selectors";
import {SnackbarProvider, useSnackbar} from "notistack";
import {uiActions} from "../../bus/ui/actions";

function MyToast() {
  const { enqueueSnackbar } = useSnackbar();
  const {isToastOpen, toastData} = useSelector(uiSelector);
  const dispatch = useDispatch();

  const closeHandler = () => {
    dispatch(uiActions.toastClose());
    dispatch(uiActions.clearToastData());
  };

  useEffect(() => {
    isToastOpen && enqueueSnackbar(toastData, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      onClose: closeHandler
    });
  }, [isToastOpen, toastData]);

  return (
    <></>
  );
}

const ToastMessage = () => {
  return (
    <SnackbarProvider maxSnack={3}>
      <MyToast/>
    </SnackbarProvider>
  );
};

export default ToastMessage;