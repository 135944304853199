import {call} from "redux-saga/effects";
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {Workers} from "../../../../setup/typedefs";

export function* saveVoucherTags({payload}: Workers){
  try {
    const {formData, voucherID} = payload;

    const response = yield call(fetchWithToken, {
      endpoint: `vouchers/${voucherID}/tags`,
      method: 'PUT',
      body: {tags: formData.tags}
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    yield call(handleMsg, 'New Tags added');

  } catch (e) {
    console.log(e);
  }
}