import React, {useEffect, useState} from 'react';
import clsx from "clsx";
import MobileNavigation from "../MobileNavigation";
import {Drawer} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import {Place} from "../../bus/business/typedefs";
import {useHistory} from "react-router";

const useStyles = makeStyles(styles);

type BurgerMenuProps = {
  isSaleManager: boolean,
  places: Place[] | null,
  choicePlace: any,
  currentPlace: Place | undefined
}

const BurgerMenu: React.FC<BurgerMenuProps> = ({isSaleManager, places, choicePlace, currentPlace}) => {
  const classes = useStyles();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setOpenMobileMenu(false);
  }, [history.location.pathname]);

  const toggleBurgerMenu = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpenMobileMenu(open);
  };

  return (
    <>
      <div
        className={classes.burger}
        onClick={toggleBurgerMenu(true)}>
        <div className={
          clsx(classes.linesWrapper, {
            [classes.burgerOpen]: openMobileMenu
          })
        }>
          <span/>
          <span/>
          <span/>
        </div>
      </div>
      <Drawer
        className={classes.mobileMenu}
        anchor={'right'}
        open={openMobileMenu}
        onClose={toggleBurgerMenu(false)}>
        <MobileNavigation
          isSaleManager={isSaleManager}
          places={places}
          choicePlace={choicePlace}
          currentPlace={currentPlace}
        />
      </Drawer>
    </>
  );
};

export default BurgerMenu;
