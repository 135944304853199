import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import InputTags from "../../../../../../components/InputTags";

const RenderInputTags: React.FC<WrappedFieldProps & any> = ({...props}) => {
  return (
    <FieldRow
      labelText={'Tags'}
      toolTipText={'Start typing tags. Hit tab, comma, or return to complete. Hit backspace/delete to remove'}>
      <InputTags {...props}/>
    </FieldRow>
  );
};

export default RenderInputTags;
