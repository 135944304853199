import React, {useState} from 'react';
import FieldRow from "../../../../components/FieldRow";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import {Field} from "redux-form";
import RenderInputRecommendedGuests from "./components/RenderInputRecommendedGuests";
import RenderInputMaxGuests from "./components/RenderInputMaxGuests";
import CheckBoxField from "../../../../components/CheckBoxField";

const useStyles = makeStyles(styles);

type RenderInputPhotosProps = {
  disabled?: boolean
}

const AdditionalSettings: React.FC<RenderInputPhotosProps> = ({disabled}) => {
  const classes = useStyles();
  const [disabledFields, setDisabledFields] = useState(true);

  return (
    <FieldRow
      labelText={'Additional settings'}
      toolTipText={'These clauses are designed to provide the best possible experience and optimize the performance of your deal'}>
      <div className={classes.rootWrapper}>
        <Field
          disabled={disabled}
          name={'promotePlace'}
          setDisabledFields={setDisabledFields}
          label={'Promote my place in the Groupi app'}
          component={CheckBoxField}
        />
        <Typography variant={'body2'}>
          The number of guests you wish to invite to your location
        </Typography>
        <div className={classes.inputsWrapper}>
          <Field
            disabled={disabled || disabledFields}
            name={'recommendedGuests'}
            component={RenderInputRecommendedGuests}
          />
          <Field
            disabled={disabled || disabledFields}
            name={'maxGuests'}
            component={RenderInputMaxGuests}
          />
        </div>
      </div>
    </FieldRow>
  );
};

export default AdditionalSettings;
