//Core
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//Helpers
import {getPlace} from "../../../../../bus/business/selectors";
import {editVoucherFormSelector, initialEditVoucherFormSelector} from "../../../../../bus/forms/selectors";
import {actionsWithCover, showArrayDiff} from "../../../../../utils/helpers";
import {vouchersActions} from "../../../../../bus/vouchers/actions";
import {ActionWithFile} from "../../../../../setup/typedefs";
import {Voucher} from "../../../../../bus/vouchers/typedefs";
import {usePreventRedirect} from "./usePreventRedirect";
import {profileIsVerified} from "../../../../../bus/profile/selectors";

export const useVoucherEditForm = () => {
  const {saving, isChanged} = usePreventRedirect();
  const dispatch = useDispatch();

  const initVoucherValue = useSelector(initialEditVoucherFormSelector);
  const currentVoucherValue = useSelector(editVoucherFormSelector);
  const place = useSelector(getPlace);
  const isVerified = useSelector(profileIsVerified);

  const [allowDraft, setAllowDraft] = useState(false);
  const [updateTags, setUpdateTags] = useState(false);
  const [actionWithCover, setActionWithCover] = useState<ActionWithFile>('nothing');

  const placeTime = {
    openedTimeEnd: place?.openedTimeEnd,
    openedTimeStart: place?.openedTimeStart
  };

  useEffect(() => {
    currentVoucherValue?.tags.length && setUpdateTags(!!showArrayDiff(initVoucherValue?.tags, currentVoucherValue?.tags).length);
  }, [currentVoucherValue?.tags]);

  useEffect(() => {
    currentVoucherValue && setAllowDraft(!!currentVoucherValue.name);
  },[currentVoucherValue]);

  useEffect(() => {
    const action = actionsWithCover(initVoucherValue, currentVoucherValue);
    setActionWithCover(action);
  },[initVoucherValue, currentVoucherValue]);

  const goPreview = () => {
    currentVoucherValue && dispatch(vouchersActions.createPreview(currentVoucherValue));
  };

  const onSubmit = (values: Voucher) => {
    saving();

    dispatch(vouchersActions.updateVoucher({
      formData: {
        ...values,
        isPublished: true
      },
      info: {
        voucherID: values.id,
        updateTags: updateTags,
        actionWithCover: actionWithCover
      }
    }));
  };

  const onDraftSubmit = () => {
    saving();

    dispatch(vouchersActions.updateVoucher({
      formData: {
        ...currentVoucherValue,
        isPublished: false
      },
      info: {
        voucherID: currentVoucherValue.id,
        updateTags: updateTags,
        actionWithCover: actionWithCover
      }
    }));
  };

  return {
    goPreview,
    placeTime,
    allowDraft,
    isChanged,
    isVerified,
    onSubmit,
    onDraftSubmit
  };
};
