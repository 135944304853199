import {call, put} from "redux-saga/effects";
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {Workers} from "../../../../setup/typedefs";
import {uiActions} from "../../../ui/actions";
import {push} from "connected-react-router";
import {book} from "../../../../routes/book";
import {placesActions} from "../../actions";

export function* reassignPlace({payload}: Workers){
  try {
    yield put(uiActions.loading(true));

    const {placeId, mail, userId, redirectUrl} = payload
    const endpoint = `places/${placeId}/reassign`;
    const body = {email: mail, redirectUrl};

    const response = yield call(fetchWithToken, {
      endpoint,
      method: 'PUT',
      body
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    yield call(handleMsg, 'Place successful reassign');
    yield put(placesActions.fetchPlaces(userId));
    yield put(push(book.personal));

  } catch (e) {
    console.log(e);
  } finally {
    yield put(uiActions.loading(false));
  }
}
