import React from 'react';
import {WrappedFieldProps} from "redux-form";
import {
  MenuItem,
  Select
} from "@material-ui/core";
import FieldRow from "../../../../../../components/FieldRow";
import DropDownArrow from "../../../../../../components/DropDownArrow";
import {defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {getCountries} from "../../../../../../bus/options/selectors";

const msg = defineMessages({
  my_place_form_render_input_country_label: {
    id: "my_place_form_render_input_country_label",
    defaultMessage: "Country"
  }
});

const RenderInputCountry = ({input}: WrappedFieldProps) => {
  const {formatMessage} = useIntl();
  const countries = useSelector(getCountries);

  const countriesJSX = countries?.map((country) => {
    return (
      <MenuItem
        key={country.id}
        value={country.id}>
        {country.name}
      </MenuItem>
    );
  });

  return (
    <FieldRow
      labelText={formatMessage(msg.my_place_form_render_input_country_label)}
      onlyStar>
      <Select
        IconComponent={DropDownArrow}
        fullWidth
        {...input}
        variant={"outlined"}
        labelId="label"
        id="select">
        {countriesJSX}
      </Select>
    </FieldRow>
  );
};

export default RenderInputCountry;