import React from 'react';
import IconHolder from "../IconHolder";
import Upload from "../Icons/Upload";
import {Typography, useTheme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {styles} from "./styles";
import {GroupiTheme} from "../../customTheme";
import {useDropzone} from "react-dropzone";
import {uiActions} from "../../bus/ui/actions";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(styles);

const DropFrame: React.FC<any> = (props) => {
  const {maxSize, singleImage, input, files} = props;
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const dispatch = useDispatch();

  const sizeForFront = Math.round(maxSize / 1000000);

  const {getRootProps, getInputProps} = useDropzone({
    multiple: !singleImage,
    maxFiles: 9,
    accept: 'image/jpeg, image/png, image/jpg',
    maxSize: maxSize,
    onDropRejected: (fileRejections) => {
      handleError(fileRejections);
    },
    onDrop: (acceptedFiles: object[]) => {
      if (acceptedFiles.length + files.length <= 9) {
        input.onChange(
          files.concat(
            acceptedFiles?.map((file: object, index: number) => Object.assign(file, {
              preview: URL.createObjectURL(file),
              origin: URL.createObjectURL(file),
              index: files?.length + index
            }))
          ));
      } else {
        dispatch(uiActions.toast('Too many files'));
      }
    }
  });

  const handleError = (errors: any) => {
    errors.forEach((item: any) => {
      item.errors.forEach((item: any) => {
        dispatch(uiActions.toast(item.message));
      });
    });
  };

  return (
    <div
      {...getRootProps()}
      className={classes.fileDropZone}>
      <input
        {...getInputProps()}
        name={input.name}
      />
      <div className={classes.innerInfo}>
        <IconHolder
          size={41}
          color={theme.palette.common.gray88}>
          <Upload/>
        </IconHolder>
        <div>
          <Typography variant={'body2'}>
            browse to choose a file
          </Typography>
          <Typography variant={'caption'}>
            .JPG .JPEG .PNG (max
            {' '}
            {sizeForFront}
            {' '}
            MB)
          </Typography>
        </div>
      </div>
      <div className={classes.textWrapper}>
        <Typography variant={'body2'}>
          Drag and drop an image or
          {' '}
          <span
            {...getRootProps()}
            className={classes.blueText}>
            browse
          </span>
          {' '}
          to choose a file
        </Typography>
      </div>
    </div>
  );
};

export default DropFrame;