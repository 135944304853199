import React from 'react';
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import clsx from "clsx";
import RenderNotification from "./components/RenderNotification";
import {useSelector} from "react-redux";
import {notificationsSelector} from "../../bus/notifications/selectors";
import SidebarBottom from "../../components/SidebarBottom";

const useStyles = makeStyles(styles);

type PageContainerProps = {
  children: React.ReactNode,
  withOutHeader?: boolean,
  withOutSidebar?: boolean,
}

const PageContainer: React.FC<PageContainerProps> = ({children, withOutHeader = false, withOutSidebar = false}) => {
  const classes = useStyles();
  const notificationList = useSelector(notificationsSelector);

  return (
    <div className={
      clsx({
        [classes.header]: !withOutHeader,
        [classes.sidebar]: !withOutSidebar
      })
    }>
      {
        !withOutHeader &&(
          <Header/>
        )
      }
      {
        !withOutSidebar &&(
          <div className={classes.sidebarWrapper}>
            <Sidebar withOutHeader={withOutHeader}/>
            <SidebarBottom/>
          </div>
        )
      }
      <div className={classes.pageHolder}>
        {
          !!notificationList?.length &&(
            <RenderNotification notificationList={notificationList}/>
          )
        }
        {children}
      </div>
    </div>
  );
};

export default PageContainer;
