import {call, put} from "redux-saga/effects";
import {Workers} from "../../../../setup/typedefs";
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {vouchersActions} from "../../actions";
import {push} from "connected-react-router";
import {book} from "../../../../routes/book";
import {uiActions} from "../../../ui/actions";

export function* createVoucher({payload}: Workers){
  try {
    const {formData, info: {placeID, actionWithCover}} = payload;

    const body = {
      name: formData.name,
      dateStart: Number(formData.dateStart / 1000),
      dateEnd: Number(formData.dateEnd / 1000),
      usePeriod: formData.usePeriod,
      timeStart: formData.timeStart,
      timeEnd: formData.timeEnd,
      availability: formData.availability,
      description: formData.description,
      originalPrice: formData.originalPrice,
      discountPrice: formData.discountPrice,
      isPublished: formData.isPublished
    };

    const response = yield call(fetchWithToken, {
      endpoint: `places/${placeID}/vouchers`,
      method: 'PUT',
      body
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    const {data: {name, id: voucherID}} = yield call([response, 'json']);

    if (actionWithCover === 'save') {
      yield put(vouchersActions.saveVoucherCover({formData, voucherID}));
    } else if (actionWithCover === 'remove') {
      yield put(vouchersActions.removeCover({voucherID}));
    } else if (actionWithCover === 'reSave') {
      yield put(vouchersActions.saveVoucherCover({formData, voucherID}));
    }

    if (formData?.tags.length) {
      yield put(vouchersActions.saveVoucherTags({formData, voucherID}));
    }

    yield call(handleMsg, `Voucher "${name}" successful create`);
    yield put(vouchersActions.clear());
    yield put(push(book.activeVouchers));

  } catch (e) {
    console.log(e);
  }
  yield put(uiActions.confirm(false));
}
