import React from 'react';
import {WrappedFieldProps} from "redux-form";
import FieldRow from "../../../../../../components/FieldRow";
import InputField from "../../../../../../components/InputField";

const RenderInputDescription = ({...props}:WrappedFieldProps) => {
  return (
    <FieldRow
      labelText={'Short description'}>
      <InputField
        limit={200}
        {...props}
        type={'text'}
        placeholder={'Write a few words about your business...'}
        multiline
        rows={4}
      />
    </FieldRow>
  );
};

export default RenderInputDescription;
