import {RootState} from "../../setup/typedefs";
import {createSelector} from "reselect";

export const profileSelector = (state: RootState) => state.profile;

export const profileIsVerified = createSelector([profileSelector], (result) => {
  return result.isVerified;
});

export const profileEmail = createSelector([profileSelector], (result) => {
  return result.email;
});

export const profileID = createSelector([profileSelector], (result) => {
  return result.id;
});

export const profileName = createSelector([profileSelector], (result) => {
  return result.name;
});

export const profilePhone = createSelector([profileSelector], (result) => {
  return result.phone;
});

export const profileStatus = createSelector([profileSelector], (result) => {
  return result.status;
});

export const isSaleManager = createSelector([profileSelector], (result) => {
  return result.type === 'seller';
});
