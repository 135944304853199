import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const Warning = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 36 36">
      <path
        d="M17.9995 23.5565C17.1049 23.5565 16.355 24.3064 16.355 25.201C16.355 26.0957 17.1049 26.8456 17.9995 26.8456C18.8612 26.8456 19.644 26.0957 19.6045 25.2405C19.644 24.2998 18.9007 23.5565 17.9995 23.5565Z"
      />
      <path
        d="M33.2213 29.3847C34.2541 27.6021 34.2606 25.4773 33.2345 23.7013L22.9332 5.86147C21.9136 4.06565 20.0717 3 18.0062 3C15.9407 3 14.0988 4.07223 13.0792 5.85489L2.76473 23.7144C1.73855 25.5102 1.74513 27.6481 2.78447 29.4308C3.81065 31.1937 5.64594 32.2528 7.6983 32.2528H28.2746C30.3335 32.2528 32.182 31.1806 33.2213 29.3847ZM30.9847 28.0954C30.4125 29.0821 29.3994 29.6676 28.268 29.6676H7.69173C6.57345 29.6676 5.567 29.0953 5.00786 28.1283C4.44215 27.1482 4.43557 25.9773 5.00129 24.9906L15.3157 7.13762C15.8749 6.15748 16.8747 5.57861 18.0062 5.57861C19.131 5.57861 20.1375 6.16406 20.6966 7.1442L31.0045 24.9971C31.557 25.9575 31.5505 27.1153 30.9847 28.0954Z"
      />
      <path
        d="M17.5918 12.0122C16.809 12.2359 16.3223 12.9463 16.3223 13.8081C16.3617 14.3277 16.3946 14.854 16.4341 15.3737C16.5459 17.3537 16.6577 19.2942 16.7696 21.2742C16.809 21.9452 17.3287 22.4319 17.9997 22.4319C18.6706 22.4319 19.1969 21.9123 19.2298 21.2347C19.2298 20.8269 19.2298 20.4519 19.2693 20.0375C19.3416 18.7679 19.4205 17.4984 19.4929 16.2288C19.5324 15.4065 19.6047 14.5843 19.6442 13.762C19.6442 13.466 19.6047 13.2029 19.4929 12.9398C19.1574 12.203 18.3746 11.8281 17.5918 12.0122Z"
      />
    </SvgIcon>
  );
};

export default Warning;
