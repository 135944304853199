//Core
import React from 'react';

//Helpers
import {ListStatus} from "../../../../../setup/typedefs";
import {book} from "../../../../../routes/book";

//Components
import ListOfOffers from "../ListOfOffers";

const ExpiredOffers = () => {
  return (
    <ListOfOffers
      status={ListStatus.Expired}
      paginationPath={book.expiredDeals}
      title={'Expired'}
    />
  );
};

export default ExpiredOffers;