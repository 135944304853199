import {types} from "./types";
import {AnyAction} from 'redux';
import {INotification} from "../../setup/typedefs";

export type NotificationsState = {
  data: INotification[]
}

const initialState: NotificationsState = {
  data: []
};

export const notificationsReducer = (state= initialState, action: AnyAction) => {
  switch (action.type) {
  case types.FILL_NOTIFICATIONS:
    return {
      ...state,
      data: action.payload
    };

  case types.CLEAR:
    return initialState;

  default:
    return state;
  }
};