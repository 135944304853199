import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

const LetterIconInNotification = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 36 36">
      <path
        d="M32.8571 7H3.14288C2.51167 7 2 7.51167 2 8.14281V28.7142C2 29.3454 2.51167 29.8571 3.14288 29.8571H32.8571C33.4883 29.8571 34 29.3454 34 28.7142V8.14281C34 7.51167 33.4883 7 32.8571 7ZM30.9829 9.28569L18 19.272L5.01714 9.28569H30.9829ZM31.7142 27.5714H4.28569V11.6068L17.304 21.6205C17.7145 21.9357 18.2855 21.9357 18.696 21.6205L31.7142 11.6068V27.5714Z"
        fill={props.fill}
      />
    </SvgIcon>
  );
};

export default LetterIconInNotification;