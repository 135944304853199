import React from 'react';
import SvgIcon, {SvgIconProps} from "@material-ui/core/SvgIcon";

const Party = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 1L18.495 2.50503L20 3L18.495 3.49497L18 5L17.505 3.49497L16 3L17.505 2.50503L18 1ZM12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3ZM9.30872 8.10884L8.50002 10.3732V12C8.50002 14.4215 10.0649 16.4775 12.2386 17.2119L16.2484 16.3527C12.8121 14.792 10.2598 11.7579 9.30872 8.10884ZM6.58025 15.7486L7.57211 12.9714C7.86332 14.9148 9.01489 16.5768 10.6268 17.5573L8.29449 18.0571C7.43324 17.5755 6.7964 16.7406 6.58025 15.7486ZM6.06642 17.1873C6.30745 17.6198 6.61775 18.0085 6.98232 18.3382L5.5454 18.6461L6.06642 17.1873ZM7.81151 6.35462C8.15242 5.40007 9.28573 5.00164 10.149 5.53287L10.2608 5.60167C14.1849 8.01651 17.1681 11.6974 18.7178 16.0366C19.0397 16.938 18.4944 17.9168 17.5585 18.1173L4.20955 20.9778C3.85487 21.0538 3.48686 20.9318 3.24784 20.659C3.00882 20.3861 2.93628 20.0053 3.05828 19.6637L7.81151 6.35462ZM21 17C21.5523 17 22 16.5523 22 16C22 15.4477 21.5523 15 21 15C20.4477 15 20 15.4477 20 16C20 16.5523 20.4477 17 21 17ZM21.5713 13.2572C20.8849 12.1133 19.4012 11.7424 18.2573 12.4287L17.7428 11.5713C19.3603 10.6008 21.4583 11.1252 22.4288 12.7428L21.5713 13.2572ZM12.7764 4.44721L12.9325 4.52526C13.8003 4.95914 14.2503 5.93751 14.0149 6.87873L14.9851 7.12127C15.3341 5.72534 14.6667 4.27432 13.3797 3.63083L13.2236 3.55279L12.7764 4.44721ZM16.4472 9.72361C17.4283 7.76146 19.8143 6.96614 21.7764 7.94721L22.2236 7.05279C19.7675 5.82472 16.7809 6.82026 15.5528 9.27639L16.4472 9.72361Z"
      />
    </SvgIcon>
  );
};

export default Party;