import {book} from "../../routes/book";

//для добавления перевода нужно сделать глобальную обертку на все приложение, но это не точно
const accountItems: SideBarLinkType[] = [
  {
    name: 'profile',
    label: 'Personal',
    link: book.personal,
    error: false
  }
  // {
  //   name: 'password',
  //   label: 'Password',
  //   link: book.password,
  //   error: false
  // },
  // {
  //   name: 'subscriptions',
  //   label: 'Subscriptions',
  //   link: book.mySubscriptions,
  //   error: false
  // }
];

const businessItems: SideBarLinkType[] = [
  {
    name: 'place',
    label: 'My place',
    link: book.myPlace,
    error: false
  }
  // {
  //   name: 'myRevenue',
  //   label: 'My revenue',
  //   link: book.myRevenue,
  //   error: false
  // }
];

const offersItems: SideBarLinkType[] = [
  {
    name: 'new',
    label: 'Create new',
    link: book.createDeals,
    error: false
  },
  {
    name: 'active',
    label: 'Active',
    link: book.activeDeals + '/1',
    error: false
  },
  {
    name: 'expired',
    label: 'Expired',
    link: book.expiredDeals + '/1',
    error: false
  },
  {
    name: 'pending',
    label: 'Pending',
    link: book.pendingDeals + '/1',
    error: false
  },
  {
    name: 'rejected',
    label: 'Rejected',
    link: book.rejectedDeals + '/1',
    error: false
  },
  {
    name: 'drafts',
    label: 'Drafts',
    link: book.draftDeals + '/1',
    error: false
  }
];

const vouchersItems: SideBarLinkType[] = [
  {
    name: 'active',
    label: 'Active',
    link: book.activeVouchers + '/1',
    error: false
  },
  {
    name: 'expired',
    label: 'Expired',
    link: book.expiredVouchers + '/1',
    error: false
  },
  {
    name: 'pending',
    label: 'Pending',
    link: book.pendingVouchers + '/1',
    error: false
  },
  {
    name: 'rejected',
    label: 'Rejected',
    link: book.rejectedVouchers + '/1',
    error: false
  },
  {
    name: 'drafts',
    label: 'Drafts',
    link: book.draftVouchers + '/1',
    error: false
  }
];

const marketingItems: SideBarLinkType[] = [
  {
    name: 'marketing',
    label: 'Marketing',
    link: book.marketing,
    error: false
  }
];

export {accountItems, businessItems, offersItems, vouchersItems, marketingItems};

export type SideBarLinkType = {
  name: string,
  label: string,
  link: string,
  error?: boolean
}
