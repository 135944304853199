import {types} from "./types";
import {IPopupData} from "./typedefs";

export const uiActions = {
  loading: (status: boolean) => {
    return {
      type: types.LOADING,
      payload: status
    };
  },
  confirm: (confirm: boolean) => {
    return {
      type: types.CONFIRM,
      payload: confirm
    };
  },
  popup: (data: IPopupData) => {
    return {
      type: types.POPUP,
      payload: data
    };
  },
  fillPopupData: (data: IPopupData) => {
    return {
      type: types.FILL_POPUP_DATA,
      payload: data
    };
  },
  clearPopupData: () => {
    return {
      type: types.CLEAR_POPUP_DATA
    };
  },
  popupOpen: () => {
    return {
      type: types.POPUP_OPEN
    };
  },
  popupClose: () => {
    return {
      type: types.POPUP_CLOSE
    };
  },
  toast: (data: string) => {
    return {
      type: types.TOAST,
      payload: data
    };
  },
  toastOpen: () => {
    return {
      type: types.TOAST_OPEN
    };
  },
  toastClose: () => {
    return {
      type: types.TOAST_CLOSE
    };
  },
  fillToastData: (data: IPopupData) => {
    return {
      type: types.FILL_TOAST_DATA,
      payload: data
    };
  },
  clearToastData: () => {
    return {
      type: types.CLEAR_TOAST_DATA
    };
  },
  clear: () => {
    return {
      type: types.CLEAR
    };
  }
};