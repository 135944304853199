import React from 'react';
import {IconButton, Typography, useTheme} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {PopupProps} from "../../index";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import IconHolder from "../../../IconHolder";
import CrossInCircle from "../../../Icons/CrossInCircle";
import {GroupiTheme} from "../../../../customTheme";
import PopupForm from "./form/PopupForm";

const useStyles = makeStyles(styles);

const PopupInvitation = ({onClose}:PopupProps) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.closeCross}
        onClick={onClose}
        color="inherit"
        size="small">
        <IconHolder
          size={24}
          color={theme.palette.common.gray88}>
          <CrossInCircle/>
        </IconHolder>
      </IconButton>
      <Typography variant={'h3'}>
        Send an invitation
      </Typography>
      <div className={classes.content}>
        <span/>
        <Typography variant={'body2'}>
          Lorem ipsum dolor sit amet, latine erroribus per ei, ut dolore propriae ius. Cu has iuvaret fuisset electram, no sit soleat perfecto posidonium.
        </Typography>
        <PopupForm/>
      </div>
      <div className={classes.action}>
        <Button
          className={classes.sendButton}
          variant="contained"
          color="primary">
          Send an invitation
        </Button>
      </div>
    </div>
  );
};

export default PopupInvitation;
