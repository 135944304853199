//Core
import React from 'react';

//Helpers
import {ListStatus} from "../../../../../setup/typedefs";
import {book} from "../../../../../routes/book";

//Components
import ListOfVouchers from "../ListOfVouchers";

const RejectedVouchers = () => {
  return (
    <ListOfVouchers
      status={ListStatus.Rejected}
      paginationPath={book.recreateVoucher}
      title={'Rejected'}
    />
  );
};

export default RejectedVouchers;
