import React from 'react';
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import syncValidate from "../../../../../utils/syncValidate";
import {Button} from "@material-ui/core";
import schema from "./schema";
import {styles} from "./styles";
import {book} from "../../../../../routes/book";
import {Link} from "react-router-dom";
import RenderInputMail from "./formFields/RenderInputMail";
import {defineMessages, useIntl} from "react-intl";
import makeStyles from "@material-ui/core/styles/makeStyles";

const msg = defineMessages({
  reset_password_button_send: {
    id: "reset_password_button_send",
    defaultMessage: "Send reset letter"
  },
  reset_password_button_back: {
    id: "reset_password_button_back",
    defaultMessage: "Back"
  }
});

const useStyles = makeStyles(styles);

const ResetPasswordForm = (props: InjectedFormProps) => {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const {handleSubmit} = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.input}>
        <Field
          name={'email'}
          component={RenderInputMail}
        />
      </div>
      <div className={classes.buttonsWrapper}>
        <Button
          className={classes.sendButton}
          type='submit'
          variant="contained"
          color="primary">
          {formatMessage(msg.reset_password_button_send)}
        </Button>
        <Link
          to={book.signIn}
          className={classes.backButton}>
          <Button
            type='submit'
            variant="outlined"
            color="primary">
            {formatMessage(msg.reset_password_button_back)}
          </Button>
        </Link>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'resetPasswordForm',
  validate: syncValidate(schema)
})(ResetPasswordForm);
