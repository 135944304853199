import {types} from "./types";
import {AnyAction} from 'redux';
import {Nullable, ListStatus} from "../../setup/typedefs";

export type ProfileState = {
  id: Nullable<number>,
  name: Nullable<string>,
  email: Nullable<string>,
  phone: Nullable<string>,
  isVerified: Nullable<boolean>,
  type: Nullable<'default' | 'seller'>,
  status: Nullable<ListStatus>

}

const initialState: ProfileState = {
  id: null,
  name: null,
  email: null,
  phone: null,
  isVerified: null,
  type: 'default',
  status: null
};

export const profileReducer = (state= initialState, action: AnyAction) => {
  switch (action.type) {
  case types.FILL_PROFILE:
    return {
      ...state,
      ...action.payload
    };

  case types.CLEAR:
    return initialState;

  default:
    return state;
  }
};
