import React from 'react';
import {IconButton, Typography, useTheme} from "@material-ui/core";
import IconHolder from "../../../../../../components/IconHolder";
import {GroupiTheme} from "../../../../../../customTheme";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import Recreate from "../../../../../../components/Icons/Recreate";
import {useHistory} from "react-router-dom";
import {book} from "../../../../../../routes/book";
import {useParams} from "react-router";

const useStyles = makeStyles(styles);

const RecreateButton = () => {
  const {id} = useParams<{ id: string }>();
  const history = useHistory();
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();

  return (
    <div className={classes.button}>
      <IconButton
        color="primary"
        onClick={() => {history.push(book.recreateVoucher + `/${id}`);}}>
        <IconHolder
          size={32}
          color={theme.palette.common.blue50}>
          <Recreate/>
        </IconHolder>
      </IconButton>
      <Typography variant={'body2'}>
        Recreate
      </Typography>
    </div>
  );
};

export default RecreateButton;