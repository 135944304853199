//Core
import React from 'react';

//Helpers
import {useRenderSingleOffer} from "../../customHooks/useRenderSingleOffer";
import {concatDate, concatTime} from "../../../../../../utils/helpers";
import {Offer} from "../../../../../../bus/offers/typedefs";

//Styles
import {Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import OfferStatus from "../../../../../../components/Status";
import Calendar from "../../../../../../components/Icons/Calendar";
import Repeat from "../../../../../../components/Icons/Repeat";
import Clock from "../../../../../../components/Icons/Clock";
import WrapRow from "../../../../components/WrapRow";
import RenderPeriod from "../RenderPeriod";
import RenderDescription from "../../../../components/RenderDescription";
import StatisticsWrapRow from "../StatisticsWrapRow";
import ControlButtons from "../ControlButtons";
import Dynamics from "../Dynamics";

const useStyles = makeStyles(styles);

type RenderSingleOfferProps = {
  data: Offer
}

const RenderSingleOffer: React.FC<RenderSingleOfferProps> = ({data}) => {
  const classes = useStyles();
  const {tablet, randomImg, goPreview} = useRenderSingleOffer(data);

  return (
    <div className={classes.root}>
      <OfferStatus status={data.status}/>
      <Typography
        variant={'h1'}
        className={classes.offerName}>
        {data.name}
      </Typography>
      <div className={classes.topBlock}>
        <div className={classes.wrapperImgNButton}>
          <div className={classes.imgHolder}>
            <img
              src={data.cover?.origin ? data.cover?.origin : `/defaultImage${randomImg}.svg`}
              alt={data.name}
            />
          </div>
          {!tablet && (
            <Button
              onClick={goPreview}
              className={classes.previewButton}
              variant="outlined"
              color="primary">
              Preview
            </Button>
          )}
        </div>
        <div className={classes.mainInfoBlock}>
          <WrapRow
            icon={<Calendar/>}
            content={concatDate(data.dateStart, data.dateEnd, true)}
          />
          <WrapRow
            icon={<Repeat/>}
            content={data.availability}
          />
          <WrapRow
            icon={<Clock/>}
            content={concatTime(data.timeStart, data.timeEnd)}
          />
          {tablet && (
            <Button
              onClick={goPreview}
              className={classes.previewButton}
              variant="outlined"
              color="primary">
              Preview
            </Button>
          )}
          {!tablet && (
            <RenderDescription data={data}/>
          )}
        </div>
      </div>
      {tablet && (
        <RenderDescription data={data}/>
      )}
      <ControlButtons data={data}/>
      <RenderPeriod data={data}/>
      <div className={classes.subMainInfoBlock}>
        <Typography variant={'h3'}>
          Statistics
        </Typography>
        <div className={classes.statisticsWrapper}>
          <StatisticsWrapRow
            value={data.viewsCount}
            title={'Views'}
          />
          <StatisticsWrapRow
            value={data.favoritesCount}
            title={'Added to favorite'}
          />
          <StatisticsWrapRow
            value={data.reservationsCount}
            title={'Reservations'}
          />
        </div>
      </div>
      <div className={classes.subMainInfoBlock}>
        <Typography variant={'h3'}>
          Dynamics
        </Typography>
        <Dynamics/>
      </div>
    </div>
  );
};

export default RenderSingleOffer;
