import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPlace} from "../../../../../bus/business/selectors";
import {offersActions} from "../../../../../bus/offers/actions";
import {Offer} from "../../../../../bus/offers/typedefs";
import {profileIsVerified} from "../../../../../bus/profile/selectors";
import {getOfferPreview} from "../../../../../bus/offers/selectors";
import {getFieldsInArray} from "../../../../../utils/helpers";
import {change, getFormValues, isDirty, isPristine, isValid, reset} from "redux-form";

export const useOfferCreateForm = () => {
  const dispatch = useDispatch();

  const form = 'OfferCreateForm';
  const formValue = useSelector<Offer>(getFormValues(form)) as Offer;
  const place = useSelector(getPlace);
  const offerPreview = useSelector(getOfferPreview);
  const isVerified = useSelector(profileIsVerified);
  const pristine = useSelector(isPristine(form));
  const dirty = useSelector(isDirty(form));
  const valid = useSelector(isValid(form));

  const [placeNotActiveStatus, setPlaceNotActiveStatus] = useState<boolean>();

  useEffect(() => {
    place?.status && setPlaceNotActiveStatus(place?.status !== 'active');
  }, [place]);

  //offer from preview
  useEffect(() => {
    if (offerPreview) {
      getFieldsInArray(offerPreview).forEach((item) => {
        // @ts-ignore
        dispatch(change(form, item, offerPreview[item]));
      });
    }
  },[offerPreview]);

  //create and go to preview
  const goPreview = () => {
    formValue && dispatch(offersActions.createPreview(formValue));
  };

  //save offer
  const onSubmit = (isPublished: any, values: Offer | any) => {
    dispatch(reset(form));

    if (values && place) {
      dispatch(offersActions.updateOffer({
        formData: {
          ...values,
          isPublished
        },
        info: {
          placeID: place?.id,
          actionWithCover: !values.cover ? 'nothing' : 'save',
          updateTags: !!values.tags.length
        }
      }));
    }
  };

  return {
    goPreview,
    isVerified,
    onSubmit,
    placeNotActiveStatus,
    pristine,
    dirty,
    valid
  };
};
