import {createMuiTheme, createStyles} from "@material-ui/core";
import {GroupiTheme} from "../../../../../../customTheme";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  dynamicsWrapper: {
    position: 'relative'
  },
  dynamicsForBrush: {
    pointerEvents: 'none',
    '&.recharts-wrapper': {
      pointerEvents: 'none',
      transform: 'translate(20px, 45px)'
    },
    top: '100%'
  },
  dynamics: {
    height: 500,
    '& .recharts-surface': {
      height: 500
    },
    [breakpoints.down(1200)]: {},
    '& line': {
      stroke: theme.palette.common.gray88
    },
    '& text': {
      fill: theme.palette.common.gray75,
      fontFamily: 'Source Sans Pro',
      fontWeight: 700,
      fontSize: 11,
      lineHeight: 1
    },
    '& .recharts-cartesian-grid-horizontal': {
      '& line': {
        '&:last-child': {
          display: 'none'
        }
      }
    },
    '& .recharts-active-dot': {
      '& circle': {
        fill: theme.palette.common.blue50
      }
    },
    '& .recharts-line': {
      '& path': {
        stroke: theme.palette.common.blue50,
        strokeWidth: '2px'
      },
      '& circle': {
        stroke: theme.palette.common.blue50,
        strokeWidth: '2px',
        r: 4
      }
    },
    '& .xAxis': {
      '& text': {
        transform: 'translateY(15px)'
      }
    },
    '& .recharts-brush': {
      transform: 'translateY(40px)',
      '& .recharts-brush-traveller': {
        '& rect': {
          fill: theme.palette.common.blue50,
          opacity: .8
        }
      },
      '& rect': {
        stroke: theme.palette.common.gray60,
        '&.recharts-brush-slide': {
          stroke: theme.palette.common.gray75,
          fill: theme.palette.common.blue50
        }
      }
    }
  }
});
