import React, {useEffect, useState} from 'react';
import {TextField, Typography, useTheme} from "@material-ui/core";
import {WrappedFieldProps} from "redux-form";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import {GroupiTheme} from "../../customTheme";

const useStyles = makeStyles(styles);

const InputField = (props: WrappedFieldProps & InputFieldPropsInterface) => {
  const theme: GroupiTheme = useTheme();
  const classes = useStyles();
  const {
    input,
    meta,
    type,
    disabled = false,
    variant = 'outlined',
    multiline = false,
    rows = 1,
    placeholder = '',
    limit = false,
    width = '100%'
  } = props;
  const [textLimit, setTextLimit] = useState(limit);
  const [currentLengthValue, setCurrentLengthValue] = useState(0);

  useEffect(() => {
    limit && setTextLimit(limit);
  }, [limit]);

  const typing = (event: any) => {
    const val = event.target.value;

    if (limit) {
      setCurrentLengthValue(val?.length);
      if (textLimit > val?.length) {
        input.onChange(val);
      }
    } else {
      input.onChange(val);
    }
  };

  return (
    <div
      className={classes.root}
      style={{'width': width}}>
      <TextField
        {...input}
        onChange={typing}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        multiline={multiline}
        rows={rows}
        fullWidth
        variant={variant}
        helperText={meta.touched && !meta.active && meta.error}
        error={meta.touched && !meta.active && !!meta.error}
      />
      {
        !!limit && (
          <div className={classes.innerLimitText}>
            <Typography
              color={'inherit'}
              style={{color: theme.palette.common.gray60}}
              variant={'caption'}>
              {currentLengthValue}
              /
              {textLimit}
            </Typography>
          </div>
        )
      }
    </div>
  );
};

export default InputField;

export interface InputFieldPropsInterface {
  type: string,
  placeholder?: string,
  multiline?: boolean,
  disabled?: boolean,
  rows?: number,
  variant?: 'standard' | 'filled' | 'outlined' | undefined,
  limit?: boolean | number,
  width?: string
}
