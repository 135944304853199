import {call} from "@redux-saga/core/effects";
import {ResetPasswordFormValues} from "../../../../containers/AuthContainer/pages/ResetPassword/form/typedefs";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {fetchWithoutToken} from "../../../utils/fetching";

export function* resetPassword({payload: body}: {type: string, payload: ResetPasswordFormValues}){
  try {
    const dataForFetching = {
      endpoint: 'merchants/auth/send-forgot-pass-email',
      method: 'POST'
    };

    const response = yield call(fetchWithoutToken, {...dataForFetching, body});

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    yield call(handleMsg, 'Please check you email');

  } catch (e) {
    console.log(e);
  }
}
