import {GroupiTheme} from "../../customTheme";
import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: 111,
    borderLeftColor: theme.palette.common.gray88,
    borderLeft: '1px solid',
    [breakpoints.down(600)]: {
      width: 67
    }
  },
  logOutButton: {
    height: '100%',
    width: '100%'
  },
  logoutIcon: {
    fontSize: 32,
    color: theme.palette.common.blue50
  }
});