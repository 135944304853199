import { all, call, takeEvery } from 'redux-saga/effects';
import {types} from "../types";
import {
  fetchGooglePlace,
  fetchPlace,
  fetchPlaces,
  updatePlaceDocuments,
  updatePlacePhotos,
  updatePlace,
  updatePlaceTags,
  createPreview,
  reassignPlace,
} from "./workers";

function * watchFetchPlaces() {
  yield takeEvery(types.FETCH_PLACES, fetchPlaces);
}
function * watchReassignPlaces() {
  yield takeEvery(types.REASSIGN_PLACE, reassignPlace);
}

function * watchUpdatePlaceTags() {
  yield takeEvery(types.UPDATE_PLACE_TAGS, updatePlaceTags);
}

function * watchUpdatePlacePhotos() {
  yield takeEvery(types.UPDATE_PLACE_PHOTOS, updatePlacePhotos);
}

function * watchUpdatePlaceDocuments() {
  yield takeEvery(types.UPDATE_PLACE_DOCUMENTS, updatePlaceDocuments);
}

function * watchUpdatePlace() {
  yield takeEvery(types.UPDATE_PLACE, updatePlace);
}

function * watchFetchPlace() {
  yield takeEvery(types.FETCH_PLACE, fetchPlace);
}

function * watchFetchGooglePlace() {
  yield takeEvery(types.FETCH_GOOGLE_PLACE, fetchGooglePlace);
}

function * watchCreatePreview() {
  yield takeEvery(types.CREATE_PREVIEW, createPreview);
}

export function* watchPlaces(){
  yield all([
    call(watchReassignPlaces),
    call(watchCreatePreview),
    call(watchFetchPlace),
    call(watchUpdatePlace),
    call(watchUpdatePlaceTags),
    call(watchUpdatePlacePhotos),
    call(watchUpdatePlaceDocuments),
    call(watchFetchPlaces),
    call(watchFetchGooglePlace)
  ]);
}