import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup.string()
    .matches(/^[\w-\.]{3,}@([\w-]{3,}\.)+[\w-]{2,4}$/, {message: 'Email must be a valid. Do not use special characters: #, %, ^, &, *, ?, !'})
    .required('Email is required field.'),
  contactName: yup.string()
    .nullable()
    .required('Enter contact name.'),
  contactNumber: yup.string()
    .nullable()
    .required('Enter contact number.')
});

export default schema;
