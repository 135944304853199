import React, {useState} from 'react';
import Alert from "@material-ui/lab/Alert";
import {Collapse, IconButton, Typography, useTheme} from "@material-ui/core";
import IconHolder from "../IconHolder";
import CrossInCircle from "../Icons/CrossInCircle";
import {GroupiTheme} from "../../customTheme";
import LetterIconInNotification from "../Icons/LetterIconInNotification";
import Warning from "../Icons/Warning";
import Prolong from "../Icons/Prolong";
import CheckMarkInNotification from "../Icons/CheckMarkInNotification";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {INotification} from "../../setup/typedefs";
import ReactHtmlParser from "react-html-parser";
import {notificationsActions} from "../../bus/notifications/actions";
import {profileID} from "../../bus/profile/selectors";

const useStyles = makeStyles(styles);

type NotificationTemplateProps = {
  singleNotification: INotification
}

const NotificationTemplate: React.FC<NotificationTemplateProps> = ({singleNotification}) => {
  const classes = useStyles();
  const theme: GroupiTheme = useTheme();
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const merchantID = useSelector(profileID);

  const closeNotification = (notificationID: number) => {
    setOpen(false);
    merchantID && dispatch(notificationsActions.disableNotifications({notificationID, merchantID}));
  };

  const setCrossColor = (type: string) => {
    switch (type) {
    case 'success':
      return theme.palette.common.green;

    case 'warning':
      return theme.palette.common.yellow;

    case 'error':
      return theme.palette.common.red;

    default:
      return theme.palette.common.gray60;
    }
  };

  const renderIcon = (icon: string) => {
    switch (icon) {
    case 'mail':
      return (
        <IconHolder
          size={36}
          color={theme.palette.common.white}>
          <LetterIconInNotification/>
        </IconHolder>
      );

    case 'attention':
      return (
        <IconHolder
          size={36}
          color={theme.palette.common.white}>
          <Warning/>
        </IconHolder>
      );

    case 'prolong':
      return (
        <IconHolder
          size={46}
          color={theme.palette.common.white}>
          <Prolong/>
        </IconHolder>
      );

    case 'success':
      return (
        <IconHolder
          size={36}
          color={theme.palette.common.white}>
          <CheckMarkInNotification/>
        </IconHolder>
      );

    default:
      return false;
    }
  };

  return (
    <Collapse in={open}>
      <Alert
        className={classes.root}
        icon={renderIcon(singleNotification.icon)}
        action={
          <IconButton
            onClick={() => closeNotification(singleNotification.id)}
            color="inherit"
            size="small">
            <IconHolder
              size={18}
              color={setCrossColor(singleNotification.type)}>
              <CrossInCircle/>
            </IconHolder>
          </IconButton>
        }
        severity={singleNotification.type}>
        <Typography variant={'subtitle1'}>
          {ReactHtmlParser(singleNotification.msg)}
        </Typography>
      </Alert>
    </Collapse>
  );
};

export default NotificationTemplate;