import React from 'react';
import RateStar from "../Icons/RateStar";

const Rate = () => {

  return (
    <div style={{height: 16}}>
      <RateStar fullness={'full'}/>
      <RateStar fullness={'full'}/>
      <RateStar fullness={'full'}/>
      <RateStar fullness={'half'}/>
      <RateStar fullness={'empty'}/>
    </div>
  );
};

export default Rate;