//Core
import React from 'react';

//Helpers
import {Offer} from "../../../../bus/offers/typedefs";

//Styles
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import RenderTags from "../../pages/SingleOffer/components/RenderTags";

const useStyles = makeStyles(styles);

type RenderDescriptionProps = {
  data: Offer
}

const RenderDescription: React.FC<RenderDescriptionProps> = ({data}) => {
  const classes = useStyles();

  return (
    <>
      {
        data.description && (
          <div className={classes.description}>
            <Typography variant={'subtitle2'}>
              Description
            </Typography>
            <Typography variant={'body2'}>
              {data.description}
            </Typography>
          </div>
        )
      }
      <RenderTags data={data}/>
    </>
  );
};

export default RenderDescription;