import {useEffect, useState} from "react";
import useWindowDimensions from "../../../../../utils/customHooks/useWindowDimensions";

export const usePreview = () => {
  const {width} = useWindowDimensions();
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    setMobileView(width < 475);
  }, [width]);

  return {
    mobileView
  };
};
