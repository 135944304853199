import {GroupiTheme} from "../../customTheme";
import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  root: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    zIndex: 1,
    background: theme.palette.common.white,
    width: 268,
    minWidth: 268,
    height:  136,
    overflowY: 'auto',
    maxHeight: 136,
    borderTop: '1px solid',
    borderRight: '1px solid',
    paddingTop: 15,
    borderTopColor: theme.palette.common.gray88,
    borderRightColor: theme.palette.common.gray88,
    [breakpoints.down(1200)]: {
      position: 'unset',
      left: 'unset',
      bottom: 'unset',
      overflowY: 'auto',
      overflowX: 'hidden',
      height: 'calc(100% - 73px)',
      width: '100%',
      minWidth: '100%',
      paddingTop: '10px',
      margin: 'auto 0 73px'
    }
  }
});
