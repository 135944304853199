import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';
import {GroupiTheme} from "../../../customTheme";
import {useTheme} from "@material-ui/core";

const Repeat = (props: SvgIconProps) => {
  const theme: GroupiTheme = useTheme();

  return (
    <SvgIcon
      htmlColor={theme.palette.common.blue50}
      {...props}
      fontSize={'inherit'}
      viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00001 3C5.44773 3 5.00001 3.44772 5.00001 4C5.00001 4.55228 5.44773 5 6.00001 5H6.34315C5.40629 5.75711 4.62545 6.69905 4.05348 7.77475C3.1612 9.4529 2.82308 11.3704 3.0876 13.2526C3.35211 15.1347 4.20567 16.8847 5.52595 18.2519C6.84623 19.6191 8.56544 20.5332 10.4372 20.8633L10.7845 18.8937C9.32868 18.637 7.99151 17.926 6.96463 16.8626C5.93775 15.7992 5.27387 14.4381 5.06813 12.9742C4.8624 11.5103 5.12538 10.0189 5.81938 8.7137C6.3433 7.72834 7.09257 6.88729 8.00001 6.25543V7C8.00001 7.55228 8.44773 8 9.00001 8C9.55229 8 10 7.55228 10 7V4V3H9.00001H6.00001ZM16 17.7446C16.9074 17.1127 17.6567 16.2717 18.1806 15.2863C18.8746 13.9811 19.1376 12.4897 18.9319 11.0258C18.7262 9.56192 18.0623 8.20076 17.0354 7.13739C16.0085 6.07402 14.6713 5.36304 13.2155 5.10635L13.5628 3.13673C15.4346 3.46677 17.1538 4.38089 18.4741 5.74808C19.7943 7.11526 20.6479 8.86533 20.9124 10.7474C21.1769 12.6296 20.8388 14.5471 19.9465 16.2252C19.3746 17.301 18.5937 18.2429 17.6569 19H18C18.5523 19 19 19.4477 19 20C19 20.5523 18.5523 21 18 21H15H14V20V17C14 16.4477 14.4477 16 15 16C15.5523 16 16 16.4477 16 17V17.7446Z"
      />
    </SvgIcon>
  );
};

export default Repeat;
