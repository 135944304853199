import React from 'react';
import {Typography} from "@material-ui/core";
import {styles} from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import Star from "../Icons/Star";
import IconHolder from "../IconHolder";

const useStyles = makeStyles(styles);

const RatePart = ({children}: any) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant={'body2'}>
        {children}
      </Typography>
      <div className={classes.starsWrap}>
        <IconHolder size={32}>
          <Star/>
        </IconHolder>
        <IconHolder size={32}>
          <Star/>
        </IconHolder>
        <IconHolder size={32}>
          <Star/>
        </IconHolder>
        <IconHolder size={32}>
          <Star/>
        </IconHolder>
        <IconHolder size={32}>
          <Star/>
        </IconHolder>
      </div>
    </div>
  );
};

export default RatePart;