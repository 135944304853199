import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

const useStyles = makeStyles(styles);

const PhoneMockUp = ({children}: any) => {
  const classes = useStyles();

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.phoneImage}/>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
};

export default PhoneMockUp;