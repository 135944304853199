import {call, put} from "redux-saga/effects";
import {optionsActions} from "../../actions";
import {fetchWithToken} from "../../../utils/fetching";
import {handleErrors} from "../../../../utils/handleErrors";

export function* fetchSettings() {
  try {
    const response = yield call(fetchWithToken, {
      endpoint: 'settings',
      method: 'GET'
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    const {data} = yield call([response, 'json']);

    yield put(optionsActions.fillCountriesOptions(data.countries));
    yield put(optionsActions.fillBusinessTypes(data.business_types));
    yield put(optionsActions.fillUsePeriod(
      data.voucher_usage_period_variants.map((item: any) => {
        return {
          name: item.display_name,
          value: item.days
        };
      })
    ));

  } catch (e) {
    console.log(e);
  }
}
