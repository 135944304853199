import {call} from "@redux-saga/core/effects";
import {Workers} from "../../../../setup/typedefs";
import {handleErrors, handleMsg} from "../../../../utils/handleErrors";
import {fetchWithToken} from "../../../utils/fetching";
import {put} from "redux-saga/effects";
import {uiActions} from "../../../ui/actions";

export function* contactAdmin({payload}: Workers) {
  try {
    yield put(uiActions.loading(true));
    const body = {
      message: payload.describe,
      email: payload.emailAddress,
      subject: payload.subject
    };

    const response = yield call(fetchWithToken, {
      endpoint: `public-handler/contact-us`,
      method: 'POST',
      body
    });

    if (response.status !== 200) {
      yield call(handleErrors, response);
      throw new Error(response);
    }

    yield call(handleMsg, 'You message was delivered');

  } catch (e) {
    console.log(e);
  } finally {
    yield put(uiActions.loading(false));
  }
}
