import {types} from "./types";
import {BusinessType, Countries} from "./typedefs";

export const optionsActions = {
  fillBusinessTypes: (data: BusinessType[]) => {
    return {
      type: types.FILL_BUSINESS_OPTIONS,
      payload: data
    };
  },
  getAllOptions: () => {
    return {
      type: types.GET_ALL_OPTIONS
    };
  },
  fetchSettings: () => {
    return {
      type: types.FETCH_SETTINGS
    };
  },
  fillUsePeriod: (data: any) => {
    return {
      type: types.FILL_USE_PERIOD,
      payload: data
    };
  },
  fetchAllTags: () => {
    return {
      type: types.FETCH_ALL_TAGS
    };
  },
  fillAllTags: (data: string[]) => {
    return {
      type: types.FILL_ALL_TAGS,
      payload: data
    };
  },
  fillCountriesOptions: (data: Countries[]) => {
    return {
      type: types.FILL_COUNTRIES_OPTIONS,
      payload: data
    };
  },
  clear: () => {
    return {
      type: types.CLEAR
    };
  }
};
