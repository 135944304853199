//Core
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";

//Helpers
import {getPlace} from "../../../../bus/business/selectors";
import {getVouchers, getVouchersMeta} from "../../../../bus/vouchers/selectors";
import {vouchersActions} from "../../../../bus/vouchers/actions";
import {ListStatus} from "../../../../setup/typedefs";
import {Voucher} from "../../../../bus/vouchers/typedefs";

export const useVouchersLists = (paginationPath: string, status: ListStatus) => {
  const {page} = useParams<{page: string}>();
  const dispatch = useDispatch();

  const place = useSelector(getPlace);
  const meta = useSelector(getVouchersMeta);
  const vouchers = useSelector(getVouchers);

  const [vouchersList, setVouchersList] = useState<Voucher[]>([]);

  useEffect(() => {
    if (place) {
      dispatch(vouchersActions.fetchVouchers({
        placeID: place.id,
        page: Number(page),
        perPage: 3,
        status: status
      }));
    }
  }, [dispatch, page, place]);

  useEffect(() => {
    vouchers.length
      ? setVouchersList(vouchers)
      : setVouchersList([]);
  }, [vouchers]);

  return {
    list: vouchersList,
    meta
  };
};