//Core
import React from 'react';
import {Field} from "redux-form";

//Styles
import {Box, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

//Components
import RequiredAsterisk from "../../../../components/RequiredAsterisk";
import InputEndTimePicker from "../../../../components/InputDateTimePickers/EndTimePicker";
import InputStartTimePicker from "../../../../components/InputDateTimePickers/StartTimePicker";

const useStyles = makeStyles(styles);

type TimeFieldRowProps = {
  labelText: string,
  isRequired?: boolean,
  onlyStar?: boolean,
  initialValues?: any,
  disabled?: boolean
}

const TimeFieldRow: React.FC<TimeFieldRowProps> = (props) => {
  const classes = useStyles();
  const {
    labelText,
    isRequired = false,
    onlyStar = false,
    disabled
  } = props;

  return (
    <Grid
      item
      xs={12}>
      <Box className={classes.wrapper}>
        <Box className={classes.label}>
          <Typography variant={"subtitle2"}>
            {labelText}
            {isRequired || onlyStar ? <RequiredAsterisk/> : null}
          </Typography>
        </Box>
        <Box className={classes.fields}>
          <Field
            disabled={disabled}
            name={'openedTimeStart'}
            component={InputStartTimePicker}
          />
          <div className={classes.divide}/>
          <Field
            disabled={disabled}
            name={'openedTimeEnd'}
            component={InputEndTimePicker}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default TimeFieldRow;
