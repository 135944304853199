import {GroupiTheme} from "../../customTheme";
import {createMuiTheme, createStyles} from "@material-ui/core";

const defTheme = createMuiTheme({});
const {breakpoints} = defTheme;

export const styles = (theme: GroupiTheme) => createStyles({
  person: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 40,
    [breakpoints.down(600)]: {
      marginRight: 8,
      width: 'calc(100% - 90px)'
    },
    '& p': {
      maxWidth: 360,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }
  },
  avatar: {
    width: 48,
    minWidth: 48,
    height: 48,
    minHeight: 48,
    marginRight: 20,
    borderRadius: '50%',
    background: theme.palette.common.blue57,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    [breakpoints.down(804)]: {
      overflow: 'hidden'
    },
    [breakpoints.down(600)]: {
      marginRight: 8
    }

  }
});