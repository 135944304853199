export const types = {
  GET_ALL_OPTIONS: '@OPTIONS/GET_ALL_OPTIONS',
  FILL_ALL_TAGS: '@OPTIONS/FILL_ALL_TAGS',
  FETCH_ALL_TAGS: '@OPTIONS/FETCH_ALL_TAGS',
  FILL_BUSINESS_OPTIONS: '@OPTIONS/FILL_BUSINESS_OPTIONS',
  FILL_COUNTRIES_OPTIONS: '@OPTIONS/FILL_COUNTRIES_OPTIONS',
  FETCH_SETTINGS: '@OPTIONS/FETCH_SETTINGS',
  FILL_USE_PERIOD: '@OPTIONS/FILL_USE_PERIOD',
  CLEAR: '@OPTIONS/CLEAR'
};
