import { all, call, takeEvery } from 'redux-saga/effects';
import {types} from "../types";
import {updatePersonalData, changePassword, contactAdmin} from "./workers";
import {fetchProfile} from "./workers/fetchProfile";

function * watchUpdatePersonalData() {
  yield takeEvery(types.UPDATE_PERSONAL_DATA, updatePersonalData);
}

function * watchFetchProfile() {
  yield takeEvery(types.FETCH_PROFILE, fetchProfile);
}

function * watchContactAdmin() {
  yield takeEvery(types.CONTACT_ADMIN, contactAdmin);
}

function * watchChangePassword() {
  yield takeEvery(types.CHANGE_PASSWORD, changePassword);
}

export function* watchAccount(){
  yield all([
    call(watchUpdatePersonalData),
    call(watchFetchProfile),
    call(watchContactAdmin),
    call(watchChangePassword)
  ]);
}
