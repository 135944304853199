//Core
import React from 'react';

//Helpers
import {ListStatus} from "../../../../../setup/typedefs";
import {book} from "../../../../../routes/book";

//Components
import ListOfVouchers from "../ListOfVouchers";

const DraftVouchers = () => {
  return (
    <ListOfVouchers
      status={ListStatus.Draft}
      paginationPath={book.draftVouchers}
      title={'Draft'}
    />
  );
};

export default DraftVouchers;
