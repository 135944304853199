//Core
import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import {WrappedFieldProps} from "redux-form";

//Helpers
import "react-datepicker/dist/react-datepicker.css";
import {getDates} from "../../utils/helpers";

//Styles
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";

const useStyles = makeStyles(styles);

interface StartDateInterface {
  disabled?: boolean,
  setStart?: any
}

const InputStartDatePicker: React.FC<WrappedFieldProps & StartDateInterface> = (props) => {
  const classes = useStyles();
  const {today} = getDates();
  const {width} = useWindowDimensions();
  const [portal, setPortal] = useState(false);

  const {
    input,
    setStart,
    disabled = false
  } = props;

  useEffect(() => {
    setPortal(width < 600);
  }, [width]);

  useEffect(() => {
    setStart(input.value);
  }, [input]);

  return (
    <Box className={classes.root}>
      <DatePicker
        {...input}
        withPortal={portal}
        autoComplete={'off'}
        disabled={disabled}
        selectsStart
        minDate={today}
        dateFormat="dd.MM.yyyy"
        selected={new Date(input.value)}
        onBlur={(v: any) => v.defaultPrevented}
        startDate={input.value}
        onChange={(date: Date) => input.onChange(date)}
        endDate={getDates(input.value).ninetyDaysAfterToday}
      />
    </Box>
  );
};

export default InputStartDatePicker;
